<template>
  <v-card width="1220" height="582" class="main-container">
    <v-card-title class="d-flex justify-center mb-5">
      Pembuatan Surat Rujukan
    </v-card-title>
    <v-row class="mb-6">
      <v-col>
        <div class="grid-input">
          <span class="required text-right font-weight-bold"
            >Tujuan Rujukan</span
          >
          <v-radio-group
            class="mt-0 pt-0"
            row
            v-model="input.referralPurpose"
            @change="getReferralPurpose()"
          >
            <v-radio value="specialist" label="Spesialis"> </v-radio>
            <v-radio value="khusus" label="Khusus"> </v-radio>
          </v-radio-group>

          <span class="required text-right font-weight-bold pt-2"
            >Tujuan Rujukan
            {{
              input.referralPurpose == 'khusus' ? 'Khusus' : 'Spesialis'
            }}</span
          >
          <v-combobox
            v-if="input.referralPurpose == 'specialist'"
            height="30"
            placeholder="Pilih Tujuan Rujukan Spesialis"
            single-line
            dense
            append-icon="mdi-chevron-down"
            v-model="input.specialistReferralPurpose"
            outlined
            :loading="loading.specialist"
            :items="resource.specialist"
            item-value="code"
            item-text="name"
            @change="
              getSubSpecialist();
              resetForm();
            "
          >
          </v-combobox>
          <v-combobox
            v-else
            height="30"
            placeholder="Pilih Tujuan Rujukan Khusus"
            single-line
            dense
            append-icon="mdi-chevron-down"
            v-model="input.specificReferralPurpose"
            outlined
            :loading="loading.specialist"
            :items="resource.specific"
            item-value="code"
            item-text="name"
            @change="
              getSubSpecialist();
              resetForm();
            "
          >
          </v-combobox>
          <span
            v-if="subRequired"
            class="required text-right font-weight-bold pt-2"
            >Tujuan Rujukan Subspesialis</span
          >
          <v-combobox
            v-if="subRequired"
            height="30"
            placeholder="Pilih Tujuan Rujukan Subspesialis"
            single-line
            dense
            append-icon="mdi-chevron-down"
            v-model="input.subspecialistReferralPurpose"
            :loading="loading.subSpecialist"
            outlined
            :items="resource.subSpecialist"
            item-value="code"
            item-text="name"
            @change="resetPlan()"
          >
          </v-combobox>
          <span class="required text-right font-weight-bold pt-2"
            >Tanggal Rencana Rujukan</span
          >
          <v-menu
            dense
            ref="menu"
            v-model="menu.plan"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                height="30"
                placeholder="Masukkan Tanggal Rencana Rujukan"
                single-line
                readonly
                dense
                v-bind="attrs"
                v-on="on"
                clearable
                append-icon="mdi-chevron-down"
                v-model="computedDatePlan"
                outlined
              >
              </v-text-field>
            </template>
            <v-date-picker
              @change="getReferralAgency()"
              :min="today"
              :max="oneWeek"
              v-model="input.date.plan"
            ></v-date-picker
          ></v-menu>
          <span class="text-right font-weight-bold pt-2">Sarana</span>
          <v-combobox
            height="30"
            placeholder="Pilih Sarana"
            single-line
            dense
            append-icon="mdi-chevron-down"
            v-model="input.medium"
            outlined
            :items="resource.medium"
            @change="getReferralAgency()"
            item-value="code"
            item-text="name"
          >
          </v-combobox>
        </div>
      </v-col>
      <v-col class="second-column">
        <div class="grid-input">
          <span class="required text-right font-weight-bold pt-2"
            >Nama Instansi Rujukan</span
          >
          <v-combobox
            height="30"
            placeholder="Pilih Nama Instansi Rujukan"
            single-line
            dense
            append-icon="mdi-chevron-down"
            v-model="input.agency"
            outlined
            :items="resource.referralAgency"
            :loading="loading.agency"
            item-text="name"
            @change="getScheduleAgency()"
          >
          </v-combobox>
          <span class="required text-right font-weight-bold pt-2"
            >Jadwal Periksa</span
          >
          <v-combobox
            height="30"
            placeholder="Pilih Jadwal Periksa"
            single-line
            dense
            append-icon="mdi-chevron-down"
            v-model="input.date.schedule"
            outlined
            :items="resource.schedule"
          >
          </v-combobox>
          <!-- <v-menu
            dense
            ref="menu"
            v-model="menu.schedule"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                height="30"
                placeholder="Masukkan Jadwal Periksa"
                single-line
                readonly
                dense
                v-bind="attrs"
                v-on="on"
                clearable
                append-icon="mdi-chevron-down"
                v-model="input.date.schedule"
                outlined
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="input.date.schedule"></v-date-picker
          ></v-menu> -->
          <span class="text-right font-weight-bold pt-2"
            >Terapi yang telah diberikan</span
          >
          <v-textarea
            no-resize
            placeholder="Masukkan Terapi"
            single-line
            dense
            height="100"
            v-model="input.therapy"
            outlined
          >
          </v-textarea>
          <span class="text-right font-weight-bold pt-2">Saran/Anjuran</span>
          <v-textarea
            no-resize
            placeholder="Masukkan saran"
            single-line
            dense
            height="100"
            v-model="input.advice"
            outlined
          >
          </v-textarea>
        </div>
      </v-col>
    </v-row>
    <v-row class="pr-15">
      <v-col class="d-flex justify-end">
        <v-btn
          @click="$emit('close-form')"
          color="primary"
          class="text-capitalze font-weight-bold primary--text"
          outlined
          >Kembali</v-btn
        >
        <v-btn
          @click="$emit('save', input)"
          depressed
          color="primary"
          class="ml-4 white--text font-weight-bold text-capitalize"
          >Simpan</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const moment = require('moment');

export default {
  name: 'ReferralLetter',
  mixins: [AlertMixin],
  props: {
    therapy: {
      type: Array,
      default: null,
    },
    patientData: {
      type: Object,
      default: null,
    },
    letterData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      today: moment().format(),
      oneWeek: moment()
        .add(7, 'days')
        .format(),
      subRequired: false,
      menu: {
        plan: false,
        schedule: false,
      },
      loading: {
        specialist: false,
        subSpecialist: false,
        medium: false,
        agency: false,
      },
      resource: {
        specific: [],
        specialist: [],
        subSpecialist: [],
        medium: [],
        referralAgency: [],
        schedule: [],
      },
      input: {
        referralPurpose: '',
        specialistReferralPurpose: '',
        specificReferralPurpose: '',
        subspecialistReferralPurpose: '',
        date: {
          plan: '',
          schedule: '',
        },
        medium: '',
        agency: '',
        therapy: '',
        advice: '',
      },
    };
  },
  mounted() {
    this.getReferralLetter();
    this.getMedium();
    this.autoFillTherapy();
  },
  computed: {
    computedDatePlan() {
      return this.formatDate(this.input.date.plan);
    },
  },
  methods: {
    autoFillTherapy() {
      if (this.letterData == '') {
        const therapy = this.therapy.filter(v => v != '');
        this.input.therapy = therapy.join(',');
      }
    },
    getReferralLetter() {
      if (!this.letterData) return;
      this.input = this.letterData;
      if (this.input.specialistReferralPurpose) this.getSpecialist();
      if (this.input.subspecialistReferralPurpose) {
        this.subRequired = true;
        this.getSubSpecialist();
      }
      this.getReferralAgency();
    },
    resetForm() {
      this.input.subspecialistReferralPurpose = '';
      this.input.agency = '';
      this.input.medium = '';
      this.input.date = {
        plan: '',
        schedule: '',
      };
      this.loading.subSpecialist = true;
      this.subRequired = true;
    },
    resetPlan() {
      this.input.agency = '';
      this.input.medium = '';
      this.input.date = {
        plan: '',
        schedule: '',
      };
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getReferralPurpose() {
      if (this.input.referralPurpose == 'specialist') {
        this.getSpecialist();
      } else {
        this.getSpecific();
      }
    },
    getSpecialist() {
      this.loading.specialist = true;
      axios
        .get(Constant.apiUrl.concat('/integration/pcare/specialist'))
        .then(response => {
          const data = response.data.data;
          this.resource.specialist = data.list.map(v => {
            return {
              code: v.kdSpesialis,
              name: v.nmSpesialis,
            };
          });
          this.loading.specialist = false;
        })
        .catch(() => {
          this.loading.specialist = false;
        });
    },
    getSpecific() {
      this.loading.specialist = true;
      axios
        .get(Constant.apiUrl.concat('/integration/pcare/specific'))
        .then(response => {
          if (response.status == 200) {
            const data = response.data.data;
            this.resource.specific = data.list.map(v => {
              return {
                code: v.kdKhusus,
                name: v.nmKhusus,
              };
            });
          }
          this.loading.specialist = false;
        })
        .catch(error => {
          this.loading.specialist = false;
          this.showErrorAxios(error);
        });
    },
    getSubSpecialist() {
      if (this.input.referralPurpose == 'specialist') {
        axios
          .get(
            Constant.apiUrl.concat(
              `/integration/pcare/specialist/${this.input.specialistReferralPurpose.code}/subspecialist`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.resource.subSpecialist = data.list.map(v => {
              return {
                code: v.kdSubSpesialis,
                name: v.nmSubSpesialis,
              };
            });
            this.loading.subSpecialist = false;
          })
          .catch(error => {
            if (error.response.status <= 500) {
              Swal.fire({
                title:
                  'Maaf Sistem Sedang Tidak Terhubung dengan BPJS. Silakan Coba Lagi Nanti',
                icon: 'warning',
              });
              return;
            }
            this.loading.subSpecialist = false;
            this.showErrorAxios(error);
          });
      } else if (
        this.input.referralPurpose == 'khusus' &&
        (this.input.specificReferralPurpose.code == 'THA' ||
          this.input.specificReferralPurpose.code == 'HEM')
      ) {
        axios
          .get(Constant.apiUrl.concat(`/master/subspecific`))
          .then(response => {
            const data = response.data.data;
            this.resource.subSpecialist = data.map(v => {
              return {
                code: v.code,
                name: v.description,
              };
            });
            this.loading.subSpecialist = false;
          })
          .catch(() => {
            this.loading.subSpecialist = false;
          });
      } else {
        this.subRequired = false;
      }
    },
    getMedium() {
      this.loading.medium = true;
      axios
        .get(Constant.apiUrl.concat(`/integration/pcare/specialist/medium`))
        .then(response => {
          const data = response.data.data;
          this.resource.medium = data.list.map(v => {
            return {
              code: v.kdSarana,
              name: v.nmSarana,
            };
          });
          this.loading.medium = false;
        })
        .catch(error => {
          this.loading.medium = false;
          this.showErrorAxios(error);
        });
    },
    getReferralAgency() {
      if (!this.letterData) {
        this.input.agency = '';
        this.input.date.schedule = '';
        this.resource.agency = [];
        this.resource.schedule = [];
      }
      if (this.input.referralPurpose == 'khusus') {
        this.loading.agency = true;
        axios
          .get(
            Constant.apiUrl.concat(
              `/integration/pcare/reference/specific/${
                this.input.specificReferralPurpose.code
              }/${this.input.subSpecialist?.code || 0}/${
                this.patientData.meta.guarantorNumber
              }/${this.computedDatePlan}`,
            ),
          )
          .then(response => {
            if (response.status == 200) {
              const data = response.data.data;
              this.resource.referralAgency = data.list.map(v => {
                let schedule = this.generateSchedule(v.jadwal);
                return {
                  code: v.kdppk,
                  name: v.nmppk,
                  schedule: schedule,
                };
              });
            } else {
              Swal.fire(
                'Maaf',
                `Tidak ada jadwal rujukan di ${this.input.specificReferralPurpose.name} pada tanggal ${this.computedDatePlan}`,
                'error',
              );
            }
            this.loading.agency = false;
          })
          .catch(error => {
            this.loading.agency = false;
            this.showErrorAxios(error);
          });
      } else {
        if (
          this.input.subspecialistReferralPurpose.code &&
          this.computedDatePlan
        ) {
          this.loading.agency = true;
          axios
            .get(
              Constant.apiUrl.concat(
                `/integration/pcare/reference/${
                  this.input.subspecialistReferralPurpose.code
                }/${this.input.medium?.code || 0}/${this.computedDatePlan}`,
              ),
            )
            .then(response => {
              if (response.status == 200) {
                const data = response.data.data;
                this.resource.referralAgency = data.list.map(v => {
                  let schedule = this.generateSchedule(v.jadwal);
                  return {
                    code: v.kdppk,
                    name: v.nmppk,
                    schedule: schedule,
                  };
                });
              } else {
                Swal.fire('Jadwal Tidak ditemukan', '', 'error');
              }
              this.loading.agency = false;
            })
            .catch(error => {
              this.loading.agency = false;
              this.showErrorAxios(error);
            });
        }
      }
    },
    getScheduleAgency() {
      this.resource.schedule = this.input.agency.schedule.map(v => {
        return v;
      });
    },
    generateSchedule(schedule) {
      const data = schedule.split(': ');
      const cleanData = data[1].split(/[,;]+/g);
      // const cleanData = data[1].split(', ');
      const dataSchedule = cleanData.map(v => {
        return v.trim();
      });
      return dataSchedule;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  .second-column {
    padding-right: 7rem;
  }
  .grid-input {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    span {
      font-size: 0.859rem;
    }
  }
}
.required:after {
  content: ' *';
  color: red;
}
</style>
