<template>
  <v-card class="pa-8">
    <div class="d-flex justify-space-between">
      <h1>Obat Hampir Kadaluarsa</h1>
      <v-btn text fab @click="$emit('close-form')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <div class="d-flex justify-end">
      <v-col cols="4" class="d-flex">
        <v-autocomplete
          class="mr-8 pt-2 input-filter"
          dense
          flat
          append-icon="mdi-chevron-down"
          placeholder="Unit"
          return-object
          item-text="name"
          auto-select-first
          v-model="unit"
          :items="resource.unit"
          clearable
          single-line
          hide-details
        ></v-autocomplete>
        <v-text-field
          class="input-search mr-3"
          background-color="grey lighten-3"
          placeholder="Cari barang di sini.."
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-col>
    </div>
    <div class="drugs-table">
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :items-per-page="itemsPerRow"
        class="elevation-0"
        :loading="isLoading"
        hide-default-footer
      >
        <template v-slot:no-data>Tidak ada obat hampir kadaluarsa</template>
      </v-data-table>
    </div>
    <div class="d-flex justify-space-between">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.itemPages"
        :total-visible="5"
        @input="getAllStock()"
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
      ></v-pagination>
      <div class="d-flex justify-space-between align-center">
        <div class="total-price">
          <label for="total-price" class="font-weight-bold subtitle-2"
            >Total Persediaan Barang :
            <!-- <span> {{ currency(totalPrice || 0) }}</span> -->
            <v-progress-circular
              v-if="isPriceLoading"
              :size="20"
              :width="2"
              indeterminate
              color="black"
            ></v-progress-circular>
            <span v-else> {{ currency(totalPrice || 0) }}</span>
          </label>
        </div>
        <v-btn text class="mr-5 ml-15" @click="$emit('close-form')"
          >Kembali</v-btn
        >
        <router-link :to="toStockInfo" active class="route">
          <v-btn
            outlined
            color="primary"
            class="text-capitalize font-weight-medium"
            >Informasi Stok</v-btn
          ></router-link
        >
      </div>
    </div>
  </v-card>
</template>

<script>
const axios = require('axios');
import Constant from '@/const';
import moment from 'moment';
import formatMixin from '@/mixin/formatMixin';

const _ = require('lodash');
export default {
  name: 'AlmostExpiredDrugs',
  mixins: [formatMixin],
  data() {
    return {
      search: '',
      unit: '',
      totalPrice: 0,
      isLoading: false,
      isPriceLoading: false,
      resource: {
        unit: [],
      },
      table: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'number',
            width: '2%',
            class: 'pr-0 black--text',
          },
          {
            text: 'Nama Barang',
            align: 'left',
            class: 'pa-0 ma-0 black--text',
            sortable: false,
            value: 'displayName',
            width: '25%',
          },
          {
            text: 'Unit',
            class: 'pa-0 pl-5 ma-0 black--text',
            width: '10%',
            align: 'center',
            sortable: false,
            value: 'unit',
          },
          {
            text: 'Tgl Kadaluarsa',
            class: 'pa-0 ma-0 black--text',
            align: 'center',
            sortable: false,
            value: 'expiry_date',
            width: '11%',
          },
          {
            text: 'Nomor Batch',
            class: 'pa-0 ma-0 black--text',
            align: 'center',
            sortable: false,
            value: 'batch_number',
            width: '8%',
          },
          {
            text: 'Min Stok',
            class: 'pa-0 ma-0 black--text',
            align: 'center',
            sortable: true,
            value: 'stock_min',
            width: '8%',
          },
          {
            text: 'Sisa Stok',
            class: 'pa-0 ma-0 black--text',
            align: 'center',
            sortable: true,
            value: 'stock_remain',
            width: '8%',
          },
          {
            text: 'Harga Jual',
            align: 'right',
            class: 'pa-0 ma-0 pr-3 black--text',
            sortable: false,
            value: 'sell_price',
            width: '10%',
          },
          {
            text: 'Harga Persediaan',
            align: 'right',
            class: 'pa-0 ma-0 black--text pr-3',
            sortable: false,
            value: 'inventory_price',
            width: '10%',
          },
        ],
        items: [],
      },
      pagination: {
        page: 1,
        itemPages: 1,
      },
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
    unit(val) {
      if (!val) {
        this.getAllStock(true, true);
        this.showTotalPrice(true);
      }
      if (val) {
        this.getAllStock(true);
        this.showTotalPrice();
      }
    },
  },
  computed: {
    toStockInfo() {
      return '/inventaris/stok';
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  mounted() {
    this.getAllUnit();
    this.getAllStock();
    this.showTotalPrice();
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllStock();
    }, 650),
    async getAllStock(isFilter = false, isShowAll = false) {
      try {
        this.isLoading = true;
        let id_unit;
        if (isShowAll) {
          id_unit = '';
        } else {
          id_unit = this.unit;
        }
        if (isFilter) {
          this.pagination.page = 1;
        }
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/stock/all?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&page=${this.pagination.page}&search=${this.search}&itemCount=${
              this.itemsPerRow
            }&category=Obat&status_filter=Hampir Kadaluarsa&unit_name=${
              this.unit ? this.unit.id : id_unit
            }`,
          ),
        );
        const { data } = response.data;
        const { length: dataLength } = response.data;
        this.pagination.itemPages = Math.ceil(dataLength / this.itemsPerRow);
        this.table.items = data.stock.map((item, index) => {
          return {
            number: (this.pagination.page - 1) * this.itemsPerRow + index + 1,
            name: item.name,
            unit: item.unit[0].name,
            expiry_date: item.expiry_date
              ? moment(item.expiry_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
              : '-',
            displayName: item.display_name,
            batch_number: item.batch_number,
            stock_min: item.min_stock,
            stock_remain: item.quantity,
            sell_price: this.currency(item.sellprice),
            inventory_price: this.currency(item.hpp),
          };
        });
      } catch (error) {
        this.table.items = [];
      } finally {
        this.isLoading = false;
      }
    },
    async showTotalPrice(isShowAll = false) {
      try {
        this.isPriceLoading = true;
        let id_unit;
        if (isShowAll) {
          id_unit = '';
        } else {
          id_unit = this.$store.getters.userLoggedIn.unit_id;
        }
        const keyword = this.search || '';
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/stock/total?search=${keyword}&category=Obat&status_filter=Hampir Kadaluarsa&unit_name=${
              this.unit ? this.unit.id : id_unit
            }`,
          ),
        );

        this.totalPrice = response.data.data;
      } catch {
        this.totalPrice = 0;
      } finally {
        this.isPriceLoading = false;
      }
    },
    getAllUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.unit = data
            .filter(
              x => x.detail.installation !== 'Administrasi dan Tata Usaha',
            )
            .map(unit => {
              return { name: unit.name, id: unit._id };
            });
        })
        .catch(() => {
          this.resource.unit = [];
        });
    },
  },
};
</script>

<style scoped lang="scss">
.input-search {
  width: 200px;
  min-width: 0px;
}
.total-price {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  background-color: #c4e3f5;
}
@media screen and (max-width: 1903px) {
  .drugs-table {
    min-height: 61vh;
    ::v-deep .v-data-table-header {
      background-color: #ededed;
    }
  }
}
@media screen and (min-width: 1904px) {
  .drugs-table {
    min-height: 68vh;
    ::v-deep .v-data-table-header {
      background-color: #ededed;
    }
  }
}
.route {
  text-decoration: none;
}
</style>
