<template>
  <v-card>
    <v-card-title class="d-flex justify-center black--text headline"
      >Input Tarif Layanan</v-card-title
    >
    <v-container fluid class="container-layout">
      <!-- Tarif Layanan -->
      <v-row>
        <v-col cols="5">
          <v-form ref="form" lazy-validation>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="required font-weight-medium black--text"
                    for="Nama Layanan"
                  >
                    Nama Layanan</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="input.service"
                  placeholder="Masukkan Nama Layanan"
                  :rules="[v => !!v || 'Nama Layanan Harus diisi']"
                  dense
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="required font-weight-medium black--text"
                    for="Unit"
                  >
                    Unit</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-select
                  class="hide-input"
                  v-model="input.unit"
                  :items="resource.unit"
                  :rules="[v => v.length > 0 || 'Unit Harus diisi']"
                  item-text="name"
                  placeholder="Pilih Unit"
                  @change="getSubCategory()"
                  outline
                  dense
                  multiple
                  single-line
                  hide-selected
                  return-object
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="required font-weight-medium black--text"
                    for="Kategori"
                  >
                    Kategori</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="input.category"
                  :items="resource.category"
                  placeholder="Pilih Kategori"
                  dense
                  :rules="[v => !!v || 'Kategori Harus diisi']"
                  clearable
                  @change="getSubCategory()"
                />
              </v-col>
            </v-row>

            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="font-weight-medium black--text"
                    for="Sub Kategori"
                  >
                    Sub Kategori</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  :loading="is_subcategory_loading"
                  :items="resource.sub_category"
                  v-model="input.sub_category"
                  clearable
                  placeholder="Masukkan Nama Layanan"
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col class="input-main text-left" cols="7">
          <v-chip
            v-for="(unit, index) in input.unit"
            :key="unit.id"
            @click:close="input.unit.splice(index, 1)"
            class="ma-2"
            close
          >
            {{ unit.name }}
          </v-chip>
          <v-btn
            class="text-none"
            outlined
            color="error"
            absolute
            left
            bottom
            @click="deleteAllUnit()"
          >
            Hapus semua unit
          </v-btn>
        </v-col>
      </v-row>

      <!-- Tabs -->

      <v-row align="center">
        <v-col cols="12">
          <v-tabs grow center-active v-model="tabs">
            <v-tab>Umum</v-tab>
            <v-tab>BPJS</v-tab>
            <v-tab>Asuransi</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <PatientFee :input="input" :table="table" :tabs="tabs" />
            </v-tab-item>
            <v-tab-item>
              <PatientFee :input="input" :table="table" :tabs="tabs" />
            </v-tab-item>
            <v-tab-item>
              <PatientFee :input="input" :table="table" :tabs="tabs" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined width="100" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn color="primary" width="100" depressed @click="addNewServices()">
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import jwtMixin from '@/mixin/jwtMixin';
import PatientFee from './PatientFee.vue';

const axios = require('axios');

export default {
  name: 'InputNewServices',
  components: {
    PatientFee,
  },
  mixins: [jwtMixin],
  data() {
    return {
      search: null,
      menu: false,
      is_subcategory_loading: false,
      resource: {
        sub_category: [],
        unit: [],
        category: [
          'Administrasi',
          'Konsultasi/ Tindakan Medis',
          'Pemeriksaan Penunjang',
          'Pelayanan Penunjang',
        ],
        room: [],
      },
      input: {
        name: '',
        service: '',
        category: null,
        sub_category: '',
        unit: [],
        totalPrice: 0,
        cost: [
          {
            assurance: 'umum',
            medical_service: {
              staff: 0,
              specialize: 0,
              nurse: 0,
              midwife: 0,
              psychologist: 0,
              nutritionists: 0,
              health_analyst: 0,
              radiographer: 0,
            },
            consumables: {
              detail: '',
              quantity: null,
            },
            medicalTools: {
              detail: '',
              quantity: null,
            },
            unit_load: 0,
            margin: 0,
          },
          {
            assurance: 'bpjs',
            medical_service: {
              staff: 0,
              specialize: 0,
              nurse: 0,
              midwife: 0,
              psychologist: 0,
              nutritionists: 0,
              health_analyst: 0,
              radiographer: 0,
            },
            consumables: {
              detail: '',
              quantity: null,
            },
            medicalTools: {
              detail: '',
              quantity: null,
            },
            unit_load: 0,
            margin: 0,
          },
          {
            assurance: 'asuransi',
            medical_service: {
              staff: 0,
              specialize: 0,
              nurse: 0,
              midwife: 0,
              psychologist: 0,
              nutritionists: 0,
              health_analyst: 0,
              radiographer: 0,
            },
            consumables: {
              detail: '',
              quantity: null,
            },
            medicalTools: {
              detail: '',
              quantity: null,
            },
            unit_load: 0,
            margin: 0,
          },
        ],
        room: '',
      },

      tabs: null,
      table: [
        {
          medicalTools: {
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Alat Medis Habis Pakai', value: 'name' },
              { text: 'Jumlah', value: 'quantity' },
              { text: 'Satuan', value: 'unit' },
              { text: 'Harga', value: 'price' },
              { text: '', value: 'actions', sortable: false },
            ],
            items: [],
          },
          consumables: {
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Bahan Medis Habis Pakai', value: 'name' },
              { text: 'Jumlah', value: 'quantity' },
              { text: 'Satuan', value: 'unit' },
              { text: 'Harga', value: 'price' },
              { text: '', value: 'actions', sortable: false },
            ],
            items: [],
          },
        },
        {
          medicalTools: {
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Alat Medis Habis Pakai', value: 'name' },
              { text: 'Jumlah', value: 'quantity' },
              { text: 'Satuan', value: 'unit' },
              { text: 'Harga', value: 'price' },
              { text: '', value: 'actions', sortable: false },
            ],
            items: [],
          },
          consumables: {
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Bahan Medis Habis Pakai', value: 'name' },
              { text: 'Jumlah', value: 'quantity' },
              { text: 'Satuan', value: 'unit' },
              { text: 'Harga', value: 'price' },
              { text: '', value: 'actions', sortable: false },
            ],
            items: [],
          },
        },
        {
          medicalTools: {
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Alat Medis Habis Pakai', value: 'name' },
              { text: 'Jumlah', value: 'quantity' },
              { text: 'Satuan', value: 'unit' },
              { text: 'Harga', value: 'price' },
              { text: '', value: 'actions', sortable: false },
            ],
            items: [],
          },
          consumables: {
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Bahan Medis Habis Pakai', value: 'name' },
              { text: 'Jumlah', value: 'quantity' },
              { text: 'Satuan', value: 'unit' },
              { text: 'Harga', value: 'price' },
              { text: '', value: 'actions', sortable: false },
            ],
            items: [],
          },
        },
      ],
    };
  },
  computed: {
    isGeneralFormFilled() {
      return (
        this.input.cost[0].medical_service.staff > 1 ||
        this.input.cost[0].medical_service.specialize > 1 ||
        this.input.cost[0].medical_service.nurse > 1 ||
        this.input.cost[0].medical_service.midwife > 1 ||
        this.input.cost[0].medical_service.psychologist > 1 ||
        this.input.cost[0].medical_service.nutritionists > 1 ||
        this.input.cost[0].medical_service.health_analyst > 1 ||
        this.input.cost[0].medical_service.radiographer > 1 ||
        this.input.cost[0].unit_load > 1 ||
        this.input.cost[0].margin > 1 ||
        this.table[0].medicalTools.items.length > 0 ||
        this.table[0].consumables.items.length > 0
      );
    },
    isGeneralReadyToSave() {
      if (!this.isGeneralFormFilled) {
        return true;
      }
      if (
        this.isGeneralFormFilled &&
        (this.input.cost[0].medical_service.staff > 1 ||
          this.input.cost[0].medical_service.specialize > 1 ||
          this.input.cost[0].medical_service.nurse > 1 ||
          this.input.cost[0].medical_service.midwife > 1 ||
          this.input.cost[0].medical_service.psychologist > 1 ||
          this.input.cost[0].medical_service.nutritionists > 1 ||
          this.input.cost[0].medical_service.health_analyst > 1 ||
          this.input.cost[0].medical_service.radiographer > 1 ||
          this.input.cost[0].unit_load > 1 ||
          this.input.cost[0].margin > 1)
      ) {
        return true;
      }
      return false;
    },
    isBpjsFormFilled() {
      return (
        this.input.cost[1].medical_service.staff > 1 ||
        this.input.cost[1].medical_service.specialize > 1 ||
        this.input.cost[1].medical_service.nurse > 1 ||
        this.input.cost[1].medical_service.midwife > 1 ||
        this.input.cost[1].medical_service.psychologist > 1 ||
        this.input.cost[1].medical_service.nutritionists > 1 ||
        this.input.cost[1].medical_service.health_analyst > 1 ||
        this.input.cost[1].medical_service.radiographer > 1 ||
        this.input.cost[1].unit_load > 1 ||
        this.input.cost[1].margin > 1 ||
        this.table[1].medicalTools.items.length > 0 ||
        this.table[1].consumables.items.length > 0
      );
    },
    isBpjsReadyToSave() {
      if (!this.isGeneralFormFilled) {
        return true;
      }
      if (
        this.isGeneralFormFilled &&
        (this.input.cost[1].medical_service.staff > 1 ||
          this.input.cost[1].medical_service.specialize > 1 ||
          this.input.cost[1].medical_service.nurse > 1 ||
          this.input.cost[1].medical_service.midwife > 1 ||
          this.input.cost[1].medical_service.psychologist > 1 ||
          this.input.cost[1].medical_service.nutritionists > 1 ||
          this.input.cost[1].medical_service.health_analyst > 1 ||
          this.input.cost[1].medical_service.radiographer > 1 ||
          this.input.cost[1].unit_load > 1 ||
          this.input.cost[1].margin > 1)
      ) {
        return true;
      }
      return false;
    },
    isAssuranceFormFilled() {
      return (
        this.input.cost[2].medical_service.staff > 1 ||
        this.input.cost[2].medical_service.specialize > 1 ||
        this.input.cost[2].medical_service.nurse > 1 ||
        this.input.cost[2].medical_service.midwife > 1 ||
        this.input.cost[2].medical_service.psychologist > 1 ||
        this.input.cost[2].medical_service.nutritionists > 1 ||
        this.input.cost[2].medical_service.health_analyst > 1 ||
        this.input.cost[2].medical_service.radiographer > 1 ||
        this.input.cost[2].unit_load > 1 ||
        this.input.cost[2].margin > 1 ||
        this.table[2].medicalTools.items.length > 0 ||
        this.table[2].consumables.items.length > 0
      );
    },
    isAssuranceReadyToSave() {
      if (!this.isGeneralFormFilled) {
        return true;
      }
      if (
        this.isGeneralFormFilled &&
        (this.input.cost[2].medical_service.staff > 1 ||
          this.input.cost[2].medical_service.specialize > 1 ||
          this.input.cost[2].medical_service.nurse > 1 ||
          this.input.cost[2].medical_service.midwife > 1 ||
          this.input.cost[2].medical_service.psychologist > 1 ||
          this.input.cost[2].medical_service.nutritionists > 1 ||
          this.input.cost[2].medical_service.health_analyst > 1 ||
          this.input.cost[2].medical_service.radiographer > 1 ||
          this.input.cost[2].unit_load > 1 ||
          this.input.cost[2].margin > 1)
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getUnit();
        this.getRoom();
        this.getSubCategory();
      });
    });
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    updateData() {
      this.$emit('update-data');
    },
    coba(unit) {
      this.selectedUnit.push(unit);
      this.resource.unit = this.resource.unit.filter(v => v.id !== unit.id);
      this.input.unit = '';
    },
    removeUnit(item) {
      this.input.unit.push(item);
      // this.selectedUnit = this.selectedUnit.filter(unit => unit.id !== item.id);
    },
    getSubCategory() {
      this.is_subcategory_loading = true;
      axios
        .get(`${Constant.apiUrl}/master/service`)
        .then(res => {
          const { data } = res.data;
          if (this.input.unit.name && !this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return (
                  i.unit.length > 0 && i.unit[0].name === this.input.unit.name
                );
              })
              .map(item => {
                return item.sub_category;
              });
          } else if (!this.input.unit.name && this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return i.category === this.input.category;
              })
              .map(item => {
                return item.sub_category;
              });
          } else if (this.input.unit.name && this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return (
                  i.category === this.input.category &&
                  i.unit[0].name === this.input.unit.name
                );
              })
              .map(item => {
                return item.sub_category;
              });
          } else {
            this.resource.sub_category = data.map(item => {
              return item.sub_category;
            });
          }
        })
        .catch(() => {
          this.resource.sub_category = [];
        })
        .finally(() => (this.is_subcategory_loading = false));
    },
    getUnit() {
      axios
        .get(`${Constant.apiUrl}/master/unit`)
        .then(response => {
          const unitData = response.data.data;
          this.resource.unit = unitData.map(unit => {
            return {
              id: unit._id,
              name: unit.name,
            };
          });
        })
        .catch(() => {
          this.resource.unit = [];
        });
    },
    addNewServices() {
      // handle when two tabs hasn't filled
      if (this.$refs.form.validate()) {
        if (
          this.isGeneralFormFilled &&
          this.isBpjsFormFilled &&
          !this.isGeneralReadyToSave &&
          !this.isBpjsReadyToSave
        ) {
          Swal.fire(
            'Biaya Layanan Umum dan BPJS Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }
        if (
          !this.isGeneralReadyToSave &&
          !this.isAssuranceReadyToSave &&
          this.isGeneralFormFilled &&
          this.isAssuranceFormFilled
        ) {
          Swal.fire(
            'Biaya Layanan Umum dan Asuransi Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }
        if (
          !this.isAssuranceReadyToSave &&
          !this.isBpjsReadyToSave &&
          this.isAssuranceFormFilled &&
          this.isBpjsFormFilled
        ) {
          Swal.fire(
            'Biaya Layanan BPJS dan Asuransi Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }

        // handle when single tab hasn't filled
        if (this.isGeneralFormFilled && !this.isGeneralReadyToSave) {
          Swal.fire(
            'Biaya Layanan Umum Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }
        if (this.isBpjsFormFilled && !this.isBpjsReadyToSave) {
          Swal.fire(
            'Biaya Layanan BPJS Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }
        if (this.isAssuranceFormFilled && !this.isAssuranceReadyToSave) {
          Swal.fire(
            'Biaya Layanan Asuransi Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }
        // handle when all tabs hasn't filled
        if (
          (!this.isGeneralFormFilled &&
            !this.isAssuranceFormFilled &&
            !this.isBpjsFormFilled) ||
          (!this.isGeneralReadyToSave &&
            !this.isAssuranceReadyToSave &&
            !this.isBpjsReadyToSave)
        ) {
          Swal.fire(
            'Biaya Semua Layanan Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          return;
        }
        const payload = {
          name: this.input.service,
          category: this.input.category,
          sub_category: this.input.sub_category,
          unit: this.input.unit.map(arr => arr.id),
          cost: this.input.cost
            .map((arr, index) => {
              return {
                assurance: arr.assurance,
                medical_service: arr.medical_service,
                consumable_material: this.table[index].consumables.items.map(
                  item => {
                    return {
                      id_drugs: item.id,
                      quantity: item.quantity,
                    };
                  },
                ),
                consumable_tools: this.table[index].medicalTools.items.map(
                  item => {
                    return {
                      id_drugs: item.id,
                      quantity: item.quantity,
                    };
                  },
                ),
                unit_load: arr.unit_load,
                margin: arr.margin,
              };
            })
            .filter(
              x =>
                Object.values(x.medical_service).reduce((a, b) => a + b) !==
                  0 ||
                x.consumable_material.length !== 0 ||
                x.consumable_tools.length !== 0 ||
                x.unit_load !== 0 ||
                x.margin !== 0,
            ),
        };
        axios
          .post(Constant.apiUrl.concat('/master/service/'), payload)
          .then(response => {
            if (response.status === 200) {
              Swal.fire(
                'Berhasil',
                'Data Tarif Layanan Berhasil Ditambahkan',
                'success',
              );
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          })
          .finally(() => {
            this.updateData();
          });
      }
    },
    getRoom() {
      axios
        .get(Constant.apiUrl.concat(`/master/room/`))
        .then(response => {
          const room = response.data.data;
          this.resource.room = room.map(arr => {
            return {
              id: arr._id,
              name: arr.room_name,
            };
          });
        })
        .catch(() => {
          this.resource.room = [];
        });
    },

    deleteAllUnit() {
      this.input.unit = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-main {
  position: relative;
  width: 100%;
  height: 250px;
}

.container-layout {
  height: 82vh;
  overflow: auto;
}

.total {
  border-radius: 8px;
  padding: 20px;
}
.hide-input ::v-deep .v-select__selection.v-select__selection--comma {
  display: none;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
