<template>
  <section class="mb-8">
    <v-row>
      <v-col cols="8" class="pl-15 d-flex justify-space-between mb-5">
        <div
          class="emoticon"
          v-for="(emoti, index) in resource.emoticon"
          :key="'emoticon-' + index"
        >
          <img :src="emoti.src" :alt="emoti.alt" />
          <label>{{ emoti.label }}</label>
        </div>
      </v-col>
      <v-col cols="4"></v-col>
      <v-col cols="8" class="pl-15"
        ><v-slider
          v-model="score"
          :tick-labels="ticksLabels"
          :max="10"
          :readonly="isEmr"
          step="1"
          ticks="always"
          tick-size="5"
        ></v-slider
      ></v-col>
      <v-col cols="4"></v-col>
      <v-col cols="8" class="range pl-15 mb-8">
        <div>
          <div class="range-no-pain"></div>
          <label style="margin-left: -5vw">Tidak Nyeri</label>
        </div>
        <div class="range-container-light">
          <div class="range-light-pain"></div>
          <label>Nyeri Ringan</label>
        </div>
        <div class="range-container-medium">
          <div class="range-medium-pain"></div>
          <label>Nyeri Sedang</label>
        </div>
        <div>
          <div class="range-heavy-pain"></div>
          <label>Nyeri Berat</label>
        </div>
      </v-col>
      <v-row>
        <v-col>
          <div class="grid-display">
            <label :class="!score && 'grey--text'">Faktor Nyeri</label>
            <v-combobox
              :disabled="!score"
              v-model="pain_factor"
              :readonly="isEmr"
              :items="resource.painFactor"
              placeholder="Faktor Nyeri"
              append-icon="mdi-chevron-down"
              multiple
            >
              <template v-slot:prepend-item>
                <p class="grey--text ma-4 text-start body-1">
                  Pilih atau Ketik Untuk Pilihan Lainnya
                </p>
              </template>
            </v-combobox>
            <label :class="!score && 'grey--text'">Rasa Nyeri Seperti</label>
            <v-combobox
              :readonly="isEmr"
              v-model="pain_like"
              :items="resource.painLike"
              :disabled="!score"
              placeholder="Rasa Nyeri Seperti"
              append-icon="mdi-chevron-down"
              multiple
            >
              <template v-slot:prepend-item>
                <p class="grey--text ma-4 text-start body-1">
                  Pilih atau Ketik Untuk Pilihan Lainnya
                </p>
              </template>
            </v-combobox>
            <label :class="!score && 'grey--text'" class="mb-4"
              >Nyeri Menjalar ke Bagian Tubuh Lain</label
            >
            <v-radio-group
              :disabled="!score"
              hide-details
              :readonly="isEmr"
              row
              v-model="pain_radiation"
            >
              <v-radio label="Tidak" :value="false"></v-radio>
              <v-radio label="Ya" :value="true"></v-radio>
            </v-radio-group>
            <label :class="!score && 'grey--text'" class="pt-3"
              >Tingkat Keparahan</label
            >
            <v-radio-group
              :disabled="!score"
              hide-details
              :readonly="isEmr"
              row
              v-model="severity"
            >
              <v-radio label="Tidak Nyeri" :value="0"></v-radio>
              <v-radio label="Ringan" :value="1"></v-radio>
              <v-radio label="Sedang" :value="2"></v-radio>
              <v-radio label="Berat" :value="3"></v-radio>
            </v-radio-group>
          </div>
        </v-col>
        <v-col>
          <div class="grid-display">
            <label :class="!score && 'grey--text'" class="pt-3 mb-4"
              >Frekuensi Nyeri</label
            >
            <v-radio-group
              :disabled="!score"
              :readonly="isEmr"
              hide-details
              row
              v-model="pain_frequency"
            >
              <v-radio label="Jarang" :value="0"></v-radio>
              <v-radio label="Terus Menerus" :value="1"></v-radio>
              <v-radio label="Hilang Timbul" :value="2"></v-radio>
            </v-radio-group>
            <label :class="!score && 'grey--text'" class="pt-3 mb-4"
              >Durasi Nyeri</label
            >
            <v-radio-group
              :readonly="isEmr"
              :disabled="!score"
              hide-details
              row
              v-model="pain_duration"
            >
              <v-radio label="<30 menit" :value="0"></v-radio>
              <v-radio label=">30 menit" :value="1"></v-radio>
            </v-radio-group>
            <label :class="!score && 'grey--text'">Deskripsi</label>
            <v-textarea
              :readonly="isEmr"
              :disabled="!score"
              no-resize
              height="150"
              placeholder="Masukkan deskripsi"
              v-model="description"
              outlined
            >
            </v-textarea>
          </div>
        </v-col>
      </v-row>
    </v-row>
  </section>
</template>

<script>
import HappyFace from '@/assets/image/HappyFace.svg';
import SmillingFace from '@/assets/image/SmilingFace.svg';
import NoExpressionFace from '@/assets/image/NoExpressionFace.svg';
import FrowningFace from '@/assets/image/FrowningFace.svg';
import WorriedFace from '@/assets/image/WorriedFace.svg';
import Cryingface from '@/assets/image/CryingFace.svg';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('PainAssessment');
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);

export default {
  name: 'PainAssessment',
  data() {
    return {
      ticksLabels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      pain: 0,
      resource: {
        emoticon: [
          {
            src: HappyFace,
            alt: 'Happy Face',
            label: 'Tidak Nyeri',
          },
          {
            src: SmillingFace,
            alt: 'Smiling Face',
            label: 'Agak Nyeri',
          },
          {
            src: NoExpressionFace,
            alt: 'No Expression Face',
            label: 'Nyeri Sedikit',
          },
          {
            src: FrowningFace,
            alt: 'Frowning Face',
            label: 'Nyeri',
          },
          {
            src: WorriedFace,
            alt: 'Worried Face',
            label: 'Lebih Nyeri',
          },
          {
            src: Cryingface,
            alt: 'Crying Face',
            label: 'Sangat Nyeri',
          },
        ],
        painFactor: ['Cahaya', 'Gelap', 'Gerakan', 'Berbaring'],
        painLike: [
          'Ditusuk',
          'Dipukul',
          'Berdenyut',
          'Ditikam',
          'Kram',
          'Ditarik',
          'Dibakar',
          'Tajam',
        ],
      },
    };
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getScore',
      'getPainFactor',
      'getPainLike',
      'getPainRadiation',
      'getSeverity',
      'getPainFrequency',
      'getPainDuration',
      'getDescription',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    score: {
      get() {
        return this.getScore;
      },
      set(value) {
        this.setScore(value);
      },
    },
    pain_factor: {
      get() {
        return this.getPainFactor;
      },
      set(value) {
        this.setPainFactor(value);
      },
    },
    pain_like: {
      get() {
        return this.getPainLike;
      },
      set(value) {
        this.setPainLike(value);
      },
    },
    pain_radiation: {
      get() {
        return this.getPainRadiation;
      },
      set(value) {
        this.setPainRadiation(value);
      },
    },
    severity: {
      get() {
        return this.getSeverity;
      },
      set(value) {
        this.setSeverity(value);
      },
    },
    pain_frequency: {
      get() {
        return this.getPainFrequency;
      },
      set(value) {
        this.setPainFrequency(value);
      },
    },
    pain_duration: {
      get() {
        return this.getPainDuration;
      },
      set(value) {
        this.setPainDuration(value);
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(value) {
        this.setDescription(value);
      },
    },
  },
  methods: {
    ...mapMutations([
      'clearPainAssessmentState',
      'setScore',
      'setPainFactor',
      'setPainLike',
      'setPainRadiation',
      'setSeverity',
      'setPainFrequency',
      'setPainDuration',
      'setDescription',
    ]),
  },
  beforeDestroy() {
    this.clearPainAssessmentState();
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
.emoticon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8dvh;

  label {
    font-size: 1vw;
  }
}

.range {
  display: flex;
  font-size: 1vw;

  #{&}-container-light {
    margin-right: 9%;
  }

  #{&}-container-medium {
    margin-right: 9%;
  }

  @media screen and (min-width: 1904px) {
    #{&}-container-light {
      margin-right: 10%;
    }
    #{&}-container-medium {
      margin-right: 9.5%;
    }
  }

  #{&}-no-pain {
    width: 5.2vw;
    margin-bottom: 1.5vh;
    margin-right: 1vw;
    height: 2vh;
    border: 2.5px solid #fff;
    border-top: 0px;
  }

  #{&}-light-pain {
    width: 12vw;
    margin-bottom: 1.5vh;
    height: 2vh;
    border: 2px solid #137bc0;
    border-top: 0px;
  }

  #{&}-medium-pain {
    width: 12.3vw;
    margin-bottom: 1.5vh;
    height: 2vh;
    border: 2px solid #137bc0;
    border-top: 0px;
  }

  #{&}-heavy-pain {
    width: 18vw;
    margin-bottom: 1.5vh;
    height: 2vh;
    border: 2px solid #137bc0;
    border-top: 0px;
  }
}

img {
  width: 3.2vw;
}
</style>
