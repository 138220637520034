<template>
  <div class="inventory">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col style="display: grid" class="text-left">
            <v-toolbar-title class="font-weight-bold float-left">
              Penerimaan Barang
            </v-toolbar-title>
            <div class="caption grey--text float-left">
              Penerimaan barang dari supplier
            </div>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn @click="filterBtn()" text>
            <span class="text-capitalize">Filters</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text @click="refresh()">
            <v-icon :class="{ refresh: isRefresh }">mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="table.search"
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <div class="filter-menu mt-4" v-if="filter">
        <v-row no-gutters align="center" justify="center">
          <v-menu
            dense
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="filterDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                class="input mr-4"
                label="Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="false"
                v-model="date"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @change="
                $refs.menu.save(date);
                getAllItem();
              "
            ></v-date-picker>
          </v-menu>

          <v-select
            class="input"
            item-text="name"
            item-value="id"
            :items="supplierName"
            dense
            return-object
            v-model="filterSupplier"
            label="Nama Supplier"
            outlined
            hide-details="false"
            clearable
            @change="getAllItem"
          ></v-select>
        </v-row>
      </div>

      <v-container fluid>
        <v-col class="pa-0">
          <v-data-table
            class="flex-grow-1"
            :headers="table.headers"
            :items="table.dataItem"
            :items-per-page="itemsPerRow"
            hide-default-footer
            :loading="isLoading"
          >
            <template v-slot:item="{ item, index }">
              <tr class="row-datatable" :key="index">
                <td class="text-sm-left">
                  {{ (table.pagination.page - 1) * itemsPerRow + index + 1 }}
                </td>
                <td class="text-sm-left">
                  {{ item.receiptDate }}
                </td>
                <td class="text-sm-left">
                  {{ item.dueDate }}
                </td>
                <td class="text-sm-left">
                  {{ item.invoiceNumber }}
                </td>
                <td class="text-sm-left">
                  {{ item.supplierName }}
                </td>
                <td class="text-sm-left text-capitalize">
                  {{ item.paymentMethod }}
                </td>
                <td class="text-sm-left">{{ item.user }}</td>
                <td class="text-sm-left" v-if="!hasRoles().isSuperManager">
                  <v-icon small class="mr-2" @click="onClickFooter(1, item)">
                    mdi-dots-vertical
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-container>
    </v-main>

    <v-footer color="white" class="py-0">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="itemPages"
            total-visible="7"
            @input="refreshAndAutoUpdate()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1200px" fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!hasRoles().isSuperManager"
                  color="primary"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="onClickFooter(0, '')"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <InputItemReceipt
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <DetailItemReceipt
                v-if="selectedForm == 1 && dialog"
                :selected-data="selectedData"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputItemReceipt from './ItemReceiptComponent/InputItemReceipt';
import DetailItemReceipt from './ItemReceiptComponent/DetailItemReceipt';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import moment from 'moment-timezone';
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'ItemReceipt',
  mixins: [jwtMixin, roleMixin],
  components: {
    InputItemReceipt,
    DetailItemReceipt,
  },
  data() {
    return {
      isLoading: false,
      menu: false,
      filterDate: '',
      date: '',
      filter: false,
      dialog: false,
      filterSupplier: '',
      isRefresh: false,
      supplierName: [],
      editedItem: {
        noQueue: 0,
        billDate: '',
        sp: '',
        supplierName: '',
        user: '',
      },
      itemPages: 0,
      show: false,
      table: {
        search: '',
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
        dataItem: [],
        headers: [
          { text: 'No.', value: 'noQueue' },
          { text: 'Tanggal penerimaan', value: 'receiptDate' },
          { text: 'Tanggal Tempo', value: 'dueDate' },
          { text: 'Nomor Faktur', value: 'invoiceNumber' },
          { text: 'Nama Supplier', value: 'supplierName' },
          { text: 'Metode Pembayaran', value: 'paymentMethod' },
          { text: 'Petugas Penerima', value: 'user' },
          { text: '', value: 'actions', sortable: false },
        ],
      },
      selectedForm: null,
      selectedData: null,
    };
  },

  watch: {
    'table.search'() {
      this.searchData(this);
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null;
      }
    },
    date(val) {
      if (val) {
        this.dateFormat = this.formatDate(this.date);
      } else if (val === null) {
        this.getAllItem();
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getSupplier();
    });
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 250),
    filterBtn() {
      if (this.filter === false) {
        this.filter = true;
      } else {
        this.filter = false;
        (this.filterName = ''), (this.dateFormat = '');
        this.date = '';
      }
      this.getAllItem();
    },
    refresh() {
      this.isRefresh = true;
      this.getAllItem();
    },
    getSupplier() {
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/supplier')).then(response => {
          const getSupplier = response.data.data;
          this.supplierName = getSupplier.map(supplier => {
            return {
              id: supplier._id,
              name: supplier.name,
            };
          });
        });
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    onClickFooter(index, receipt) {
      this.changeSelectedItem(receipt);

      switch (index) {
        default:
          this.changeForm(index);
          break;
      }
      this.dialog = true;
    },

    changeSelectedItem(receipt) {
      this.selectedData = receipt;
    },
    changeForm(item) {
      this.selectedForm = item;
    },

    closeForm() {
      this.dialog = false;
      this.getAllItem();
    },

    getAllItem() {
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      const supplier = this.filterSupplier ? this.filterSupplier.name : '';
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/supply/itemlist?page=${
                this.table.pagination.page
              }&itemCount=${itemCount}&search=${this.table.search ||
                ''}&date=${this.date || ''}&supplier_name=${supplier}`,
            ),
          )
          .then(response => {
            if (response.data.data.length === 0) {
              this.isLoading = false;
              this.table.dataItem = [];
            }
            const { data } = response.data;
            const { length: dataLength } = response.data;
            this.itemPages = Math.ceil(dataLength / 5);
            this.table.dataItem = data
              .sort(
                (a, b) =>
                  moment(b.timestamps.created_at) -
                  moment(a.timestamps.created_at),
              )
              .map(dataItem => {
                return {
                  id: dataItem._id.toString(),
                  dueDate: moment()
                    .add(dataItem.bill_due, 'days')
                    .format('DD/MM/YYYY'),
                  receiptDate: moment(dataItem.timestamps.created_at).format(
                    'DD/MM/YYYY',
                  ),
                  invoiceNumber: dataItem.invoice_number,
                  paymentMethod: dataItem.payment_type,
                  pendingReason: dataItem.pending_reason,
                  supplierName: dataItem.supplier
                    ? dataItem.supplier.name
                    : '-',
                  idSupplier: dataItem.id_supplier ? dataItem.id_supplier : '-',
                  user: dataItem.staff_name,
                  deliveryFee: dataItem.cost ? dataItem.cost.shipping_cost : '',
                  total: dataItem.cost ? dataItem.cost.total_cost : '',
                  stampCost: dataItem.cost ? dataItem.cost.stamp_cost : '',
                  receiptNumber: dataItem.receive_number,
                  item: dataItem.item,
                  orderDate: dataItem.supply_request
                    ? moment(dataItem.supply_request.order_date).format(
                        'DD/MM/YYYY',
                      )
                    : '-',
                  orderNumber: dataItem.supply_request
                    ? dataItem.supply_request.order_number
                    : '-',
                };
              });
          })
          .catch(() => {
            this.table.dataItem = [];
            this.itemPages = 0;
          })
          .finally(() => {
            this.isLoading = false;
            this.isRefresh = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllItem();
      this.interval = setInterval(this.getAllItem, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.input {
  max-width: 30%;
}
</style>
