<template>
  <section>
    <v-card-title class="title font-weight-bold primary--text text-left">
      Assesmen Risiko Jatuh
    </v-card-title>
    <v-row v-if="!isAdult" class="mb-3">
      <v-col cols="6">
        <v-row no-gutters align="baseline" class="grid-display">
          <label>Usia</label>
          <p class="text-start">{{ kidAge.text }}</p>
          <label>Diagnosis</label>
          <v-combobox
            dense
            :readonly="isEmr"
            placeholder="Diagnosa"
            hide-details
            append-icon="mdi-chevron-down"
            return-object
            item-text="text"
            :items="resource.DiagnoseFallRisk"
            v-model="diagnose"
            class="mb-5"
          />
          <label>Gangguan Kognitif</label>
          <v-combobox
            dense
            :readonly="isEmr"
            :clearable="!emrData"
            placeholder="Gangguan Kognitif"
            return-object
            item-text="text"
            class="mb-5"
            append-icon="mdi-chevron-down"
            hide-details
            v-model="cogntiveImpairment"
            :items="resource.cogntiveImpairment"
          />
          <label>Pembedahan/Sedasi/Anestesi</label>
          <v-combobox
            dense
            :clearable="!emrData"
            :readonly="isEmr"
            placeholder="Pembedahan/Sedasi/Anestesi"
            append-icon="mdi-chevron-down"
            return-object
            item-text="text"
            hide-details
            v-model="surgery"
            :items="resource.surgery"
          />
        </v-row>
      </v-col>
      <v-col cols="6" class="grid-display">
        <label>Penggunaan Medikamentosa</label>
        <v-combobox
          dense
          :clearable="!emrData"
          placeholder="Penggunaan Medikamentosa"
          :readonly="isEmr"
          append-icon="mdi-chevron-down"
          return-object
          item-text="text"
          hide-details
          v-model="medicamentosa"
          :items="resource.medicamentosa"
        />
        <h4 class="ml-5 text-start font-weight-bold primary--text">
          Total Skor :
        </h4>
        <h4 class="text-start font-weight-bold primary--text">
          {{ fallRiskAssesmentResult.score }}
        </h4>
        <h4 class="ml-5 text-start font-weight-bold primary--text">
          Hasil :
        </h4>
        <div :class="`chip-${fallRiskAssesmentResult?.color}`">
          {{ fallRiskAssesmentResult?.text }}
        </div>
      </v-col>
    </v-row>
    <v-row v-else class="mb-3">
      <v-col cols="6">
        <v-row no-gutters align="baseline" class="grid-display">
          <label>Riwayat Jatuh (Baru saja atau dalam 3 bulan)</label>
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="fallHistory"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 25 }"></v-radio>
          </v-radio-group>
          <label>Diagnosis Sekunder</label>
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="secDiagnose"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 25 }"></v-radio>
          </v-radio-group>
          <label>Alat Bantu Jalan</label>
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="walker"
          >
            <v-radio
              label="Normal"
              :value="{ check: 'normal', score: 0 }"
            ></v-radio>
            <v-radio
              label="Penopang"
              :value="{ check: 'crutch', score: 15 }"
            ></v-radio>
            <v-radio
              label="Furnitur"
              :value="{ check: 'furniture', score: 30 }"
            ></v-radio>
          </v-radio-group>
          <label>Memakai Terapi Heparin Lock/IV</label>
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="heparinLockTherapy"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 20 }"></v-radio>
          </v-radio-group>
        </v-row>
      </v-col>
      <v-col cols="6" class="grid-display">
        <label>Cara Berjalan</label>
        <v-radio-group
          :disabled="isEmr"
          dense
          hide-details
          row
          class="my-0"
          v-model="walk"
        >
          <v-radio
            label="Normal"
            :value="{ check: 'normal', score: 0 }"
          ></v-radio>
          <v-radio
            label="Lemah"
            :value="{ check: 'weak', score: 10 }"
          ></v-radio>
          <v-radio
            label="Terganggu"
            :value="{ check: 'disturbed', score: 20 }"
          ></v-radio>
        </v-radio-group>
        <label>Status Mental</label>
        <v-radio-group
          :disabled="isEmr"
          dense
          hide-details
          row
          class="my-0"
          v-model="mentalStatus"
        >
          <v-radio
            label="Sesuai Kemampuan"
            :value="{ check: 'accordingToAbility', score: 0 }"
          ></v-radio>
          <v-radio
            label="Lupa"
            :value="{ check: 'forget', score: 20 }"
          ></v-radio>
        </v-radio-group>
        <h4 class="ml-5 text-start font-weight-bold primary--text">
          Total Skor :
        </h4>
        <h4 class="text-start font-weight-bold primary--text">
          {{ fallRiskAssesmentResult?.score }}
        </h4>
        <h4 class="ml-5 text-start font-weight-bold primary--text">
          Hasil :
        </h4>
        <div :class="`chip-${fallRiskAssesmentResult?.color}`">
          {{ fallRiskAssesmentResult?.text }}
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters, mapMutations } = createNamespacedHelpers('FallRisk');

export default {
  name: 'FallRisk',
  props: {
    isAdult: Boolean,
    kidAge: Object,
    patientData: Object,
  },
  data() {
    return {
      resource: {
        medicamentosa: [
          {
            text:
              'Penggunaan Multiple Sedative, Obat Hypnosis, Barbiturate, Fenotiazi, Antidepressan, Pencahar, Diuretic, Narkose',
            score: 3,
            value: 'useOfMultipleSedative',
          },
          {
            text: 'Penggunaan Obat Salah Satu di Atas',
            score: 2,
            value: 'useOfAnyOfAboveDrug',
          },
          {
            text: 'Penggunaan Medikasi Lainnya / Tidak Ada Medikasi',
            score: 1,
            value: 'useOfOtherMedication',
          },
        ],
        surgery: [
          {
            text: 'Dalam 24 Jam',
            value: 'oneDay',
            score: 3,
          },
          {
            text: 'Dalam 48 Jam',
            value: 'twoDay',
            score: 2,
          },
          {
            text: '>48 jam dan Tidak Mengalami Pembedahan/Sedasi/Anestesi',
            value: 'aboveTwoDay',
            score: 1,
          },
        ],
        cogntiveImpairment: [
          {
            text: 'Tidak Menyadari Keterbatasan Lainnya',
            score: 3,
            value: 'notAwareOfOtherLimitation',
          },
          {
            text: 'Lupa Akan Adannya Keterbatasan',
            score: 2,
            value: 'forgetAboutLimitations',
          },
          {
            text: 'Orientasi Baik Terhadap Diri Sendiri',
            score: 1,
            value: 'goodSelfOriented',
          },
        ],
        DiagnoseFallRisk: [
          {
            text: 'Diagnosis Neurologi',
            score: 4,
            value: 'neurologi',
          },
          {
            text:
              'Perubahan Okisgenasi (diagnosis respiratorik, dehidrasi, anemia, anoreksia, sinkop, dll)',
            score: 3,
            value: 'oxygenationChange',
          },
          {
            text: 'Gangguan Perilaku/Psikiatri',
            score: 2,
            value: 'behaviorDisorder',
          },
          {
            text: 'Diagnosis Lainnya',
            score: 1,
            value: 'other',
          },
        ],
      },
    };
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getSurgery',
      'getDiagnose',
      'getCogntiveImpairment',
      'getMedicamentosa',
      'getHeparinLockTherapy',
      'getMentalStatus',
      'getFallHistory',
      'getSecDiagnose',
      'getWalker',
      'getWalk',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    surgery: {
      get() {
        if (this.getSurgery.text) {
          return this.getSurgery;
        } else {
          return this.resource.surgery.filter(
            i => i.score === this.getSurgery.score,
          )[0];
        }
      },
      set(value) {
        this.setSurgery(value);
      },
    },
    diagnose: {
      get() {
        if (this.getDiagnose.text) {
          return this.getDiagnose;
        } else {
          return this.resource.DiagnoseFallRisk.filter(
            i => i.score === this.getDiagnose.score,
          )[0];
        }
      },
      set(value) {
        this.setDiagnose(value);
      },
    },
    cogntiveImpairment: {
      get() {
        if (this.getCogntiveImpairment.text) {
          return this.getCogntiveImpairment;
        } else {
          return this.resource.cogntiveImpairment.filter(
            i => i.score === this.getCogntiveImpairment.score,
          )[0];
        }
      },
      set(value) {
        this.setCogntiveImpairment(value);
      },
    },
    medicamentosa: {
      get() {
        if (this.getMedicamentosa.text) {
          return this.getMedicamentosa;
        } else {
          return this.resource.medicamentosa.filter(
            i => i.score === this.getMedicamentosa.score,
          )[0];
        }
      },
      set(value) {
        this.setMedicamentosa(value);
      },
    },
    heparinLockTherapy: {
      get() {
        return this.getHeparinLockTherapy;
      },
      set(value) {
        this.setHeparinLockTherapy(value);
      },
    },
    mentalStatus: {
      get() {
        return this.getMentalStatus;
      },
      set(value) {
        this.setMentalStatus(value);
      },
    },
    fallHistory: {
      get() {
        return this.getFallHistory;
      },
      set(value) {
        this.setFallHistory(value);
      },
    },
    secDiagnose: {
      get() {
        return this.getSecDiagnose;
      },
      set(value) {
        this.setSecDiagnose(value);
      },
    },
    walker: {
      get() {
        return this.getWalker;
      },
      set(value) {
        this.setWalker(value);
      },
    },
    walk: {
      get() {
        return this.getWalk;
      },
      set(value) {
        this.setWalk(value);
      },
    },
    fallRiskAssesmentResult() {
      let score, result;
      if (this.isAdult) {
        score =
          (this.fallHistory?.score || 0) +
          (this.secDiagnose?.score || 0) +
          (this.walker?.score || 0) +
          (this.heparinLockTherapy?.score || 0) +
          (this.walk?.score || 0) +
          (this.mentalStatus?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 24) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
            score,
          };
        }
        if (score > 24 && score <= 50) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
            score,
          };
        }
        if (score > 50) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (this.patientData.meta.gender === 'Laki-laki' ? 2 : 1) +
          this.kidAge?.score +
          (this.diagnose?.score || 0) +
          (this.cogntiveImpairment?.score || 0) +
          (this.surgery?.score || 0) +
          (this.medicamentosa?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score < 7) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
            score,
          };
        }
        if (score >= 7 && score <= 11) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
            score,
          };
        }
        if (score > 11) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
            score,
          };
        }
      }
      return result;
    },
  },
  beforeDestroy() {
    this.clearFallRiskState();
  },
  methods: {
    ...mapMutations([
      'clearFallRiskState',
      'setSurgery',
      'setDiagnose',
      'setCogntiveImpairment',
      'setMedicamentosa',
      'setHeparinLockTherapy',
      'setMentalStatus',
      'setFallHistory',
      'setSecDiagnose',
      'setWalker',
      'setWalk',
    ]),
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  border-radius: 6px;
  color: $outline;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  height: max-content;
  font-size: calc($standard-font-size - 0.1vw);
}

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.chip-green {
  @include result-chip(#e5fff0, #2d965a);
}
.chip-yellow {
  @include result-chip(#fff9e5, #e5a82e);
}
.chip-red {
  @include result-chip(#fff2f2, #eb4747);
}
.chip-grey {
  @include result-chip(#f6f6f6, #928f8f);
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

.title {
  font-size: 1.4rem;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
