<template>
  <v-card class="pa-3">
    <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
      <v-col>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Nomor Surat</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ numberLetter }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Nama Pasien</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.name }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">No RM</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.rmNumber }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Jenis Kelamin</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.gender }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Tanggal Lahir</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.birthDate }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Alamat</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.address }}</label>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Dokter Pemeriksa</label>
          </v-col>
          <v-col cols="6" class="">
            <v-combobox
              :rules="[v => !!v || 'Wajib diisi']"
              dense
              class="pt-0 mt-0"
              placeholder="Pilih Dokter"
              :clearable="!hasRoles().isSuperManager"
              auto-select-first
              :items="resource.doctor"
              item-text="doctorName"
              return-object
              v-model="input.doctor"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tanggal mulai izin sakit</label>
          </v-col>
          <v-col cols="6" class="text-left">
            <v-menu
              ref="menuDate"
              v-if="!isSuperManager"
              v-model="menuDate"
              :close-on-content-click="false"
              :return-value.sync="input.date_start"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="input.date_start"
                  placeholder="Pilih tanggal"
                  append-icon="mdi-chevron-down"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details="false"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="input.date_start"
                no-title
                scrollable
                @input="
                  $refs.menuDate.save(input.date_start);
                  menuDate = false;
                "
              />
            </v-menu>
            <v-text-field
              v-else
              readonly
              v-model="input.date_start"
              placeholder="Pilih tanggal"
              append-icon="mdi-chevron-down"
              v-bind="attrs"
              v-on="on"
              dense
              hide-details="false"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tanggal selesai izin sakit</label>
          </v-col>
          <v-col cols="6" class="text-left">
            <v-menu
              v-if="!isSuperManager"
              ref="last"
              v-model="last"
              :close-on-content-click="false"
              :return-value.sync="input.date_end"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="input.date_end"
                  placeholder="Pilih tanggal"
                  append-icon="mdi-chevron-down"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details="false"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="input.date_end"
                :min="input.date_start"
                no-title
                scrollable
                @input="
                  $refs.last.save(input.date_end);
                  last = false;
                "
              />
            </v-menu>
            <v-text-field
              v-else
              readonly
              v-model="input.date_end"
              placeholder="Pilih tanggal"
              append-icon="mdi-chevron-down"
              v-bind="attrs"
              v-on="on"
              dense
              hide-details="false"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-btn class="text-none" plain @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        class="text-none"
        width="116"
        :disabled="disabled"
        @click="printSickLetter()"
      >
        <font-awesome-icon icon="print" />
        Print
      </v-btn>
      <v-btn
        v-if="!hasRoles().isSuperManager"
        color="primary"
        tile
        elevation="0"
        class="text-none"
        width="108"
        @click="addSickLetter()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import 'jspdf-autotable';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';
import Letter from '@/mixin/Letter';
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'SickLetter',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter, roleMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      last: false,
      doctorSip: '',
      menuDate: false,
      resource: {
        doctor: [],
      },
      input: {
        date_start: new Date().toISOString().substr(0, 10),
        date_end: new Date().toISOString().substr(0, 10),
        doctor: '',
      },
      timestamps: new Date(),
      numberLetter: '',
      disabled: false,
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getSickLetter();
        this.getDoctor();
      });
    });
  },
  methods: {
    getDoctor() {
      axios
        .get(Constant.apiUrl.concat('/master/staff'))
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data
              .filter(
                v => v.role.role.toLowerCase() === 'dokter' && v.role.status,
              )
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                };
              });

            // this.getOutpatientLetter();
          }
        })
        .catch(() => {
          this.resource.doctor = [];
        });
    },
    addSickLetter() {
      axios
        .post(Constant.apiUrl.concat('/letter/sick-patient-letter'), {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: {
            ...this.input,
            doctor: this.input.doctor.doctorId,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        })
        .then(() => {
          Swal.fire('Surat Keterangan Sakit Berhasil Dibuat', '', 'success');
          this.getSickLetter();

          // this.$emit('close-form');
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    printSickLetter() {
      const doc = this.generateSickLetter(this.patientData, this.input);
      this.printDocument(doc);
    },
    generateNumber() {
      axios
        .get(Constant.apiUrl.concat('/letter/sick-patient-letter/generate'))
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    getSickLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/sick-patient-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          this.disabled = false;
          const sick = response.data.data;
          this.numberLetter = sick.letter_number;
          this.input = {
            date_start: _.get(sick, 'detail.date_start', ''),
            date_end: _.get(sick, 'detail.date_end', ''),
            doctor: {
              doctorId: sick.detail.doctor._id,
              doctorName: sick.detail.doctor.detail[0].name,
            },
          };
          this.doctorSip = sick.detail.doctor.detail[0].sip;
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.disabled = true;
            this.input.doctor = {
              doctorId: this.patientData.meta.doctorId,
              doctorName: this.patientData.meta.doctor,
            };
            this.generateNumber();
          } else this.showErrorAxios(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  padding-left: 150px;
}
.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 20px;
  }
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}
</style>
