<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Detail Barang Rumah Tangga</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row no-gutters>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Barang</label>
                  </div>
                  <v-text-field
                    disabled
                    v-model="goods.name"
                    id="name"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="barcode">Barcode</label>
                  </div>
                  <v-text-field
                    disabled
                    id="barcode"
                    v-model="goods.barcode"
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="category">Kategori</label>
                  </div>
                  <v-combobox
                    disabled
                    class="detail-input__input-text"
                    v-model="goods.category"
                    :items="resource.category"
                  ></v-combobox>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="hpp">HPP</label>
                  </div>
                  <v-currency-field
                    disabled
                    id="hpp"
                    v-model="goods.hpp"
                    prefix="Rp. "
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="hna">HNA</label>
                  </div>
                  <v-currency-field
                    disabled
                    id="hna"
                    v-model="goods.hna"
                    prefix="Rp. "
                    class="detail-input__input-text"
                  />
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="pabrik">Pabrik</label>
                  </div>
                  <v-combobox
                    disabled
                    class="detail-input__input-text"
                    v-model="goods.factory"
                    :items="resource.factory"
                  ></v-combobox>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="pabrik">Minimal Stok</label>
                  </div>
                  <v-text-field
                    disabled
                    class="detail-input__input-text"
                    v-model="goods.min_stock"
                  ></v-text-field>
                </div>
                <div class="detail-input">
                  <div
                    class="detail-input__label detail-input__label--position"
                  >
                    <label for="pabrik">Aktif</label>
                  </div>
                  <v-checkbox disabled v-model="goods.isActive"></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="10" offset-md="1" class="d-flex align-center">
            <template>
              <v-data-table
                :headers="packaging_header"
                :items="goods.packaging"
                :items-per-page="goods.packaging.length"
                sort-by="calories"
                hide-default-footer
                class="table-kemasan"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Kemasan</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-col>
          <v-col cols="12">
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                width="100"
                text
                class="text-none"
                @click="onCloseButtonClick()"
              >
                Kembali
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');

export default {
  name: 'DetailGoods',
  mixins: [jwtMixin],
  props: {
    goodsDetail: {
      type: Object,
    },
  },
  data() {
    return {
      resource: {
        category: [],
        packages: ['tes'],

        factory: [],
      },

      packaging_header: [
        { text: 'Kemasan', value: 'package', align: 'center' },
        { text: 'Jumlah', value: 'quantity', align: 'center' },
        { text: 'Satuan', value: 'package_unit', align: 'center' },
        { text: 'Kemasan Terkecil', value: 'isDefault', align: 'center' },
      ],
      goods: {
        name: this.goodsDetail.name,
        barcode: this.goodsDetail.barcode,
        hna: this.goodsDetail.hna,
        hpp: this.goodsDetail.hpp,

        category: this.goodsDetail.category,

        isActive: this.goodsDetail.isActive,
        factory: this.goodsDetail.factory,
        min_stock: this.goodsDetail.min_stock,
        packaging: [],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getDataById();
        this.getCategoryByMenu();
        this.getFactory();
      });
    });
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    getDataById() {
      axios
        .get(`${Constant.apiUrl}/master/goods/${this.goodsDetail.id}`)
        .then(response => {
          this.loading = true;
          const goodsData = response.data.data;
          const getPackaging = goodsData.detail.packaging;

          this.goods.packaging = getPackaging.map(item => {
            return {
              package: item.package,
              quantity: item.quantity,
              package_unit: item.package_unit,
              isDefault: item.isDefault === true ? 'Ya' : 'Tidak',
            };
          });
          this.loading = false;
        });
    },
    getCategoryByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/category/menu/Rumah Tangga'))
        .then(response => {
          const categoryData = response.data.data;
          this.resource.category = categoryData.map(cat => {
            return cat.name;
          });
        });
    },
    getFactory() {
      axios.get(Constant.apiUrl.concat('/master/factory')).then(response => {
        const resData = response.data.data;
        this.resource.factory = resData.map(prep => {
          return prep.name;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;

    &--position {
      position: relative;
      right: 140px;
    }
  }

  &__input-text {
    margin-left: 10px;
    max-width: 40%;
  }
}

.padding {
  padding-left: 10px;
}

.v-input__control {
  display: flex;
  align-self: center;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
