<template>
  <v-card class="pa-5 main">
    <v-row class="d-flex justify-end">
      <v-btn @click="closeForm" small text fab>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <h2 class="mb-10 mx-auto">Print Dokumen</h2>
      <v-btn
        @click="onClickActionButton(1)"
        color="primary"
        class="mx-auto main-button text-capitalize"
        dark
        dense
        depressed
        >Informed Consent</v-btn
      >
      <v-btn
        @click="onClickActionButton(2)"
        color="primary"
        class="mx-auto main-button text-capitalize"
        dark
        dense
        depressed
        >Surgical Safety Check List</v-btn
      >
      <br />
      <label
        :class="
          `mx-auto font-weight-bold ${
            props.generalConsent ? 'success--text' : 'red--text'
          }`
        "
        >General Consent
        {{ props.generalConsent ? 'Sudah' : 'Belum' }} Diproses</label
      >
    </v-row>
    <v-dialog persistent width="1700" v-model="dialog">
      <informed-consent
        @close-form="closeDialog()"
        v-if="dialog && selectedForm === 1"
      />
      <surgical-safety-checklist
        :closeForm="closeDialog"
        v-if="dialog && selectedForm === 2"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import InformedConsent from '../../Registration/components/InformedConsent.vue';
import SurgicalSafetyChecklist from './ConsentDocuments/SurgicalSafetyChecklist.vue';
import Swal from 'sweetalert2';

export default {
  name: 'PrintDocuments',
  components: {
    InformedConsent,
    SurgicalSafetyChecklist,
  },
  props: {
    props: Object,
  },
  data() {
    return {
      dialog: false,
      selectedForm: '',
    };
  },
  methods: {
    onClickActionButton(form) {
      if (
        form == 2 &&
        !this.$store.getters.selectedPatient?.isInformedConsentCreated
      ) {
        Swal.fire({
          title: 'Informed Consent Belum Diproses',
          icon: 'error',
          text:
            'Anda tidak dapat mengisi formulir surgical safety check list. Lakukan pembuatan informed consent terlebih dahulu!',
          confirmButtonText: 'Informed Consent',
        }).then(action => {
          if (action.isConfirmed) {
            this.selectedForm = 1;
            this.dialog = true;
          }
        });
        return;
      }
      this.selectedForm = form;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    closeForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  min-height: 33vh !important;

  #{&}-button {
    width: 90%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1903px) {
  .main {
    min-height: 40vh !important;
  }
}
</style>
