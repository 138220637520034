<template>
  <v-card flat class="main-card px-6 py-3">
    <h1>Kunjungan Rawat Jalan Hari ini</h1>
    <div
      v-if="data.isRefreshing"
      class="d-flex justify-center align-center"
      style="height: 90%;"
    >
      <v-progress-circular
        color="primary lighten-2"
        size="100"
        width="6"
        indeterminate
      />
    </div>
    <div class="d-flex justify-center" v-if="!data.isRefreshing">
      <apexchart
        type="donut"
        width="280"
        :options="data.chartOptions"
        :series="data.series"
      />
    </div>
    <div
      style="height: 11vw;"
      class="d-flex flex-column justify-end"
      v-if="!data.isRefreshing"
    >
      <div
        v-for="(item, index) in data.chartOptions.labels"
        :key="`text-${index}`"
        class="d-flex justify-space-between"
      >
        <div class="d-flex align-center">
          <v-icon :color="data.chartOptions.colors[index]" small class="mr-3"
            >mdi-circle</v-icon
          >
          <span class="font-weight-regular">{{ item }}</span>
        </div>
        <span class="font-weight-bold title">{{ data.series[index] }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PieChartDashboard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  border: 2.5px solid #e0e0e0;
  border-radius: 8px;
  height: 100%;

  span {
    font-size: 0.9vw;
  }

  h1 {
    font-size: 1vw;
    text-align: start;
    margin-bottom: 1vw;
  }
}
</style>
