<template>
  <v-container fluid class="main-container">
    <v-layout row wrap v-show="activeTab" class="pa-5">
      <!-- flex dan layout untuk grafik -->
      <v-flex xs9 class="px-3">
        <v-layout column class="ma-0 pa-3 chart-style">
          <v-flex>
            <h3 class="chart-title">Visit Reporting</h3>
            <h4 class="chart-subtitle body-2">
              Kunjungan semua pasien tahun {{ thisYear }}
            </h4>
          </v-flex>
          <v-flex class="chart-container">
            <apexcharts
              ref="realtimeChart"
              height="290"
              type="line"
              :options="chartOptions"
              :series="series"
            ></apexcharts>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- flex dan layout untuk button home, cek dan keterangan -->
      <v-flex xs3>
        <v-layout column>
          <v-flex>
            <v-layout column class="py-10" v-show="activeTab">
              <v-flex class="title-desc py-5">Keterangan Warna</v-flex>
              <template v-for="(item, index) in series">
                <v-flex pb-2 align-self-start :key="item.name + '-' + index">
                  <v-btn
                    depressed
                    :color="chartOptions.colors[index]"
                    :ripple="false"
                    class="ml-0 mr-0 white--text text-none body-2"
                    style="border-radius: 10px; width: 143px"
                  >
                    {{ item.name }}
                  </v-btn>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
          <v-flex class="text-left pt-5">
            <v-btn
              v-show="activeTab"
              class="ml-0 mr-0 text-none white--text"
              color="#3498DB"
              depressed
              @click="
                activeTab = !activeTab;
                getDataByUnit();
              "
              >Cek Laporan Unit Harian</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- flex dan layout untuk rekap -->
      <v-flex xs4 class="action-container">
        <v-form v-show="activeTab">
          <v-layout row wrap class="pa-3">
            <v-flex xs12 class="title-action py-2"
              >Detail Laporan Per Unit</v-flex
            >
            <v-flex xs4 class="text-left font-weight-medium pt-1">
              <label>Pilih Unit</label>
            </v-flex>
            <v-flex xs8>
              <v-select
                :items="resource.unit"
                placeholder="Pilih Poli"
                dense
                item-text="unit"
                item-value="id"
                single-line
                v-model="input.id_unit"
                clearable
                @click:clear="getData()"
              ></v-select>
            </v-flex>
            <v-flex xs4 class="text-left font-weight-medium pt-1">
              <label>Pilih Bulan</label>
            </v-flex>
            <v-flex xs4 class="pr-2">
              <v-select
                :items="resource.months"
                placeholder="Bulan"
                dense
                single-line
                item-text="name"
                item-value="value"
                v-model="input.month"
                clearable
                @click:clear="getData()"
              ></v-select>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-select
                :items="resource.year"
                placeholder="Tahun"
                dense
                single-line
                v-model="input.year"
                clearable
                @click:clear="getData()"
              ></v-select>
            </v-flex>
            <v-flex xs5 class="pr-2">
              <v-btn
                block
                :loading="isLoading"
                depressed
                class="action-button"
                @click="getData()"
              >
                Cari
              </v-btn>
            </v-flex>
            <v-flex xs7 class="pl-2">
              <v-btn
                block
                depressed
                @click="exportData()"
                class="action-button"
              >
                Unduh Laporan
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
      <!-- flex dan layout untuk tabel -->
      <v-flex xs5 class="pl-4 pt-4" v-show="activeTab">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 class="data-grid sum-data-grid head">
            <label class="label-item-table head left">Jenis Pasien</label>
            <label class="label-item-table head">Jumlah Kunjungan</label>
          </v-flex>
          <v-progress-linear
            :indeterminate="isTableLoading"
            v-if="isTableLoading"
            height="2"
          ></v-progress-linear>
          <v-flex xs12 class="data-grid sum-data-grid scrollable mt-2">
            <template v-for="(item, index) in table.data">
              <label class="label-item-table left" :key="'name-' + index">{{
                item.name
              }}</label>
              <label class="label-item-table" :key="'total-' + index">{{
                item.total
              }}</label>
            </template>
          </v-flex>
          <v-flex xs12 class="data-grid sum-data-grid footer">
            <label class="label-item-table bold-text left"
              >Jumlah Seluruh Pasien</label
            >
            <label class="label-item-table">{{ totalVisit }}</label>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- layout kunjungan -->
    <v-main v-show="!activeTab">
      <v-row>
        <v-toolbar outlined flat style="height: 70px">
          <v-col cols="6" class="text-left px-5">
            <h2>Kunjungan Harian Pasien</h2>
          </v-col>
          <v-col cols="3">
            <!-- <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datePick"
                  label="Pilih tanggal"
                  prepend-inner-icon="mdi-calendar-month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  single-line
                  dense
                  hide-details="false"
                  clearable
                  @click:clear="
                    datePick = '';
                    getData();
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datePick"
                @input="
                  menu = false;
                  getData();
                "
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu> -->

            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="datepicker"
              persistent
              ref="menu"
              min-width="100px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mr-4 input-filter date"
                  dense
                  flat
                  v-on="on"
                  v-bind="attrs"
                  readonly
                  append-icon="mdi-chevron-down"
                  placeholder="Rentang Tanggal"
                  v-model="date.formatted"
                  :value="date.formatted"
                  single-line
                  clearable
                  @input="getDataByUnit()"
                  @click:clear="clearDate()"
                  hide-details
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                no-title
                multiple
                v-model="selectedDate"
                @input="changedate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datepicker = false">
                  CANCEL
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu.save(date);
                    getDataByUnit();
                  "
                >
                  FILTER
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3" align-self="start">
            <v-btn
              depressed
              tile
              class="white--text text-none"
              color="#3498DB"
              @click="
                activeTab = !activeTab;
                getDataByUnit();
              "
            >
              Cek Laporan Per Unit
            </v-btn>
          </v-col>
        </v-toolbar>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-10">
          <v-data-iterator
            :items="tableUnit.data"
            :loading="isTableUnitLoading"
            hide-default-footer
            class="data-iterator-style"
          >
            <template v-slot:header>
              <v-toolbar flat dense class="header-style">
                <v-toolbar-items class="detail-grid grey--text text--darken-3">
                  <template v-for="(item, index) in tableUnit.headers">
                    <div :key="'header-' + index">
                      <span class="caption font-weight-bold" :class="item.class"
                        >{{ item.text }}
                      </span>
                      <!-- <v-icon
                        class="grey--text"
                        small
                        :class="{ rotateIcon: sortedAsc }"
                        v-if="item.sortable"
                      >
                        {{
                          sortedBy === item.value && sortedAsc
                            ? 'mdi-arrow-up'
                            : 'mdi-arrow-down'
                        }}</v-icon
                      > -->
                    </div>
                  </template>
                </v-toolbar-items>
              </v-toolbar>
            </template>
            <template v-slot:item="props">
              <v-card flat color="transparent" style="width: 800px">
                <!-- <v-card flat color="transparent" style="min-width: 700px"> -->
                <v-card-title class="detail-grid py-0 px-3 my-3 data-item">
                  <span>
                    {{ tableUnit.data.indexOf(props.item) + 1 }}
                  </span>
                  <span class="text-center">
                    <v-btn
                      rounded
                      depressed
                      color="primary"
                      class="text-none font-weight-medium"
                      block
                      >{{ props.item.unit }}</v-btn
                    >
                  </span>
                  <span>
                    {{ props.item.total }}
                  </span>
                  <span class="">{{ props.item.total_bpjs?.count || 0 }}</span>
                  <span class="">{{ props.item.total_umum?.count || 0 }}</span>
                  <span class="">{{
                    props.item.total_assurance?.count || 0
                  }}</span>
                  <!-- <span class="">1</span>
                  <span class="">1</span>
                  <span>1</span>
                  <span>1</span>
                  <span>1</span>
                  <span>1</span>
                  <span>1</span>
                  <span>1</span> -->
                </v-card-title>
              </v-card>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Constant from '@/const';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');

export default {
  name: 'PatientVisit',
  mixins: [jwtMixin, alertMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      datepicker: false,
      date: {
        raw: [],
        formatted: '',
      },
      selectedDate: [],
      isLoading: false,
      isTableLoading: false,
      isTableUnitLoading: false,
      activeTab: true,
      datePick: '',
      menuDate: false,
      thisYear: new Date().getFullYear(),
      resource: {
        months: [],
        unit: [],
        year: [],
      },
      input: {
        year: '',
        month: '',
        id_unit: '',
      },
      chartOptions: {
        chart: {
          id: 'basic-bar',
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Ags',
            'Sep',
            'Okt',
            'Nov',
            'Des',
          ],
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#80C2FF', '#FFCC80', '#FF8080'],
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'Pasien Asuransi',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: 'Pasien Umum',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: 'Pasien BPJS',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      table: {
        headers: [{ text: 'No', value: 'no', width: '70px', sortable: true }],
        data: [],
      },
      tableUnit: {
        headers: [
          { text: 'No', value: 'no', width: '70px', sortable: true },
          { text: 'Unit', value: 'unit', width: '100px', sortable: false },
          {
            text: 'Total Pasien',
            value: 'total',
            width: '170px',
            sortable: false,
          },
          {
            text: 'Pasien BPJS',
            value: 'total_bpjs.count',
            width: '150px',
            sortable: false,
          },
          {
            text: 'Pasien Umum',
            value: 'total_umum.count',
            width: '150px',
            sortable: false,
          },
          {
            text: 'Pasien Asuransi',
            value: 'total_assurance.count',
            width: '150px',
            sortable: false,
          },
          // {
          //   text: 'Pasien CT Scan RSI Wonosobo',
          //   value: 'group.ctWonosobo',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien CT Scan RSUD Setjonegoro',
          //   value: 'group.ctSetjonegoro',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien Dana Sehat Aisyah',
          //   value: 'group.aisyah',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien Dana Sehat Muhammadiyah',
          //   value: 'group.muhammadiyah',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien JAMKESDA',
          //   value: 'group.jamkesda',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien PT. Djarum',
          //   value: 'group.djarum',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien PKU Muhammadiyah Wonosobo',
          //   value: 'group.pkuWonosobo',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien Jasa Raharja',
          //   value: 'group.jasaRahaja',
          //   width: '150px',
          //   sortable: false,
          // },
          // {
          //   text: 'Pasien Geodipa',
          //   value: 'group.geodipa',
          //   width: '150px',
          //   sortable: false,
          // },
        ],
        data: [],
      },
      totalVisit: 0,
    };
  },
  // watch: {
  //   selectedDate() {
  //     // this.today = '';
  //     this.getDataByUnit();
  //   },
  //   date() {
  //     this.getDataByUnit();
  //   },
  // },
  mounted() {
    this.changedate([moment().format('YYYY-MM-DD')]);
    this.date;

    for (let i = 0; i < 12; i++) {
      this.resource.months.push({
        name: moment()
          .startOf('year')
          .add(i, 'month')
          .format('MMMM'),
        value: i + 1,
      });
    }

    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getUnit();
    });
  },
  methods: {
    async exportData() {
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/report/visitors/export?branch=${this.$store.getters.userLoggedIn.branch_id}&month=${this.input.month}&year=${this.input.year}&id_unit=${this.input.id_unit}`,
          ),
        );
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            // title: 'Terjadi Kesalahan',
            title: 'Dokumen yang anda unduh tidak tersedia',
          });
        }
      }
    },

    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD/MM/YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], data[0]],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD/MM/YYYY'),
        };
      }
    },
    clearDate() {
      this.date.raw = [];
      this.selectedDate = [];
      // this.datepicker = true;
      // this.getDataByUnit();
    },
    getData() {
      this.isLoading = true;
      this.isTableLoading = true;
      const year = moment(new Date()).format('YYYY');
      const month = moment(new Date()).format('MMMM');
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/visitors/data?branch=${
                this.$store.getters.userLoggedIn.branch_id
              }&month=${month}&year=${year}&id_unit=${this.input.id_unit ||
                ''}`,
            ),
          )
          .then(response => {
            // const data = response.data.data;
            const { graph, table, listYear } = response.data.data;
            this.resource.year = listYear;
            this.table.data = table.map(d => {
              return {
                name: d.name,
                total: d.value,
              };
            });

            this.totalVisit = this.table.data.reduce(
              (a, { total }) => a + total,
              0,
            );

            this.$refs.realtimeChart.updateSeries(
              [
                {
                  name: 'Pasien Asuransi',
                  data: graph.insurance,
                },
                {
                  name: 'Pasien Umum',
                  data: graph.general,
                },
                {
                  name: 'Pasien BPJS',
                  data: graph.bpjs,
                },
              ],
              false,
              true,
            );
          })
          .catch(err => {
            if (err.status === 404)
              Swal.fire('Data tidak ditemukan', '', 'error');
            this.input.year = '';
            this.input.month = '';
            this.input.id_unit = '';
          })
          .finally(() => {
            this.isLoading = false;
            this.isTableLoading = false;
          });
      });
    },
    getUnit() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/unit?page=&itemCount=&search=&sort=name',
            ),
          )
          .then(response => {
            const unit = response.data.data;
            this.resource.unit = unit
              .filter(v => v.detail.installation === 'Instalasi Rawat Jalan')
              .map(arr => {
                return {
                  id: arr._id,
                  unit: arr.name,
                };
              });
          });
      });
    },
    getDataByUnit() {
      this.isTableUnitLoading = true;
      let startDate = '';
      let endDate = '';
      if (this.date.raw.length > 0) {
        startDate = moment(this.date.raw[0]).format('YYYY-MM-DD');
        endDate =
          this.date.raw[1] === ''
            ? moment().format('YYYY-MM-DD')
            : moment(this.date.raw[1]).format('YYYY-MM-DD');
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/visitors?branch=${this.$store.getters.userLoggedIn.branch_id}&startDate=${startDate}&endDate=${endDate}`,
            ),
          )
          .then(response => {
            const allUnit = response.data.data;
            this.tableUnit.data = allUnit.map(arr => {
              return {
                unit: arr.unit_name,
                total: arr.total,
                total_bpjs: arr.list_assurance.find(
                  data => data.assurance === 'bpjs',
                ),
                total_umum: arr.list_assurance.find(
                  data => data.assurance === 'umum',
                ),
                total_assurance: arr.list_assurance.find(
                  data => data.assurance === 'asuransi',
                ),
              };
            });
          })
          .catch(err => {
            if (err) {
              this.tableUnit.data = [];
            }
          })
          .finally(() => {
            this.isTableUnitLoading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getData();
      this.interval = setInterval(this.getData, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  font-family: Roboto;
  font-style: normal;

  .line-head {
    border-bottom: 3px solid #e7e7e7;
  }

  .chart-style {
    background: #f0f9ff;
    border-radius: 25px;
    height: 350px;

    .chart-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      text-align: left;
      padding-left: 20px;
    }
    .chart-subtitle {
      padding-left: 20px;
      text-align: left;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      color: #adb5bd;
    }
    .chart-container {
      position: relative;
      margin: auto;
      height: 100%;
      width: 100%;
    }
  }
  .title-desc {
    font-weight: 500;
    text-align: left;
    color: #000;
  }
  .action-container {
    padding-left: 12px;
    padding-right: 12px;
    .title-action {
      font-weight: bold;
      color: #000;
      text-align: left;
      font-size: 1em;
    }
    .action-button {
      height: 38px;
      font-weight: 700;
      background-color: #fff !important;
      color: #3498db;
      border: 2px solid $primary-color;
    }
  }
  .data-grid {
    display: grid;

    &.sum-data-grid {
      grid-template-columns: 0.7fr 0.3fr;
      grid-row-gap: 2px;
      grid-auto-rows: 30px;
      &.head {
        border-bottom: 1px solid black;
      }
      &.footer {
        border-top: 1px solid black;
        align-items: center;
      }
    }
    .label-item-table {
      font-family: Roboto;
      font-style: normal;
      font-size: 0.9rem;
      &.head {
        height: 100%;
        font-weight: bold;
      }
      &.bold-text {
        font-weight: bold;
      }
      &.left {
        text-align: left;
        padding-left: 12px;
      }
    }
  }

  .data-iterator-style {
    max-width: 1070px;
    overflow: auto;
    max-height: 430px;
    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 0.5em;
      height: 0.5em;
      background-color: #bdbdbd;
      border-radius: 5px;
    }

    .header-style {
      height: 48px;
      background-color: rgb(224, 224, 224);
      // min-width: 300px;
      width: 800px;
      border: 0.5px solid #828282;
      border-radius: 5px;
    }
    .detail-grid {
      display: grid;
      grid-template-columns: 40px 100px 100px repeat(8, 150px) 200px repeat(
          2,
          100px
        );
      grid-column-gap: 10px;
      align-items: center;
      grid-template-rows: 48px;
    }
    .data-item {
      border: 0.5px solid #828282;
      height: 50px;
      font-size: 14px;
    }
  }
}
</style>
