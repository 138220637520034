var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-wrap pe-10"},[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"no-gutters":"","align":"baseline"}},[(_vm.activityStatusLab === 'confirmed')?_c('div',[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Anda tidak dapat melakukan perubahan karena permintaan ini sudah dikonfirmasi oleh pihak Laboratorium ")])],1):_vm._e(),_c('v-col',{staticClass:"py-4",attrs:{"cols":"6"}},[_vm._l(([
            { label: 'No Order', value: _vm.noOrder },
            { label: 'Tanggal Order', value: _vm.today },
          ]),function(item){return _c('div',{key:item.label},[_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(item.label)+" ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',[_vm._v(" "+_vm._s(item.value)+" ")])])],1)],1)}),_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{class:_vm.activityStatusLab !== 'scheduled' ? '' : 'required'},[_vm._v(" Diagnosa Sementara ")])]),_c('v-col',{attrs:{"cols":"8"}},[(
                _vm.dataLab?.isConfirmed === undefined
                  ? _vm.activityStatusLab !== 'scheduled' &&
                    _vm.activityStatusLab !== 'registration'
                  : _vm.dataLab.isConfirmed
              )?_vm._l((_vm.provisionalDiagnosis),function(item,index){return _c('div',{key:index,staticClass:"pb-2"},[_vm._v(" "+_vm._s(item.description === undefined ? '' + item : item.code + ' - ' + item.description)+" ")])}):_vm._l((_vm.provisionalDiagnosis),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{attrs:{"items":_vm.getAllDiagnose,"item-value":getAllDiagnose => getAllDiagnose,"item-text":"display_name","id":"Diagnosa Sementara","append-icon":"mdi-chevron-down","placeholder":"Pilih Diagnosis","dense":"","loading":_vm.onLoading('diagnoseCheckup'),"rules":[index === 0 ? v => !!v || 'Wajib Diisi' : true]},on:{"keyup":_vm.handleChangeDiagnose,"change":val => _vm.handleIsChanged(val, index)},model:{value:(_vm.provisionalDiagnosis[index]),callback:function ($$v) {_vm.$set(_vm.provisionalDiagnosis, index, $$v)},expression:"provisionalDiagnosis[index]"}}),_c('div',{staticClass:"ml-2 d-flex justify-center align-baseline"},[_c('v-btn',{attrs:{"disabled":index === 0,"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.handleRemoveDiagnoseArr(index)}}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleAddDiagnoseArr()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)])})],2)],1)],2),_c('v-col',{attrs:{"cols":"6"}})],1),_c('div',{staticClass:"table-wrap"},[_c('v-data-table',{attrs:{"single-expand":"","hide-default-footer":"","headers":_vm.table.headers,"items":_vm.inputOrderCheckedItems,"disable-pagination":""},scopedSlots:_vm._u([{key:`item.no`,fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:`item.name`,fn:function({ item, index }){return [(
              _vm.dataLab?.isConfirmed === undefined
                ? _vm.activityStatusLab !== 'scheduled' &&
                  _vm.activityStatusLab !== 'registration'
                : _vm.dataLab.isConfirmed
            )?_c('div',[_vm._v(" "+_vm._s(item.name + '')+" ")]):_c('div',{staticClass:"service-name"},[_c('v-autocomplete',{attrs:{"items":_vm.laboratoryServices.flatMap(service => service.items),"item-value":laboratoryServices => {
                  return {
                    id_service:
                      laboratoryServices.id_service || laboratoryServices.id,
                    name: laboratoryServices.name,
                    category: laboratoryServices.category,
                  };
                },"item-disabled":a => a.id === item.id_service,"item-text":"name","dense":"","append-icon":"mdi-chevron-down","rules":[index === 0 ? v => !!v || 'Wajib Diisi' : true]},on:{"change":_vm.handleIsChanged},model:{value:(_vm.checkedItems[index]),callback:function ($$v) {_vm.$set(_vm.checkedItems, index, $$v)},expression:"checkedItems[index]"}})],1)]}},{key:`item.category`,fn:function({ item }){return [(
              _vm.dataLab?.isConfirmed === undefined
                ? _vm.activityStatusLab !== 'scheduled' &&
                  _vm.activityStatusLab !== 'registration'
                : _vm.dataLab.isConfirmed
            )?_c('div',[_vm._v(" "+_vm._s(item.category + '')+" ")]):_c('div',[_vm._v(" "+_vm._s(item.category === undefined ? '' : item.category + '')+" ")])]}},{key:`item.action`,fn:function({ item, index }){return [(
              (_vm.dataLab?.isConfirmed === undefined
                ? _vm.activityStatusLab === 'scheduled'
                : !_vm.dataLab.isConfirmed) ||
                _vm.activityStatusLab === 'registration'
            )?_c('div',[(index !== 0)?_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.handleRemoveCheckedItemsArr(index)}}},[_vm._v("mdi-delete")])],1)],1):_vm._e()]):_vm._e()]}}],null,true)})],1)],1),(
      (_vm.dataLab?.isConfirmed === undefined
        ? _vm.activityStatusLab === 'scheduled'
        : !_vm.dataLab.isConfirmed) || _vm.activityStatusLab === 'registration'
    )?_c('div',{staticClass:"check-wrap__button"},[_c('button',{staticClass:"button-add",on:{"click":_vm.handleAddCheckedItemsArr}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Tambah Item ")],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }