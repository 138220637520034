<template>
  <v-container fluid class="dashboard">
    <v-row no-gutters fill-height class="inner-dashboard">
      <dashboard-sidenav :window-width="windowWidth"></dashboard-sidenav>
      <v-snackbar
        :timeout="-1"
        style="z-index: 99 !important"
        :value="offlineSnackbar"
        content-class="d-flex justify-space-between align-center"
        absolute
        centered
        tile
        top
        color="#CF212A"
      >
        <b class="mr-3">KONEKSI INTERNET ANDA TERPUTUS</b>
        <v-btn @click="offlineSnackbar = false" small text fab
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-snackbar>
      <v-snackbar
        :timeout="4000"
        style="z-index: 99 !important"
        :value="onlineSnackbar"
        content-class="d-flex justify-space-between align-center"
        absolute
        centered
        tile
        top
        color="#31B057"
      >
        <b class="mr-3">KONEKSI INTERNET ANDA BERHASIL TERHUBUNG</b>
        <v-btn small text fab @click="onlineSnackbar = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-snackbar>
      <v-col
        style="min-width: 200px;"
        class="content"
        :class="{ 'blue-background': routeSplit[1] === 'emr' }"
      >
        <v-app-bar
          class="header"
          :class="{ 'blue-background no-shadow': routeSplit[1] === 'emr' }"
        >
          <v-row justify-space-between align="center">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider=">"
              class="breadcrumbs"
              :style="{ 'max-width': windowWidth <= 1200 ? '300px' : '600px' }"
            />
            <v-spacer></v-spacer>
            <span class="red--text font-weight-medium caption mr-3"
              >Sisa Waktu Login Anda: {{ timer || 'Habis' }}</span
            >
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="menu"
              ref="menu"
              nudge-left="30"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-on="on"
                  v-bind="attrs"
                  class="profile d-flex align-end flex-column"
                  :class="{ active: isDropdownProfileActive }"
                  @click="toggleDropdownProfile"
                >
                  <!-- v-click-outside="hideDropdownProfile" -->
                  <div class="d-flex align-center">
                    <v-avatar size="32" color="grey lighten-4" class="mr-2">
                      <img
                        src="@/assets/image/avatar_placeholder.svg"
                        alt="avatar"
                      />
                    </v-avatar>
                    <span>Halo, {{ user.name }}</span>
                  </div>
                </div>
              </template>
              <div class="account-menu">
                <v-list
                  v-if="hasRoles().isSuperManagerAdmin"
                  dense
                  light
                  class="py-0"
                >
                  <v-subheader class="caption grey--text">{{
                    `${$store.getters.userLoggedIn.clinic.name}`
                  }}</v-subheader>
                  <v-divider class="mb-1"></v-divider>
                  <v-list-item-group v-model="branchActive" color="primary">
                    <v-list-item
                      v-for="(item, i) in branches"
                      :key="i"
                      class="pa-0 px-2"
                      @click="
                        changeUnit($store.getters.userLoggedIn.unit_id, item.id)
                      "
                    >
                      <v-list-item-icon class="mx-0">
                        <v-icon color="primary" small v-if="branchActive === i"
                          >mdi-check-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content class="pa-0">
                        <v-list-item-subtitle
                          class="button"
                          v-text="item.text"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-list
                  v-else
                  v-for="(branch, index) in branchesAndUnits"
                  :key="'branch-' + index"
                  dense
                  light
                  class="py-0"
                >
                  <v-subheader class="caption grey--text">{{
                    `${$store.getters.userLoggedIn.clinic.name} ${branch.name_branch}`
                  }}</v-subheader>
                  <v-divider class="mb-1"></v-divider>
                  <v-list-item-group
                    :value="
                      branch.id_branch === $store.getters.userLoggedIn.branch_id
                        ? unitActive
                        : ''
                    "
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in branch.unit"
                      :key="i"
                      class="pa-0 px-2"
                      @click="changeUnit(item.id_unit, branch.id_branch)"
                    >
                      <v-list-item-icon class="mx-0">
                        <v-icon
                          color="primary"
                          small
                          v-if="
                            branch.id_branch ===
                              $store.getters.userLoggedIn.branch_id &&
                              unitActive === i
                          "
                          >mdi-check-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content class="pa-0">
                        <v-list-item-subtitle
                          class="button"
                          v-text="item.name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider class="mb-2"></v-divider>
                <div class="d-flex flex-column">
                  <span class="caption font-weight-bold action-footer pl-4"
                    >Ganti Password</span
                  >
                  <span
                    @click="logout"
                    class="caption font-weight-bold action-footer logout pl-4 red--text"
                    >Keluar</span
                  >
                </div>
              </div>
            </v-menu>
          </v-row>
        </v-app-bar>
        <v-container fluid class="inner-content ma-0 pa-0">
          <router-view />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardSidenav from '../components/Dashboard/DashboardSideNav.vue';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import roleMixin from '@/mixin/roleMixin';
import Constant from '@/const';

const axios = require('axios');

const _ = require('lodash');
let interval;

export default {
  name: 'DashboardPage',
  mixins: [AlertMixin, roleMixin],
  components: {
    DashboardSidenav,
  },
  data() {
    return {
      isOnline: true,
      onlineSnackbar: false,
      offlineSnackbar: false,
      user: {
        name: this.$store.getters.userLoggedIn.name,
      },
      isDropdownProfileActive: false,
      menu: false,
      isChangeUnit: false,
      timer: '',
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    if (!navigator.onLine) {
      this.offlineSnackbar = true;
      this.isOnline = false;
    }
    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
    window.addEventListener('online', () => {
      this.isOnline = true;
    });
    this.$nextTick(() => {
      if (this.isLoggedIn) {
        this.refresh();
      } else {
        this.logout();
      }
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  watch: {
    isOnline(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.onlineSnackbar = true;
        this.offlineSnackbar = false;
      }
      if (!newValue && oldValue) {
        this.onlineSnackbar = false;
        this.offlineSnackbar = true;
      }
    },
  },
  computed: {
    isLoggedIn() {
      const now = moment();
      return now < moment(this.$store.getters.tokenTime * 1000);
    },
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[1];
    },
    branches() {
      return this.$store.getters.userLoggedIn.branches.map(branch => {
        return {
          text: branch.name_branch,
          id: branch.id_branch,
        };
      });
    },
    unitActive() {
      return this.$store.getters.userLoggedIn.branches[
        this.branchActive
      ].unit.findIndex(
        item => item.id_unit === this.$store.getters.userLoggedIn.unit_id,
      );
    },
    branchActive() {
      return this.$store.getters.userLoggedIn.branches.findIndex(
        item => item.id_branch === this.$store.getters.userLoggedIn.branch_id,
      );
    },
    branchesAndUnits() {
      return this.$store.getters.userLoggedIn.branches;
    },
    breadcrumbs() {
      if (this.routeSplit[1] === 'emr') {
        return [
          {
            text: this.$store.getters.userLoggedIn.clinic.altName,
            disabled: false,
            to: '/',
          },
          {
            text: this.routeSplit[1].toUpperCase(),
            disabled: true,
          },
        ];
      } else {
        if (this.route === '/') {
          return [
            {
              text: this.$store.getters.userLoggedIn.clinic.altName,
              disabled: false,
              to: '/',
            },
            {
              text: '',
              disabled: false,
            },
          ];
        } else if (
          this.routeSplit.length === 2 ||
          this.routeSplit.length === 3
        ) {
          if (this.routeSplit[2] === 'igd') {
            return [
              {
                text: this.$store.getters.userLoggedIn.clinic.altName,
                disabled: false,
                to: '/',
              },
              {
                text: _.startCase(this.routeSplit[1]),
                disabled: true,
              },
              {
                text: this.routeSplit[2].toUpperCase(),
                disabled: true,
              },
            ];
          } else {
            return [
              {
                text: this.$store.getters.userLoggedIn.clinic.altName,
                disabled: false,
                to: '/',
              },
              {
                text: _.startCase(this.routeSplit[1]),
                disabled: true,
              },
              {
                text: _.startCase(this.routeSplit[2]),
                disabled: true,
              },
            ];
          }
        } else if (this.routeSplit.length === 4) {
          let route = `/${this.routeSplit[1]}/${this.routeSplit[2]}`;
          return [
            {
              text: this.$store.getters.userLoggedIn.clinic.altName,
              disabled: false,
              to: '/',
            },
            {
              text: _.startCase(this.routeSplit[1]),
              disabled: true,
            },
            {
              text: _.startCase(this.routeSplit[2]),
              disabled: false,
              exact: true,
              to: route,
            },
            {
              text: _.startCase(this.routeSplit[3]),
              disabled: true,
            },
          ];
        }
      }
      return [
        {
          text: this.$store.getters.userLoggedIn.clinic.altName,
          disabled: false,
          to: '/',
        },
      ];
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async changeUnit(id, idBranch) {
      this.isChangeUnit = true;
      const payload = {
        nip: this.$store.getters.userLoggedIn.nip,
        unit: id,
        branch: idBranch,
      };
      delete axios.defaults.headers.Authorization;
      localStorage.removeItem('token');
      this.$store.commit('removeToken');
      try {
        const response = await axios.post(
          Constant.apiUrl.concat('/master/staff/login/unit'),
          payload,
        );
        if (response.status === 200) {
          this.$store.commit('saveToken', response.data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
          document.location.reload(true);
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    toggleDropdownProfile() {
      this.isDropdownProfileActive = !this.isDropdownProfileActive;
    },
    hideDropdownProfile() {
      this.isDropdownProfileActive = false;
    },
    logout() {
      delete axios.defaults.headers.Authorization;
      localStorage.removeItem('token');
      this.$store.commit('removeToken');
      this.$router.push('/login');
      document.location.reload(true);
    },
    resetPassword() {},
    refresh() {
      if (interval !== null) {
        interval = clearInterval(interval);
      }
      this.recount();
      interval = setInterval(this.recount, 1000);
    },
    recount() {
      const now = moment();
      if (now < moment(this.$store.getters.tokenTime * 1000)) {
        const diff = moment(this.$store.getters.tokenTime * 1000).diff(now);
        this.timer = moment.utc(diff).format('HH:mm:ss');
      } else {
        this.timer = false;
        interval = clearInterval(interval);
        if (this.isChangeUnit) return;
        this.showErrorJwt();
      }
    },
  },
  beforeDestroy() {
    interval = clearInterval(interval);
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.account-menu {
  width: 14vw;
  background-color: white;
  padding: 1rem;
  text-align: start;

  .clinic-name {
    border-bottom: 1px solid blue;
  }

  .action-footer {
    background-color: white;
    padding: 0.4rem;
    cursor: pointer;

    &:hover {
      background-color: #e4effa;
    }

    &.logout {
      background-color: white;
      padding: 0.4rem;

      &:hover {
        background-color: #ffe2e5;
      }
    }
  }
}
.dashboard {
  background-color: $background-dashboard;
  min-height: 100vh;

  padding: 0.8em 0em 0em 0.8em;

  .inner-dashboard {
    width: 100%;
    flex-wrap: 0;
    margin: 0;
    .content {
      background-color: white;
      &.blue-background {
        background-color: #f6f9fb;
      }
    }
    .inner-content {
      max-height: 87vh;
      min-height: 87vh;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10px;
        background-color: #9ca2a5;
      }
      &::-webkit-scrollbar-thumb {
        width: 10px;
        background-color: #86bcdb;
      }
    }
    a {
      text-decoration: none;
    }
  }
  .header {
    padding-right: 15px;
    background-color: white !important;
    box-shadow: 0px 4px 3px 0px rgb(219, 219, 219) !important;
    font-weight: 500;
    position: relative;
    z-index: 98;
    font-family: Roboto, sans-serif !important;

    &.blue-background {
      background-color: #f6f9fb !important;
    }

    &.no-shadow {
      box-shadow: none !important;
    }

    // &.blue-background {
    //   background-color: #f6f9fb;
    // }

    .breadcrumbs {
      color: #3498db;
      text-align: left;
      flex-grow: 2;
    }
    .profile {
      cursor: pointer;
      position: relative;
      .dropdown {
        z-index: 1;
        position: absolute;
        width: 100%;
        display: none;
        &.active {
          display: block;
        }
        & > * {
          color: white;
        }
      }
      & > * {
        margin: 5px;
      }
      &:hover,
      &:focus,
      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }
  }
}
</style>
