<template>
  <v-card>
    <v-card-title class="justify-center">
      <h4>Pendaftaran Pasien</h4></v-card-title
    >
    <v-card-text>
      <v-row no-gutters class="pa-5 mx-5">
        <v-col>
          <v-card height="300" color="#C4E3F5" class="mr-3 pa-5">
            <h2>Pasien Lama</h2>
            <div class="pt-5 pb-3">
              <label class="body-2"
                >Pasien yang sudah pernah <br />
                berkunjung ke Klinik</label
              >
            </div>

            <v-combobox
              solo
              flat
              hide-details="false"
              placeholder="Cari berdasarkan nama/ nomor rekam medis"
              prepend-inner-icon="mdi-magnify"
              dense
              background-color="white"
              :items="itemPatient"
              :search-input.sync="searchPatient"
              :loading="searchLoading"
              item-text="displayName"
            >
              <template v-slot:item="{ item }">
                <div
                  class="text-left"
                  style="display: grid"
                  @click="$emit('on-clicked', item.rmNumber)"
                >
                  <h4>
                    {{ item.rmNumber }} {{ item.name }} ({{ item.age }} tahun)
                    <v-icon small>{{
                      item.gender ? 'mdi-gender-male' : 'mdi-gender-female'
                    }}</v-icon>
                  </h4>

                  <label class="caption"
                    >Kunjungan terakhir : {{ item.lastVisit }}</label
                  >
                  <label class="caption">{{ item.address }}</label>
                </div>
              </template>
            </v-combobox>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            height="300"
            style="position: relative"
            color="#288BCD"
            class="ml-3 pa-5"
          >
            <h2 class="white--text">Pasien Baru</h2>
            <div class="py-5" style="height: 80%">
              <label class="body-2 white--text"
                >Pasien yang belum pernah <br />
                berkunjung ke Klinik</label
              >
            </div>
            <v-btn
              color="white"
              class="text-none blue--text"
              depressed
              @click="$emit('on-clicked', null)"
            >
              Daftar
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');
const _ = require('lodash');

export default {
  data() {
    return {
      itemPatient: [],
      searchPatient: '',
      searchLoading: false,
      patient: '',
      patientSelected: null,
    };
  },
  watch: {
    searchPatient() {
      this.searchData(this);
    },
  },
  mounted() {
    this.getPatient();
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getPatient();
    }, 650),
    getPatient() {
      const keyword = this.searchPatient;
      this.searchLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/emr/all?page=1&itemCount=10&search=${keyword ||
              ''}&sort=`,
          ),
        )
        .then(response => {
          const patient = response.data.data;
          this.itemPatient = patient.map(arr => {
            const addressData = [arr.social.address.text];

            if (arr.social.address.sub_district) {
              addressData.push(arr.social.address.sub_district);
            }
            if (arr.social.address.district) {
              addressData.push(arr.social.address.district);
            }
            if (arr.social.address.city) {
              addressData.push(arr.social.address.city);
            }
            if (arr.social.address.province) {
              addressData.push(arr.social.address.province);
            }
            return {
              name: arr.social.name,
              rmNumber: arr.medical_record_number,
              displayName: `${arr.social.name} ${arr.medical_record_number}`,
              lastVisit:
                arr.last_visit === '-' || !arr.last_visit
                  ? '-'
                  : moment(arr.last_visit).format('DD/MM/YYYY'),
              age: arr.social.age,
              gender: arr.social.gender,
              address: addressData.join(', '),
            };
          });
          this.searchLoading = false;
        })
        .catch(() => {
          this.searchLoading = false;
        });
    },
  },
};
</script>

<style></style>
