import Constant from '@/const';
import axios from 'axios';
import store from '../../store/index';

const getStaff = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan&branch=${store.getters.userLoggedIn.branch_id}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getStaff;
