<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Pendaftaran Pasien Rawat Inap</h3>
      <span>{{ totalAvailable }} Bed Tersedia</span>
    </v-card-title>
    <v-row no-gutters class="header-bed offset-1" justify="center">
      <label class="grey--text">No. RM</label>
      <label class="font-weight-bold">{{ medicalRecord }}</label>
      <label class="grey--text">Usia</label>
      <label class="font-weight-bold"
        >{{ selectedPatient.meta.age }} tahun</label
      >
      <label class="grey--text">Tipe Penjamin</label>
      <label class="font-weight-bold text-uppercase">{{
        selectedPatient.meta.guarantorType
      }}</label>

      <label class="grey--text">Nama Pasien</label>
      <label class="font-weight-bold">{{ selectedPatient.meta.name }}</label>
      <label class="grey--text">Jenis Kelamin</label>
      <label class="font-weight-bold">{{ selectedPatient.meta.gender }}</label>
      <label class="grey--text">Jenis Kasus</label>
      <label class="font-weight-bold">{{ selectedPatient.caseType }}</label>
    </v-row>

    <v-divider></v-divider>
    <v-container class="px-15 pb-0">
      <h3 class="black--text text-left">Administrasi Ruang Rawat Inap</h3>

      <v-row no-gutters class="detail-beds pt-8">
        <div class="detail-bed">
          <v-icon color="green">mdi-bed</v-icon>
          <label class="caption ml-3">Tersedia</label>
        </div>
        <div class="detail-bed">
          <v-icon color="primary">mdi-bed</v-icon>
          <label class="caption ml-3">Ditempati Pasien Laki-laki</label>
        </div>
        <div class="detail-bed">
          <v-icon color="#F78FB3">mdi-bed</v-icon>
          <label class="caption ml-3">Ditempati Pasien Perempuan</label>
        </div>
        <div class="detail-bed">
          <v-icon color="black">mdi-bed</v-icon>
          <label class="caption ml-3">Tidak Tersedia (Rusak)</label>
        </div>
      </v-row>
      <!-- <div>
        <label class="body-1 font-weight-bold primary--text">{{
          input.room.name ? `Ruang ${input.room.name}` : ''
        }}</label
        ><br />
        <label class="caption">{{
          input.room.name ? `Tersedia ${input.room.length} Bed` : ''
        }}</label>
      </div> -->
      <v-row no-gutters>
        <!-- <v-col cols="3">
          <v-card
            color="white"
            height="280"
            width="230"
            rounded="lg"
            class="text-left pa-3"
          >
            <h4 class="text-left">Pilih Ruang</h4>
            <span class="red--text caption">*harus dipilih</span>
            <div class="ward-choose">
              <template v-for="(room, index) in rooms">
                <v-btn
                  :key="'room-' + index"
                  width="201"
                  height="59"
                  color="white"
                  class="hover mb-2 py-1 px-3 text-left grey--text"
                  :class="{ active: input.room === room }"
                  @click="
                    input.room = room;
                    roomIndex = index;
                  "
                  style="border-radius: 10px;"
                >
                  <v-row style="display: grid;" class="pa-3">
                    <label class="text-none font-weight-bold body-2"
                      >Ruang {{ room.name }}</label
                    >
                    <label class="text-none body-2"
                      >Tersedia : {{ room.length }} bed</label
                    >
                  </v-row>
                </v-btn>
              </template>
            </div>
          </v-card>
        </v-col> -->
        <v-col class="py-10">
          <v-row no-gutters style="grid-column-gap: 3vw; grid-row-gap: 5vh;">
            <template v-for="(bed, idx) in beds">
              <v-btn
                width="120"
                height="90"
                class="bed-choose"
                :class="{
                  active: input.bed === bed._id,
                  notAvailable:
                    bed.availability === 'occupied' ||
                    bed.availability === 'broken',
                }"
                :key="'bed-' + idx"
                @click="input.bed = bed._id"
              >
                <div style="display: grid;">
                  <v-icon
                    x-large
                    color="green"
                    v-if="bed.availability === 'available'"
                    >mdi-bed</v-icon
                  >
                  <v-icon
                    x-large
                    color="primary"
                    v-else-if="
                      bed.availability === 'occupied' && bed.gender === true
                    "
                    >mdi-bed</v-icon
                  >
                  <v-icon
                    x-large
                    color="#F78FB3"
                    v-else-if="
                      bed.availability === 'occupied' && bed.gender === false
                    "
                    >mdi-bed</v-icon
                  >
                  <v-icon
                    x-large
                    color="black"
                    v-else-if="bed.availability === 'broken'"
                    >mdi-bed</v-icon
                  >
                  <v-icon x-large color="grey" v-else>mdi-bed</v-icon>
                  <label class="font-weight-bold">{{ bed.bed_number }}</label>
                </div>
              </v-btn>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn
        text
        class="text-none font-weight-bold"
        @click="$emit('on-clicked', 1)"
        >Kembali</v-btn
      >
      <v-btn
        color="primary"
        class="text-none font-weight-bold"
        :disabled="!input.bed"
        @click="saveData()"
        width="150"
        >Daftar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'BedInpatient',
  mixins: [AlertMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: null,
    },
    medicalRecord: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      rooms: [],
      beds: [],
      roomIndex: 0,
      totalAvailable: 0,
      input: {
        room: '',
        bed: '',
      },
    };
  },
  mounted() {
    this.getRoom();
  },
  methods: {
    saveData() {
      const payload = {
        id_registration: this.selectedPatient.id_registration,
        id_emr: this.selectedPatient.id_emr,
        isRegistered: true,
        // id_room: this.input.room.id,
        id_bed: this.input.bed, // make sure bed is empty/available
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .post(Constant.apiUrl.concat('/inpatient'), payload)
        .then(() => {
          Swal.fire('Pendaftaran Pasien Rawat Inap Sukses!', '', 'success');
          this.$emit('close-form');
        })
        .catch(err => {
          this.showErrorAxios(err);
          // console.log(err);
        });
    },
    getRoom() {
      axios.get(Constant.apiUrl.concat('/master/bed/')).then(response => {
        const { data } = response.data;
        // console.log(data);
        this.beds = data.map(arr => {
          // this.beds.push(arr);
          return {
            ...arr,
          };
        });
        // console.log(this.beds);
        this.totalAvailable = data.filter(
          v => v.availability === 'available',
        ).length;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bed {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 130px 150px);
  grid-template-rows: repeat(2, 30px);
  row-gap: 10px;
  font-size: 0.9rem;
  text-align: left;
  height: 10vh;
}
.ward-choose {
  overflow: auto;
  height: 230px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: grey;
  }

  .hover:hover,
  .active {
    background-color: #288bcd !important;
    color: white !important;
  }
}
.bed-choose {
  background-color: white !important;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  &:hover,
  &.active {
    border: 2px solid #137bc0;
    box-shadow: 0px 8px 16px rgba(62, 62, 62, 0.1);
  }

  &.notAvailable {
    background-color: #ededed !important;
  }
}
.detail-beds {
  grid-template-columns: 9vw 15vw 15vw 14vw;
  height: 12vh;
  display: grid;
  text-align: left;
  width: auto;
}

.detail-bed {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}
</style>
