<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Input Barang Rumah Tangga</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-form v-model="valid" ref="form" class="col-12">
              <v-row no-gutters>
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Nama Barang</label>
                    </div>
                    <v-text-field
                      v-model="goods.name"
                      id="name"
                      class="detail-input__input-text"
                      required
                      :rules="rules.goodsName"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="barcode">Barcode</label>
                    </div>
                    <v-text-field
                      id="barcode"
                      v-model="goods.barcode"
                      class="detail-input__input-text"
                      :rules="rules.barcode"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="category">Kategori</label>
                    </div>
                    <v-combobox
                      class="detail-input__input-text"
                      v-model="goods.category"
                      :items="resource.category"
                      :rules="rules.category"
                    ></v-combobox>
                  </div>
                </v-col>
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="hpp">HPP</label>
                    </div>
                    <v-currency-field
                      id="hpp"
                      v-model="goods.hpp"
                      prefix="Rp. "
                      class="detail-input__input-text"
                      :rules="rules.hpp"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="hna">HNA</label>
                    </div>
                    <v-currency-field
                      id="hna"
                      v-model="goods.hna"
                      prefix="Rp. "
                      class="detail-input__input-text"
                      :rules="rules.hna"
                    />
                  </div>

                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="pabrik">Pabrik</label>
                    </div>
                    <v-combobox
                      class="detail-input__input-text"
                      v-model="goods.factory"
                      :items="resource.factory"
                      :rules="rules.factory"
                    ></v-combobox>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="pabrik">Minimal Stok</label>
                    </div>
                    <v-text-field
                      class="detail-input__input-text"
                      v-model="goods.min_stock"
                      :rules="rules.min_stock"
                    ></v-text-field>
                  </div>
                  <div class="detail-input">
                    <div
                      class="detail-input__label detail-input__label--position"
                    >
                      <label for="pabrik">Aktif</label>
                    </div>
                    <v-checkbox v-model="goods.isActive"></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col md="10" offset-md="1" class="d-flex align-center">
            <template>
              <v-data-table
                :headers="packaging_header"
                :items="goods.packaging"
                :items-per-page="goods.packaging.length"
                sort-by="calories"
                hide-default-footer
                class="table-kemasan"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Kemasan</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2 text-none"
                          v-bind="attrs"
                          v-on="on"
                          depressed
                        >
                          Tambah Kemasan
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Tambah Kemasan</span>
                        </v-card-title>

                        <v-container>
                          <v-row align-content="center" justify="center">
                            <v-col cols="12" class="d-flex justify-center">
                              <v-combobox
                                label="Kemasan"
                                class="padding"
                                dense
                                hide-details
                                single-line
                                menu-props="auto"
                                clearable
                                v-model="editedItem.package"
                                :items="resource.packages"
                              ></v-combobox>
                              <v-text-field
                                class="padding"
                                dense
                                v-model="editedItem.quantity"
                                label="Jumlah"
                                placeholder="0"
                              />
                              <v-combobox
                                label="Satuan"
                                class="padding"
                                dense
                                hide-details
                                single-line
                                menu-props="auto"
                                clearable
                                v-model="editedItem.package_unit"
                                :items="resource.packages"
                              ></v-combobox>
                              <v-checkbox
                                class="padding"
                                dense
                                v-model="editedItem.isDefault"
                                label="Satuan Terkecil"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="close" text
                            >Cancel</v-btn
                          >
                          <v-btn color="primary" @click="save" text>Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.isDefault`]="{ item }">
                  <span>{{ item.isDefault ? 'Ya' : 'Tidak' }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-col>
          <v-col cols="12" class="margin-top">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                width="100"
                text
                class="text-none"
                @click="onCloseButtonClick()"
              >
                Kembali
              </v-btn>
              <v-btn
                color="primary"
                width="100"
                class="text-none"
                depressed
                @click="
                  addNewGoods();
                  updateData();
                "
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
export default {
  name: 'InputNewGoods',
  mixins: [jwtMixin],
  data() {
    return {
      dialog: false,
      defaultItem: {
        isDefault: false,
        package: '',
        quantity: '',
        package_unit: '',
      },
      editedItem: {
        isDefault: false,
        package: '',
        quantity: '',
        package_unit: '',
      },
      editedIndex: -1,
      resource: {
        category: [],
        packages: [],
        factory: [],
      },
      packaging_header: [
        { text: 'Kemasan', value: 'package', align: 'center' },
        { text: 'Jumlah', value: 'quantity', align: 'center' },
        { text: 'Satuan', value: 'package_unit', align: 'center' },
        { text: 'Kemasan Terkecil', value: 'isDefault', align: 'center' },
        { text: '', value: 'action' },
      ],
      goods: {
        name: '',
        barcode: '',
        hna: 0,
        hpp: 0,
        min_stock: 0,
        auto_stock: 0,
        category: '',
        shelf: '',
        isActive: false,
        factory: '',
        packaging: [],
        timestamps: {
          created_by: '',
        },
      },
      rules: {
        goodsName: [v => !!v || 'Masukkan nama barang'],
        barcode: [v => !!v || 'Masukkan barcode'],
        category: [v => !!v || 'Pilih kategori'],
        preparation: [v => !!v || 'Pilih sediaan'],
        factory: [v => !!v || 'Pilih pabrik'],
        min_stock: [v => !!v || 'Masukkan minimal stok'],
        hpp: [v => !!v || 'Masukkan HPP'],
        hna: [v => !!v || 'Masukkan HNA'],
      },
      valid: true,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Kemasan' : 'Ubah Kemasan';
    },
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getFactory();
        this.getCategoryByMenu();
        this.getPackagesByMenu();
      });
    });
  },
  methods: {
    getFactory() {
      axios.get(Constant.apiUrl.concat('/master/factory')).then(response => {
        const resData = response.data.data;
        this.resource.factory = resData.map(prep => {
          return prep.name;
        });
      });
    },
    getCategoryByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/category/menu/Rumah Tangga'))
        .then(response => {
          const categoryData = response.data.data;
          this.resource.category = categoryData.map(cat => {
            return cat.name;
          });
        });
    },
    getPackagesByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/package/menu/inventory'))
        .then(response => {
          const packageData = response.data.data;
          this.resource.packages = packageData.map(pac => {
            return pac.name;
          });
        });
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    updateData() {
      this.$emit('update-data');
    },

    editItem(item) {
      this.editedIndex = this.goods.packaging.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.goods.packaging.indexOf(item);
      this.goods.packaging.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (
        this.editedItem.package !== '' &&
        this.editedItem.quantity !== '' &&
        this.editedItem.package_unit !== ''
      ) {
        if (this.editedIndex > -1) {
          Object.assign(
            this.goods.packaging[this.editedIndex],
            this.editedItem,
          );
        } else {
          this.goods.packaging.push(this.editedItem);
        }
        this.close();
      }
    },
    addNewGoods() {
      if (this.$refs.form.validate()) {
        try {
          const goodsSave = { detail: [] };
          goodsSave.detail.push(this.goods);
          goodsSave.detail[0].timestamps.created_by = this.$store.getters.userLoggedIn.id;
          // eslint-disable-next-line no-unused-vars
          let ctr = 0;

          goodsSave.detail[0].packaging.forEach(e => {
            if (e.isDefault) {
              ctr += 1;
            }
          });

          if (ctr > 1) {
            Swal.fire(
              'Gagal disimpan',
              'Kemasan terkecil lebih dari 1',
              'error',
            );
          } else {
            axios
              .post(Constant.apiUrl.concat('/master/goods/'), {
                detail: goodsSave.detail,
              })
              .then(response => {
                if (response.status === 200) {
                  Swal.fire(
                    'Berhasil',
                    'Data barang berhasil ditambahkan',
                    'success',
                  );
                  this.onCloseButtonClick();
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan;
                  Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: message,
                  });
                } else if (error.request) {
                  Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Tidak dapat menyambung ke server',
                  });
                }
              });
          }
        } catch (error) {
          Swal.fire('Gagal', 'Terjadi kesalahan', 'error');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;

    &--position {
      position: relative;
      right: 140px;
    }
  }

  &__input-text {
    margin-left: 10px;
    max-width: 40%;
  }
}

.padding {
  padding-left: 10px;
}

.v-input__control {
  display: flex;
  align-self: center;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
