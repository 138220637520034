<template>
  <div>
    <v-toolbar flat outlined>
      <v-row align="center" class="header-page">
        <div>
          <!-- <v-toolbar-title class="font-weight-bold text-left">
              Poli Klinik Ibu dan Anak
            </v-toolbar-title> -->
          <h2 class="main-title">Poli Klinik Ibu dan Anak</h2>
          <h5 class="subtitle caption">
            {{ patient.served }} pasien dilayani, {{ patient.unserved }} pasien
            belum dilayani, dari {{ patient.total }} total pasien
            <v-progress-circular
              size="16"
              color="#adb5bd"
              width="2"
              :indeterminate="serveLoading"
              v-if="serveLoading"
            ></v-progress-circular>
          </h5>
        </div>
        <div class="px-0">
          <v-menu
            dense
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Pilih Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="date"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="
                $refs.menu.save(date);
                kia.pagination.page = 1;
                showAllKIAPoly();
                getUnit();
              "
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="px-0">
          <v-autocomplete
            placeholder="Pilih Dokter"
            class="ml-3"
            outlined
            dense
            :items="resource.doctor"
            item-value="doctorId"
            item-text="doctor"
            v-model="chooseDoctor"
            @change="
              kia.pagination.page = 1;
              showAllKIAPoly();
            "
            clearable
            :loading="loading"
            hide-details="false"
          ></v-autocomplete>
        </div>
        <div class="px-0">
          <v-btn color="grey" icon text @click="refresh()">
            <v-icon :class="{ refresh: isRefresh }">mdi-autorenew </v-icon>
          </v-btn>
        </div>
        <div class="px-0">
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </div>
      </v-row>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-data-table
          :headers="kia.headers"
          :items="kia.data"
          :items-per-page="itemsPerRow"
          :page.sync="kia.pagination.page"
          item-key="noRegist"
          :expanded="expanded"
          hide-default-footer
          @click:row="clickedRow"
          :loading="loading"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name }}</span
            ><v-chip
              v-if="item?.pcare?.visit && !item.pcare?.visit?.isSuccess"
              color="red"
              light
              small
              class="send-btn"
              height="500"
              ><v-icon left class="icon" color="white" x-small
                >mdi-send-outline</v-icon
              ></v-chip
            >
            <v-icon
              v-if="item?.pcare?.visit && item.pcare?.visit?.isSuccess"
              color="green"
              >mdi-check-circle</v-icon
            >
          </template>
          <template v-slot:[`item.nurseAssessment`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.nurseAssessment === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="item.activityStatus.nurseAssessment === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.doctorAssessment`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.doctorAssessment === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="item.activityStatus.doctorAssessment === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.serviceStatus === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon v-if="item.activityStatus.serviceStatus === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.laboratory`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.labStatus === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon v-if="item.activityStatus.labStatus === 'scheduled'"
              >mdi-clock-time-three-outline</v-icon
            >
            <v-icon v-if="item.activityStatus.labStatus === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.erecipe`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.recipeStatus === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon v-if="item.activityStatus.recipeStatus === 'scheduled'"
              >mdi-clock-time-three-outline</v-icon
            >
            <v-icon v-if="item.activityStatus.recipeStatus === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters>
                  <v-col cols="2" style="max-width: 195px"></v-col>
                  <v-col cols="2" class="text-left pl-3 grey--text caption">
                    <span
                      >Terdaftar {{ item.timestamps.created_at }} WIB <br />{{
                        item.date
                      }}</span
                    ></v-col
                  >
                  <v-col
                    cols="3"
                    class="text-left pl-4 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span>{{ item.gender }} {{ item.age }} Tahun</span></v-col
                  >
                  <v-col cols="2" class="text-left grey--text caption">{{
                    item.isNew
                  }}</v-col>
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 0)"
                          >
                            <font-awesome-icon icon="user" />
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 1)"
                          >
                            <font-awesome-icon icon="notes-medical" />
                          </v-btn>
                        </template>
                        <span>Asesmen</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            v-if="!item.isFinish"
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 2)"
                          >
                            <font-awesome-icon icon="syringe" />
                          </v-btn>
                        </template>
                        <span>Tindakan dan BHP</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-if="!item.isFinish"
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 3)"
                          >
                            <font-awesome-icon icon="prescription-bottle" />
                          </v-btn>
                        </template>

                        <span>E-Resep</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-if="!item.isFinish"
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item, 4)"
                          >
                            <font-awesome-icon icon="flask" />
                          </v-btn>
                        </template>

                        <span>Permintaan Laboratorium</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 5)"
                          >
                            <font-awesome-icon icon="print" />
                          </v-btn>
                        </template>
                        <span>Pembuatan Surat</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 6)"
                          >
                            <v-icon size="21" style="padding-top: 2px"
                              >mdi-printer</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Print Dokumen</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item, 7)"
                            v-if="!item.isFinish && !hasRoles().isSuperManager"
                          >
                            <font-awesome-icon icon="clipboard-check" />
                          </v-btn>
                        </template>

                        <span>Selesai</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-dialog
        v-model="dialog"
        :max-width="
          selectedForm === null ? 1400 : dialogWidth[selectedForm].width
        "
        :fullscreen="
          selectedForm === 1 || selectedForm === 4 || selectedForm === 3
        "
        persistent
      >
        <SocialDataPatients
          v-if="selectedForm === 0 && dialog"
          @close-form="closeForm"
          :medical-record="selectedPatient.rmNumber"
        />
        <assessment
          v-if="selectedForm === 1 && dialog"
          :patient-data="selectedPatient"
          @close-form="closeForm"
        />
        <BHPandAction
          v-else-if="selectedForm === 2 && dialog"
          :patient-data="selectedPatient"
          @close-form="closeForm"
        />
        <ERecipe
          v-else-if="selectedForm === 3 && dialog"
          :patient-data="selectedPatient"
          @close-form="closeForm"
        />
        <Warrant
          v-else-if="selectedForm === 5 && dialog"
          @close-form="closeForm"
          :patient-data="selectedPatient"
        />
        <PrintDocuments
          v-else-if="selectedForm === 6 && dialog"
          :props="{ generalConsent: generalConsentPatient }"
          @close-form="closeForm"
        />
        <LabRequest
          v-else-if="selectedForm === 4 && dialog"
          @close-dialog="closeForm"
          :patient-data="selectedPatient"
          :activityStatus="activityStatusLab"
          confirmationState="order"
        />
        <FinishService
          v-else-if="selectedForm === 7 && dialog"
          @close-form="closeForm"
          :patient-data="selectedPatient"
        />
      </v-dialog>
    </v-main>
    <v-footer color="white">
      <v-pagination
        v-model="kia.pagination.page"
        :length="pageLength"
        total-visible="5"
        @input="refreshAndAutoUpdate()"
      ></v-pagination>
    </v-footer>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import Warrant from './components/Letters/Warrant';
import Assessment from './components/KIApoly/Assessment';
import BHPandAction from './components/BHPandAction';
import ERecipe from './components/ERecipe';
import SocialDataPatients from '../SharedComponent/SocialData.vue';
import PrintDocuments from './components/PrintDocuments.vue';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import FinishService from './components/FinishService.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'KIAPoly',
  mixins: [jwtMixin, roleMixin],
  components: {
    Warrant,
    Assessment,
    BHPandAction,
    ERecipe,
    SocialDataPatients,
    LabRequest,
    FinishService,
    PrintDocuments,
  },
  data() {
    return {
      tab: null,
      expanded: [],
      dialog: false,
      isRefresh: false,
      serveLoading: false,
      dialogWarrant: false,
      generalConsentPatient: false,
      assessment: false,
      dialogBHP: false,
      dialogErecipe: false,
      dialogSocial: false,
      menu: false,
      date: '',
      isFilter: this.chooseDoctor ? true : false,
      search: '',
      patient: {
        total: '',
        served: '',
        unserved: '',
      },
      kia: {
        headers: [
          {
            text: 'No. Antrian',
            align: 'start',
            value: 'queue',
            width: '5%',
            sortable: false,
          },
          {
            text: 'No. Pendaftaran',
            value: 'noRegist',
            width: '5%',
            sortable: false,
          },
          { text: 'Nama', value: 'name', width: '15%', sortable: false },
          { text: 'No. RM.', value: 'noRM', width: '5%', sortable: false },
          {
            text: 'Tipe Penjamin',
            value: 'guarantorType',
            width: '7%',
            sortable: false,
          },
          {
            text: 'Nama Dokter',
            value: 'doctor',
            width: '10%',
            sortable: false,
          },
          {
            text: 'As. Perawat',
            value: 'nurseAssessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'As. Dokter',
            value: 'doctorAssessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'Tindakan',
            align: 'center',
            value: 'action',
            width: '6%',
            sortable: false,
          },
          {
            text: 'Lab',
            align: 'center',
            value: 'laboratory',
            width: '6%',
            sortable: false,
          },
          {
            text: 'E-Resep',
            value: 'erecipe',
            align: 'center',
            width: '6%',
            sortable: false,
          },
        ],
        data: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      resource: {
        doctor: [],
      },
      chooseDoctor: '',
      loading: false,
      selectedForm: null,
      dialogWidth: [
        { name: 'SocialData', width: 1200 },
        { name: 'GeneralPoly', width: 1400 },
        { name: 'BHPandAction', width: 1400 },
        { name: 'ERecipe', width: 1400 },
        { name: 'LabRequest', width: 1400 },
        { name: 'Warrant', width: 1400 },
        { name: 'PrintDocument', width: 346 },
        { name: 'FinishPatient', width: 346 },
      ],
      pageLength: 1,
      idUnit: '',
      unitName: '',
      activityStatusLab: '',
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.refreshAndAutoUpdate();
    });
    this.clearSnackbar();
  },
  watch: {
    date(newVal) {
      if (newVal === null) {
        this.showAllKIAPoly();
      }
    },
    search() {
      this.kia.pagination.page = 1;
      this.searchData(this);
    },
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'clearSnackbar', 'setShowDialog']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.kia.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 650),
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    refresh() {
      this.isRefresh = true;
      this.refreshAndAutoUpdate();
    },
    clickedRow(value) {
      if (this.expanded.length && this.expanded[0].number == value.number) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    selectItem(item, index) {
      this.changeSelectedPatient(item);
      this.setShowDialog(true);
      this.activityStatusLab = item.activityStatus.labStatus;
      this.selectedForm = index;
      this.dialog = true;
    },
    showAllKIAPoly() {
      const itemCount = this.itemsPerRow;
      let doctor = this.chooseDoctor;
      this.loading = true;
      const today = moment().format('YYYY-MM-DD');

      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/registration/unit?search=${this.search || ''}&page=${
              this.kia.pagination.page
            }&itemCount=${itemCount}&sort=&unit_name=Poli KIA&date=${this
              .date || today}&doctor=${doctor || ''}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data;
            this.pageLength = Math.ceil(
              response.data.length / this.itemsPerRow,
            );
            this.kia.data = data.map(patient => {
              const addressData = [patient.emr.social[0].address.text];
              if (patient.emr.social[0].address.sub_district) {
                addressData.push(patient.emr.social[0].address.sub_district);
              }
              if (patient.emr.social[0].address.district) {
                addressData.push(patient.emr.social[0].address.district);
              }
              if (patient.emr.social[0].address.city) {
                addressData.push(patient.emr.social[0].address.city);
              }
              if (patient.emr.social[0].address.province) {
                addressData.push(patient.emr.social[0].address.province);
              }

              return {
                id_registration: patient._id.toString(),
                //registration
                hasGeneralConsent: patient.emr?.document?.generalConsentCreated,
                id_emr: patient.id_emr.toString(),
                noRegist: patient.registration_number.toString(),
                isInformedConsentCreated:
                  patient.document?.informConsentCreated,
                polyId: patient.id_unit,
                // poly: patient.id_unit.name,
                doctorId: patient.doctor[0] ? patient.doctor[0]._id : '',
                doctor: patient.doctor[0]
                  ? patient.doctor[0].detail[0].name
                  : '',
                role: patient.doctor[0] ? patient.doctor[0].role[0].role : '',
                noQueue: patient.queue.number.toString(),
                pcare: patient?.pcare,
                queue: patient.queue_code
                  ? `${
                      patient.queue_code
                    }-${patient.queue.number.toString().padStart(3, '0')}`
                  : patient.queue.number.toString().padStart(3, '0'),
                isNew: patient.status.isNew ? 'Pasien Baru' : 'Pasien Lama',
                status: patient.status,
                isFinish: patient.queue.isFinish,
                time: patient.time,
                date: moment(patient.date)
                  .locale('id')
                  .format('LL'),
                profession: patient.emr.social[0].profession,
                returnStatus: patient.emr.checkup[0].assesment.return_status,

                //medical record
                noRM: patient.emr.medical_record_number,
                name: patient.emr.social[0].name,
                phoneNumber: patient.emr.social[0].phone_number,
                birthDate: moment(patient.emr.social[0].birth.date)
                  .locale('id')
                  .format('LL'),
                birthPlace: patient.emr.social[0].birth.place,
                age: patient.emr.social[0].age,
                gender: patient.emr.social[0].gender
                  ? 'Laki-laki'
                  : 'Perempuan',
                address: addressData.join(', '),
                identityType: patient.emr.social[0].identity.type,
                identityNumber: patient.emr.social[0].identity.number,
                // //assurance
                guarantorType: _.startCase(
                  patient.assurance.type,
                ).toUpperCase(),
                guarantorName: patient.emr.social[0].assurance.name,
                guarantorNumber: patient.emr.social[0].assurance.number,
                isProlanis: patient.emr.social[0].assurance.isProlanis,
                activityStatus: {
                  doctorAssessment:
                    patient.activity_status?.assesment_doctor_status ||
                    'not_scheduled',
                  nurseAssessment:
                    patient.activity_status?.assesment_nurse_status ||
                    'not_scheduled',
                  labStatus:
                    patient.activity_status?.lab_status || 'not_scheduled',
                  recipeStatus:
                    patient.activity_status?.recipe_status || 'not_scheduled',
                  serviceStatus:
                    patient.activity_status?.service_status || 'not_scheduled',
                },
                timestamps: {
                  created_at: moment(patient.timestamps.created_at)
                    .locale('id')
                    .format('LT'),
                },
              };
            });
          }
        })
        .catch(() => {
          this.kia.data = [];
        })
        .finally(() => {
          this.loading = false;
          this.isRefresh = false;
        });
    },
    getDoctor() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/staff/active?id_unit=${this.idUnit}&branch=${this.$store.getters.userLoggedIn.branch_id}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                doctorId: doctor._id,
                doctor: doctor.detail.name,
              };
            });
          })
          .catch(() => {
            this.resource.doctor = [];
          });
      });
    },
    close() {
      this.dialog = false;
      this.showAllKIAPoly();
    },
    changeSelectedPatient(item) {
      this.generalConsentPatient = item.hasGeneralConsent;
      const patientData = {
        id_emr: item.id_emr,
        id_registration: item.id_registration,
        isInformedConsentCreated: item.isInformedConsentCreated,
        noQueue: item.noQueue,
        rmNumber: item.noRM,
        patientType: item.status.type,
        isFinish: item.isFinish,
        meta: {
          name: item.name,
          phoneNumber: item.phoneNumber,
          birthDate: item.birthDate,
          gender: item.gender,
          age: item.age,
          address: item.address,
          identityType: item.identityType,
          identityNumber: item.identityNumber,
          doctor: item.doctor,
          doctorId: item.doctorId,
          role: item.role,
          poly: item.poly,
          id_poly: item.polyId,
          polyName: this.unitName,
          profession: item.profession,
          guarantorType: item.guarantorType,
          guarantorName: item.guarantorName,
          guarantorNumber: item.guarantorNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getUnit();
      this.showAllKIAPoly();
      this.interval = setInterval(this.showAllKIAPoly, 60000);
    },
    getUnit() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/unit?page=1&itemCount=8&search=Poli KIA&sort=name',
            ),
          )
          .then(response => {
            const [unit] = response.data.data;
            this.idUnit = unit._id.toString();
            this.unitName = unit.name;
            this.getDoctor();
            this.getServedCount(this.idUnit);
          });
      });
    },
    getServedCount(id) {
      this.serveLoading = true;
      const today = moment(new Date()).format('YYYY-MM-DD');
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/registration/count?id_unit=${id}&startDate=${this
                .date || today}&endDate=${this.date || today}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.patient = {
              total: data.servicedPatient + data.unservicedPatient,
              served: data.servicedPatient,
              unserved: data.unservicedPatient,
            };
          })
          .catch(() => {
            this.patient = {
              total: 0,
              served: 0,
              unserved: 0,
            };
          })
          .finally(() => {
            this.serveLoading = false;
          });
      });
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.send-btn {
  cursor: pointer;
  padding-top: 0.8rem;
  width: 2rem !important;
  padding-bottom: 3px;
  padding-right: 1rem;
  border-radius: 7px !important;
  margin-left: 5px;
  .icon {
    rotate: -45deg;
    margin-left: -1.1rem;
  }
}
.title {
  text-align: left;
  padding: 10px 25px 25px 25px;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-title {
  text-align: left;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  padding-bottom: 0 !important;
}
.subtitle {
  text-align: left;
  color: #adb5bd !important;
  font-weight: 500;
}

.header-page {
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.6fr 0.1fr 0.6fr;
  padding: 10px 10px 10px 10px;
}

.btn-refresh,
.search {
  margin-top: 20px;
}

.dataTable {
  padding: 0 25px;
  border: none !important;
  width: 100%;
}

.header {
  border-bottom: 1px solid #e7e7e7;
}

.icon {
  padding-left: 20px;
}

.hover:hover {
  color: #2196f3;
}

.filter-dokter {
  max-width: 15%;
}

::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
.my-border {
  border-left: 5px solid #59abe1;
  background-color: white;
}
</style>
