<template>
  <div>
    <v-toolbar flat outlined height="89">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="7">
            <v-toolbar-title class="font-weight-bold text-left">
              <h4>Rawat Inap</h4>
              <label class="caption" style="color: #adb5bd">
                Semua Bangsal : {{ this.totalInpatient.nonRegistered }} Pasien
                belum dilayani, {{ this.totalInpatient.registered }} Pasien
                sedang dilayani
              </label>
            </v-toolbar-title>
          </v-col>
          <v-col cols="2" class="px-0">
            <v-autocomplete
              placeholder="Nama DPJP"
              outlined
              dense
              :items="filter.doctor"
              item-value="doctorId"
              item-text="doctor"
              v-model="chooseDoctor"
              @change="
                table.pagination.page = 1;
                getInpatient();
              "
              clearable
              :loading="isLoading"
              hide-details="false"
            ></v-autocomplete>
          </v-col>
          <v-col cols="1" class="px-0" style="max-width: fit-content">
            <v-btn color="grey" icon text @click="refresh()">
              <v-icon :class="{ refresh: isRefresh }">mdi-autorenew </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="px-0" style="max-width: fit-content">
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :expanded="expanded"
        :search="search"
        :loading="isLoading"
        @click:row="
          (item, slot) => {
            slot.expand(!slot.isExpanded);
          }
        "
        :items-per-page="itemsPerRow"
        :page.sync="table.pagination.page"
        :height="height"
        hide-default-footer
        item-key="noRegist"
        single-expand
      >
        <template v-slot:[`item.no`]="{ item }">
          <td class="text-left">
            {{
              (table.pagination.page - 1) * itemsPerRow +
                table.items.indexOf(item) +
                1
            }}
          </td>
        </template>
        <template v-slot:[`item.guarantorType`]="{ item }">
          <td class="text-left text-uppercase">{{ item.guarantorType }}</td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-0">
            <v-card flat tile>
              <v-row no-gutters class="expand">
                <v-col></v-col>
                <v-col class="text-left grey--text caption">
                  <span
                    >Terdaftar {{ item.time }} <br />
                    {{ item.date }}</span
                  ></v-col
                >
                <v-col class="text-left grey--text caption">
                  <span
                    >{{ item.gender ? 'Laki-laki' : 'Perempuan' }}
                    {{ item.age }} Tahun</span
                  ></v-col
                >
                <v-col class="text-left grey--text caption"
                  >Pasien {{ item.isNew ? 'Baru' : 'Lama' }}</v-col
                >
              </v-row>
            </v-card>

            <v-card flat tile color="grey lighten-4" class="text-right">
              <v-container class="text-">
                <v-row no-gutters>
                  <v-col cols="12">
                    <!-- <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(0, item)"
                        >
                          <font-awesome-icon icon="user" />
                        </v-btn>
                      </template>
                      <span>Data Sosial</span>
                    </v-tooltip> -->

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(0, item)"
                        >
                          <font-awesome-icon icon="file-signature" />
                        </v-btn>
                      </template>
                      <span>Catatan Permintaan dan Pemberian Obat</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(1, item)"
                        >
                          <font-awesome-icon icon="notes-medical" />
                        </v-btn>
                      </template>
                      <span>Resume Medis</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(2, item)"
                        >
                          <font-awesome-icon icon="syringe" />
                        </v-btn>
                      </template>
                      <span>Tindakan dan BHP</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(3, item)"
                        >
                          <font-awesome-icon icon="prescription-bottle" />
                        </v-btn>
                      </template>

                      <span>E-Resep</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(4, item)"
                        >
                          <font-awesome-icon icon="print" />
                        </v-btn>
                      </template>
                      <span>Pembuatan Surat</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectItem(6, item)"
                        >
                          <font-awesome-icon icon="flask" />
                        </v-btn>
                      </template>

                      <span>Permintaan Laboratorium</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectItem(5, item)"
                          v-if="
                            !item.isFinishInpatient &&
                              !hasRoles().isSuperManager
                          "
                        >
                          <font-awesome-icon icon="clipboard-check" />
                        </v-btn>
                      </template>
                      <span>Selesai</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-main>
    <v-footer color="white">
      <v-pagination
        v-model="table.pagination.page"
        :length="pageLength"
        total-visible="5"
        @input="getInpatient"
      ></v-pagination>
    </v-footer>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      :max-width="selectedForm === null ? 1400 : widthMenu[selectedForm].width"
      :persistent="selectedForm !== 1"
      :fullscreen="
        selectedForm === 0 ||
          selectedForm === 1 ||
          selectedForm === 3 ||
          selectedForm === 6
      "
    >
      <DrugRecord
        v-if="selectedForm === 0 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <MedicalResume
        v-else-if="selectedForm === 1 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <BHPandAction
        v-else-if="selectedForm === 2 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <ERecipe
        v-else-if="selectedForm === 3 && dialog"
        :patient-data="selectedPatient"
        :is-inpatient="true"
        @close-form="closeForm"
      />
      <Warrant
        v-else-if="selectedForm === 4 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <LabRequest
        v-else-if="selectedForm === 6 && dialog"
        @close-dialog="closeForm"
        :patient-data="selectedPatient"
        :activityStatus="activityStatusLab"
        confirmationState="order"
      />
      <FinishInpatient
        v-else-if="selectedForm === 5 && dialog"
        @close-form="closeForm"
        :patient-data="selectedPatient"
      />
    </v-dialog>
  </div>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import Warrant from './components/Letters/Warrant';
import ERecipe from './components/ERecipe';
import DrugRecord from './components/Inpatient/DrugRecord.vue';
import BHPandAction from './components/BHPandAction';
import MedicalResume from './components/Inpatient/MedicalResume.vue';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import jwtMixin from '@/mixin/jwtMixin';
import roleMixin from '@/mixin/roleMixin';
import AlertMixin from '@/mixin/alertMixin';
import FinishInpatient from './components/FinishInpatient.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'InpatientDashboard',
  mixins: [jwtMixin, AlertMixin, roleMixin],
  components: {
    Warrant,
    ERecipe,
    DrugRecord,
    BHPandAction,
    MedicalResume,
    LabRequest,
    FinishInpatient,
  },
  data() {
    return {
      isLoading: false,
      search: '',
      chooseDoctor: '',
      pageLength: 1,
      unitName: '',
      expanded: [],
      isRefresh: false,
      selectedForm: null,
      activityStatusLab: '',
      dialog: false,
      filter: {
        doctor: [],
      },
      totalInpatient: {
        registered: 0,
        nonRegistered: 0,
      },
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'No. Bed', value: 'bed' },
          { text: 'No. Pendaftaran', value: 'noRegist' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Dokter', value: 'doctor' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      widthMenu: [
        { text: 'CPO', width: 1400 },
        { text: 'ResumeMedis', width: 1400 },
        { text: 'BHP', width: 1400 },
        { text: 'ERecipe', width: 1400 },
        { text: 'Warrant', width: 1400 },
        { text: 'FinishInpatient', width: 450 },
        { name: 'LabRequest', width: 1400 },
      ],
    };
  },
  watch: {
    search() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
    this.clearSnackbar();
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'clearSnackbar', 'setShowDialog']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 650),
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    refresh() {
      this.isRefresh = true;
      this.refreshAndAutoUpdate();
    },
    getUnit() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/unit?page=1&itemCount=8&search=Unit Rawat Inap&sort=name',
            ),
          )
          .then(response => {
            const [unit] = response.data.data;
            // this.idUnit = unit._id.toString();
            this.unitName = unit.name;
            // console.log(this.unitName);
            // this.getDoctor();
          });
      });
    },
    getDoctor() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/staff?branch=${this.$store.getters.userLoggedIn.branch_id}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.filter.doctor = data
              .filter(doctor => doctor.role.status === true)
              .map(doctor => {
                return {
                  doctorId: doctor._id,
                  doctor: doctor.detail.name,
                };
              });
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getInpatient();
      this.getUnit();
      this.getInpatientNonRegister();
      this.getDoctor();
      this.interval = setInterval(this.getInpatient, 60000);
    },
    async getInpatient() {
      try {
        const itemCount = this.itemsPerRow;
        let doctor = this.chooseDoctor;
        this.isLoading = true;
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/inpatient?search=${this.search || ''}&page=${
              this.table.pagination.page
            }&itemCount=${itemCount}&doctor=${doctor ||
              ''}&isAdmitted=true&branch=${
              this.$store.getters.userLoggedIn.branch_id
            }`,
          ),
        );

        const { data } = res.data;

        this.pageLength = Math.ceil(res.data.length / this.itemsPerRow);
        this.table.items = data.map(arr => {
          const addressData = [_.get(arr, 'social.address.text')];

          if (_.get(arr, 'social.address.sub_district', '')) {
            addressData.push(_.get(arr, 'social.address.sub_district', ''));
          }
          if (_.get(arr, 'social.address.district', '')) {
            addressData.push(_.get(arr, 'social.address.district', ''));
          }
          if (_.get(arr, 'social.address.city', '')) {
            addressData.push(_.get(arr, 'social.address.city', ''));
          }
          if (_.get(arr, 'social.address.province', '')) {
            addressData.push(_.get(arr, 'social.address.province', ''));
          }

          return {
            id_inpatient: arr._id,
            id_registration: arr.id_registration,
            id_emr: arr.id_emr,
            name: _.get(arr, 'social.name', ''),
            gender:
              _.get(arr, 'social.gender', '') === true
                ? 'Laki-laki'
                : 'Perempuan',
            birthDate: _.get(arr, 'social.birth.date', ''),
            noRegist: _.get(arr, 'registration[0].registration_number', ''),
            guarantorType: _.get(arr, 'registration[0].assurance.type', ''),
            age: _.get(arr, 'social.age', ''),
            isNew: _.get(arr, 'registration[0].status.isNew'),
            date: moment(
              _.get(arr, 'registration[0].timestamps.created_at', null),
            ).format('DD/MM/YYYY'),
            time: moment(
              _.get(arr, 'registration[0].timestamps.created_at', null),
            ).format('hh:mm [WIB]'),
            bed: _.get(arr, 'bed_number', ''),
            noRM: _.get(arr, 'medical_record_number', ''),
            role: _.get(arr, 'doctor_role.role', ''),
            doctor: _.get(arr, 'doctor_dpjp', ''),
            doctorId: _.get(arr, 'doctor', ''),
            noQueue: _.get(arr, 'registration[0].queue.number', ''),
            address: addressData.join(', '),
            identityType: _.get(arr, 'social.identity.type', ''),
            identityNumber: _.get(arr, 'social.identity.number', ''),
            id_poly: _.get(arr, 'registration[0].id_unit', ''),
            profession: _.get(arr, 'social.profession', ''),
            guarantorName: _.get(arr, 'social.assurance.name', ''),
            guarantorNumber: _.get(arr, 'social.assurance.number', ''),
            isFinishInpatient: _.get(
              arr,
              'registration[0].queue.isFinishInpatient',
              '',
            ),
            activityStatus: {
              labStatus: _.get(
                arr,
                'registration[0].activity_status.lab_status',
                'not_scheduled',
              ),
            },
          };
        });
      } catch (error) {
        this.table.items = [];
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
        // this.pageLength = Math.ceil(this.table.items.length / 5);
        this.totalInpatient.registered = this.table.items.length;
      }
    },
    async getInpatientNonRegister() {
      this.isLoading = true;
      const res = await axios.get(
        Constant.apiUrl.concat('/letter/control-inpatient-letter/all/'),
      );
      this.totalInpatient.nonRegistered = res.data.data.length;
    },
    changeSelectedPatient(item) {
      const patientData = {
        id_emr: item.id_emr,
        id_registration: item.id_registration,
        rmNumber: item.noRM,
        noQueue: item.noQueue,
        id_inpatient: item.id_inpatient,
        meta: {
          name: item.name,
          birthDate: moment(item.birthDate).format('DD/MM/YYYY'),
          gender: item.gender,
          age: item.age,
          address: item.address,
          identityType: item.identityType,
          identityNumber: item.identityNumber,
          doctor: item.doctor,
          doctorId: item.doctorId,
          polyName: this.unitName,
          role: item.role,
          id_poly: item.id_poly,
          profession: item.profession,
          guarantorType: item.guarantorType,
          guarantorName: item.guarantorName,
          guarantorNumber: item.guarantorNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
    },
    selectItem(index, item) {
      this.changeSelectedPatient(item);
      this.activityStatusLab = item.activityStatus.labStatus;
      this.selectedForm = index;
      this.setShowDialog(true);
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.expand {
  grid-template-columns: 0.6fr 0.7fr 1.1fr 1.2fr 1fr 1fr 1fr;
  display: grid;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
