<template>
  <div>
    <v-layout v-if="nextPage === 0">
      <v-flex>
        <v-layout>
          <v-flex xs6 mt-3 ml-6 class="title-head">
            <h2>Laporan Jumlah Kepesertaan Pasien</h2>
            <h5 class="title-head__sub">Update: {{ timestamp }}</h5>
          </v-flex>
          <v-flex class="d-flex align-center justify-center" mt-3>
            <v-btn
              class="mr-2"
              color="grey"
              to="/reporting/laporan-pelayanan/"
              icon
              text
            >
              <font-awesome-icon :to="routeBack" icon="home" />
            </v-btn>
          </v-flex>
          <v-flex class="d-flex align-center justify-center" mt-3>
            <font-awesome-icon
              icon="sync-alt"
              class="btn-refresh"
              @click="refresh()"
              :class="{ loadingrefresh: refreshLoad }"
            />
          </v-flex>
          <v-flex mt-3 xs2 mr-3>
            <v-layout>
              <font-awesome-icon
                icon="calendar-alt"
                color="#3498DB"
                class="mt-4 ml-3 mr-2 fa-2x"
              ></font-awesome-icon>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Pilih tanggal awal"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :dateOne="date"
                  v-model="date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <v-flex mt-3 xs2 mr-3>
            <v-layout>
              <font-awesome-icon
                icon="calendar-alt"
                color="#3498DB"
                class="mt-4 ml-3 mr-2 fa-2x"
              ></font-awesome-icon>
              <v-menu
                ref="menuStop"
                v-model="menuStop"
                :close-on-content-click="false"
                :return-value.sync="dateStop"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStop"
                    label="Pilih tanggal akhir"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :dateTwo="dateStop"
                  v-model="dateStop"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStop = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuStop.save(dateStop)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout justify-space-around>
          <v-flex xs4>
            <v-card height="160" class="card-grad ml-6" elevation="0">
              <v-layout>
                <v-flex class="label-text" pl-4 pt-7>
                  <p style="font-size: 13px; font-weight: 500">
                    Kepesertaan Pasien
                  </p>
                  <p style="font-size: 14px; font-weight: 700">BPJS</p>
                  <p
                    v-show="!isLoading.bpjs"
                    style="font-size: 22px; font-weight: 700"
                  >
                    {{ amountBpjs.length }} pasien
                  </p>
                  <v-progress-circular
                    v-show="isLoading.bpjs"
                    indeterminate
                    color="white"
                    class="pt-3"
                  ></v-progress-circular>
                  <a
                    v-show="!isLoading.bpjs"
                    href="#"
                    @click="
                      nextPage = 1;
                      getDetail(0);
                    "
                    class="href"
                    style="font-size: 12px; font-weight: 500; padding-top: 12px"
                    >Lihat Detail ></a
                  >
                </v-flex>
                <v-img :src="picLocation[0]" class="patient-img"></v-img>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card height="160" class="card-grad2 ml-6" elevation="0">
              <v-layout>
                <v-flex class="label-text" pl-4 pt-7>
                  <p style="font-size: 13px; font-weight: 500">
                    Kepesertaan Pasien
                  </p>
                  <p style="font-size: 14px; font-weight: 700">Umum</p>
                  <p
                    v-show="!isLoading.general"
                    style="font-size: 22px; font-weight: 700"
                  >
                    {{ amountGeneral.length }} pasien
                  </p>
                  <v-progress-circular
                    v-show="isLoading.general"
                    indeterminate
                    color="white"
                    class="pt-3"
                  ></v-progress-circular>
                  <a
                    v-show="!isLoading.general"
                    href="#"
                    @click="
                      nextPage = 1;
                      getDetail(1);
                    "
                    class="href"
                    style="font-size: 12px; font-weight: 500; padding-top: 12px"
                    >Lihat Detail ></a
                  >
                </v-flex>
                <v-img :src="picLocation[1]" class="patient-img"></v-img>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs4 class="pr-3">
            <v-card height="160" class="card-grad3 ml-6" elevation="0">
              <v-layout>
                <v-flex class="label-text" pl-4 pt-7>
                  <p style="font-size: 13px; font-weight: 500">
                    Kepesertaan Pasien
                  </p>
                  <p style="font-size: 14px; font-weight: 700">Asuransi</p>
                  <p
                    v-show="!isLoading.isurance"
                    style="font-size: 22px; font-weight: 700"
                  >
                    {{ amountIsurance.length }} pasien
                  </p>
                  <v-progress-circular
                    v-show="isLoading.isurance"
                    indeterminate
                    color="white"
                    class="pt-3"
                  ></v-progress-circular>
                  <a
                    v-show="!isLoading.isurance"
                    href="#"
                    @click="
                      nextPage = 1;
                      getDetail(2);
                    "
                    class="href"
                    style="font-size: 12px; font-weight: 500"
                    >Lihat Detail ></a
                  >
                </v-flex>
                <v-img :src="picLocation[2]" class="patient-img"></v-img>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex mt-3>
            <v-card
              width="850"
              class="patient-graph rounded-xl ml-6 mt-3"
              elevation="0"
            >
              <v-layout>
                <v-flex class="text-left ml-3 mt-3">
                  <h3>
                    Jumlah Kepesertaan Pasien
                    <v-progress-circular
                      class="pl-5"
                      color="primary"
                      v-if="isLoadingGraph"
                      indeterminate
                      width="2"
                      size="20"
                    ></v-progress-circular>
                  </h3>
                  <h5 style="color: #adb5bd">
                    Rata-rata angka jumlah kepesertaan Neurovi Clinic Medical
                    System tahun ini
                  </h5>
                </v-flex>
              </v-layout>
              <div id="chart">
                <apexchart
                  ref="graph"
                  type="line"
                  height="350"
                  :options="patientGraph.chartOptions"
                  :series="patientGraph.series"
                ></apexchart>
              </div>
            </v-card>
          </v-flex>
          <v-flex mt-6 class="px-3">
            <v-card height="140" class="total-card mb-15" elevation="0">
              <v-layout>
                <v-flex class="label-text text-left mt-4 ml-5">
                  <p style="font-size: 13px; font-weight: 500">
                    Jumlah Kepesertaan
                  </p>
                  <p style="font-size: 14px; font-weight: 700">
                    Seluruh Pasien
                  </p>
                  <p
                    style="font-size: 22px; font-weight: 700; padding-top: 30px"
                  >
                    {{
                      amountBpjs.length +
                        amountGeneral.length +
                        amountIsurance.length
                    }}
                    Pasien
                  </p>
                </v-flex>
                <v-img :src="picLocation[3]" class="patient-img"></v-img>
              </v-layout>
            </v-card>
            <h4 class="text-left mb-4 pl-4">Keterangan Warna</h4>
            <div class="grid-legend">
              <v-btn
                v-for="(item, index) in patientGraph.series"
                :key="index"
                :color="patientGraph.chartOptions.colors[index]"
                depressed
                class="text-capitalize white--text mb-2 legend-btn"
                >Pasien {{ item.name }}</v-btn
              >
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <MembershipDetail
      :selected="selected"
      :dateOne="date"
      :dateTwo="dateStop"
      v-if="nextPage === 1"
    />
  </div>
</template>

<script>
import patient from '@/assets/image/memberReport_laying-patient.svg';
import doctor from '@/assets/image/memberReport_doctor.svg';
import consulting from '@/assets/image/memberReport_consulting.svg';
import total from '@/assets/image/memberReport_total.svg';
import MembershipDetail from '../Membership/MembershipDetail';

import Constant from '@/const';
const axios = require('axios');
// const moment = require('moment-timezone');

export default {
  components: {
    MembershipDetail,
  },
  data() {
    return {
      amountBpjs: '',
      amountGeneral: '',
      amountIsurance: '',
      refreshLoad: false,
      picLocation: [patient, doctor, consulting, total],
      menu: false,
      isLoadingGraph: false,
      menuStop: false,
      nextPage: 0,
      date: '',
      dateStop: '',
      timestamp: '',
      selected: 0,
      isLoading: {
        bpjs: false,
        isurance: false,
        general: false,
      },
      patientGraph: {
        series: [
          {
            name: 'UMUM',
            data: [],
          },
          {
            name: 'BPJS',
            data: [],
          },
          {
            name: 'ASURANSI',
            data: [],
          },
        ],
        chartOptions: {
          colors: ['#f57002', '#2596be', '#dd62f0'],
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getDataBpjs();
      this.getDataGeneral();
      this.getDataIsurance();
      this.getGraph();
    });
  },
  watch: {
    date() {
      this.getDataBpjs();
      this.getDataGeneral();
      this.getDataIsurance();
    },
    dateStop() {
      this.getDataBpjs();
      this.getDataGeneral();
      this.getDataIsurance();
    },
  },
  methods: {
    getDetail(tab) {
      this.selected = tab;
    },
    async getGraph() {
      this.isLoadingGraph = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/report/patient-enrollment/graph/all?branch=${this.$store.getters.userLoggedIn.branch_id}`,
          ),
        );
        const { asuransi, bpjs, umum } = response.data.data.graph;
        this.$refs.graph.updateSeries([
          {
            name: 'UMUM',
            data: umum,
          },
          {
            name: 'BPJS',
            data: bpjs,
          },
          {
            name: 'ASURANSI',
            data: asuransi,
          },
        ]);
      } catch {
        this.isLoadingGraph = false;
      }
    },
    reouteBack() {
      return '/reporting/laporan-pelayanan/';
    },
    refresh() {
      this.refreshLoad = true;
      this.date = '';
      this.dateStop = '';
      this.getDataBpjs();
      this.getDataGeneral();
      this.getDataIsurance();
    },
    getDataBpjs() {
      this.isLoading.bpjs = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/patient-enrollment/bpjs?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&start_date=${this.date ? this.date : ''}&last_date=${
              this.dateStop ? this.dateStop : ''
            }`,
          ),
        )
        .then(response => {
          const getDataBpjs = response.data;
          this.amountBpjs = getDataBpjs;
        })
        .catch(() => {
          this.amountBpjs = [];
        })
        .finally(() => {
          this.isLoading.bpjs = false;
          this.refreshLoad = false;
        });
    },

    getDataGeneral() {
      this.isLoading.general = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/patient-enrollment/umum?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&start_date=${this.date ? this.date : ''}&last_date=${
              this.dateStop ? this.dateStop : ''
            }`,
          ),
        )
        .then(response => {
          const getDataGeneral = response.data;

          this.amountGeneral = getDataGeneral;
        })
        .catch(() => {
          this.amountGeneral = [];
        })
        .finally(() => {
          this.isLoading.general = false;
          this.refreshLoad = false;
        });
    },

    getDataIsurance() {
      this.isLoading.isurance = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/patient-enrollment/asuransi?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&start_date=${this.date ? this.date : ''}&last_date=${
              this.dateStop ? this.dateStop : ''
            }`,
          ),
        )
        .then(response => {
          const getDataInsurance = response.data;

          this.amountIsurance = getDataInsurance;
        })
        .catch(() => {
          this.amountIsurance = [];
        })
        .finally(() => {
          this.isLoading.isurance = false;
          this.refreshLoad = false;
        });
    },
    getNow() {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const today = new Date();
      const date =
        today.getDate() +
        ' ' +
        monthNames[today.getMonth()] +
        ' ' +
        today.getFullYear();
      this.timestamp = date;
    },
  },
  created() {
    setInterval(this.getNow, 500);
  },
};
</script>

<style lang="scss" scoped>
.grid-legend {
  display: grid;
  width: 95%;
  grid-template-rows: repeat(3, 1fr);
  row-gap: 5px;
}
.title-head {
  text-align: left;
  &__sub {
    color: #828282;
  }
}
.btn-refresh {
  cursor: pointer;
}
.loadingrefresh {
  -webkit-animation: fa-spin 0.2s infinite linear;
  -moz-animation: fa-spin 0.2s infinite linear;
  -o-animation: fa-spin 0.2s infinite linear;
  animation: fa-spin 0.2s infinite linear;
}
.card-grad {
  background-image: linear-gradient(to right, #80a1ff, #b2d1ff);
}
.card-grad2 {
  background-image: linear-gradient(to right, #ff8a58, #ffd08d);
}
.card-grad3 {
  background-image: linear-gradient(to right, #a199ff, #ddb2ff);
}
.label-text {
  text-align: left;
  color: white;
  z-index: 1;
}
p {
  margin-bottom: 0 !important;
}
.patient-img {
  position: absolute;
  z-index: 0;
}
.patient-graph {
  background-color: #f0f9ff;
}
.total-card {
  background-image: linear-gradient(to right, #e599ff, #c780ff);
}
</style>
