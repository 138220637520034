<template>
  <div>
    <v-toolbar flat>
      <v-row class="pt-6" align="center">
        <v-col cols="3" class="text-left">
          <v-toolbar-title class="font-weight-bold float-left">
            <h4>Klinik IGD</h4>
            <label class="caption" style="color: #adb5bd">
              Pasien belum dilayani, Pasien sedang dilayani
            </label>
          </v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex">
          <v-menu
            dense
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Pilih Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="date"
                clearable
                hide-details="false"
              ></v-text-field>
              <!-- @click:clear="getGeneralPoly()" -->
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="
                $refs.menu.save(date);
                getIGDPatient();
              "
            ></v-date-picker>
          </v-menu>
          <v-btn class="mx-2" color="grey" icon text @click="getIGDPatient('')">
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
        </v-col>
        <!-- <v-col cols="1" class="px-0">
        </v-col> -->
        <v-col cols="3" class="pl-0">
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
            @input="getIGDPatient(search)"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-tabs
      v-model="tab"
      centered
      style="border-bottom: 3px solid #f4f5f5"
      class="pt-3"
    >
      <v-tab key="emergency" class="text-none font-weight-bold"
        >Emergency</v-tab
      >
      <v-tab class="text-none font-weight-bold">Non-Emergency</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-data-table
          :headers="table.emergency.headers"
          :items="table.emergency.items"
          :expanded="expanded"
          :loading="loading"
          :page.sync="table.emergency.pagination.page"
          :items-per-page="5"
          hide-default-footer
          single-expand
          item-key="registNo"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-container class="pt-0" fluid>
                  <v-row no-gutters>
                    <v-col cols="1"></v-col>
                    <v-col
                      cols="2"
                      offset="1"
                      class="text-left grey--text caption"
                    >
                      <span>Terdaftar {{ item.time }} {{ item.date }}</span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-left ml-3 pl-15 grey--text caption"
                    >
                      <span
                        >{{ item.gender ? 'Laki-laki' : 'Perempuan' }} <br />
                        {{ item.age }} Tahun</span
                      >
                    </v-col>
                    <v-col cols="2" class="text-left pl-10 grey--text caption">
                      Pasien {{ item.isNew ? 'Baru' : 'Lama' }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right pa-3">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(0, item)"
                        >
                          <font-awesome-icon icon="file-signature" />
                        </v-btn>
                      </template>
                      <span>Catatan Permintaan dan Pemberian Obat</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="on"
                          @click="selectItem(1, item)"
                        >
                          <font-awesome-icon icon="user" />
                        </v-btn>
                      </template>
                      <span>Data Sosial</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(2, item)"
                        >
                          <font-awesome-icon icon="file-signature" />
                        </v-btn>
                      </template>
                      <span>Triase</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(3, item)"
                        >
                          <font-awesome-icon icon="syringe" />
                        </v-btn>
                      </template>
                      <span>Tindakan dan BHP</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(4, item)"
                        >
                          <font-awesome-icon icon="notes-medical" />
                        </v-btn>
                      </template>
                      <span>Asesmen</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(5, item)"
                        >
                          <font-awesome-icon icon="prescription-bottle" />
                        </v-btn>
                      </template>

                      <span>E-Resep</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(6, item)"
                        >
                          <font-awesome-icon icon="flask" />
                        </v-btn>
                      </template>
                      <span>Permintaan Lab</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                      <template #activator="{ on: tooltip }">
                        <v-btn icon class="mx-2 hover" v-on="{ ...tooltip }">
                          <font-awesome-icon icon="clipboard-check" />
                        </v-btn>
                      </template>
                      <span>Selesai</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </td>
          </template>
        </v-data-table>
        <v-footer color="white">
          <v-container fluid>
            <v-row align="center">
              <v-pagination
                v-model="table.emergency.pagination.page"
                :length="igdPatientEmergency"
                :total-visible="5"
              ></v-pagination>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!hasRoles().isSuperManager"
                color="primary"
                fab
                dark
                @click="selectItem(2, null)"
                elevation="0"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-footer>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="table.nonemergency.headers"
          :items="table.nonemergency.items"
          hide-default-footer
          :expanded="expanded"
          :page.sync="table.nonemergency.pagination.page"
          :items-per-page="5"
          single-expand
          :loading="loading"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-container class="pt-0">
                  <v-row no-gutters>
                    <v-col cols="2"></v-col>
                    <v-col cols="2" class="text-left grey--text caption">
                      <span>Terdaftar {{ item.time }} {{ item.date }}</span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-left ml-3 pl-15 grey--text caption"
                    >
                      <span
                        >{{ item.gender ? 'Laki-laki' : 'Perempuan' }} <br />
                        {{ item.age }} Tahun</span
                      >
                    </v-col>
                    <v-col cols="2" class="text-left pl-10 grey--text caption">
                      Pasien {{ item.isNew ? 'Baru' : 'Lama' }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right pa-3">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(0, item)"
                        >
                          <font-awesome-icon icon="file-signature" />
                        </v-btn>
                      </template>
                      <span>Catatan Permintaan dan Pemberian Obat</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="on"
                          @click="selectItem(1, item)"
                        >
                          <font-awesome-icon icon="user" />
                        </v-btn>
                      </template>
                      <span>Data Sosial</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(2, item)"
                        >
                          <font-awesome-icon icon="file-signature" />
                        </v-btn>
                      </template>
                      <span>Triase</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(3, item)"
                        >
                          <font-awesome-icon icon="syringe" />
                        </v-btn>
                      </template>
                      <span>Tindakan dan BHP</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(4, item)"
                        >
                          <font-awesome-icon icon="notes-medical" />
                        </v-btn>
                      </template>
                      <span>Asesmen</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(5, item)"
                        >
                          <font-awesome-icon icon="prescription-bottle" />
                        </v-btn>
                      </template>

                      <span>E-Resep</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(6, item)"
                        >
                          <font-awesome-icon icon="flask" />
                        </v-btn>
                      </template>
                      <span>Permintaan Lab</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn icon class="mx-2 hover" v-on="{ ...tooltip }">
                          <font-awesome-icon icon="clipboard-check" />
                        </v-btn>
                      </template>
                      <span>Selesai</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </td>
          </template>
        </v-data-table>
        <v-footer color="white">
          <v-container fluid>
            <v-row align="center">
              <v-pagination
                v-model="table.nonemergency.pagination.page"
                :length="igdPatientNon"
                :total-visible="5"
              ></v-pagination>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                fab
                v-if="!hasRoles().isSuperManager"
                dark
                @click="selectItem(2, null)"
                elevation="0"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-footer>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar?.show"
      elevation="0"
      :color="snackbar?.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar?.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>

    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="
        selectedForm === 4 ||
          selectedForm === 6 ||
          selectedForm === 5 ||
          selectedForm === 0
      "
      :width="selectedForm !== null ? widthMenu[selectedForm].width : 1200"
    >
      <DrugRecord
        v-if="selectedForm === 0 && dialog"
        :patient-data="selectedPatient"
        @close-form="dialog = false"
      />
      <SocialDataPatient
        v-if="selectedForm === 1 && dialog"
        :medical-record="selectedPatient.rmNumber"
        @close-form="dialog = false"
      />
      <Triase
        v-else-if="selectedForm === 2 && dialog"
        :patient-data="selectedPatient"
        @close-form="dialog = false"
      />
      <BHPandAction
        v-else-if="selectedForm === 3 && dialog"
        @close-form="dialog = false"
        :patient-data="selectedPatient"
      />
      <IGDAssessment
        v-else-if="selectedForm === 4 && dialog"
        :patient-data="selectedPatient"
        @close-form="dialog = false"
      />
      <ERecipe
        v-else-if="selectedForm === 5 && dialog"
        :patient-data="selectedPatient"
        @close-form="dialog = false"
      />
      <LabRequest
        v-else-if="selectedForm === 6 && dialog"
        :patient-data="selectedPatient"
        @close-dialog="dialog = false"
        :activityStatus="activityStatusLab"
        confirmationState="order"
      />
    </v-dialog>
  </div>
</template>

<script>
import SocialDataPatient from '../SharedComponent/SocialData.vue';
import IGDAssessment from './components/IGDclinic/IGDAssessment';
import Triase from './components/IGDclinic/Triase';
import BHPandAction from './components/BHPandAction';
import ERecipe from './components/ERecipe';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import DrugRecord from './components/Inpatient/DrugRecord.vue';
import Constant from '@/const';
import roleMixin from '@/mixin/roleMixin';
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);

const axios = require('axios');

export default {
  name: 'IGDClinic',
  mixins: [roleMixin],
  components: {
    DrugRecord,
    IGDAssessment,
    Triase,
    BHPandAction,
    ERecipe,
    SocialDataPatient,
    LabRequest,
  },
  data() {
    return {
      tab: null,
      menu: false,
      date: '',
      expanded: [],
      dialog: false,
      drugRecord: false,
      patientProgress: false,
      igdPatientEmergency: 0,
      igdPatientNon: 0,
      loading: false,
      table: {
        emergency: {
          headers: [
            { text: 'No Triase', value: 'triase_number' },
            { text: 'No. Pendaftaran', value: 'registNo' },
            { text: 'Nama', value: 'name' },
            { text: 'No. RM', value: 'rm_no' },
            { text: 'Tipe Penjamin', value: 'guarantorType' },
            { text: 'Dokter', value: 'doctor' },
          ],
          items: [],
          pagination: {
            descending: false,
            page: 1,
            rowsPerPage: 5,
          },
        },
        nonemergency: {
          headers: [
            { text: 'No Triase', value: 'triase_number' },
            { text: 'No. Pendaftaran', value: 'registNo' },
            { text: 'Nama', value: 'name' },
            { text: 'No. RM', value: 'rm_no' },
            { text: 'Tipe Penjamin', value: 'guarantorType' },
            { text: 'Dokter', value: 'doctor' },
          ],
          items: [],
          pagination: {
            descending: false,
            page: 1,
            rowsPerPage: 5,
          },
        },
      },

      selectedForm: null,
      widthMenu: [
        { text: 'CPO', width: 1400 },
        { name: 'SocialData', width: 1200 },
        { name: 'Triase', width: 1200 },
        { name: 'BHPandAction', width: 1200 },
        { name: 'IGDAssessment', width: 1200 },
        { name: 'ERecipe', width: 1200 },
        { name: 'LabRequest', width: 1400 },
      ],
      search: '',
      activityStatusLab: '',
    };
  },
  mounted() {
    this.getIGDPatient('');
    this.clearSnackbar();
  },
  watch: {
    date() {
      this.getIGDPatient('');
    },
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'clearSnackbar', 'setShowDialog']),
    selectItem(val, item) {
      this.changeSelectedPatient(item);
      this.setShowDialog(true);
      this.activityStatusLab = item?.labStatus;
      this.selectedForm = val;
      this.dialog = true;
    },
    async getIGDPatient(keyword) {
      try {
        this.loading = true;
        const date = moment(new Date())
          .locale('id')
          .format('YYYY-MM-DD');

        const res = await axios.get(
          Constant.apiUrl.concat(
            `/patient/registration/unit?search=${keyword ||
              ''}&unit_name=IGD&date=${
              this.date === '' || !this.date ? date : this.date
            }`,
          ),
        );

        const { data } = res.data;
        const igd = data.map(arr => {
          return {
            id: arr._id,
            id_emr: arr.id_emr,
            queueNo: arr.queue.number,
            registNo: arr.registration_number,
            guarantorType: arr.assurance.type.toUpperCase(),
            pacs: arr.triase[0].detail_triase[0].triase_level.pacs,
            name: arr.emr.social[0].name,
            rm_no: arr.emr.medical_record_number,
            doctor: arr.doctor.length === 0 ? '' : arr.doctor[0],
            date: moment(arr.timestamps.created_at)
              .locale('id')
              .format('DD/MM/YYYY'),
            time: moment(arr.timestamps.created_at).format('HH:mm [WIB]'),
            gender: arr.emr.social[0].gender,
            age: arr.emr.social[0].age,
            isNew: arr.status.isNew,
            labStatus: arr.activity_status.lab_status || 'not_scheduled',
            birthDate: arr.emr.social[0].birth.date,
            triase_number: arr.triase[0].triase_number,
            address: arr.emr.social[0].address.text,
            identityType: arr.emr.social[0].identity.type,
            identityNumber: arr.emr.social[0].identity.number,
            id_poly: arr.id_unit,
            guarantorName: arr.assurance.name,
            guarantorNumber: arr.assurance.number,
            unitName: arr.unit[0].name,
          };
        });

        this.table.nonemergency.items = igd.filter(
          x => x.pacs === 4 || x.pacs === 5,
        );
        this.table.emergency.items = igd.filter(
          x => x.pacs === 1 || x.pacs === 2 || x.pacs === 3,
        );
        this.igdPatientEmergency = Math.ceil(
          this.table.emergency.items.length / 5,
        );
        this.igdPatientNon = Math.ceil(
          this.table.nonemergency.items.length / 5,
        );
      } catch (error) {
        this.table.emergency.items = [];
        this.table.nonemergency.items = [];
      } finally {
        this.loading = false;
      }
    },
    changeSelectedPatient(item) {
      const patientData =
        item === null
          ? null
          : {
              id_registration: item.id,
              rmNumber: item.rm_no,
              noQueue: item.queueNo,
              triaseNumber: item.triase_number,
              id_emr: item.id_emr,
              meta: {
                name: item.name,
                birthDate: item.birthDate,
                gender: item.gender,
                age: item.age,
                address: item.address,
                identityType: item.identityType,
                identityNumber: item.identityNumber,
                doctor: item.doctor,
                id_poly: item.id_poly,
                guarantorType: item.guarantorType,
                guarantorName: item.guarantorName,
                guarantorNumber: item.guarantorNumber,
                polyName: item.unitName,
              },
            };
      this.$store.commit('changeSelectedPatient', patientData);
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
//   max-height: 95%;
//   min-height: 95%;
// }
// .btn-add {
//   position: absolute;
//   bottom: 50px;
//   right: 30px;
// }
.page {
  position: absolute;
  bottom: 50px;
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
