<template>
  <v-card>
    <v-container fluid>
      <div class="sticky">
        <div class="d-flex justify-space-between">
          <div class="text-sm-left mb-2 pl-3">
            <h2 class="heading_big mt-3">Permintaan Obat dan Alkes</h2>
          </div>
          <v-btn text fab @click="$emit('close-form')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row class="header-layout">
          <v-col>
            <v-row class="row-info">
              <v-col class="header-info-widget text-sm-left py-0" cols="5"
                ><span class="info-head">No. RM</span></v-col
              >
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head">{{ patient.rm_number }}</span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="5">
                <span class="info-head">Tanggal Lahir</span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head"
                  >{{ data.birthDate }} ({{ patient.age }} tahun)
                </span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="5">
                <span class="info-head">Tipe Penjamin</span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head">{{ patient.assurance }} </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="row-info">
              <v-col class="header-info-widget text-sm-left py-0" cols="5"
                ><span class="info-head">Nama</span></v-col
              >
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head"
                  >{{ patient.name }} ({{
                    patient.gender === 'Perempuan' ? 'P' : 'L'
                  }})</span
                >
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="5"
                ><span class="info-head">Unit</span></v-col
              >
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head">{{ patient.unit }}</span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="5"
                ><span class="info-head">DPJP</span></v-col
              >
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head">{{ patient.doctor }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pa-0"
            ><v-col class="header-info-widget text-sm-left pa-0" cols="12">
              <span class="label-head font-weight-bold">
                Riwayat Alergi
              </span>
            </v-col>
            <v-col class="text-sm-left px-0" cols="12">
              <span
                v-if="data.allergy.length === 0"
                class="red--text font-weight-bold label-head"
                >Tidak Ada Alergi</span
              >
              <v-chip
                v-for="(allergy, i) in data.allergy"
                :key="i"
                color="#EB4747"
                class="allergy text-capitalize mr-2 mb-2"
                label
                small
                caption
                >{{ allergy }}</v-chip
              >
            </v-col>
          </v-col>
        </v-row>
        <v-tabs v-model="menuSelected" centered grow class="menu-button">
          <v-tab>
            <label class="text-none font-weight-bold"> Permintaan Obat </label>
          </v-tab>
          <v-tab>
            <label class="text-none font-weight-bold">
              Permintaan Alkes
            </label>
          </v-tab>
          <v-tab>
            <label class="text-none font-weight-bold">
              History Permintaan dan Pemberian Obat
            </label>
          </v-tab>
        </v-tabs>
      </div>
      <div class="after-sticky">
        <v-tabs-items v-model="menuSelected">
          <v-tab-item>
            <v-row no-gutters>
              <drug-request
                :selected-patient="patient"
                :total-medtool="totalMedTool"
                :total-data="totalData"
                :drug-review="reviewDrug"
                :isTakeHome="isTakeHome"
                :patient="data"
                :recipe="recipe"
                :id_recipe="data.id_recipe"
                :id-pharmacy="patient.id"
                :recipe-reviewed="recipeReviewed"
                @change-data="getPatient()"
                @next="next()"
                @close-dialog-form="closeDialogForm()"
              ></drug-request>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row no-gutters>
              <ConsumableRequest
                @before="menuSelected = 0"
                @close-dialog-form="closeDialogForm()"
                @get-medtool="getPatient()"
                @review="recipeReviewed = true"
                :drug-review="reviewDrug"
                :total-medtool="totalMedTool"
                :recipe="recipe"
                :id-pharmacy="patient.id"
                :consumable="consumable"
                :totalData="totalData"
                :id_recipe="data.id_recipe"
                :id_registration="data.id_registration"
              />
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row no-gutters style="height: 65vh">
              <drug-history
                @before="menuSelected = 1"
                :historyDrug="historyDrug"
                @close-dialog-form="closeDialogForm()"
              ></drug-history>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row no-gutters style="height: 65vh">
              <drug-review
                :review-drug="reviewDrug"
                :patient="patient"
              ></drug-review>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import DrugRequest from './component/DrugRequest';
import DrugReview from './component/DrugReview';
import DrugHistory from './component/DrugHistory';
import ConsumableRequest from './component/ConsumableRequest';
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');
const _ = require('lodash');

export default {
  components: {
    DrugRequest,
    DrugReview,
    DrugHistory,
    ConsumableRequest,
  },
  props: {
    patient: Object,
    isTakeHome: Boolean,
  },
  data() {
    return {
      menuSelected: 0,
      totalData: 0,
      totalMedTool: 0,
      expanded: false,
      recipeReviewed: false,
      consumable: [],
      recipe: {},
      data: [],
      historyDrug: [],
      drug: [],
      dialog: false,
      reviewDrug: {
        administrative: '',
        clinical: '',
        drugs_review: '',
        pharmaceuticals: '',
        id_staff: '',
        type: '',
      },
    };
  },
  computed: {
    allergyList() {
      let allergy = '';
      for (let i = 0; i < this.data.allergy.length; i++) {
        allergy = allergy
          .concat(i + 1)
          .concat('. ')
          .concat(this.data.allergy[i])
          .concat(' ');
      }
      return allergy || 'Tidak ada alergi';
    },
  },
  methods: {
    next() {
      this.menuSelected += 1;
    },
    getPatient() {
      const id_pharmacy = this.patient.id;
      axios
        .get(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`))
        .then(response => {
          const data = response.data.data;
          this.recipe = data.recipe;
          this.data = {
            id_registration: data.id_registration._id,
            allergy: _.get(data, 'id_emr.allergy', []),
            birthDate:
              data.id_emr !== undefined
                ? moment(data.id_emr.social[0].birth.date)
                    .locale('id')
                    .format('L')
                : '',
            id_recipe: data.id_recipe,
          };
          this.consumable =
            data.recipe.medical_tools.length > 0
              ? {
                  id: data.recipe._id,
                  item: data.recipe.medical_tools,
                }
              : [];

          const [history] = data.history;
          this.historyDrug = {
            mix_recipe: _.get(history, 'mix_recipe', []),
            recipe: _.get(history, 'recipe', []),
            timestamps: _.get(history, `timestamps.created_at`, ''),
          };

          this.reviewDrug = {
            administrative: data.administrative,
            clinical: data.clinical,
            drugs_review: data.drugs_review,
            pharmaceuticals: data.pharmaceutical,
            id_staff: data.status[0].id_staff,
            type: data.status[0].type,
          };
          const allDrugs = data.recipe.recipe
            .concat(data.recipe.mix_recipe)
            .concat(data.recipe.medical_tools);
          this.totalData = allDrugs.length;
          this.totalMedTool = data.recipe.medical_tools.length;
        });
    },
    closeDialogForm() {
      this.$emit('close-form');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPatient();
    });
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  position: fixed;
  top: 101px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
}

.after-sticky {
  margin-top: 200px;
}

.header,
.header-2 {
  display: flex;
  list-style-type: none;
}

.detail {
  width: 100%;
  height: 100%;
  .container {
    max-width: 1280px;
  }
}
.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}
.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
  }
}
// .body-container {
//   margin-top: 10px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   // &::-webkit-scrollbar {
//   //   width: 3px;
//   //   background-color: rgb(163, 163, 163);
//   // }
//   // &::-webkit-scrollbar-thumb {
//   //   width: 3px;
//   //   background-color: black;
//   // }
// }

.header-layout {
  margin: 0 !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  width: 100%;
  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: auto;
    color: #9aa0a4;
    cursor: pointer;
    &:hover {
      color: rgb(207, 15, 15);
    }
  }
  .heading_big {
    text-align: left;
    font-size: 22px;
  }
  .row-info {
    height: 100%;
    .header-info-widget {
      .info-head {
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 0.95rem;
        // padding-right: 15px;
      }
      .label-head {
        text-align: left;
        font-size: 0.95rem;
        font-style: normal;
        color: #9ca2a5;
        &.special-label {
          max-width: 400px;
          white-space: normal;
          padding-left: 10px;
          padding-right: 15px;
        }
        &.red-color {
          font-weight: normal;
          color: red;
        }
      }
    }
  }
}

.menu-button {
  width: 100%;
  font-size: 1em;
  font-weight: 500;
  font-family: Roboto;
  font-style: normal;
  &:focus {
    outline: none;
  }
}

.title {
  text-align: left;
  font-size: 16px !important;
  padding-bottom: 10px;
  padding-top: 10px;
}

.list {
  margin-left: 30px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.druglist {
  width: 491px;
  max-height: 100%;
  margin: 0 auto;

  .subtitle {
    line-height: 5px;
    font-size: 15px;
    text-align: left;

    .icon {
      margin-top: -30px;
      float: right;
      margin-right: 0px;
    }
  }
}

.alkesReq {
  width: 90%;
  margin-left: 50px;
}
</style>
