import Constant from '../../../const';
const axios = require('axios');

const getSurgicalSafetyChecklistData = async idRegist => {
  const response = await axios.get(
    Constant.apiUrl.concat(`/document/surgical-safety-checklist/${idRegist}`),
  );
  return response;
};

export default getSurgicalSafetyChecklistData;
