<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Detail Pemesanan Barang Ke Supplier</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container class="pt-0">
        <v-row justify="center" class="px-5 mt-3 mb-5 mr-5">
          <v-col cols="12">
            <v-row class="font-weight-bold">
              <v-col cols="7" class="text-left  pl-10 ">
                <v-toolbar-title class="black--text" style="font-size:20px;">
                  Data Supplier
                </v-toolbar-title>
              </v-col>
              <v-col cols="5"></v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="data-header">
                <label
                  for="Kode"
                  class="font-weight-bold text-left pl-7 black--text"
                  >Nomor Permintaan</label
                >
                <span class="text-left"> {{ supplier.orderNumber }} </span>
                <label
                  class="font-weight-bold text-left pl-7 black--text"
                  for="date"
                  >Tanggal Pemesanan</label
                >
                <span class="text-left"> {{ supplier.date }}</span>
              </v-col>
              <v-col cols="6" class="data-header">
                <label
                  class="font-weight-bold text-left pl-7 black--text"
                  for="name"
                  >Nama Supplier</label
                >
                <span class="text-left">{{ supplier.supplierName.name }}</span>
                <label
                  class="font-weight-bold text-left pl-7 black--text"
                  for="price"
                  >Perkiraan Total Biaya</label
                >
                <span class="text-left">Rp. {{ formatNumber(totalAll) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              height="280"
              :items="table.detail"
              :items-per-page="table.detail.length"
              :pagination.sync="table.pagination"
            >
              <template v-slot:item="{ item, index }">
                <tr class="row-datatable" :key="index">
                  <td class="text-xs-left">
                    {{
                      (table.pagination.page - 1) *
                        table.pagination.rowsPerPage +
                        index +
                        1
                    }}
                  </td>
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-xs-left">{{ item.quantity }}</td>
                  <td class="text-xs-left">{{ item.package }}</td>
                  <td class="text-xs-left">{{ item.category }}</td>
                  <td class="text-left">Rp. {{ formatNumber(item.hna) }}</td>
                  <td class="text-left">
                    Rp. {{ formatNumber(item.subTotal) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            v-if="supplier.status === false"
            color="primary"
            class="text-capitalize"
            depressed
            flat
            @click="confirm()"
            >Konfirmasi Penerimaan</v-btn
          >
          <v-btn
            v-if="supplier.status === true"
            style="cursor: context-menu"
            color="success"
            class="text-capitalize"
            depressed
            flat
            >Pesanan Diterima</v-btn
          >
          <v-btn
            color="primary"
            class="ml-3"
            width="100"
            outlined
            @click="printSp()"
          >
            <v-icon>mdi-printer</v-icon>
            <span>Print</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="1200px" fullscreen>
      <InputItemReceipt
        :isFromInputItem="true"
        v-if="dialog"
        :isConfirmation="true"
        :spnumber="supplier.orderNumber"
        :supplierName="supplier.supplierName.name"
        @close-form="closeForm"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import InputItemReceipt from '../ItemReceiptComponent/InputItemReceipt.vue';
import printMixin from '@/mixin/printMixin';
import PrintItemOrder from '@/mixin/printItemOrder';
const axios = require('axios');
const moment = require('moment');

export default {
  name: 'DetailItemOrder',
  mixins: [printMixin, PrintItemOrder],
  components: {
    InputItemReceipt,
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      itemLoad: false,
      expand: false,
      dialog: false,
      date: '',
      headers: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        { sortable: false, text: 'Nama Barang', value: 'name' },
        {
          sortable: false,
          text: 'Jumlah Pesanan',
          value: 'quantity',
          class: 'text-sm-center',
        },
        {
          sortable: false,
          text: 'Package',
          value: 'package',
          class: 'text-sm-center',
        },
        {
          sortable: false,
          text: 'Kategori Barang',
          value: 'category',
          class: 'text-sm-center',
        },
        { sortable: false, text: 'Harga', value: 'hna' },
        { sortable: false, text: 'Sub Total', value: 'subTotal' },
      ],
      getdrugs: [],
      getgoods: [],
      table: {
        detail: [],
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
        },
      },
      selectBarang: [],
      selectSupplier: [],
      supplier: {
        orderNumber: '',
        date: '',
        supplierName: {
          name: '',
          id: '',
        },
        totalPrice: '',
        user: '',
        status: false,
      },

      input: {
        name: '',
        quantity: '',
        packageName: '',
        goods: null,
        idSupplier: '',
        category: '',
        hna: '',
      },
      item: [],
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    totalAll() {
      const total = this.table.detail.reduce((a, b) => +a + +b.subTotal, 0);
      return total;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getSuppllyById();
      // this.getDataDrugs();
      // this.getDataGoods();
      this.getDataSupllier();
    });
  },
  methods: {
    closeForm() {
      this.dialog = false;
      this.getSuppllyById();
    },
    confirm() {
      this.dialog = true;
    },
    printSp() {
      const doc = this.printItemOrder(
        this.$store.getters.userLoggedIn.clinic,
        this.supplier,
        this.letterData,
        this.table,
        this.$store.getters.userLoggedIn,
      );
      this.printDocument(doc);
    },

    updateItemOrder() {
      if (this.table.detail.length > 0) {
        const itemArr = this.table.detail.map(item => {
          return {
            id_drugs: item.id_drugs,
            id_goods: item.id_goods,
            quantity: item.quantity,
            package: item.package,
            price: item.subTotal,
          };
        });
        axios
          .post(
            Constant.apiUrl
              .concat('/supply/order?id_order=')
              .concat(localStorage.getItem('order_id')),
            {
              isUpdate: true,
              order_number: this.supplier.orderNumber,
              date: this.supplier.date,
              id_supplier: this.supplier.supplierName.id,
              total_price: this.supplier.totalPrice,
              item: itemArr,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          )
          .then(response => {
            if (response.status === 200) {
              Swal.fire(
                'Berhasil',
                'Data Pemesanan Barang Sudah Di Update',
                'success',
              );
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
    getSuppllyById() {
      axios
        .get(
          Constant.apiUrl
            .concat('/supply/request/')
            .concat(localStorage.getItem('order_id')),
        )
        .then(response => {
          const getDataSupply = response.data.data;
          this.supplier.supplierName = {
            name: getDataSupply.id_supplier.name,
            id: getDataSupply.id_supplier,
            address: getDataSupply.id_supplier.detail.filter(i => i.address)[0]
              .address,
          };
          this.supplier.date = moment(getDataSupply.order_date).format(
            'DD/MM/YYYY',
          );
          this.supplier.orderNumber = getDataSupply.order_number;
          this.supplier.totalPrice = getDataSupply.total_price;
          this.supplier.user = getDataSupply.timestamps.created_by;
          this.supplier.status = getDataSupply.isReceived || false;

          this.table.detail = getDataSupply.item.map(detail => {
            return {
              name: detail.name,
              id_drugs: detail.id_drugs,
              id_goods: detail.id_goods,
              package: detail.package,
              quantity: detail.quantity,
              category: detail.category,
              hna: detail.price,
              subTotal: detail.sub_total,
            };
          });
        });
    },

    onCloseButtonClick() {
      this.$emit('close-form');
    },

    addNewList() {
      this.table.detail.push({
        name: this.input.name,
        id_drugs: this.input.id_drugs,
        id_goods: this.input.id_goods,
        quantity: this.input.quantity,
        package: this.input.package,
        category: this.input.category,
        hna: this.input.hna,
        subTotal: this.input.hna * this.input.quantity,
      });
      this.totalPrice();
      this.afterAdd();
    },

    getDataSupllier() {
      axios.get(Constant.apiUrl.concat('/master/supplier')).then(response => {
        const getSupplier = response.data.data;
        this.selectSupplier = getSupplier.map(selectSupplier => {
          return {
            id: selectSupplier._id.toString(),
            name: selectSupplier.name,
          };
        });
      });
    },

    totalPrice() {
      const getTotalPrice = this.table.detail;
      this.supplier.totalPrice = getTotalPrice
        .map(totalprice => {
          return totalprice.subTotal;
        })
        .reduce((a, b) => a + b, 0);
    },

    afterAdd() {
      this.input = {
        name: '',
        goods: null,
        idSupplier: '',
      };
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>

<style lang="scss" scoped>
.data-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
}
.input {
  max-width: 50%;
}
</style>
