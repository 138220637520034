<template>
  <v-container fluid>
    <section class="d-flex justify-space-between align-center mb-2">
      <h2>Laporan Pembayaran Jasa Medis</h2>
      <div class="d-flex align-center">
        <router-link style="text-decoration: none" active :to="routeBack">
          <v-icon color="grey">mdi-home</v-icon>
        </router-link>
        <v-btn
          height="25"
          width="70"
          color="#59ABE1"
          dark
          class="text-capitalize ml-3"
          depressed
          >Export</v-btn
        >
        <v-text-field
          background-color="grey lighten-3"
          placeholder="Search"
          class="ml-3"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
        <v-btn class="ma-1" color="grey" icon text small @click="refresh()">
          <v-icon :class="{ refresh: isRefresh }">mdi-autorenew</v-icon>
        </v-btn>
      </div>
    </section>
    <v-row>
      <v-col cols="6" class="date-picker-container">
        <v-menu
          ref="menu1"
          v-model="menu1"
          dense
          :return-value.sync="dateStart"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateStart"
              outlined
              append-icon="mdi-chevron-down"
              dense
              readonly
              hide-details="auto"
              placeholder="Pilih Tanggal Awal"
              @change="getAllPayment()"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateStart"
            scrollable
            @input="
              $refs.menu1.save(dateStart);
              menu1 = false;
              getAllPayment();
            "
          />
        </v-menu>

        <p class="ma-0 pa-0 pt-2">─</p>
        <v-menu
          v-model="menu2"
          ref="menu2"
          dense
          :close-on-content-click="false"
          :return-value.sync="dateEnd"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateEnd"
              outlined
              append-icon="mdi-chevron-down"
              dense
              readonly
              hide-details="auto"
              placeholder="Pilih Tanggal Akhir"
              @change="getAllPayment()"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateEnd"
            scrollable
            @input="
              $refs.menu2.save(dateEnd);
              menu2 = false;
              getAllPayment();
            "
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs grow v-model="tab">
          <v-tab class="text-capitalize font-weight-bold">
            Belum Terbayar
          </v-tab>
          <v-tab class="text-capitalize font-weight-bold">
            Sudah Terbayar
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item class="tab-item">
              <v-data-table
                :headers="table.unpaid.headers"
                :items="table.unpaid.items"
                :items-per-page="table.unpaid.items.length"
                :loading="isLoading"
                v-model="selected"
                height="330"
                fixed-header
                hide-actions
                item-key="no"
                show-select
                hide-default-footer
              >
                <template v-slot:[`item.no`]="{ item }">
                  <td class="font-weight-medium">
                    {{ item.no + 1 }}
                  </td>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <td class="font-weight-medium text-left">{{ item.name }}</td>
                </template>
                <template v-slot:[`item.general`]="{ item }">
                  <td class="font-weight-medium text-left">
                    {{ currency(item.general) }}
                  </td>
                </template>
                <template v-slot:[`item.bpjs`]="{ item }">
                  <td class="font-weight-medium text-left">
                    {{ currency(item.bpjs) }}
                  </td>
                </template>
                <template v-slot:[`item.otherAssurance`]="{ item }">
                  <td class="font-weight-medium text-left">
                    {{ currency(item.otherAssurance) }}
                  </td>
                </template>
                <template v-slot:[`item.medserviceTotal`]="{ item }">
                  <td class="font-weight-medium text-left">
                    {{ currency(item.medserviceTotal) }}
                  </td>
                </template>
              </v-data-table>
              <v-toolbar color="white" class="footer">
                <span class="subtitle-2 font-weight-bold primary--text"
                  >{{ selected.length }} Terpilih</span
                >
                <v-btn
                  height="30"
                  color="primary"
                  depressed
                  :disabled="this.selected.length === 0"
                  @click="changeForm(0)"
                  class="text-capitalize ml-4"
                  >Bayar</v-btn
                >
              </v-toolbar>
            </v-tab-item>
            <v-tab-item class="tab-item">
              <v-data-table
                :headers="table.paid.headers"
                :items="table.paid.items"
                :items-per-page="table.paid.items.length"
                :loading="isLoading"
                height="330"
                hide-default-footer
                fixed-header
                item-key="no"
              >
                <template v-slot:[`item.no`]="{ item }">
                  <td class="justify-center">
                    {{ item.no + 1 }}
                  </td>
                </template>
                <template v-slot:[`item.general`]="{ item }">
                  <td class="text-left">
                    {{ currency(item.general) }}
                  </td>
                </template>
                <template v-slot:[`item.bpjs`]="{ item }">
                  <td class="text-left">
                    {{ currency(item.bpjs) }}
                  </td>
                </template>
                <template v-slot:[`item.otherAssurance`]="{ item }">
                  <td class="text-left">
                    {{ currency(item.otherAssurance) }}
                  </td>
                </template>
                <template v-slot:[`item.medserviceTotal`]="{ item }">
                  <td class="text-left">
                    {{ currency(item.medserviceTotal) }}
                  </td>
                </template>
                <template v-slot:[`item.detail`]="{ item }">
                  <v-chip
                    small
                    caption
                    @click="
                      changeForm(1);
                      detail = item;
                    "
                    class="text-sm-center font-weight-bold status-chip"
                    >Detail</v-chip
                  >
                </template>
              </v-data-table>
              <v-toolbar color="white" class="footer">
                <span class="subtitle-1 font-weight-bold primary--text"
                  >Total Jumlah Terbayar</span
                >
                <span class="subtitle-1 font-weight-bold primary--text ml-15">{{
                  currency(totalAll(table.paid.items))
                }}</span>
              </v-toolbar>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" :max-width="dialogWidth[selectedForm]">
      <DetailMedServicePayment
        v-if="dialog && selectedForm === 1"
        :selectedData="detail"
        @close-form="closeForm"
      />
      <InputMedServicePayment
        v-if="dialog && selectedForm === 0"
        :selectedData="selected"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        @close-form="closeForm"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import DetailMedServicePayment from './DetailMedServicePayment.vue';
import InputMedServicePayment from './InputMedServicePayment.vue';
// import moment from 'moment-timezone';
// import Swal from 'sweetalert2';
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';
import alertMixin from '@/mixin/alertMixin';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MedServicePayment',
  mixins: [jwtMixin, alertMixin, formatMixin],
  components: { DetailMedServicePayment, InputMedServicePayment },
  data() {
    return {
      isLoading: true,
      isRefresh: false,
      isDisabled: true,
      search: '',
      tab: null,
      menu1: false,
      menu2: false,
      dialog: false,
      selectedForm: '',
      dateStart: '',
      dateEnd: '',
      selected: [],
      detail: {},
      dialogWidth: ['739', '1100'],
      table: {
        unpaid: {
          headers: [
            {
              text: 'No',
              value: 'no',
              sortable: false,
              width: '2%',
              class: 'text-sm-center',
            },
            {
              text: 'Nama Dokter',
              value: 'name',
              sortable: false,
              width: '25%',
            },
            {
              text: 'Umum',
              value: 'general',
              sortable: false,
              width: '17.5%',
              class: 'text-sm-center',
            },
            {
              text: 'BPJS',
              value: 'bpjs',
              sortable: false,
              width: '17.5%',
              class: 'text-sm-center',
            },
            {
              text: 'Asuransi Lainnya',
              value: 'otherAssurance',
              sortable: false,
              width: '17.5%',
              class: 'text-sm-center',
            },
            {
              text: 'Total Jasa Medis',
              value: 'medserviceTotal',
              sortable: false,
              width: '17.5%',
              class: 'text-sm-center',
            },
          ],
          items: [],
        },
        paid: {
          headers: [
            {
              text: 'No',
              value: 'no',
              sortable: false,
              width: '2%',
              class: 'text-sm-center',
            },
            {
              text: 'Nama Dokter',
              value: 'name',
              sortable: false,
              width: '20%',
            },
            {
              text: 'Tgl Pembayaran',
              value: 'paymentDate',
              sortable: false,
              width: '10%',
            },
            {
              text: 'Periode Dibayar',
              value: 'paidPeriode',
              sortable: false,
              width: '10%',
            },
            {
              text: 'BPJS',
              value: 'bpjs',
              sortable: false,
              width: '13%',
              class: 'text-sm-center',
            },
            {
              text: 'Umum',
              value: 'general',
              sortable: false,
              width: '13%',
              class: 'text-sm-center',
            },
            {
              text: 'Asuransi Lainnya',
              value: 'otherAssurance',
              sortable: false,
              width: '13%',
              class: 'text-sm-center',
            },
            {
              text: 'Total Jasa Medis',
              value: 'medserviceTotal',
              sortable: false,
              width: '13%',
              class: 'text-sm-center',
            },
            {
              text: '',
              value: 'detail',
              sortable: false,
              width: '5%',
            },
          ],
          items: [],
        },
      },
    };
  },
  computed: {
    routeBack() {
      return '/keuangan/finance';
    },
  },
  watch: {
    search() {
      this.searchData(this);
    },
    unpaid() {
      this.getAllPayment();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllPayment();
    });
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllPayment();
    }, 650),
    closeForm() {
      this.dialog = false;
      this.selectedForm = '';
      this.refresh();
    },
    changeForm(value) {
      this.dialog = true;
      this.selectedForm = value;
    },
    refresh() {
      this.isRefresh = true;
      this.getAllPayment();
    },
    getAllPayment() {
      this.isLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/medicalservice?branch=${
                this.$store.getters.userLoggedIn.branch_id
              }&search=${this.search}&startDate=${this.dateStart ||
                ''}&endDate=${this.dateEnd || ''}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.table.unpaid.items = data
              .filter(d => d.status === 'unpaid')
              .map((med, idx) => {
                return {
                  doctorId: med.id_staff,
                  no: idx,
                  name: med.doctor,
                  date: med.date,
                  periode: med.period,
                  general: med.general_fee,
                  bpjs: med.bpjs_fee,
                  otherAssurance: med.assurance_fee,
                  medserviceTotal: med.total_fee,
                  status: med.status,
                };
              });
            this.table.paid.items = data
              .filter(d => d.status === 'paid')
              .map((med, idx) => {
                return {
                  doctorId: med.id_staff,
                  no: idx,
                  name: med.doctor,
                  paymentDate: med.paid_date,
                  paidPeriode: med.paid_period,
                  general: med.general_fee,
                  generalPatient: med.general_patient,
                  bpjs: med.bpjs_fee,
                  bpjsPatient: med.bpjs_patient,
                  otherAssurance: med.assurance_fee,
                  assurancePatient: med.assurance_patient,
                  medserviceTotal: med.total_fee,
                  status: med.status,
                };
              });
          })
          .catch(() => {
            this.table.unpaid.items = [];
            this.table.paid.items = [];
            this.selected = [];
          })
          .finally(() => {
            this.isLoading = false;
            this.isRefresh = false;
            this.selected = [];
          });
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    totalAll(items) {
      const total =
        items.length > 0
          ? items
              .map(data => {
                return data.medserviceTotal;
              })
              .reduce((a, b) => a + b)
          : 0;

      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-item {
  height: 62vh;
}
.footer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100vw;
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.75);
}
::v-deep .v-data-table-header {
  border-bottom-color: #9ca2a5;
  span {
    color: black;
  }
}
.status-chip {
  min-width: 50px;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  border-radius: 6px;
  background-color: white !important;
  border: 2px solid#137BC0 !important;
  color: #137bc0 !important;
}
.date-picker-container {
  display: grid;
  grid-template-columns: 45% 10% 45%;
}
</style>
