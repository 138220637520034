<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Ubah Barang Rumah Tangga</h3>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row no-gutters>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Barang</label>
                  </div>
                  <v-text-field
                    v-model="goods.name"
                    id="name"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="barcode">Barcode</label>
                  </div>
                  <v-text-field
                    id="barcode"
                    v-model="goods.barcode"
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="category">Kategori</label>
                  </div>
                  <v-combobox
                    class="detail-input__input-text"
                    v-model="goods.category"
                    :items="resource.category"
                  ></v-combobox>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="hpp">HPP</label>
                  </div>
                  <v-currency-field
                    id="hpp"
                    v-model="goods.hpp"
                    prefix="Rp. "
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="hna">HNA</label>
                  </div>
                  <v-currency-field
                    id="hna"
                    v-model="goods.hna"
                    prefix="Rp. "
                    class="detail-input__input-text"
                  />
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="pabrik">Pabrik</label>
                  </div>
                  <v-combobox
                    class="detail-input__input-text"
                    v-model="goods.factory"
                    :items="resource.factory"
                  ></v-combobox>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="pabrik">Minimal Stok</label>
                  </div>
                  <v-text-field
                    class="detail-input__input-text"
                    v-model="goods.min_stock"
                  ></v-text-field>
                </div>
                <div class="detail-input">
                  <div
                    class="detail-input__label detail-input__label--position"
                  >
                    <label for="pabrik">Aktif</label>
                  </div>
                  <v-checkbox v-model="goods.isActive"></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="10" offset-md="1" class="d-flex align-center">
            <template>
              <v-data-table
                :headers="packaging_header"
                :items="goods.packaging"
                :items-per-page="goods.packaging.length"
                sort-by="calories"
                hide-default-footer
                class="table-kemasan"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Kemasan</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2 text-none"
                          v-bind="attrs"
                          v-on="on"
                          depressed
                        >
                          Tambah Kemasan
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Tambah Kemasan</span>
                        </v-card-title>

                        <v-container>
                          <v-row align-content="center" justify="center">
                            <v-col cols="12" class="d-flex justify-center">
                              <v-combobox
                                label="Kemasan"
                                class="padding"
                                dense
                                hide-details
                                single-line
                                menu-props="auto"
                                clearable
                                v-model="editedItem.package"
                                :items="resource.packages"
                              ></v-combobox>
                              <v-text-field
                                v-model="editedItem.quantity"
                                class="padding"
                                dense
                                label="Jumlah"
                                placeholder="0"
                              />
                              <v-combobox
                                v-model="editedItem.package_unit"
                                :items="resource.packages"
                                label="Satuan"
                                class="padding"
                                dense
                                hide-details
                                single-line
                                menu-props="auto"
                                clearable
                              ></v-combobox>
                              <v-checkbox
                                v-model="editedItem.isDefault"
                                class="padding my-0"
                                dense
                                label="Satuan Terkecil"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="close" text>
                            Cancel
                          </v-btn>
                          <v-btn color="primary" @click="save" text>Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.isDefault`]="{ item }">
                  <span>{{ item.isDefault ? 'Ya' : 'Tidak' }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                width="100"
                text
                class="text-none"
                @click="onCloseButtonClick()"
              >
                Kembali
              </v-btn>
              <v-btn
                color="primary"
                width="100"
                class="text-none"
                depressed
                @click="
                  addNewGoods();
                  updateData();
                "
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');

export default {
  name: 'UpdateGoods',
  mixins: [jwtMixin],
  props: {
    goodsDetail: {
      type: Object,
    },
  },
  data() {
    return {
      resource: {
        category: [],
        packages: [],
        factory: [],
      },
      dialog: false,

      closeDelete: false,
      defaultItem: {
        isDefault: false,
        package: '',
        quantity: '',
        package_unit: '',
      },
      editedItem: {
        isDefault: false,
        package: '',
        quantity: '',
        package_unit: '',
      },
      editedIndex: -1,

      packaging_header: [
        { text: 'Kemasan', value: 'package', align: 'center' },
        { text: 'Jumlah', value: 'quantity', align: 'center' },
        { text: 'Satuan', value: 'package_unit', align: 'center' },
        { text: 'Kemasan Terkecil', value: 'isDefault', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      goods: {
        name: '',
        barcode: '',
        hna: '',
        hpp: '',
        min_stock: '',
        category: '',

        isActive: '',
        factory: '',
        packaging: [],
        timestamps: {
          created_by: '',
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        if (this.goodsDetail) {
          this.mapData();
          this.getDataById();
          this.getFactory();
          this.getCategoryByMenu();
          this.getPackagesByMenu();
        }
      });
    });
  },
  beforeDestroy() {
    this.goods = {
      name: '',
      barcode: '',
      hna: '',
      hpp: '',
      min_stock: '',
      category: '',

      isActive: '',
      factory: '',
      packaging: [],
      timestamps: {
        created_by: '',
      },
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    mapData() {
      this.goods = {
        name: this.goodsDetail.name,
        barcode: this.goodsDetail.barcode,
        hna: this.goodsDetail.hna,
        hpp: this.goodsDetail.hpp,
        min_stock: this.goodsDetail.min_stock,
        category: this.goodsDetail.category,

        isActive: this.goodsDetail.isActive,
        factory: this.goodsDetail.factory,
        packaging: [],
        timestamps: {
          created_by: '',
        },
      };
    },
    getFactory() {
      axios.get(Constant.apiUrl.concat('/master/factory')).then(response => {
        const resData = response.data.data;
        this.resource.factory = resData.map(prep => {
          return prep.name;
        });
      });
    },
    getPackagesByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/package/menu/inventory'))
        .then(response => {
          const { data } = response.data;
          this.resource.packages = data.map(pac => {
            return pac.name;
          });
        });
    },
    getCategoryByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/category/menu/Rumah Tangga'))
        .then(response => {
          const categoryData = response.data.data;
          this.resource.category = categoryData.map(cat => {
            return cat.name;
          });
        });
    },
    save() {
      if (
        this.editedItem.package !== '' &&
        this.editedItem.quantity !== '' &&
        this.editedItem.package_unit !== ''
      ) {
        if (this.editedIndex > -1) {
          Object.assign(
            this.goods.packaging[this.editedIndex],
            this.editedItem,
          );
        } else {
          this.goods.packaging.push(this.editedItem);
        }
        this.close();
      }
    },
    editItem(item) {
      this.editedIndex = this.goods.packaging.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.goods.packaging.indexOf(item);
      this.goods.packaging.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    updateData() {
      this.$emit('update-data');
    },
    addNewGoods() {
      try {
        const goodsSave = { detail: [] };
        goodsSave.detail.push(this.goods);
        goodsSave.detail[0].timestamps.created_by = this.$store.getters.userLoggedIn.id;
        // eslint-disable-next-line no-unused-vars
        let ctr = 0;

        goodsSave.detail[0].packaging.forEach(e => {
          if (e.isDefault) {
            ctr += 1;
          }
        });

        if (ctr > 1) {
          Swal.fire('Gagal disimpan', 'Kemasan terkecil lebih dari 1', 'error');
        } else {
          axios
            .put(`${Constant.apiUrl}/master/goods/${this.goodsDetail.id}`, {
              detail: goodsSave.detail,
            })
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Data barang berhasil diubah', 'success');
                this.onCloseButtonClick();
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan;
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: message,
                });
              } else if (error.request) {
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Tidak dapat menyambung ke server',
                });
              }
            });
        }
      } catch (error) {
        Swal.fire('Gagal', `${error}`, `error`);
      }
    },
    getDataById() {
      axios
        .get(`${Constant.apiUrl}/master/goods/${this.goodsDetail.id}`)
        .then(response => {
          this.loading = true;
          const goodsData = response.data.data;

          const getPackaging = goodsData.detail.packaging;

          this.goods.packaging = getPackaging.map(item => {
            return {
              package: item.package,
              quantity: item.quantity,
              package_unit: item.package_unit,
              isDefault: item.isDefault,
            };
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;

    &--position {
      position: relative;
      right: 140px;
    }
  }

  &__input-text {
    margin-left: 10px;
    max-width: 40%;
  }
}

.padding {
  padding-left: 10px;
}

.v-input__control {
  display: flex;
  align-self: center;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
