import JsPDF from 'jspdf';
import moment from 'moment-timezone';

export default {
  methods: {
    printItemOrder(clientData, supplier, letterData, table, userLoggedIn) {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'cm',
        format: 'a4',
      }).setProperties({
        title: `SP ${supplier.orderNumber}`,
      });
      const leftMargin = 1.0 * 2.54;
      const rightMargin = 7.3 * 2.54;
      const marginX = 2;
      const marginY = 5;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1.3;
      const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

      // letter head
      doc.setFontSize(11);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(
        doc,
        clientData.name.toUpperCase(),
        0.85 * 2.54,
        'halfCenterStart',
        letterData.title.offset * 2.54,
      );
      doc.setFontSize(9);
      doc.setFont('Helvetica', 'normal');
      this.pdfTextPrint(
        doc,
        `${clientData.address} ${
          clientData.postCode
        }. Telepon ${clientData.phone_number || ''}. ${
          clientData.hp_no ? `Hp. ${clientData.hp_no} ` : ''
        }email: ${clientData.email}`,
        1 * 2.54,
        'halfCenterStart',
        letterData.title.offsetData * 2.54,
        {
          maxWidth: 3.7 * 2.54, // wrap text content so when it reaches max width, it will makes a new line
          // maxWidth: 11, // wrap text content so when it reaches max width, it will makes a new line
          align: 'justify',
        },
      );
      doc.addImage(
        letterData.leftLogo,
        letterData.leftLogoExt,
        letterData.image.left.x * 2.54,
        letterData.image.left.y * 2.54,
        letterData.image.left.width * 2.54,
        letterData.image.left.height * 2.54,
      );
      doc.addImage(
        letterData.rightLogo,
        letterData.rightLogoExt,
        letterData.image.right.x * 2.54,
        letterData.image.right.y * 2.54,
        letterData.image.right.width * 2.54,
        letterData.image.right.height * 2.54,
      );
      doc.setLineWidth(0.01 * 2.54);
      doc.line(leftMargin, 1.55 * 2.54, rightMargin, 1.55 * 2.54);
      const placeAndDate = `Yogyakarta, ${moment()
        .locale('id')
        .format('DD MMMM YYYY')}`;
      doc.text(placeAndDate, 15, pageHeight - 24.5, 'center');

      doc.autoTable({
        body: [
          ['No. SP', `: ${supplier.orderNumber}`],
          ['Tgl. Order', `: ${supplier.date}`],
          ['NPWP No', `: `],
        ],

        margin: {
          left: marginX,
          right: marginX,
          top: marginY + 0.5,
        },
        columnStyles: {
          0: { cellWidth: 3, fontSize: 11 },
          1: { cellWidth: 6, fontSize: 11 },
        },
        theme: 'plain',
      });

      doc.setFontSize(12);
      doc.text(
        `Kepada Yth.  ${supplier.supplierName.name}`, // supplier name
        2.15,
        pageHeight - 20.9,
      );
      doc.setFontSize(12);
      doc.text(`${supplier.supplierName.address}`, 2.15, pageHeight - 20); // supplier address

      doc.setFontSize(12);
      doc.text('Dengan hormat,', 2.15, pageHeight - 18.8);

      doc.setFontSize(12);
      doc.text(
        'Kami bermaksud memesan barang sebagai berikut :',
        2.15,
        pageHeight - 18,
      );

      doc.setFontSize(10);
      const infoTable = doc.autoTable.previous;
      const items = table.detail.map((arr, index) => {
        return [index + 1, arr.name, `${arr.quantity} ${arr.package || ''}`];
      });

      doc.autoTable({
        head: [['No.', 'Nama Barang', 'Jumlah']],
        headStyles: {
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          lineWidth: 0.03,
        },
        bodyStyles: { lineColor: [0, 0, 0, 1], lineWidth: 0.03 },
        body: items,
        startY: infoTable.finalY + 4.5,
        margin: {
          left: marginX,
          right: marginX,
          top: addLine(5),
        },
        columnStyles: {
          0: { cellWidth: 2, fontSize: 11 },
          1: { cellWidth: 12, fontSize: 11 },
          2: { cellWidth: 3, fontSize: 11 },
        },
        theme: 'plain',
      });
      // const paymentTable = doc.autoTable.previous;
      let finalY = doc.lastAutoTable.finalY;

      if (finalY + 1.7 > pageHeight) {
        doc.addPage();
        finalY = marginY;
      }
      doc.setFontSize(12);
      doc.text(
        `Demikian surat pemesanan barang ini kami sampaikan kepada saudara, atas kerjasama`,
        2.15,
        finalY + 1,
      );
      doc.setFontSize(12);
      doc.text(
        `dan perhatian saudara kami ucapkan terima kasih.`,
        2.15,
        finalY + 1.7,
      );
      finalY += 2.5;

      if (finalY + marginY > pageHeight) {
        doc.addPage();
        finalY = marginY - 1;
      }
      doc.setFontSize(12);
      doc.text(`Hormat Kami,`, pageWidth / 1.5, finalY, 'center');
      doc.text(`${userLoggedIn.name}`, pageWidth / 1.5, finalY + 3.5, 'center');

      return doc;
    },
  },
};
