<template>
  <v-card flat>
    <!-- Jasa Medis Form -->

    <v-card-title class="pl-7 text-h6 blue--text">Jasa Medis</v-card-title>
    <v-row>
      <v-col cols="4">
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text"
                for="Jasa Medis Dokter Umum"
              >
                Jasa Medis Dokter Umum</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.staff"
              prefix="Rp."
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Dokter Spesialis"
              >
                Jasa Medis Dokter Spesialis</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.specialize"
              prefix="Rp."
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Perawat"
              >
                Jasa Medis Perawat</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.nurse"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Bidan"
              >
                Jasa Medis Bidan</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.midwife"
              prefix="Rp."
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text"
                for="Jasa Medis Psikologi"
              >
                Jasa Medis Psikologi</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.psychologist"
              prefix="Rp."
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Ahli Gizi"
              >
                Jasa Medis Ahli Gizi</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.nutritionists"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Analis Kesehatan"
              >
                Jasa Medis Analis Kesehatan</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.health_analyst"
              prefix="Rp."
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Radiografer"
              >
                Jasa Medis Radiografer</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].medical_service.radiographer"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Alat Medis Habis Pakai -->

    <v-card-title class="pl-7 text-h6 blue--text"
      >Alat Medis Habis Pakai</v-card-title
    >

    <v-row class="mb-7">
      <v-col cols="4">
        <v-form ref="formMedicalTools" lazy-validation>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Alat Dan Bahan Medis Habis Pakai"
                >
                  Total Alat Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model="totalMedicalTools"
                prefix="Rp."
                readonly
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Alat Medis Habis Pakai"
                >
                  Alat Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-combobox
                placeholder="Pilih Alat Medis"
                dense
                :items="resource.medicalTools"
                item-text="name"
                v-model="input.cost[tabs].medicalTools.detail"
                :rules="[v => !!v || 'Alat Medis Habis Pakai Harus diisi']"
                clearable
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label class="text-subtitle-2 black--text " for="Jumlah">
                  Jumlah</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                placeholder="Masukkan Jumlah"
                :suffix="
                  this.input.cost[this.tabs].medicalTools.detail.packaging
                "
                type="number"
                v-model.number="input.cost[tabs].medicalTools.quantity"
                :rules="[v => !!v || 'Jumlah Harus diisi']"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline">
            <v-col cols="7" offset="5">
              <v-btn
                color="primary"
                tile
                depressed
                class="text-none"
                @click="addMedicalTools()"
                outlined
              >
                <v-icon dark>mdi-plus</v-icon>
                Tambahkan Ke List
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- Table Alat Medis Habis Pakai -->
      <v-col cols="8">
        <template>
          <v-data-table
            :headers="table[tabs].medicalTools.headers"
            :items="table[tabs].medicalTools.items"
            :items-per-page="table[tabs].medicalTools.items.length"
          >
            <template v-slot:[`item.no`]="{ item }">
              <td class="text-left">
                {{ table[tabs].medicalTools.items.indexOf(item) + 1 }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td class="text-left">{{ item.name }}</td>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <td class="text-left">{{ item.quantity }}</td>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <td class="text-left">{{ item.unit }}</td>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-left">{{ currency(item.price) }}</td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 icon-style"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteMedicalTools(item)"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> Hapus </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </template>
      </v-col>
      <!-- END -->
    </v-row>
    <!-- END -->

    <!-- Bahan Medis Habis Pakai -->
    <v-row class="mb-7">
      <v-col cols="4">
        <v-card-title class="pl-7 text-h6 blue--text">
          Bahan Medis Habis Pakai
        </v-card-title>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text"
                for="Alat Dan Bahan Medis Habis Pakai"
              >
                Total Bahan Medis Habis Pakai</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model="totalConsumables"
              prefix="Rp."
              readonly
            />
          </v-col>
        </v-row>
        <v-form ref="formConsumables" lazy-validation>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Bahan Medis Habis Pakai"
                >
                  Bahan Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-combobox
                placeholder="Pilih Bahan Medis"
                dense
                :items="resource.consumables"
                item-text="name"
                v-model="input.cost[tabs].consumables.detail"
                :loading="isLoading"
                :rules="[v => !!v || 'Bahan Medis Habis Pakai Harus diisi']"
                clearable
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label class="text-subtitle-2 black--text " for="Jumlah">
                  Jumlah</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                placeholder="Masukkan Jumlah"
                :suffix="this.input.cost[tabs].consumables.detail.packaging"
                v-model.number="input.cost[tabs].consumables.quantity"
                type="number"
                :rules="[v => !!v || 'Jumlah Harus diisi']"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline">
            <v-col cols="7" offset="5">
              <v-btn
                color="primary"
                tile
                depressed
                class="text-none"
                @click="addConsumables()"
                outlined
              >
                <v-icon dark>mdi-plus</v-icon>
                Tambahkan Ke List
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- Tabel Bahan Habis Pakai -->
      <v-col cols="8">
        <v-data-table
          :headers="table[tabs].consumables.headers"
          :items="table[tabs].consumables.items"
          :items-per-page="table[tabs].consumables.length"
        >
          <template v-slot:[`item.no`]="{ item }">
            <td class="text-left">
              {{ table[tabs].consumables.items.indexOf(item) + 1 }}
            </td>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <td class="text-left">{{ item.name }}</td>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <td class="text-left">{{ item.quantity }}</td>
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <td class="text-left">{{ item.unit }}</td>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <td class="text-left">{{ currency(item.price) }}</td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 icon-style"
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteComsumables(item)"
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span> Hapus </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- END -->
    </v-row>
    <!-- END -->
    <v-row>
      <v-col cols="6">
        <v-row no-gutters align="baseline" class="px-16">
          <v-col cols="6">
            <div class="text-left">
              <label class="text-subtitle-2 black--text " for="Beban Unit">
                Beban Unit</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].unit_load"
              prefix="Rp."
            />
          </v-col>
        </v-row>

        <v-row no-gutters align="baseline" class="px-16">
          <v-col cols="6">
            <div class="text-left">
              <label class="text-subtitle-2 black--text " for="Margin">
                Margin</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model.number="input.cost[tabs].margin"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row no-gutters align="baseline" class="px-16">
          <v-col cols="12">
            <v-row class="primary total white--text mt-8" align="baseline">
              <span class="pr-3">Total Biaya Layanan</span>
              <label>{{ totalPrice }}</label>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');

export default {
  name: 'PatientFee',
  mixins: [formatMixin],
  props: {
    input: {
      type: Object,
    },
    table: {
      type: Array,
    },
    tabs: {
      type: Number,
    },
  },
  data() {
    return {
      resource: {
        consumables: [],
        medicalTools: [],
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getConsumables('');
    this.getMedicalTools('');
  },
  computed: {
    totalPrice() {
      const biaya =
        Number(this.input.cost[this.tabs].medical_service.staff) +
        Number(this.input.cost[this.tabs].medical_service.specialize) +
        Number(this.input.cost[this.tabs].medical_service.nutritionists) +
        Number(this.input.cost[this.tabs].medical_service.health_analyst) +
        Number(this.input.cost[this.tabs].medical_service.psychologist) +
        Number(this.input.cost[this.tabs].medical_service.nurse) +
        Number(this.input.cost[this.tabs].medical_service.radiographer) +
        Number(this.input.cost[this.tabs].medical_service.midwife) +
        Number(this.input.cost[this.tabs].margin) +
        Number(this.input.cost[this.tabs].unit_load);
      const totalFromTables = this.totalConsumables + this.totalMedicalTools;
      return this.currency(biaya + totalFromTables);
    },
    totalConsumables() {
      return this.table[this.tabs].consumables.items.reduce(
        (a, { price }) => a + price,
        0,
      );
    },
    totalMedicalTools() {
      return this.table[this.tabs].medicalTools.items.reduce(
        (a, { price }) => a + price,
        0,
      );
    },
  },
  methods: {
    getConsumables(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs?page=&itemCount=&search=${keyword}&sort=&category=BHP`,
          ),
        )
        .then(response => {
          const consumables = response.data.data;
          this.resource.consumables = consumables.map(arr => {
            let defaultPackage = '';

            arr.detail.packaging.find(v => {
              if (v.isDefault === true) {
                defaultPackage = v.package;
              }
            });
            return {
              id: arr._id,
              name: arr.detail.name,
              hpp: arr.detail.hpp,
              hna: arr.detail.hna,
              strength: arr.detail.strength,
              strength_unit: arr.detail.strength_unit,
              packaging:
                arr.detail.packaging.length !== 0 ? defaultPackage : '-',
            };
          });
        })
        .catch(() => {
          this.resource.consumables = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getMedicalTools(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs?page=&itemCount=&search=${keyword}&sort=&category=Alat Kesehatan`,
          ),
        )
        .then(response => {
          const medicalTools = response.data.data;
          this.resource.medicalTools = medicalTools.map(arr => {
            let defaultPackage = '';
            arr.detail.packaging.find(v => {
              if (v.isDefault === true) {
                defaultPackage = v.package;
              }
            });
            return {
              id: arr._id,
              name: arr.detail.name,
              hpp: arr.detail.hpp,
              hna: arr.detail.hna,
              packaging:
                arr.detail.packaging.length !== 0 ? defaultPackage : '-',
            };
          });
        })
        .catch(() => {
          this.resource.medicalTools = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addConsumables() {
      if (this.$refs.formConsumables.validate()) {
        this.table[this.tabs].consumables.items.push({
          no: this.table[this.tabs].consumables.items.length + 1,
          id: this.input.cost[this.tabs].consumables.detail.id,
          name: this.input.cost[this.tabs].consumables.detail.name,
          quantity: this.input.cost[this.tabs].consumables.quantity,
          unit: this.input.cost[this.tabs].consumables.detail.packaging,
          price:
            this.input.cost[this.tabs].consumables.detail.hna *
            this.input.cost[this.tabs].consumables.quantity,
        });

        this.input.cost[this.tabs].consumables = {
          detail: '',
          quantity: '',
        };
        this.$refs.formConsumables.resetValidation();
      }
    },
    addMedicalTools() {
      if (this.$refs.formMedicalTools.validate()) {
        this.table[this.tabs].medicalTools.items.push({
          no: this.table[this.tabs].medicalTools.items.length + 1,
          id: this.input.cost[this.tabs].medicalTools.detail.id,
          name: this.input.cost[this.tabs].medicalTools.detail.name,
          quantity: this.input.cost[this.tabs].medicalTools.quantity,
          unit: this.input.cost[this.tabs].medicalTools.detail.packaging,
          price:
            this.input.cost[this.tabs].medicalTools.detail.hna *
            this.input.cost[this.tabs].medicalTools.quantity,
        });

        this.input.cost[this.tabs].medicalTools = {
          detail: '',
          quantity: '',
        };
        this.$refs.formMedicalTools.resetValidation();
      }
    },
    deleteMedicalTools(item) {
      const index = this.table[this.tabs].medicalTools.items.indexOf(item);
      this.table[this.tabs].medicalTools.items.splice(index, 1);
    },
    deleteComsumables(item) {
      const index = this.table[this.tabs].consumables.items.indexOf(item);
      this.table[this.tabs].consumables.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.total {
  border-radius: 8px;
  padding: 20px;
}
</style>
