<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="examination-request">
    <v-toolbar flat>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Permintaan Pemeriksaan Lab
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>
          <v-menu
            dense
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Pilih Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                style="width: 1vw"
                v-model="dateRange"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="
                $refs.menu.save(date);
                getLabData();
              "
              :min="minDate"
              range
            >
            </v-date-picker>
          </v-menu>

          <v-btn class="mr-2" color="grey" icon text @click="getLabData()">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-tabs grow v-model="tabs">
            <v-tab class="text-none" @click="setStatus('unprocess')"
              >Belum Diproses ({{ dataLabCount.unprocess }})</v-tab
            >
            <v-tab class="text-none" @click="setStatus('inprocess')">
              Sedang Diproses ({{ dataLabCount.inprocess }})</v-tab
            >
            <v-tab class="text-none" @click="setStatus('finish')"
              >Selesai ({{ dataLabCount.finish }})
            </v-tab>
          </v-tabs>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="table.items"
              :search="search"
              :expanded="expanded"
              :height="height"
              single-expand
              calculate-widths
              item-key="orderNumber"
              class="datatable"
              :items-per-page="itemsPerRow"
              hide-default-footer
              :loading="isLoading"
            >
              <!-- :page.sync="table.pagination.page" -->
              <template v-slot:item="{ item, expand, isExpanded }">
                <tr
                  class="text-left"
                  :key="item.index"
                  @click="expand(!isExpanded)"
                >
                  <td>
                    {{
                      (table.pagination.page - 1) * itemsPerRow +
                        table.items.indexOf(item) +
                        1
                    }}
                  </td>
                  <td>{{ item.orderNumber }}</td>
                  <td>{{ item.checkDate }}</td>
                  <td>{{ item.patient.name }}</td>
                  <td>{{ item.patient.rmNumber }}</td>
                  <td class="text-uppercase">{{ item.guarantorType }}</td>
                  <td>{{ item.createdBy }}</td>
                  <td>
                    <v-chip
                      :class="item.paymentStatus ? 'chipTrue' : 'chipFalse'"
                      label
                      :color="getColor(item.paymentStatus)"
                    >
                      {{ item.paymentStatus ? 'Lunas' : 'Belum Lunas' }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length">
                  <v-card flat color="grey lighten-4" class="text-right" tile>
                    <v-container fluid>
                      <v-row no-gutters>
                        <div class="text-expanded">
                          <span> Diorder pada {{ item.timeOrder }}</span>
                          <span>&bull;</span>
                          <span
                            v-if="status === 'inprocess' || status === 'finish'"
                          >
                            Dikonfirmasi pada {{ item.confirmOrder }}</span
                          >
                          <span
                            v-if="status === 'inprocess' || status === 'finish'"
                            >&bull;</span
                          >
                          <span>
                            {{ item.polyName }}
                          </span>
                        </div>
                        <v-col cols="12" class="pr-10">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-5 hover"
                                @click="selectItem(item, 0)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-account</v-icon>
                              </v-btn>
                            </template>
                            <span>Data Sosial</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-5 hover"
                                @click="selectItem(item, 1)"
                                v-bind="attrs"
                                v-on="on"
                                v-show="status !== 'unprocess'"
                              >
                                <v-icon>mdi-needle</v-icon>
                              </v-btn>
                            </template>
                            <span>Tindakan dan BHP</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="
                                  status !== 'unprocess' ||
                                    !hasRoles().isSuperManager
                                "
                                x-small
                                icon
                                class="mx-5 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="selectItem(item, 2)"
                              >
                                <v-icon>mdi-flask</v-icon>
                              </v-btn>
                            </template>
                            <span>Konfirmasi Pemeriksaan</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="status !== 'unprocess'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-5 hover"
                                @click="selectItem(item, 3)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-text</v-icon>
                              </v-btn>
                            </template>
                            <span>Hasil Pemeriksaan</span>
                          </v-tooltip>
                          <span v-if="status === 'finish'"></span>
                          <span v-else>
                            <span
                              v-if="
                                item.isInputed === false &&
                                  status === 'unprocess'
                              "
                            >
                              <v-tooltip
                                bottom
                                v-if="!hasRoles().isSuperManager"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    v-show="!item.status"
                                    icon
                                    class="mx-5 hover"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteUnit(item.idLab)"
                                  >
                                    <v-icon color="red">mdi-close-thick</v-icon>
                                  </v-btn>
                                </template>
                                <span>Batalkan Permintaan</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="!hasRoles().isSuperManager"
                                    x-small
                                    v-show="
                                      !item.status && item.isInputed === true
                                    "
                                    icon
                                    class="mx-5 hover"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="finishUnit(item.idLab)"
                                  >
                                    <v-icon>mdi-clipboard-check</v-icon>
                                  </v-btn>
                                </template>
                                <span>Selesaikan Permintaan</span>
                              </v-tooltip>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="white">
      <v-pagination
        v-model="table.pagination.page"
        :length="pageLength"
        total-visible="5"
        @input="getLabData()"
      />
    </v-footer>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="selectedForm === 2 || selectedForm === 3"
    >
      <SocialData
        v-if="selectedForm === 0 && dialog"
        @close-form="dialog = false"
        :medical-record="patientData.rmNumber"
      />
      <BHPandAction
        v-else-if="selectedForm === 1 && dialog"
        @close-form="dialog = false"
        :patient-data="patientData"
      />
      <ExaminationDialog
        v-else-if="selectedForm === 2 && dialog"
        @close-dialog="closeForm()"
        confirmationState="confirmation"
        :dataLab="dataLab"
      />
      <ExaminationDialog
        v-else-if="selectedForm === 3 && dialog"
        @close-dialog="closeForm()"
        confirmationState="result"
        :dataLab="dataLab"
      />
      <!-- <ExaminationResultDialog
        v-else-if="selectedForm === 3 && dialog"
        @close-form="closeForm()"
        :patient-data="selectedPatient"
      /> -->
    </v-dialog>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SocialData from '../SharedComponent/SocialData.vue';
import BHPandAction from '../Service/components/BHPandAction';
import ExaminationDialog from './components/ExaminationOrder/ExaminationDialogV2.vue';
// import ExaminationResultDialog from './components/ExaminationResultDialog.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'ExaminationRequest',
  mixins: [roleMixin],
  components: {
    ExaminationDialog,
    // ExaminationResultDialog,
    BHPandAction,
    SocialData,
  },
  data() {
    return {
      menu: '',
      date: [],
      tabel: [],
      expanded: [],
      isLoading: false,
      examinationDialog: false,
      bhpDialog: false,
      resultDialog: false,
      selectedItem: null,
      status: 'unprocess',
      table: {
        headers: [
          { text: 'No', value: 'index' },
          { text: 'No Order', value: 'orderNumber' },
          { text: 'Tanggal Periksa', value: 'checkDate' },
          { text: 'Nama Pasien', value: 'name' },
          { text: 'No. RM', value: 'rmNumber' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Petugas', value: 'doctor' },
          { text: 'Status Pembayaran', value: 'status' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      pageLength: 0,
      selectedForm: null,
      dialog: false,
      search: '',
      tabs: null,
      dataLab: null,
      dataLabCount: {},
      patientData: {},
    };
  },
  mounted() {
    this.getLabData();
    this.clearSnackbar();
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    dateRange: {
      get() {
        if (this.date && this.date.length === 2) {
          const [start, end] = this.date;
          const range = `${moment(start).format('DD/MM/YYYY')} s.d. ${moment(
            end,
          ).format('DD/MM/YYYY')}`;
          return range;
        } else {
          return moment(this.date[0]).format('DD/MM/YYYY');
        }
      },
      set(val) {
        if (val === null) {
          this.date = [];
          this.getLabData();
        } else {
          this.getLabData();
          return this.date;
        }
      },
    },
    minDate() {
      if (this?.date[0] !== null && this?.date?.length === 1) {
        return this?.date[0];
      } else {
        return null;
      }
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '57vh';
          break;
        case 'lg':
          height = '60vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
  },
  watch: {
    search() {
      this.searchData(this);
    },
    date(newVal) {
      if (newVal === null) {
        this.getLabData();
      }
    },
  },
  methods: {
    ...mapMutations([
      'setSnackbar',
      'clearSnackbar',
      'setShowDialog',
      'setDataLab',
    ]),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.getLabData();
    }, 500),
    closeForm() {
      this.dialog = false;
      this.getLabData();
    },
    getColor(status) {
      if (status) return '#E5FFF0';
      else return '#FFF2F2';
    },
    selectItem(item, index) {
      this.setShowDialog(true);
      this.changeSelectedLab(item);
      this.selectedForm = index;
      this.dialog = true;
    },
    finishUnit(id) {
      Swal.fire({
        title:
          '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Apakah Anda Yakin Ingin</p><p>Menyelesaikan Permintaan ini?</p></div>',
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        showCancelButton: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-cancel',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(
              Constant.apiUrl.concat(`/supportservice/laboratory/finish/${id}`),
            )
            .then(res => {
              if (res.status === 200) {
                this.setSnackbar({
                  show: () => {
                    true;
                    return setTimeout(() => {
                      return false;
                    }, 2000);
                  },
                  text: 'Permintaan Laboratorium Berhasil Diselesaikan',
                  color: 'success',
                });
                this.getLabData();
              }
            })
            .catch(error => {
              this.showErrorAxios(error);
            });
        }
      });
    },
    deleteUnit(id) {
      Swal.fire({
        title:
          '<label style="font-weight: 700; font-family: Roboto; font-size: 20px; color: #222222; line-height: 30px;">Yakin Ingin Membatalkan Pendaftaran Laboratorium Ini?</label>',
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        showCancelButton: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-cancel',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(
              Constant.apiUrl.concat(`/supportservice/laboratory/cancel/${id}`),
            )
            .then(res => {
              if (res.status === 200) {
                Swal.fire('Berhasil', `${res.data.pesan}`, 'success');
                this.getLabData();
              }
            })
            .catch(error => {
              this.showErrorAxios(error);
            });
        }
      });
    },
    setStatus(status) {
      this.status = status;
      this.getLabData();
    },
    getLabDataCount() {
      return axios
        .get(
          Constant.apiUrl.concat(
            `/supportservice/laboratory/status?startDate=${
              this.date[0] !== undefined
                ? this.date[0]
                : moment().format('YYYY-MM-DD')
            }&endDate=${
              this.date[1] !== undefined
                ? this.date[1]
                : moment(this.date[0]).format('YYYY-MM-DD')
            }`,
          ),
        )
        .then(res => {
          this.dataLabCount = res.data.data;
        })
        .catch(
          (this.dataLabCount = {
            unprocess: 0,
            inprocess: 0,
            finish: 0,
          }),
        );
    },
    async getLabData() {
      try {
        this.isLoading = true;
        const itemCount = this.itemsPerRow;
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/supportservice/laboratory/request?status=${this.status}&page=${
              this.table.pagination.page
            }&itemCount=${itemCount}&search=${this.search}&startDate=${
              this.date[0] !== undefined
                ? this.date[0]
                : moment().format('YYYY-MM-DD')
            }&endDate=${
              this.date[1] !== undefined
                ? this.date[1]
                : moment(this.date[0]).format('YYYY-MM-DD')
            }`,
          ),
        );
        this.getLabDataCount();
        const { data } = res.data;
        // const { length: dataLength } = res.data;
        const dataLength = res.data.length;
        this.pageLength = Math.ceil(dataLength / itemCount);
        this.table.items = data
          // .sort((a, b) =>
          //   moment(b.timestamps.created_at - a.timestamps.created_at),
          // )
          .map(arr => {
            // const addressData = [arr.emr[0].social[0].address.text];

            // if (arr.emr[0].social[0].address.sub_district) {
            //   addressData.push(arr.emr[0].social[0].address.sub_district);
            // }
            // if (arr.emr[0].social[0].address.district) {
            //   addressData.push(arr.emr[0].social[0].address.district);
            // }
            // if (arr.emr[0].social[0].address.city) {
            //   addressData.push(arr.emr[0].social[0].address.city);
            // }
            // if (arr.emr[0].social[0].address.province) {
            //   addressData.push(arr.emr[0].social[0].address.province);
            // }
            return {
              orderNumber: arr.order_code,
              checkDate: moment(_.get(arr, 'created_at', '-'))
                .locale('id')
                .format('DD/MM/YYYY'),
              patient: {
                name: _.get(arr, 'patient.name', '-'),
                birthDate: _.get(arr, 'patient.birth_date', '-'),
                rmNumber: _.get(arr, 'patient.medical_record_number', '-'),
                gender: arr?.patient?.gender === true ? 'L' : 'P',
                age: _.get(arr, 'patient.age', '-'),
                address: {
                  province: _.get(arr, 'patient.address.province', ''),
                  city: _.get(arr, 'patient.address.city', ''),
                  district: _.get(arr, 'patient.address.district', ''),
                  subDistrict: _.get(arr, 'patient.address.sub_district', ''),
                  text: _.get(arr, 'patient.address.text', ''),
                },
              },
              guarantorType: _.get(arr, 'assurance', '-'),
              doctor: _.get(arr, 'doctor', '-'),
              polyName: _.get(arr, 'unit', '-'),
              timeOrder: moment(_.get(arr, 'created_at', '-'))
                .locale('id')
                .format('HH.mm z, DD MMMM YYYY'),
              confirmOrder: moment(_.get(arr, 'confirmed_at', '-'))
                .locale('id')
                .format('HH.mm z, DD MMMM YYYY'),
              id_registration: _.get(arr, 'id_registration', ''),
              id_emr: _.get(arr, 'id_emr', ''),
              isConfirmed: _.get(arr, 'isConfirmed', false),
              idLab: _.get(arr, 'id_lab', ''),
              isInputed: _.get(arr, 'isInputed', false),
              createdBy: _.get(arr, 'created_by', ''),
              paymentStatus: _.get(arr, 'payment_status', ''),
              isFinished: _.get(arr, 'isFinished', ''),
              // isConfirmed: true,
              // id_regist: arr.id_registration,
              // id_lab: _.get(arr, '_id'),
              // address: addressData.join(', '),
              // unit: _.get(arr, 'unit[0].name', ''),
              // emr: _.get(arr, 'emr[0]', ''),
              // status: _.get(arr, 'isPaid', false),
              // testItem: _.get(arr, 'test_item', []),
              // id_registration: _.get(arr, 'id_registration', ''),
              // has_tested: arr.test_item.some(x => x.result.length > 0),
            };
          });
      } catch (error) {
        this.table.items = [];
        this.getLabDataCount();
      } finally {
        this.isLoading = false;
      }
    },
    changeSelectedLab(item) {
      const patientData = {
        id_emr: item.id_emr,
        id_registration: item.id_registration,
        rmNumber: item.patient.rmNumber,
        meta: {
          guarantorType: item.guarantorType,
          name: item.patient.name,
          birthDate: moment(item.patient.birthDate)
            .locale('id')
            .format('LL'),
          gender: item.patient.gender,
          age: item.patient.age,
          // address: item.address,
          // doctor: item.doctor,
        },
      };
      this.patientData = patientData;
      this.dataLab = item;
      this.setDataLab(item);
      // this.$store.commit('changeSelectedPatient', patientData);
    },
  },
};
</script>

<style>
.button-confirm {
  background-color: #137bc0 !important;
  border-radius: 4px !important;
}
.button-cancel {
  background-color: white !important;
  border: 1px solid #137bc0 !important;
  border-radius: 4px !important;
  color: #137bc0 !important;
}
</style>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

.border {
  border-left: 5px solid #3498db !important;
  border-radius: none;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
  .datatable {
    border-radius: 0;
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
}

.chipTrue {
  border: 1px solid #2d965a !important;
  color: #2d965a;
}

.chipFalse {
  border: 1px solid #eb4747 !important;
  color: #eb4747;
}
.text-expanded {
  display: flex;
  gap: 10px;
  color: #9ca2a5;
  font-weight: 400;
  font-size: 12px;
  padding-left: 80px;
}
</style>
