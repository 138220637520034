<template>
  <v-container :style="style" fluid>
    <div v-for="(item, index) in data" :key="index" class="column">
      <label>
        {{ item.label }}
      </label>
      <p>
        {{ item.text }}
      </p>
    </div>
    <div class="column">
      <h2>{{ title }}</h2>
      <v-btn
        class="ml-3"
        v-if="closeBtn"
        text
        fab
        small
        @click="onClickCloseBtn"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'HeaderContent',
  props: {
    data: { type: Array },
    title: { type: String },
    closeBtn: { type: Boolean },
    closeForm: { type: Function },
    pl: { type: String },
    pr: { type: String },
    pt: { type: String },
    pb: { type: String },
    ml: { type: String },
    mr: { type: String },
    mt: { type: String },
    mb: { type: String },
  },
  computed: {
    style() {
      return `
      --pl: ${this.pl}; 
      --pr: ${this.pr};
      --pt: ${this.pt};
      --pb: ${this.pb};
      --mb: ${this.mb};
      --mt: ${this.mt};
      --ml: ${this.ml};
      --mr: ${this.mr};
      `;
    },
  },
  methods: {
    onClickCloseBtn() {
      this.closeForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  font-size: 0.9vw;
  flex-wrap: nowrap;
  max-width: 100%;
  padding-left: var(--pl);
  padding-right: var(--pr);
  padding-top: var(--pt);
  padding-bottom: var(--pb);
  margin-left: var(--ml);
  margin-right: var(--mr);
  margin-top: var(--mt);
  margin-bottom: var(--mb);

  p {
    font-weight: 500;
    margin: auto 2rem;
  }

  .column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;

    &:last-child {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
