<template>
  <v-container fluid class="main-div pt-0 px-0">
    <EMRDashboardSkletonLoader v-if="loading" />
    <v-row no-gutters v-if="!loading">
      <v-col cols="6" class="patient-primary-data">
        <v-card class="social-data" flat tile>
          <v-card-title class="title-header-primary prim-text-size px-3 py-2">
            <label>RINGKASAN DATA PASIEN</label>
          </v-card-title>
          <div class="text-left pl-3">
            <label class="headline-blue mr-2 text-uppercase">{{
              patientData.social?.name
            }}</label>
            <v-btn depressed outlined tile class="btn-style my-0">
              <label class="prim-text-size">{{
                patientData.medical_record_number
              }}</label>
            </v-btn>
          </div>
          <v-row class="float-left align-center px-2" no-gutters>
            <v-icon class="pr-2 grey--text" style="font-size: 20px"
              >mdi-account</v-icon
            >
            <label class="prim-text-size dark-grey--text padding-item">{{
              patientData.social?.gender ? `Laki-Laki` : `Perempuan`
            }}</label>
            <span style="font-size: 7px" class="pr-2 grey--text">&#9679;</span>
            <label class="prim-text-size dark-grey--text padding-item">
              {{ patientData.social?.age }} tahun</label
            >
            <span style="font-size: 7px" class="pr-2 grey--text">&#9679;</span>
            <label class="prim-text-size dark-grey--text padding-item">{{
              patientData.birthDate
            }}</label>
          </v-row>
          <br />
          <div class="text-left pl-2">
            <v-icon class="pr-2 grey--text" style="font-size: 20px"
              >mdi-home</v-icon
            >
            <label class="prim-text-size dark-grey--text padding-item">{{
              patientData.addressData
            }}</label>
          </div>

          <v-row
            class="px-3"
            no-gutters
            justify="space-between"
            align="baseline"
          >
            <label class="prim-text-size dark-grey2--text padding-item"
              >Kunjungan Terakhir: {{ patientData.last_visit || '-' }}</label
            >
            <v-btn
              depressed
              small
              color="primary"
              text
              @click="changeForm(null)"
              >Data Sosial
              <v-icon style="font-size: 16px">mdi-arrow-right</v-icon></v-btn
            >
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3" class="patient-primary-data">
        <v-card flat tile class="disease-before">
          <v-card-title class="title-header-primary prim-text-size px-3 py-2">
            <label>RIWAYAT PENYAKIT SEBELUMNYA</label>
          </v-card-title>
          <v-col class="disease-style">
            <ul class="text-left" v-if="resource.lastCheckup">
              <li
                v-for="(disease, index) in resource.lastCheckup.disease_history"
                :key="'disease-' + index"
                class="text-capitalize"
              >
                {{ disease }}
              </li>
            </ul>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="3" class="patient-primary-data">
        <v-card flat tile class="allergy">
          <v-card-title class="title-header-primary prim-text-size px-3 py-2">
            <label>RIWAYAT ALERGI</label>
          </v-card-title>
          <v-col class="allergy-style">
            <ul class="text-left" v-if="resource.lastCheckup">
              <li
                v-for="(alergi, index) in resource.lastCheckup.allergy"
                :key="'alergi-' + index"
                class="text-capitalize"
              >
                {{ alergi }}
              </li>
            </ul>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-tabs v-model="tabs" v-if="!loading">
      <v-tab class="text-none">Kunjungan</v-tab>
      <v-tab class="text-none">Riwayat Treatment</v-tab>
      <v-tab class="text-none">Obat</v-tab>
      <v-tab class="text-none">Pemeriksaan Lab</v-tab>
      <v-tab class="text-none">Surat</v-tab>
      <v-tab class="text-none">Dokumen</v-tab>
    </v-tabs>
    <v-tabs-items v-if="!loading" v-model="tabs">
      <v-tab-item>
        <v-row class="pa-3">
          <v-col cols="9">
            <v-card class="pb-2">
              <v-card-title>
                <label
                  class="font-weight-bold grey--text text--lighten-1 body-2"
                  >PATIENT SUMMARY</label
                >
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="red"
                  v-if="filter_open"
                  class="mx-3 white--text"
                  @click="reset()"
                  tile
                  >RESET</v-btn
                >
                <v-btn depressed color="#F4F5F5" @click="filterOpen()">
                  <img src="@/assets/image/ic_filter.png" class="mr-2" />
                  <span class="black--text text-capitalize">Filters</span>
                </v-btn>
                <v-text-field
                  placeholder="Cari dokter atau klinik disini..."
                  solo
                  flat
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  class="search mx-3"
                  v-model="search"
                  @input="checkUpHistoryFilter()"
                />
              </v-card-title>
              <v-row v-if="filter_open" class="pb-2 px-3">
                <v-col class="text-left">
                  <label class="font-weight-medium body-2">Range Tanggal</label>
                  <v-menu
                    offset-y
                    :close-on-content-click="false"
                    v-model="datepicker"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="date.formatted"
                        v-on="on"
                        readonly
                        placeholder="Tanggal"
                        outlined
                        dense
                        hide-details="false"
                        append-icon="mdi-chevron-down"
                        single-line
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      multiple
                      v-model="selectedDate"
                      @input="changedate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  align-self-center
                  class="pt-8 px-0"
                  style="max-width: 30px"
                >
                  <v-btn icon class="ma-0">
                    <v-icon>mdi-swap-vertical</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="text-left">
                  <label class="font-weight-medium body-2">Cabang</label>
                  <v-combobox
                    placeholder="Semua Klinik"
                    outlined
                    hide-details
                    single-line
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    v-model="branchFilter"
                    clearable
                    dense
                    :items="resource.branch"
                    @change="getEmrById()"
                  />
                </v-col>
                <v-col class="text-left">
                  <label class="font-weight-medium body-2">Unit</label>
                  <v-combobox
                    placeholder="Semua Klinik"
                    outlined
                    append-icon="mdi-chevron-down"
                    hide-details
                    single-line
                    item-text="name"
                    v-model="unitFilter"
                    clearable
                    dense
                    :items="resource.unit"
                    @change="getEmrById()"
                  />
                </v-col>
                <v-col class="text-left">
                  <label class="font-weight-medium body-2">Nama Dokter</label>
                  <v-combobox
                    placeholder="Semua Dokter"
                    outlined
                    hide-details
                    :items="resource.doctor"
                    append-icon="mdi-chevron-down"
                    single-line
                    @change="getEmrById()"
                    item-text="name"
                    v-model="doctorFilter"
                    clearable
                    dense
                  />
                </v-col>
              </v-row>

              <div v-if="patientData.checkup?.length !== 0">
                <v-card
                  v-for="(item, index) in patientData.checkup"
                  :key="'data-' + index"
                  flat
                  class="pa-2 ma-3 text-left"
                  style="border: 0.5px solid #429ddb; border-radius: 5px"
                >
                  <v-row no-gutters align="center">
                    <h2 class="mr-3">{{ item.unit[0].name }}</h2>
                    •
                    <h4 class="primary--text ml-3">
                      Cabang
                      {{
                        item.unit[0].name === 'Unit Rawat Inap'
                          ? ''
                          : item?.branch[0]?.name
                      }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="expandable()">
                      <v-icon v-if="!expand">mdi-chevron-down</v-icon>
                      <v-icon v-else>mdi-chevron-up</v-icon>
                    </v-btn>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="subdata-visited" xs6>
                      <label class="caption grey--text"
                        >Kunjungan Terakhir</label
                      >
                      <label class="caption font-weight-medium"
                        >:
                        {{ item.dateSplit[0] }}
                        pada
                        {{ item.dateSplit[1] }}</label
                      >
                      <label class="caption grey--text">Dokter</label>
                      <label class="caption font-weight-medium"
                        >:
                        {{
                          item.timestamps?.doctor_by?.[0]?.detail?.[0]?.name ||
                            '-'
                        }}</label
                      >
                    </v-col>
                    <v-col class="subdata-visited-2" xs5>
                      <label class="caption grey--text">Diagnosa :</label>
                      <label class="caption">{{
                        item.assesment.main_diagnose.diagnose.description
                      }}</label>
                    </v-col>
                    <v-col class="pa-0 text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            slot="activator"
                            class="pa-0"
                            v-bind="attrs"
                            v-on="on"
                            @click="changeForm(item)"
                          >
                            <font-awesome-icon
                              icon="notes-medical"
                              class="fa-icon data-icon"
                            />
                          </v-btn>
                        </template>
                        <span>Asesmen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div v-else>
                <v-alert :value="true" type="info" class="white--text mx-3">
                  Maaf data riwayat pasien tidak ditemukan
                </v-alert>
              </div>
            </v-card>
          </v-col>
          <v-col cols="3">
            <physical-check-card
              :data="resource.lastCheckup.physical"
              v-if="resource.lastCheckup?.physical?.length > 0"
            />
            <health-status-card
              :data="resource.lastCheckup.healthStatus"
              v-if="resource.lastCheckup?.healthStatus?.length > 0"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <action
          :branch="resource.branch"
          :id_emr="id_emr"
          :unit="resource.unit"
          :doctor="resource.doctor"
        ></action>
      </v-tab-item>
      <v-tab-item class="drug-component">
        <drug></drug>
      </v-tab-item>
      <v-tab-item>
        <lab
          :id_emr="id_emr"
          :clinic="resource.unit"
          :doctor="resource.doctor"
          :patientData="patientData"
        />
      </v-tab-item>
      <v-tab-item>
        <letter
          :branch="resource.branch"
          :id_emr="id_emr"
          :clinic="resource.unit"
        />
      </v-tab-item>
      <v-tab-item>
        <GeneralConsent :patientData="patientData" :id_emr="id_emr" />
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialog" width="1200" :fullscreen="selectedForm !== 99">
      <social-data
        v-if="selectedForm === 99"
        @close-form="dialog = false"
        :medical-record="patientData.medical_record_number"
      />
      <GeneralAssessment
        v-else-if="selectedForm === 0"
        :patient-data="selectedPatient"
        :emr-data="selectedCheckup"
        :is-emr="true"
        @close-form="closeDialog()"
      />

      <KiaAssessment
        v-else-if="selectedForm === 1"
        :patient-data="selectedPatient"
        :emr-data="selectedCheckup"
        :is-emr="true"
        @close-form="closeDialog()"
      />

      <DentalAssessment
        v-else-if="selectedForm === 2"
        :patient-data="selectedPatient"
        :emr-data="selectedCheckup"
        :is-emr="true"
        @close-form="closeDialog()"
      />

      <ImunizationAssessment
        v-else-if="selectedForm === 3"
        :patient-data="selectedPatient"
        :emr-data="selectedCheckup"
        :is-emr="true"
        @close-form="closeDialog()"
      />
      <IGDAssessment
        :patient-data="selectedPatient"
        v-else-if="selectedForm === 5"
        @close-form="closeDialog()"
      />

      <MedicalResume
        v-else-if="selectedForm === 4"
        :patient-data="selectedPatient"
        :emr-data="selectedCheckup"
        :is-emr="true"
        @close-form="closeDialog()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import Constant from '@/const';
import IGDAssessment from '../../Service/components/IGDclinic/IGDAssessment.vue';
import GeneralAssessment from '../../Service/components/GeneralPoly/GeneralPolyAssestment.vue';
import KiaAssessment from '../../Service/components/KIApoly/Assessment.vue';
import DentalAssessment from '../../Service/components/DentalPoly/DentalPolyAssestment.vue';
import ImunizationAssessment from '../../Service/components/VaccinePoly/VaccinePolyAssestment.vue';
import MedicalResume from '../../Service/components/Inpatient/MedicalResume.vue';
import PhysicalCheckCard from './EMRDashboardComponent/PhysicalCheckCard.vue';
import HealthStatusCard from './EMRDashboardComponent/HealthStatusCard.vue';
import EMRDashboardSkletonLoader from './EMRDashboardComponent/EMRDashboardSkletonLoader.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapMutations } = createNamespacedHelpers('IGDAssessment');
const SocialData = () => import('../../SharedComponent/SocialData.vue');
const Letter = () => import('./LetterComponent.vue');
const Action = () => import('./ActionComponent.vue');
const Lab = () => import('./LabComponent.vue');
const Drug = () => import('./DrugComponent.vue');
const GeneralConsent = () => import('./GeneralConsentComponent.vue');

const moment = require('moment');
const axios = require('axios');

export default {
  name: 'EmrDashboardPage',
  components: {
    SocialData,
    Letter,
    Action,
    Lab,
    Drug,
    GeneralConsent,
    GeneralAssessment,
    IGDAssessment,
    KiaAssessment,
    DentalAssessment,
    ImunizationAssessment,
    MedicalResume,
    PhysicalCheckCard,
    HealthStatusCard,
    EMRDashboardSkletonLoader,
  },
  data() {
    return {
      expand: false,
      hide: false,
      loading: false,
      tabs: null,
      dialog: false,
      patientData: [],
      id_emr: '',
      visitData: [],
      poly: [],
      filter_open: false,
      doctorFilter: '',
      unitFilter: '',
      branchFilter: '',
      selectedCheckup: {},
      resource: {
        branch: [],
        doctor: [],
        unit: [],
        emrData: [],
        checkup: [],
        lastCheckup: '',
      },
      selectedDate: [],
      socialData: [],
      date: {
        raw: [],
        formatted: moment().format('DD-MM-YYYY'),
      },
      datePicker: false,
      search: '',
      selectedForm: null,
    };
  },
  mounted() {
    this.id_emr = this.$route.params.id;
    this.getEmrById();
    this.getUnit();
    this.getDoctor();
    this.getBranch();
  },
  computed: {
    isAdult() {
      const birthDate = moment(this.patientData.birthDate, 'DD-MM-YYYY');
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations(['setIsEmr']),
    expandable() {
      this.expand = !this.expand;
    },
    closeDialog() {
      this.dialog = false;
      this.selectedForm = null;
    },
    //TODO : KURANG FILTER DATE
    checkUpHistoryFilter() {
      const dateFilter = this.date;
      const unit = this.unitFilter;
      const doctor = this.doctorFilter;
      const search = this.search;
      const isFilter = this.filter_open;
      if (search == '' && !isFilter)
        this.patientData.checkup = this.resource.checkup;
      else {
        const checkupFiltered = this.resource.checkup.filter(v => {
          if (isFilter && (unit || doctor)) {
            return (
              (doctor && v.doctor[0]._id == doctor.id) ||
              (unit && v.unit[0]._id == unit.id)
            );
          } else if (!isFilter && search) {
            return (
              v.doctor[0]?.detail[0]?.name?.match(
                new RegExp(`.*${search}.*`, 'i'),
              ) || v.unit[0].name.match(new RegExp(`.*${search}.*`, 'i'))
            );
          } else return true;
        });
        this.patientData.checkup = checkupFiltered;
      }
    },
    getEmrById() {
      this.loading = true;
      const id = this.$route.params.id;
      axios
        .get(
          `${Constant.apiUrl}/patient/emr/${id}?startDate=${this.date.raw[0] ||
            ''}&endDate=${this.date.raw[1] || ''}&branch=${
            this.branchFilter ? this.branchFilter.name : ''
          }&unit=${this.unitFilter ? this.unitFilter.name : ''}&doctor=${
            this.doctorFilter ? this.doctorFilter.name : ''
          }`,
        )
        .then(response => {
          let { data } = response.data;
          data.last_visit = data.last_visit
            ? moment(data.last_visit)
                .locale('id')
                .format('LLL [WIB]')
            : '-';
          this.patientData = {
            ...data,
            birthDate: moment(data.social.birth.date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
          };
          this.resource.checkup = data.checkup;
          this.getLastCheckupData();
          this.getAddress(data);
        })
        .catch(() => {
          this.patientData.checkup = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLastCheckupData() {
      axios
        .get(Constant.apiUrl.concat(`/patient/emr/checkup/last/${this.id_emr}`))
        .then(response => {
          const data = response.data.data;
          // Can change depending on needs
          this.resource.lastCheckup = {
            physical: [
              {
                label: 'Kepala',
                result: data.assesment?.physical?.head || '-',
              },
              {
                label: 'Mata',
                result: data.assesment?.physical?.eye || '-',
              },
              {
                label: 'Telinga',
                result: data.assesment?.physical?.ear || '-',
              },
              {
                label: 'Hidung',
                result: data.assesment?.physical?.nose || '-',
              },
              {
                label: 'Mulut',
                result: data.assesment?.physical?.mouth || '-',
              },
              {
                label: 'Leher',
                result: data.assesment?.physical?.neck || '-',
              },
              {
                label: 'Rambut',
                result: data.assesment?.physical?.hair || '-',
              },
              {
                label: 'Bibir',
                result: data.assesment?.physical?.lips || '-',
              },
              {
                label: 'Gigi Geligi',
                result: data.assesment?.physical?.teeth || '-',
              },
              {
                label: 'Lidah',
                result: data.assesment?.physical?.tongue || '-',
              },
              {
                label: 'Langit-langit',
                result: data.assesment?.physical?.palate || '-',
              },
              {
                label: 'Tenggorokan',
                result: data.assesment?.physical?.throat || '-',
              },
              {
                label: 'Dada',
                result: data.assesment?.physical?.chest || '-',
              },
              {
                label: 'Dada',
                result: data.assesment?.physical?.chest || '-',
              },
              {
                label: 'Payudara',
                result: data.assesment?.physical?.breast || '-',
              },
              {
                label: 'Perut',
                result: data.assesment?.physical?.stomach || '-',
              },
              {
                label: 'Genitalia',
                result: data.assesment?.physical?.genitalia || '-',
              },
              {
                label: 'Anus/Dubur',
                result: data.assesment?.physical?.anus || '-',
              },
              {
                label: 'Lengan Atas',
                result: data.assesment?.physical?.upper_arm || '-',
              },
              {
                label: 'Lengan Bawah',
                result: data.assesment?.physical?.forearm || '-',
              },
              {
                label: 'Jari Tangan',
                result: data.assesment?.physical?.finger || '-',
              },
              {
                label: 'Kuku Tangan',
                result: data.assesment?.physical?.hand_nail || '-',
              },
              {
                label: 'Persendian Tangan',
                result: data.assesment?.physical?.hand_joint || '-',
              },
              {
                label: 'Tungkai Atas',
                result: data.assesment?.physical?.upper_limb || '-',
              },
              {
                label: 'Tungkai Bawah',
                result: data.assesment?.physical?.lower_limb || '-',
              },
              {
                label: 'Jari Kaki',
                result: data.assesment?.physical?.toe || '-',
              },
              {
                label: 'Kuku Kaki',
                result: data.assesment?.physical?.toe_nail || '-',
              },
              {
                label: 'Persendian Kaki',
                result: data.assesment?.physical?.leg_joint || '-',
              },
              {
                label: 'Persendian Tangan',
                result: data.assesment?.physical?.hand_joint || '-',
              },
            ],
            healthStatus: [
              {
                label: 'Status Kawin',
                result: data.social?.martial_status || '-',
              },
              {
                label: 'Golongan Darah',
                result: data.social?.blood_type.toUpperCase() || '-',
              },
              {
                label: 'Berat Badan (kg)',
                result: data.objective?.weight || '-',
              },
              {
                label: 'Tinggi Badan (m)',
                result: data.objective?.height / 100 || '-',
              },
              {
                label: 'IMT',
                result: this.imtChipDecision(data.nutrition_category || ''),
                isChip: true,
              },
              {
                label: 'Skrining Gizi',
                result: this.nutritionScreeningResult(
                  data.nutrition_screening || null,
                ),
                isChip: true,
              },
              {
                label: 'Risiko Jatuh',
                result: this.fallRiskResult(data.assesment?.fallRisk || null),
                isChip: true,
              },
            ],
            allergy: data.subjective?.allergy_history || [],
            disease_history:
              data.subjective.disease_history.length == 0
                ? []
                : data.subjective.disease_history.map(v => {
                    return v;
                  }),
          };
        })
        .catch(e => {
          this.resource.lastCheckup = {
            physical: [
              {
                label: 'Kepala',
                result: '-',
              },
              {
                label: 'Mata',
                result: '-',
              },
              {
                label: 'Telinga',
                result: '-',
              },
              {
                label: 'Hidung',
                result: '-',
              },
              {
                label: 'Mulut',
                result: '-',
              },
              {
                label: 'Leher',
                result: '-',
              },
              {
                label: 'Rambut',
                result: '-',
              },
              {
                label: 'Bibir',
                result: '-',
              },
              {
                label: 'Gigi Geligi',
                result: '-',
              },
              {
                label: 'Lidah',
                result: '-',
              },
              {
                label: 'Langit-langit',
                result: '-',
              },
              {
                label: 'Tenggorokan',
                result: '-',
              },
              {
                label: 'Dada',
                result: '-',
              },
              {
                label: 'Dada',
                result: '-',
              },
              {
                label: 'Payudara',
                result: '-',
              },
              {
                label: 'Perut',
                result: '-',
              },
              {
                label: 'Genitalia',
                result: '-',
              },
              {
                label: 'Anus/Dubur',
                result: '-',
              },
              {
                label: 'Lengan Atas',
                result: '-',
              },
              {
                label: 'Lengan Bawah',
                result: '-',
              },
              {
                label: 'Jari Tangan',
                result: '-',
              },
              {
                label: 'Kuku Tangan',
                result: '-',
              },
              {
                label: 'Persendian Tangan',
                result: '-',
              },
              {
                label: 'Tungkai Atas',
                result: '-',
              },
              {
                label: 'Tungkai Bawah',
                result: '-',
              },
              {
                label: 'Jari Kaki',
                result: '-',
              },
              {
                label: 'Kuku Kaki',
                result: '-',
              },
              {
                label: 'Persendian Kaki',
                result: '-',
              },
              {
                label: 'Persendian Tangan',
                result: '-',
              },
            ],
            healthStatus: [
              {
                label: 'Status Kawin',
                result: '',
              },
              {
                label: 'Golongan Darah',
                result: '',
              },
              {
                label: 'Berat Badan (kg)',
                result: '-',
              },
              {
                label: 'Tinggi Badan (m)',
                result: '-',
              },
              {
                label: 'IMT',
                result: '-',
              },
              {
                label: 'Skrining Gizi',
                result: '-',
              },
              {
                label: 'Risiko Jatuh',
                result: '-',
              },
            ],
            allergy: [],
            disease_history: [],
          };
        });
    },
    fallRiskResult(data) {
      if (!data) return null;
      let result, score;
      if (this.isAdult) {
        score =
          (data.fallHistory?.score || 0) +
          (data.howToWalk?.score || 0) +
          (data.mentalStatus?.score || 0) +
          (data.secondaryDiagnosis?.score || 0) +
          (data.useHeparinLockTherapy?.score || 0) +
          (data.walkTool?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 24) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
          };
        }
        if (score > 24 && score <= 50) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
          };
        }
        if (score > 50) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
          };
        }
      } else {
        score =
          (data.ageScore || 0) +
          (data.cognitiveImpairment?.score || 0) +
          (data.diagnosis?.score || 0) +
          (data.medicinalUse?.score || 0) +
          (data.surgery?.score || 0) +
          (data.genderScore || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score < 7) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'grey',
          };
        }
        if (score >= 7 && score <= 11) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
          };
        }
        if (score > 11) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
          };
        }
      }
      return result;
    },
    nutritionScreeningResult(data) {
      if (!data) return null;
      let result, score;
      if (this.isAdult) {
        score =
          (data.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
          (data.isHaveSeriousIllness?.score || 0) +
          (data.isUnplannedWeightLoss?.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score <= 1) {
          result = {
            text: 'Tidak Berisiko Malnutrisi',
            color: 'green',
            score,
          };
        }
        if (score === 2) {
          result = {
            text: 'Risiko Malnutrisi',
            color: 'yellow',
            score,
          };
        }
        if (score >= 3) {
          result = {
            text: 'Malnutrisi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (data.isThereCausesMalnourished.score || 0) +
          (data.isThereOfTheseCondition.score || 0) +
          (data.isThin.score || 0) +
          (data.isWeightLossLastMonth.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score === 0) {
          result = {
            text: 'Berisiko Rendah',
            color: 'green',
          };
        }
        if (score >= 1 && score <= 3) {
          result = {
            text: 'Berisiko Sedang',
            color: 'yellow',
          };
        }
        if (score >= 4 && score <= 5) {
          result = {
            text: 'Berisiko Tinggi',
            color: 'red',
          };
        }
      }
      return result;
    },
    imtChipDecision(text) {
      if (!text) return null;
      let result;
      switch (text.toLowerCase()) {
        case 'gizi buruk':
        case 'underweight':
        case 'obesitas':
        case 'obesity':
          result = {
            text,
            color: 'red',
          };
          break;
        case 'gizi kurang':
        case 'gizi lebih':
        case 'overweight ':
          result = {
            text,
            color: 'yellow',
          };
          break;
        case 'gizi baik':
        case 'normal':
          result = {
            text,
            color: 'green',
          };
          break;
        case 'berisiko gizi lebih':
          result = {
            text,
            color: 'light-yellow',
          };
          break;
      }
      return result;
    },
    getAddress(data) {
      const addressData = [data.social.address.text];
      if (data.social.address.sub_district) {
        addressData.push(data.social.address.sub_district);
      }
      if (data.social.address.district) {
        addressData.push(data.social.address.district);
      }
      if (data.social.address.city) {
        addressData.push(data.social.address.city);
      }
      if (data.social.address.province) {
        addressData.push(data.social.address.province);
      }
      this.patientData.addressData = addressData.join(', ');
    },
    getBranch() {
      axios
        .get(Constant.apiUrl.concat('/branch'))
        .then(response => {
          if (response.status === 200) {
            const { data } = response.data;
            this.resource.branch = data.map(branch => {
              return {
                id: branch._id.toString(),
                name: branch.name,
              };
            });
          }
        })
        .catch(() => {
          this.resource.branch = [];
        });
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=8&search=&sort=name&installation=Instalasi Rawat Jalan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.resource.unit = response.data.data.map(unit => {
              return {
                id: unit._id.toString(),
                name: unit.name,
              };
            });
          }
        });
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff/active?id_unit=&role=dokter&branch=${this.$store.getters.userLoggedIn.branch_id}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                id: doctor._id.toString(),
                name: doctor.detail.name,
              };
            });
          }
        });
    },
    filterOpen() {
      this.filter_open = !this.filter_open;
    },
    changeForm(val) {
      this.selectedCheckup = val;
      if (val === null) {
        this.selectedForm = 99;
        this.dialog = true;
      } else {
        this.changeSelectedPatient(val);
        if (val.unit[0].name === 'Poli Umum') this.selectedForm = 0;
        else if (val.unit[0].name === 'Poli KIA') this.selectedForm = 1;
        else if (val.unit[0].name === 'Poli Gigi') this.selectedForm = 2;
        else if (val.unit[0].name === 'Poli Imunisasi') this.selectedForm = 3;
        else if (val.unit[0].name === 'IGD') {
          this.selectedForm = 5;
          this.setIsEmr(true);
        } else this.selectedForm = 4;

        this.dialog = true;
      }
    },
    changeSelectedPatient(item) {
      const patientData = {
        id_emr: this.$route?.params?.id,
        id_registration: item.id_registration?.[0]?._id,
        rmNumber: this.patientData?.medical_record_number,
        triaseNumber: item.triase?.[0]?.triase_number,
        meta: {
          name: this.patientData?.social?.name,
          birthDate: this.patientData?.social?.birth.date,
          gender: this.patientData?.social?.gender,
          age: this.patientData?.social?.age,
          doctor: item.doctor?.[0]?.detail?.[0]?.name,
          poly: item.unit?.[0]?.name,
          id_poly: item.unit?.[0]?._id,
          guarantorType: this.patientData?.social?.assurance?.type,
        },
      };

      this.$store.commit('changeSelectedPatient', patientData);
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.datepicker = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
      this.getEmrById();
      // this.getVisit('');
    },
    getDiagnoseById(index, id) {
      axios
        .get(Constant.apiUrl.concat(`/master/diagnose/${id}`))
        .then(response => {
          this.visitData[index].diagnose.push({
            id: id,
            name: response.description,
          });
        });
    },
    reset() {
      this.date.raw = [];
      this.date.formatted = moment().format('DD-MM-YYYY');
      this.unitFilter = null;
      this.doctorFilter = null;
      this.patientData.checkup = this.resource.checkup;
      this.getEmrById();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  background: #f6f9fb;
  width: 100%;
  font-family: 'Roboto';
  .patient-primary-data {
    flex-direction: row;
    .padding-item {
      padding-right: 12px;
    }
    .social-data {
      height: 11.3rem;
      background-color: #f0f9ff;
      border: 1px solid #ececec;
      .title-header-primary {
        align-items: center;
        font-weight: 700;
        color: $primary-color;
        padding-bottom: 12px;
      }
    }
    .disease-before {
      height: 11.3rem;
      background-color: #e5fff0;
      border: 1px solid #ececec;
      .title-header-primary {
        align-items: center;
        font-weight: 700;
        color: #2d965a;
        padding-bottom: 12px;
      }
      .disease-style {
        overflow-y: auto;
        max-height: 120px;
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #e5fff0;
        }
        &::-webkit-scrollbar-thumb {
          width: 5px;
          background-color: #cdd2d7;
        }
      }
    }
    .allergy {
      height: 11.3rem;
      background-color: #fff2f2;
      border: 1px solid #ececec;
      .title-header-primary {
        align-items: center;
        font-weight: 700;
        color: #e05151;
        padding-bottom: 12px;
      }
      .allergy-style {
        overflow-y: auto;
        max-height: 120px;
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #fff2f2;
        }
        &::-webkit-scrollbar-thumb {
          width: 5px;
          background-color: #cdd2d7;
        }
      }
    }
    .social-history {
      height: 11.3rem;
      background-color: #fff9e5;
      border: 1px solid #ececec;
      .title-header-primary {
        align-items: center;
        font-weight: 700;
        color: #f2994a;
        padding-bottom: 12px;
      }
      .social-style {
        overflow-y: auto;
        max-height: 120px;
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #fff9e5;
        }
        &::-webkit-scrollbar-thumb {
          width: 5px;
          background-color: #cdd2d7;
        }
      }
    }
    .prim-text-size {
      font-size: 0.75rem;
    }
    .allergy-table {
      border-radius: 5px;
      flex-grow: 1;
      text-align: left;
      .title {
        font-size: 1.2em;
        padding: 16px;
      }
      .allergy-history {
        width: 500px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        padding-left: 30px;
        font-size: 1.2em;
      }
    }
  }

  .drug-component {
    @media screen and (max-width: 1903px) {
      max-width: 82vw !important;
    }
    @media screen and (min-width: 1903px) {
      max-width: 86vw !important;
    }
  }
  .headline-blue {
    font-size: 18px;
    padding-right: 5px;
    font-weight: 700;
  }
  .btn-style {
    background-color: #fff2f2 !important;
    color: red;
    min-width: 53px;
    height: 24px;
  }
  .btn-arrow {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }
  .text-col-left {
    text-align: left;
  }
  .float-left {
    // display: flex;
    align-items: center;
    text-align: left;
    &.pad-bottom {
      padding-bottom: 16px;
    }
  }
  &::v-deep .v-input__control {
    min-height: 30px !important;
  }
  .search {
    border: 1px solid #9ca2a5;
    border-radius: 3px;
    width: 150px;
  }
  .subdata-visited {
    display: grid;
    grid-template-columns: 110px 250px;
    grid-template-rows: 25px;
  }
  .subdata-visited-2 {
    display: grid;
    grid-template-columns: 10vh 40vh;
  }
  .medical-status {
    display: grid;
    grid-template-columns: auto 15vh;
    grid-row-gap: 8px;
  }
  ::v-deep .v-input__slot {
    min-height: 0px !important;
  }
  ::v-deep .v-text-field.v-text-field--enclosed .v-input__append-inner,
  .v-text-field.v-text-field--enclosed .v-input__append-outer,
  .v-text-field.v-text-field--enclosed .v-input__prepend-inner,
  .v-text-field.v-text-field--enclosed .v-input__prepend-outer {
    margin-top: 5px;
  }
  ::v-deep .v-text-field--box.v-text-field--single-line input,
  .v-text-field--full-width.v-text-field--single-line input,
  .v-text-field--outline.v-text-field--single-line input {
    margin-top: 0px !important;
  }
  ::v-deep input {
    margin-top: 0px !important;
  }
  ::v-deep .v-tabs-items {
    background-color: transparent;
  }
}
</style>
