<template>
  <v-container fluid>
    <v-snackbar top v-model="snackbar" timeout="2000" color="error">
      <div class="d-flex font-weight-bold justify-center body-1">
        Tidak ada Permintaan Obat
      </div>
    </v-snackbar>
    <v-card flat class="body-container">
      <v-row class="row-layouts px-10 pt-5">
        <v-col class="py-0 text-left font-weight-medium" cols="3">
          <span style="font-size: 1.3rem" class="primary--text"
            >Telaah Resep</span
          >
          <br />
          <span class="input-label mr-13">Penelaah Resep</span>
          <span class="input-label"
            >:
            {{
              reviewDrug.recipeVerifier || $store.getters.userLoggedIn.name
            }}</span
          >
        </v-col>
        <v-col class="py-0 text-left font-weight-bold heading-6">
          <!-- <span></span> -->
        </v-col>
      </v-row>
      <v-row class="row-layouts px-10 pt-5">
        <v-col cols="4">
          <v-row>
            <v-col cols="7" class="text-left font-weight-bold body-2 mb-1"
              >Administratif</v-col
            >
            <v-col cols="2" class="font-weight-medium body-2">Ya</v-col>
            <v-col class="text-left font-weight-medium body-2 pl-0"
              >Tidak</v-col
            >
          </v-row>
          <v-divider style="width: 350px" class="mb-3"></v-divider>
          <v-row
            align-start
            v-for="(value, name, index) in input.administrative"
            :key="'administrative-' + index"
            class="block-input"
          >
            <v-col class="text-left mr-2" cols="7">
              <label class="input-label">
                {{ resource.administrative[index] }}
              </label>
            </v-col>
            <v-col class="pl-5">
              <v-radio-group
                v-model="input.administrative[name]"
                class="pa-0 ma-0"
                row
                @change="saveReview()"
              >
                <v-radio :value="true" class="pa-0"></v-radio>
                <v-radio :value="false" class="pa-0 mx-1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-row>
            <v-col cols="7" class="text-left font-weight-bold body-2 mb-1"
              >Farmasetis</v-col
            >
            <v-col cols="2" class="text-right font-weight-medium body-2"
              >Ya</v-col
            >
            <v-col class="text-left font-weight-medium body-2 pl-5"
              >Tidak</v-col
            >
          </v-row>
          <v-divider style="width: 370px" class="mb-2"></v-divider>
          <v-row
            align-start
            :key="'pharmaceuticals-' + index"
            class="block-input"
            v-for="(value, name, index) in input.pharmaceuticals"
          >
            <v-col class="text-left" cols="7">
              <label class="input-label">
                {{ resource.pharmaceuticals[index] }}
              </label>
            </v-col>
            <v-col>
              <v-radio-group
                v-model="input.pharmaceuticals[name]"
                class="pa-0 ma-0"
                row
                @change="saveReview()"
              >
                <v-radio :value="true" class="pa-0 ml-8"></v-radio>
                <v-radio :value="false" class="pa-0 mx-1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-row>
            <v-col cols="7" class="text-left font-weight-bold body-2 mb-1"
              >Aspek Klinis</v-col
            >
            <v-col cols="2" class="text-right font-weight-medium body-2"
              >Ya</v-col
            >
            <v-col class="text-left font-weight-medium body-2 pl-7"
              >Tidak</v-col
            >
          </v-row>
          <v-divider style="width: 380px" class="mb-2"></v-divider>
          <v-row
            align-start
            :key="'clinic_aspect-' + index"
            class="block-input"
            v-for="(value, name, index) in input.clinical"
          >
            <v-col class="text-left" cols="7">
              <label class="input-label">
                {{ resource.clinical[index] }}
              </label>
            </v-col>
            <v-col>
              <v-radio-group
                v-model="input.clinical[name]"
                class="pa-0 ma-0"
                row
                @change="saveReview()"
              >
                <v-radio :value="true" class="pa-0 mx-6"></v-radio>
                <v-radio :value="false" class="pa-0 mx-3"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-show="reviewDrug.type !== 'requested'" class="row-layouts px-10">
        <v-col class="text-left font-weight-bold heading-6 px-0">
          <span>Telaah Obat</span> <br />
          <label class="input-label">
            Penelaah Obat :
            {{
              reviewDrug.drugsVerifier || $store.getters.userLoggedIn.name
            }}</label
          >
        </v-col>
      </v-row>

      <v-row
        v-show="reviewDrug.type !== 'requested'"
        class="row-layouts px-10 pt-5"
        :class="{ 'mb-1': reviewDrug.type !== 'requested' }"
      >
        <v-col cols="4" class="pt-0">
          <v-row>
            <v-col cols="5"></v-col>
            <v-col cols="2" class="text-right font-weight-medium body-2"
              >Ya</v-col
            >
            <v-col class="text-left font-weight-medium body-2 pl-7"
              >Tidak</v-col
            >
          </v-row>
          <v-divider style="width: 300px"></v-divider>
          <v-row
            align-start
            :key="'drugStudyCheckList-' + index"
            class="block-input"
            v-for="(value, name, index) in input.drugs_review"
          >
            <v-col class="text-left" cols="5">
              <label class="input-label">
                {{ resource.drugs_review[index] }}
              </label>
            </v-col>
            <v-col>
              <v-radio-group
                v-model="input.drugs_review[name]"
                class="pa-0 ma-0"
                row
                @change="onChange(name, 'checkList')"
                :disabled="reviewDrug.type === 'requested'"
              >
                <v-radio :value="true" class="pl-2 mx-4"></v-radio>
                <v-radio :value="false" class="pa-0 mx-4"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-row class="ma-0 pa-0 block-input">
            <v-col class="text-left" cols="3">
              <label class="input-label">Catatan</label>
            </v-col>
            <v-col cols="9">
              <v-textarea
                style="width: 300px"
                class="line-long mt-0 pt-0"
                outlined
                flat
                rows="5"
                placeholder="Masukkan Catatan"
                hide-details
                :disabled="isReceive"
                v-model="input.note"
                @change="onChange('note', 'checkList')"
              />
            </v-col>
            <v-col cols="6" class="pt-3 font-weight-bold text-left"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card-actions
      class="action-layout pt-5"
      :class="{
        requested: reviewDrug.type === 'requested',
        handover: reviewDrug.type !== 'requested',
      }"
    >
      <v-spacer />
      <div class="price-box font-weight-medium">
        <div class="d-flex">
          <v-icon class="mr-2">mdi-shopping-outline</v-icon>
          <span>Total Harga Obat</span>
        </div>
        <span>
          {{ currency(totalPrice) }}
        </span>
      </div>
      <div
        class="action-footer"
        v-if="reviewDrug.type === 'requested' && medtoolAmount == 0"
      >
        <v-btn
          @click="addRecipeReview"
          class="btn-create"
          tile
          depressed
          :disabled="hadReviewed"
        >
          Simpan
        </v-btn>
      </div>
      <div
        class="action-footer"
        v-if="reviewDrug.type === 'requested' && medtoolAmount > 0"
      >
        <v-btn outlined color="#137BC0" class="ml-3" depressed @click="next()">
          Selanjutnya
        </v-btn>
      </div>
      <div class="action-footer" v-if="reviewDrug.type !== 'requested'">
        <v-btn
          @click="addDrugReview"
          :disabled="!isDrugsReady"
          class="btn-create"
          tile
          depressed
        >
          Simpan
        </v-btn>
      </div>
    </v-card-actions>
  </v-container>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');
export default {
  name: 'DrugReview',
  mixins: [formatMixin],
  props: {
    reviewDrug: Object,
    medtoolAmount: Number,
    patient: Object,
    idPharmacy: String,
    patient_status: String,
    totalPrice: Number,
    mix_recipe: Array,
    recipe: Array,
    recipeAll: Object,
    medtool: Array,
    totalData: Number,
  },
  data() {
    return {
      snackbar: false,
      hadReviewed: false,
      input: {
        administrative: {
          name: true,
          age: true,
          doctor: true,
          date: true,
        },
        clinical: {
          indication: true,
          dosage: true,
          time: true,
          duplication: true,
          allergy: true,
          rotd: true,
        },
        drugs_review: {
          patient: null,
          drugs: null,
          dosage: null,
          administer: null,
          time: null,
          expiry: null,
        },
        pharmaceuticals: {
          name: true,
          strength: true,
          dosage: true,
          quantity: true,
          rule: true,
        },
        note: '',
      },
      resource: {
        administrative: ['Nama Pasien', 'Umur', 'Nama Dokter', 'Tanggal Resep'],
        pharmaceuticals: [
          'Nama Obat',
          'Bentuk Kekuatan',
          'Dosis',
          'Jumlah Obat',
          'Aturan Cara Penggunaan',
        ],
        clinical: [
          'Tepat Indikasi',
          'Tepat Dosis',
          'Tepat Waktu Penggunaan',
          'Duplikasi Obat',
          'Alergi',
          'ROTD',
        ],
        drugs_review: [
          'Benar Pasien',
          'Benar Obat',
          'Benar Dosis',
          'Benar Cara Pemberian',
          'Benar Waktu Pemberian',
          'Tidak ED',
        ],
      },
      isReceive: false,
    };
  },
  created() {
    if (this.reviewDrug.type !== 'requested') {
      this.input = this.reviewDrug;
      this.input.drugs_review = {
        patient: true,
        drugs: true,
        dosage: true,
        administer: true,
        time: true,
        expiry: true,
      };
    }
  },
  mounted() {
    if (this.reviewDrug.type === 'requested') {
      this.saveReview();
    }
    // console.log(this.recipeAll);
  },
  computed: {
    isRecipeEmpty() {
      return (
        this.recipe.length === 0 &&
        this.mix_recipe.length === 0 &&
        this.medtool.length === 0
      );
    },
    isDrugsReady() {
      return this.reviewDrug.type !== 'requested'
        ? !this.isRecipeEmpty
          ? this.recipe.some(item => item.status) ||
            this.mix_recipe.some(item => item.status) ||
            this.medtool.some(item => item.status)
          : true
        : undefined;
    },
    isDrugsComplete() {
      return this.reviewDrug.type !== 'requested'
        ? this.recipe.every(item => item.status) &&
            this.mix_recipe.every(item => item.status) &&
            this.medtool.every(item => item.status)
        : undefined;
    },
  },
  methods: {
    saveReview() {
      this.$store.commit('saveRecipeReview', this.input);
    },
    next() {
      this.$emit('next');
    },
    addRecipeReview() {
      if (this.totalData == 0) {
        this.snackbar = true;
        return;
      }
      const id_pharmacy = this.idPharmacy;
      const payload = {
        status: {
          type: 'production',
          id_staff: this.reviewDrug.id_staff,
        },
        // recipe: this.recipeAll,
        // recipe: {
        //   ...this.recipeAll,
        //   recipe: this.recipeAll.recipe.map(drug => {
        //     return {
        //       ...drug,
        //       isApproved: true,
        //     };
        //   }),
        //   mix_recipe: this.recipeAll.mix_recipe.map(drug => {
        //     return {
        //       ...drug,
        //       isApproved: true,
        //     };
        //   }),
        //   medical_tools: this.recipeAll.medical_tools.map(tool => {
        //     return {
        //       ...tool,
        //       isApproved: true,
        //     };
        //   }),
        // },
        administrative: this.input.administrative,
        pharmaceutical: this.input.pharmaceuticals,
        clinical: this.input.clinical,
        recipe_verifier: this.$store.getters.userLoggedIn.id,
      };
      axios
        .put(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`), payload)
        .then(response => {
          if (response.status === 200) {
            Swal.fire(
              'Data Permintaan Obat dan Alkes Tersimpan',
              '',
              'success',
            );
            this.hadReviewed = true;
            this.$emit('review');
          }
        })
        .catch(error => {
          const data = error.response.data;
          const messageData = data.pesan.replace('Bad Request: ', '');
          Swal.fire(`${messageData}`, '', 'error');
        });
    },
    addDrugReview() {
      const id_pharmacy = this.patient.id;
      const recipe = this.recipe.map(nonRacik => {
        return {
          _id: nonRacik.id,
          isApproved: true,
          isCreated: true,
          isDelivered: true,
          isOrdered: true,
          staff_create: this.$store.getters.userLoggedIn.name,
        };
      });

      const mix_recipe = this.mix_recipe.map(racik => {
        return {
          _id: racik.id,
          name: racik.name,
          isOrdered: true,
          isApproved: true,
          isCreated: true,
          isDelivered: true,
          staff_create: this.$store.getters.userLoggedIn.name,
        };
      });

      const medical_tools = this.medtool.map(alkes => {
        return {
          name: alkes.name,
          _id: alkes.id,
          isApproved: true,
          isCreated: true,
          isDelivered: true,
          isOrdered: true,
          staff_create: this.$store.getters.userLoggedIn.name,
        };
      });
      const payload = {
        status: {
          type: 'finish',
          id_staff: this.reviewDrug.id_staff,
        },
        recipe: recipe,
        mix_recipe: mix_recipe,
        medical_tools: medical_tools,
        patient_status: this.patient_status,
        drugs_review: this.input.drugs_review,
        description: this.input.note,
        drugs_verifier: this.$store.getters.userLoggedIn.id,
      };
      if (!this.isDrugsComplete) {
        Swal.fire({
          text:
            'Terdapat obat yang belum diserahkan, Apakah Anda yakin akan melanjutkan?',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Lanjutkan',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          icon: 'warning',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .put(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`), payload)
              .then(response => {
                if (response.status === 200 || response.status === 204) {
                  this.$emit('close-form');
                  Swal.fire('Penyerahan Obat dan Alkes Selesai', '', 'success');
                }
              })
              .catch(error => {
                const data = error.response.data;
                const messageData = data.pesan.replace('Bad Request: ', '');
                Swal.fire(`${messageData}`, '', 'error');
              });
          }
        });
      } else {
        axios
          .put(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`), payload)
          .then(response => {
            if (response.status === 200 || response.status === 204) {
              this.$emit('close-form');
              Swal.fire('Penyerahan Obat dan Alkes Selesai', '', 'success');
            }
          })
          .catch(error => {
            const data = error.response.data;
            const messageData = data.pesan.replace('Bad Request: ', '');
            Swal.fire(`${messageData}`, '', 'error');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: left;
  padding-left: 35px;
}

.price-box {
  width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: #f0f9ff;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  padding: 8px;
  font-size: 0.9rem;
}

.officer {
  text-align: left;
}

.body-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .row-layouts {
    width: 100%;
  }
}
.block-input {
  height: 50px;
}
.input-label {
  font-size: 12px;
}
.main {
  display: flex;
  width: 100%;
  max-height: auto;
  min-height: 350px;
  flex-direction: column;
  position: relative;

  .btn-new {
    right: 10px;
    bottom: -20vh;
  }
}
.action-layout {
  display: flex;
  &.requested {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 0.8rem 1rem;
  }
  &.handover {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 0.8rem 1rem;
  }
  .action-footer {
    justify-content: end;
    align-items: end;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .btn-create {
      margin: 0 20px;
      max-width: 80px;
      border-radius: 4px;
      height: 30px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;

      &.next {
        max-width: 120px;
      }
    }
    .btn-entry {
      border: 2px solid #3498db;
      color: #3498db;
      outline: cadetblue;
      background-color: white !important;
    }
  }
}
</style>
