<template>
  <v-card class="d-flex main-card">
    <history-sidebar v-if="!isEmr" />
    <v-container fluid>
      <header-content
        :data="headerData"
        pl="2vw"
        pr="2vw"
        mb="2vw"
        title="Assesmen Poli Klinik Ibu dan Anak"
      />
      <v-main>
        <InputAssessment
          v-if="!emrData"
          @close-form="close()"
          :tab="tab"
          :patient-data="patientData"
        />

        <!-- EMR -->
        <InputAssessment
          v-else
          @close-form="close()"
          :patient-data="patientData"
          :emr-data="emrData"
          :is-emr="isEmr"
        />
        <!-- EMR -->
      </v-main>
    </v-container>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import HistorySidebar from '../AssessmentComponents/HistorySidebar.vue';
import InputAssessment from './InputAssessment';
import HeaderContent from '../../../SharedComponent/HeaderContent.vue';

const moment = require('moment');

export default {
  components: {
    HistorySidebar,
    InputAssessment,
    HeaderContent,
  },
  data() {
    return {
      headerData: '',
    };
  },
  props: {
    patientData: Object,
    isEmr: Boolean,
    emrData: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.headerData = [
      {
        label: 'No.RM',
        text: this.patientData.rmNumber,
      },
      {
        label: 'Nama',
        text: `${this.patientData.meta.name} (${
          this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
        })`,
      },
      {
        label: 'Tanggal Lahir',
        text: `${moment(this.patientData.meta.birthDate, 'DD MMMM YYYY').format(
          'DD/MM/YYYY',
        )} (${this.patientData.meta.age} Tahun)`,
      },
    ];
  },
  methods: {
    addData() {
      Swal.fire('Data Tindakan berhasil disimpan!', '', 'success');
      this.close();
    },
    close() {
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  height: 100%;
}
</style>
