<template>
  <v-card>
    <v-container fluid class="container-layout">
      <section-title title="data subyektif" />
      <subjective-data />
      <section-title title="data obyektif" />
      <objective-data />
      <section-title title="assesmen nyeri" />
      <pain-assessment />
      <section-title title="skrining" />
      <nutrition-screening :isAdult="isAdult" />
      <v-divider class="my-5"></v-divider>
      <fall-risk
        :isAdult="isAdult"
        :kidAge="kidAge"
        :patientData="patientData"
      />
      <v-row class="d-flex justify-end mb-6 pr-7">
        <v-btn
          @click="changeForm(0)"
          rounded
          text
          :color="hasInfectiousAssessmentData ? 'success' : 'primary'"
          class="text-capitalize"
          ><span
            style="letter-spacing: .04rem"
            class="primary--text ml-4 font-weight-bold"
            :class="{
              'primary--text': !hasInfectiousAssessmentData,
              'success--text': hasInfectiousAssessmentData,
            }"
            >Assesmen Infeksius
            <v-icon :color="hasInfectiousAssessmentData ? 'success' : 'primary'"
              >mdi-arrow-right</v-icon
            ></span
          ></v-btn
        >
        <v-btn
          @click="changeForm(1)"
          rounded
          text
          :color="hasFluidBalanceData ? 'success' : 'primary'"
          class="ml-5 text-capitalize"
          ><span
            style="letter-spacing: .04rem"
            class="ml-4 font-weight-bold"
            :class="{
              'primary--text': !hasFluidBalanceData,
              'success--text': hasFluidBalanceData,
            }"
            >Form Keseimbangan Cairan
            <v-icon :color="hasFluidBalanceData ? 'success' : 'primary'"
              >mdi-arrow-right</v-icon
            ></span
          ></v-btn
        >
      </v-row>
      <section-title title="tindakan gawat darurat" />
      <emergency-action />
      <section-title title="masalah keperawatan" />
      <nursing-problem />
      <h2 class="text-left blue--text pb-5">Data Subyektif</h2>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Keluhan Utama</label
          >
        </v-col>
        <v-col cols="5">
          <v-textarea
            :readonly="hasRoles().isSuperManager"
            no-resize
            outlined
            placeholder="Jelaskan..."
            height="200"
            hide-details="false"
            dense
            v-model="input.subjective.primary_complaint"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Riwayat Alergi</label
          >
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col class="text-left">
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.subjective.alergic_history.type === 1
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="input.subjective.alergic_history.type === 0"
                tile
                class="text-none"
                width="153"
                depressed
                @click="input.subjective.alergic_history.type = 1"
                >Ada</v-btn
              >
            </v-col>
            <v-col class="text-left">
              <v-btn
                :disabled="hasRoles().isSuperManager"
                tile
                :color="
                  input.subjective.alergic_history.type === 0
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="input.subjective.alergic_history.type === 1"
                width="153"
                depressed
                class="text-none"
                @click="input.subjective.alergic_history.type = 0"
                >Tidak Ada</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-show="input.subjective.alergic_history.type === 1">
            <v-col cols="6">
              <v-text-field
                v-for="(allergy, index) in input.subjective.alergic_history
                  .detail"
                :readonly="hasRoles().isSuperManager"
                :key="'allergy-' + index"
                :placeholder="'Alergi ' + (index + 1)"
                v-model="input.subjective.alergic_history.detail[index].type"
                @keyup="addAllergy($event, index)"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Riwayat Penyakit Dahulu</label
          >
        </v-col>
        <v-col cols="2">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            v-for="(disease, index) in input.subjective.deasease_history"
            dense
            :placeholder="'Riwayat penyakit ' + (index + 1)"
            :key="'disease-' + index"
            v-model="input.subjective.deasease_history[index]"
            @keyup="addDisease($event, index)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Riwayat Psikospiritual</label
          >
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col>
              <v-row class="text-left">
                <v-col>
                  <v-btn
                    :disabled="hasRoles().isSuperManager"
                    :color="
                      input.subjective.psychospiritual_history.type === 1
                        ? 'primary'
                        : '#A6ADB4'
                    "
                    :outlined="
                      input.subjective.psychospiritual_history.type === 0
                    "
                    tile
                    class="text-none"
                    width="153"
                    depressed
                    @click="input.subjective.psychospiritual_history.type = 1"
                    >Ada</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    :disabled="hasRoles().isSuperManager"
                    :color="
                      input.subjective.psychospiritual_history.type === 0
                        ? 'primary'
                        : '#A6ADB4'
                    "
                    :outlined="
                      input.subjective.psychospiritual_history.type === 1
                    "
                    tile
                    class="text-none"
                    width="153"
                    depressed
                    @click="input.subjective.psychospiritual_history.type = 0"
                    >Tidak Ada</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                v-show="input.subjective.psychospiritual_history.type === 1"
              >
                <v-col>
                  <v-checkbox
                    :readonly="hasRoles().isSuperManager"
                    label="Takut Kematian"
                    color="success"
                    dense
                    hide-details="false"
                    :value="1"
                    v-model="input.subjective.psychospiritual_history.detail"
                  ></v-checkbox>
                  <v-checkbox
                    :readonly="hasRoles().isSuperManager"
                    label="Takut Dioperasi"
                    color="success"
                    dense
                    hide-details="false"
                    :value="2"
                    v-model="input.subjective.psychospiritual_history.detail"
                  ></v-checkbox>
                  <v-checkbox
                    :readonly="hasRoles().isSuperManager"
                    label="Kecemasan"
                    color="success"
                    dense
                    hide-details="false"
                    :value="3"
                    v-model="input.subjective.psychospiritual_history.detail"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    :readonly="hasRoles().isSuperManager"
                    label="Putus Asa"
                    color="success"
                    dense
                    hide-details="false"
                    :value="4"
                    v-model="input.subjective.psychospiritual_history.detail"
                  ></v-checkbox>
                  <v-checkbox
                    :readonly="hasRoles().isSuperManager"
                    label="Kesurupan"
                    color="success"
                    dense
                    hide-details="false"
                    :value="5"
                    v-model="input.subjective.psychospiritual_history.detail"
                  ></v-checkbox>
                  <v-checkbox
                    :readonly="hasRoles().isSuperManager"
                    label="Tahayul, Khurofat"
                    color="success"
                    dense
                    hide-details="false"
                    :value="6"
                    v-model="input.subjective.psychospiritual_history.detail"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5">Data Obyektif</h2>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Keadaan Umum</label
          >
        </v-col>
        <v-col cols="6">
          <v-row class="text-left">
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.objective.general_condition === 1
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="input.objective.general_condition !== 1"
                tile
                class="text-none"
                width="153"
                depressed
                @click="input.objective.general_condition = 1"
                >Baik</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.objective.general_condition === 2
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="input.objective.general_condition !== 2"
                tile
                class="text-none"
                width="153"
                depressed
                @click="input.objective.general_condition = 2"
                >Sedang</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.objective.general_condition === 3
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="input.objective.general_condition !== 3"
                tile
                class="text-none"
                width="153"
                depressed
                @click="input.objective.general_condition = 3"
                >Buruk</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Tekanan Darah</label
          >
        </v-col>
        <v-col cols="1">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="input.objective.ttv.sistole"
          ></v-text-field>
        </v-col>
        <v-col style="max-width:1%;" class="px-0 text-center">/</v-col>
        <v-col cols="1">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            suffix="mmHg"
            v-model.number="input.objective.ttv.diastole"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="pr-6">
          <label class="body-2 font-weight-bold black--text">Suhu</label>
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            suffix="celcius"
            v-model.number="input.objective.ttv.temperature"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">Nadi</label>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            suffix="x/menit"
            v-model.number="input.objective.ttv.pulse"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">Saturasi O2</label>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            suffix="%"
            v-model.number="input.objective.ttv.saturation"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">BB</label>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            suffix="kg"
            v-model.number="input.objective.ttv.weight"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">RR</label>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            suffix="x/menit"
            v-model.number="input.objective.ttv.RR"
          ></v-text-field>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5">Nilai Nyeri</h2>
      <v-row class="text-left pa-5 mt-5">
        <v-col cols="6" class="mt-5">
          <v-slider
            v-model="input.objective.pain_assesment.detail.score"
            :tick-labels="satisfactionRange"
            :max="10"
            step="1"
            :disabled="hasRoles().isSuperManager"
            ticks="always"
            tick-size="5"
            thumb-label="always"
            :thumb-size="70"
          >
            <template v-slot:thumb-label="{ value }">
              <v-img
                :src="satisfactionLabels[Math.min(Math.ceil(value / 2), 5)]"
              ></v-img>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row class="text-left caption black--text">
        <v-col class="pt-0">
          <p class="mb-0">0 = No Pain</p>
          <p class="mb-0">1 - 3 = Mild</p>
          <p class="mb-0">4 - 6 = Moderate</p>
          <p class="mb-0">7 - 10 = Severe</p>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="4">
          <label class="body-2 font-weight-bold black--text"
            >Provocation: Faktor yang memperburuk rasa nyeri</label
          >
        </v-col>
        <v-col cols="5">
          <v-row align="baseline">
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                label="Cahaya"
                color="success"
                :value="1"
                v-model="input.objective.pain_assesment.detail.provocation.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                label="Gelap"
                color="success"
                :value="2"
                v-model="input.objective.pain_assesment.detail.provocation.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                label="Gerakan"
                color="success"
                :value="3"
                v-model="input.objective.pain_assesment.detail.provocation.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                label="Berbaring"
                color="success"
                :value="4"
                v-model="input.objective.pain_assesment.detail.provocation.data"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col cols="5">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="
                  input.objective.pain_assesment.detail.provocation.detail
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="4">
          <label class="body-2 font-weight-bold black--text"
            >Quality: Rasa nyeri seperti</label
          >
        </v-col>
        <v-col cols="5">
          <v-row align="baseline">
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Ditusuk"
                dense
                hide-details="false"
                color="success"
                :value="1"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Dipukul"
                dense
                hide-details="false"
                color="success"
                :value="2"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Berdenyut"
                dense
                hide-details="false"
                color="success"
                :value="3"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Ditikam"
                dense
                hide-details="false"
                color="success"
                :value="4"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Kram"
                dense
                hide-details="false"
                color="success"
                :value="5"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Ditarik"
                dense
                hide-details="false"
                color="success"
                :value="6"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Dibakar"
                dense
                hide-details="false"
                color="success"
                :value="7"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Tajam"
                dense
                hide-details="false"
                color="success"
                :value="8"
                v-model="input.objective.pain_assesment.detail.quality.data"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col cols="5">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="input.objective.pain_assesment.detail.quality.detail"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="4">
          <label class="body-2 font-weight-bold black--text"
            >Radiation : Nyeri menjalar kebagian tubuh yang lain</label
          >
        </v-col>
        <v-col cols="4">
          <v-row class="text-left">
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.objective.pain_assesment.detail.radiation
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="
                  input.objective.pain_assesment.detail.radiation === false
                "
                tile
                class="text-none"
                width="153"
                depressed
                @click="input.objective.pain_assesment.detail.radiation = true"
                >Ya</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  !input.objective.pain_assesment.detail.radiation
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="
                  input.objective.pain_assesment.detail.radiation === true
                "
                tile
                class="text-none"
                width="153"
                depressed
                @click="input.objective.pain_assesment.detail.radiation = false"
                >Tidak</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="4">
          <label class="body-2 font-weight-bold black--text"
            >Severity : Tingkat keparahan nyeri</label
          >
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col>
              <v-radio-group
                row
                v-model="input.objective.pain_assesment.detail.severity"
              >
                <v-radio
                  dense
                  hide-details="false"
                  label="Tidak Nyeri"
                  color="success"
                  :value="1"
                ></v-radio>
                <v-radio
                  dense
                  hide-details="false"
                  label="Ringan"
                  color="success"
                  :value="2"
                ></v-radio>
                <v-radio
                  dense
                  hide-details="false"
                  label="Sedang"
                  color="success"
                  :value="3"
                ></v-radio>
                <v-radio
                  dense
                  hide-details="false"
                  label="Berat"
                  color="success"
                  :value="4"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="4">
          <label class="body-2 font-weight-bold black--text"
            >Time : Waktu berlangsung</label
          >
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col>
              <v-radio-group
                row
                v-model="input.objective.pain_assesment.detail.time.type"
              >
                <v-radio
                  dense
                  hide-details="false"
                  color="success"
                  label="Terus menerus"
                  :value="1"
                ></v-radio>
                <v-radio
                  dense
                  hide-details="false"
                  color="success"
                  label="Hilang timbul"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <label class="pl-4 pt-2 body-2 font-weight-bold black--text"
              >Lama</label
            >
          </v-row>
          <v-row align="baseline">
            <v-col>
              <v-radio-group
                row
                v-model="input.objective.pain_assesment.detail.time.duration"
              >
                <v-radio
                  dense
                  hide-details="false"
                  color="success"
                  label="<30 menit"
                  class="mt-0"
                  :value="1"
                ></v-radio>
                <v-radio
                  dense
                  hide-details="false"
                  color="success"
                  label=">30 menit"
                  class="mt-0"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5">Asesmen Risiko Jatuh</h2>
      <v-row align="baseline">
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text">Usia</label>
        </v-col>
        <v-col cols="4" class="text-left">
          <label class="body-2 black--text"
            >{{ patientData.meta.age }} tahun</label
          >
        </v-col>
        <v-col
          cols="2"
          class="text-right"
          v-if="input.objective.risk_assessment_falls.age_type"
        >
          <label class="body-2 font-weight-bold">Humpty Dumpty Score</label>
        </v-col>
        <v-col cols="2" class="text-right" v-else>
          <label class="body-2 font-weight-bold">Score</label>
        </v-col>
        <v-col cols="2" class="text-left">
          <label>{{ scoreValue }}</label>
        </v-col>
      </v-row>
      <v-row align="baseline">
        <template v-if="input.objective.risk_assessment_falls.age_type">
          <v-col cols="2" class="text-right">
            <label class="body-2 font-weight-bold black--text">Diagnosis</label>
          </v-col>
          <v-col cols="4">
            <v-select
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              placeholder="Pilih Diagnosis"
              :items="resource.diagnosis"
              v-model="input.objective.risk_assessment_falls.children.diagnosis"
            ></v-select>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="2" class="text-right">
            <label class="body-2 font-weight-bold black--text"
              >Tidak Seimbang</label
            >
          </v-col>
          <v-col cols="4">
            <v-row class="text-left">
              <v-col class="pt-0">
                <v-btn
                  :disabled="hasRoles().isSuperManager"
                  :color="
                    input.objective.risk_assessment_falls.adult.isBalanced
                      ? 'primary'
                      : '#A6ADB4'
                  "
                  :outlined="
                    input.objective.risk_assessment_falls.adult.isBalanced ===
                      false
                  "
                  tile
                  width="153"
                  elevation="0"
                  class="text-none"
                  @click="
                    input.objective.risk_assessment_falls.adult.isBalanced = true
                  "
                  >Ya</v-btn
                >
              </v-col>
              <v-col class="pt-0">
                <v-btn
                  :disabled="hasRoles().isSuperManager"
                  :color="
                    !input.objective.risk_assessment_falls.adult.isBalanced
                      ? 'primary'
                      : '#A6ADB4'
                  "
                  :outlined="
                    input.objective.risk_assessment_falls.adult.isBalanced ===
                      true
                  "
                  tile
                  class="text-none"
                  width="153"
                  elevation="0"
                  @click="
                    input.objective.risk_assessment_falls.adult.isBalanced = false
                  "
                  >Tidak</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </template>

        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text">Risiko</label>
        </v-col>
        <v-col cols="2" class="text-left">
          <label>{{ riskValue }}</label>
        </v-col>
      </v-row>
      <v-row
        align="baseline"
        v-if="input.objective.risk_assessment_falls.age_type"
      >
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text"
            >Gangguan Kognitif</label
          >
        </v-col>
        <v-col cols="4">
          <v-select
            :readonly="hasRoles().isSuperManager"
            dense
            hide-details="false"
            placeholder="Pilih Gangguan Kognitif"
            :items="resource.cognitiveImpairment"
            v-model="
              input.objective.risk_assessment_falls.children.cognitiveImpairment
            "
          ></v-select>
        </v-col>
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text">Action</label>
        </v-col>
        <v-col cols="4" class="pt-2 pb-0 text-left">
          <label>{{ actionValue }}</label>
        </v-col>
      </v-row>
      <v-row
        class="text-right"
        align="baseline"
        v-if="input.objective.risk_assessment_falls.age_type"
      >
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Faktor Lingkungan</label
          >
        </v-col>
        <v-col cols="4">
          <v-select
            :readonly="hasRoles().isSuperManager"
            dense
            hide-details="false"
            :items="resource.environmentFactor"
            v-model="
              input.objective.risk_assessment_falls.children.environmentFactor
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        align="center"
        v-if="input.objective.risk_assessment_falls.age_type"
      >
        <v-col cols="2" class="text-right pl-0">
          <label class="body-2 font-weight-bold black--text"
            >Respon Terhadap Pembedahan/Sedasi/Anestesi</label
          >
        </v-col>
        <v-col cols="4">
          <v-select
            :readonly="hasRoles().isSuperManager"
            dense
            hide-details="false"
            :items="resource.responseToAnesthesia"
            v-model="
              input.objective.risk_assessment_falls.children
                .responseToAnesthesia
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        align="baseline"
        v-if="input.objective.risk_assessment_falls.age_type"
      >
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text"
            >Penggunaan Medikamentosa</label
          >
        </v-col>
        <v-col cols="4">
          <v-select
            :readonly="hasRoles().isSuperManager"
            hide-details="false"
            dense
            :items="resource.drugUsed"
            v-model="input.objective.risk_assessment_falls.children.drugUsed"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        align="baseline"
        v-if="!input.objective.risk_assessment_falls.age_type"
      >
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text"
            >Menggunakan Penopang</label
          >
        </v-col>
        <v-col cols="4">
          <v-row class="text-left">
            <v-col class="pt-0">
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.objective.risk_assessment_falls.adult.toolsHelper
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="
                  input.objective.risk_assessment_falls.adult.toolsHelper ===
                    false
                "
                tile
                width="153"
                elevation="0"
                class="text-none"
                @click="
                  input.objective.risk_assessment_falls.adult.toolsHelper = true
                "
                >Ya</v-btn
              >
            </v-col>
            <v-col class="pt-0">
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  !input.objective.risk_assessment_falls.adult.toolsHelper
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="
                  input.objective.risk_assessment_falls.adult.toolsHelper ===
                    true
                "
                tile
                class="text-none"
                width="153"
                elevation="0"
                @click="
                  input.objective.risk_assessment_falls.adult.toolsHelper = false
                "
                >Tidak</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5">Asesmen Kemampuan Ibadah</h2>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Wajib ibadah :</label
          >
        </v-col>
        <v-col cols="6">
          <v-row align="baseline">
            <v-radio-group
              row
              v-model="input.objective.praying_ability.worship_obligation.type"
            >
              <v-radio
                class="pr-6"
                label="Baligh"
                dense
                color="success"
                :value="1"
              ></v-radio>
              <v-radio
                class="pr-6"
                label="Belum Baligh"
                dense
                color="success"
                :value="2"
              ></v-radio>
              <v-radio
                class="pr-6"
                label="Halangan"
                dense
                color="success"
                :value="3"
              ></v-radio>
            </v-radio-group>
            <v-text-field
              :readonly="hasRoles().isSuperManager"
              dense
              color="success"
              placeholder="Lainnya"
              v-model="
                input.objective.praying_ability.worship_obligation.detail
              "
            ></v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">Bersuci :</label>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-radio-group
              row
              v-model="input.objective.praying_ability.wash_up"
            >
              <v-radio
                dense
                color="success"
                class="pr-6"
                label="Wudhu Mandiri"
                :value="1"
              ></v-radio>
              <v-radio
                class="pr-6"
                dense
                color="success"
                label="Wudhu dengan bantuan"
                :value="2"
              ></v-radio>
              <v-radio
                class="pr-6"
                label="Tayamum"
                dense
                color="success"
                :value="3"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">Sholat :</label>
        </v-col>
        <v-col cols="6">
          <v-row align="baseline">
            <v-radio-group
              row
              v-model="input.objective.praying_ability.praying"
            >
              <v-radio
                class="pr-6"
                dense
                color="success"
                label="Berdiri"
                :value="1"
              ></v-radio>
              <v-radio
                class="pr-6"
                dense
                color="success"
                label="Duduk"
                :value="2"
              ></v-radio>
              <v-radio
                class="pr-6"
                dense
                color="success"
                label="Berbaring/Miring"
                :value="3"
              ></v-radio>
              <v-radio
                dense
                color="success"
                label="Isyarat"
                :value="4"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5">Skrining Gizi</h2>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Penurunan BB 6 Bulan terakhir</label
          >
        </v-col>
        <v-col cols="2">
          <v-select
            :readonly="hasRoles().isSuperManager"
            placeholder="Pilih parameter"
            :items="resource.weight"
            dense
            v-model="input.objective.nutrition_screening.body_weight"
          ></v-select>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="2">
              <label class="body-2 font-weight-bold black--text"
                >Asupan Makan berkurang</label
              >
            </v-col>
            <v-col cols="6">
              <v-row class="text-left">
                <v-col>
                  <v-btn
                    :disabled="hasRoles().isSuperManager"
                    :color="
                      input.objective.nutrition_screening.food_intake === 1
                        ? 'primary'
                        : '#A6ADB4'
                    "
                    :outlined="
                      input.objective.nutrition_screening.food_intake === 0
                    "
                    tile
                    class="text-none"
                    width="153"
                    depressed
                    @click="input.objective.nutrition_screening.food_intake = 1"
                    >Ya</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    :disabled="hasRoles().isSuperManager"
                    :color="
                      input.objective.nutrition_screening.food_intake === 0
                        ? 'primary'
                        : '#A6ADB4'
                    "
                    :outlined="
                      input.objective.nutrition_screening.food_intake === 1
                    "
                    tile
                    class="text-none"
                    width="153"
                    depressed
                    @click="input.objective.nutrition_screening.food_intake = 0"
                    >Tidak</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <label class="body-2 font-weight-bold black--text"
                >Kesimpulan</label
              >
            </v-col>
            <v-col class="text-left">
              <label class="body-2 font-weight-bold red--text">
                {{ labelConclusion }}
              </label>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5">
        Asesmen Pasien Infeksius Dan Immunokompromise
      </h2>
      <v-row align="baseline">
        <v-col cols="3">
          <label class="body-2 font-weight-bold black--text"
            >Apakah pasien menderita penyakit infeksius atau
            immunkompromise?</label
          >
        </v-col>
        <v-col cols="4">
          <v-row class="text-left">
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  input.objective.infectious_and_immunocompromise.is_affected
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="
                  input.objective.infectious_and_immunocompromise
                    .is_affected === false
                "
                tile
                class="text-none"
                width="153"
                depressed
                @click="
                  input.objective.infectious_and_immunocompromise.is_affected = true
                "
                >Ya</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                :disabled="hasRoles().isSuperManager"
                :color="
                  !input.objective.infectious_and_immunocompromise.is_affected
                    ? 'primary'
                    : '#A6ADB4'
                "
                :outlined="
                  input.objective.infectious_and_immunocompromise
                    .is_affected === true
                "
                tile
                class="text-none"
                width="153"
                depressed
                @click="
                  input.objective.infectious_and_immunocompromise.is_affected = false
                "
                >Tidak</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="input.objective.infectious_and_immunocompromise.is_affected">
        <label class="pl-5 py-3 body-2 font-weight-bold black--text"
          >Klasifikasi Penyakit</label
        >
      </v-row>
      <v-row
        v-if="input.objective.infectious_and_immunocompromise.is_affected"
        class="text-right"
        align="baseline"
      >
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">AIRBORNE</label>
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="TBC Aktif"
              :value="1"
              v-model="
                input.objective.infectious_and_immunocompromise.airborne.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Campak (Measles)"
              :value="2"
              v-model="
                input.objective.infectious_and_immunocompromise.airborne.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="MDR-TB"
              :value="3"
              v-model="
                input.objective.infectious_and_immunocompromise.airborne.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="SARS"
              :value="4"
              v-model="
                input.objective.infectious_and_immunocompromise.airborne.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Aspergilosis"
              :value="5"
              v-model="
                input.objective.infectious_and_immunocompromise.airborne.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="Varicella-Zooster"
              :value="6"
              v-model="
                input.objective.infectious_and_immunocompromise.airborne.chosen
              "
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-col cols="2" class="pl-1">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="
                  input.objective.infectious_and_immunocompromise.airborne.other
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="input.objective.infectious_and_immunocompromise.is_affected"
        class="text-right"
        align="baseline"
      >
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">DROPLET</label>
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="ISPA"
              :value="1"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Swine Flu (H1N1)"
              :value="2"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Rubella"
              :value="3"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Dipteria"
              :value="4"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Pneumonia"
              :value="5"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Mumps (Parotitis)"
              :value="6"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="Flu Babi (H5N1)"
              :value="7"
              v-model="
                input.objective.infectious_and_immunocompromise.droplet.chosen
              "
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-col cols="2" class="pl-1">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="
                  input.objective.infectious_and_immunocompromise.droplet.other
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="input.objective.infectious_and_immunocompromise.is_affected"
        class="text-right"
        align="baseline"
      >
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">KONTAK</label>
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Hepatitis A"
              :value="1"
              v-model="
                input.objective.infectious_and_immunocompromise.contact.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="MDRO HIV"
              :value="2"
              v-model="
                input.objective.infectious_and_immunocompromise.contact.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Herpes Simplek"
              :value="3"
              v-model="
                input.objective.infectious_and_immunocompromise.contact.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Scabies"
              :value="4"
              v-model="
                input.objective.infectious_and_immunocompromise.contact.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Konjungtivitas"
              :value="5"
              v-model="
                input.objective.infectious_and_immunocompromise.contact.chosen
              "
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="Varicella-Zooster"
              :value="6"
              v-model="
                input.objective.infectious_and_immunocompromise.contact.chosen
              "
            ></v-checkbox>
          </v-row>
          <v-row align="baseline">
            <v-col cols="2" class="pl-1">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="
                  input.objective.infectious_and_immunocompromise.contact.other
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <h2 class="text-left blue--text pb-3">Masalah Keperawatan</h2>
        </v-col>
        <v-col cols="5">
          <h2 class="text-left blue--text pb-3">
            Rencana Tindakan Keperawatan
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <template
          v-for="(diagnosis, index) in input.objective.nursery_diagnosis"
        >
          <v-col cols="5" :key="'diagnosis-' + index">
            <v-select
              :readonly="hasRoles().isSuperManager"
              dense
              :items="resource.nurseDiagnoses"
              v-model="input.objective.nursery_diagnosis[index].data"
              @change="changeDiagnosis($event, index)"
              clearable
              @click:clear="deleteDataDiagnose(index)"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            v-for="(plan, idx) in diagnosis.plan"
            :key="'disableCol-' + idx + '-' + index"
            v-show="idx !== 0"
          ></v-col>
          <v-col cols="5" :key="'plan-' + idx + '-' + index">
            <v-select
              :readonly="hasRoles().isSuperManager"
              :disabled="input.objective.nursery_diagnosis[index].data === null"
              dense
              :items="resource.plan[diagnosis.data]"
              v-model="input.objective.nursery_diagnosis[index].plan[idx]"
              @change="changePlan($event, index)"
              clearable
              @click:clear="deleteDataPlan(index, idx)"
            ></v-select>
          </v-col>
        </template>
      </v-row>
      <h2 class="text-left blue--text py-5">Tindakan IGD</h2>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Bantuan Hidup Dasar</label
          >
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="RJP"
              :value="1"
              v-model="input.objective.igd_actions.basic_life_support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Gudel"
              :value="2"
              v-model="input.objective.igd_actions.basic_life_support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Intubasi"
              :value="3"
              v-model="input.objective.igd_actions.basic_life_support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Tracheostomy"
              :value="4"
              v-model="input.objective.igd_actions.basic_life_support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="Defibrilasi"
              :value="5"
              v-model="input.objective.igd_actions.basic_life_support.chosen"
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-col cols="2" class="pl-1">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="input.objective.igd_actions.basic_life_support.other"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Tindakan Invasif</label
          >
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="IV Cath"
              :value="1"
              v-model="input.objective.igd_actions.invasive_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="CVC"
              :value="2"
              v-model="input.objective.igd_actions.invasive_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Kateterisasi"
              :value="3"
              v-model="input.objective.igd_actions.invasive_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Heacting"
              :value="4"
              v-model="input.objective.igd_actions.invasive_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="Suction"
              :value="5"
              v-model="input.objective.igd_actions.invasive_actions.chosen"
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-col cols="2" class="pl-1">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="input.objective.igd_actions.invasive_actions.other"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Tindakan Bidai</label
          >
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Sling"
              :value="1"
              v-model="input.objective.igd_actions.splint_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Bidai"
              :value="2"
              v-model="input.objective.igd_actions.splint_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Gibs"
              :value="3"
              v-model="input.objective.igd_actions.splint_actions.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="Skin Traksi"
              :value="4"
              v-model="input.objective.igd_actions.splint_actions.chosen"
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-col cols="2" class="pl-1">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Lainnya"
                v-model="input.objective.igd_actions.splint_actions.other"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Px Penunjang</label
          >
        </v-col>
        <v-col>
          <v-row>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="EKG"
              :value="1"
              v-model="input.objective.igd_actions.support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="Rontgen"
              :value="2"
              v-model="input.objective.igd_actions.support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="CT Scan"
              :value="3"
              v-model="input.objective.igd_actions.support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              class="pr-3"
              dense
              hide-details="false"
              color="success"
              label="USG"
              :value="4"
              v-model="input.objective.igd_actions.support.chosen"
            ></v-checkbox>
            <v-checkbox
              :readonly="hasRoles().isSuperManager"
              dense
              hide-details="false"
              color="success"
              label="GDS"
              :value="5"
              v-model="input.objective.igd_actions.support.chosen"
            ></v-checkbox>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="text-left blue--text py-5" ref="main">Keseimbangan Cairan</h2>
      <v-row>
        <v-col cols="5">
          <v-row>
            <v-col cols="3">
              <label class="body-2 font-weight-bold blue--text"
                >Waktu Pencatatan</label
              >
            </v-col>
            <v-col>
              <v-row align="baseline">
                <v-col cols="3" class="text-right">
                  <label class="body-2 font-weight-bold black--text">Jam</label>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    ref="menu"
                    v-if="!hasRoles().isSuperManager"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="noteTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="noteTime"
                        placeholder="Waktu"
                        append-icon="mdi-chevron-down"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu"
                      v-model="noteTime"
                      full-width
                      format="24hr"
                      @click:minute="$refs.menu.save(noteTime)"
                    ></v-time-picker>
                  </v-menu>
                  <v-text-field
                    v-model="noteTime"
                    placeholder="Waktu"
                    append-icon="mdi-chevron-down"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-else
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <label class="body-2 font-weight-bold blue--text"
                >Pemasukan</label
              >
            </v-col>
            <v-col>
              <v-row align="baseline">
                <v-col cols="3" class="text-right">
                  <label class="body-2 font-weight-bold black--text"
                    >Oral</label
                  >
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :readonly="hasRoles().isSuperManager"
                    suffix="cc"
                    dense
                    type="number"
                    v-on:keypress="isNumber($event)"
                    v-model.number="income.oral"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="3">
                  <label class="body-2 font-weight-bold black--text"
                    >Cairan IV</label
                  >
                </v-col>
                <v-col class="grid-liquid">
                  <template v-for="(liquid, index) in income.ivLiquid">
                    <label
                      :key="'label-' + index"
                      v-if="index === 0 || index === 1"
                      >{{ liquid.name }}</label
                    >
                    <v-text-field
                      :readonly="hasRoles().isSuperManager"
                      v-else
                      :key="'labelFill-' + index"
                      dense
                      hide-details="false"
                      v-model="income.ivLiquid[index].name"
                    ></v-text-field>
                    <v-text-field
                      :readonly="hasRoles().isSuperManager"
                      :key="'amount-' + index"
                      dense
                      type="number"
                      v-on:keypress="isNumber($event)"
                      suffix="cc"
                      v-model.number="income.ivLiquid[index].amount"
                    ></v-text-field>
                    <v-btn
                      :disabled="hasRoles().isSuperManager"
                      icon
                      color="primary"
                      :key="'button-' + index"
                      v-if="index === 0"
                      @click="addLiquid()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    <label
                      v-else-if="index === 1"
                      :key="'button-' + index"
                    ></label>
                    <v-btn
                      :disabled="hasRoles().isSuperManager"
                      icon
                      x-small
                      color="red"
                      :key="'delete-' + index"
                      @click="deleteItem(index)"
                      v-else
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="pt-1">
          <v-row align="baseline">
            <v-col cols="3">
              <label class="body-2 font-weight-bold blue--text"
                >Pengeluaran</label
              >
            </v-col>
            <v-col>
              <template v-for="(out, index) in outcome">
                <v-row align="baseline" :key="'outcome-' + index">
                  <v-col class="text-left" cols="3">
                    <label class="body-2 font-weight-bold black--text">{{
                      out.name
                    }}</label>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :readonly="hasRoles().isSuperManager"
                      dense
                      suffix="cc"
                      type="number"
                      v-on:keypress="isNumber($event)"
                      hide-details="false"
                      v-model.number="outcome[index].amount"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            outlined
            color="primary"
            tile
            elevation="0"
            class="text-none"
            @click="isEdit ? saveEdit() : addToTable()"
          >
            <v-icon>mdi-plus-thick</v-icon>
            <span>{{
              isEdit ? 'Simpan Perubahan' : 'Tambahkan Ke Tabel'
            }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify-sm="center" class="py-5">
        <v-card elevation="0" width="780">
          <div class="grid-table">
            <label class="body-2 font-weight-bold black--text">Jam</label>
            <label class="body-2 font-weight-bold black--text text-left"
              >PEMASUKAN</label
            >
            <label class="body-2 font-weight-bold black--text">Jumlah</label>
            <label class="body-2 font-weight-bold black--text text-left"
              >PENGELUARAN</label
            >
            <label class="body-2 font-weight-bold black--text">Jumlah</label>
          </div>
          <label v-if="input.objective.fluid_balance.length === 0"
            >Tidak ada data</label
          >
          <div class="grid-oral" v-else>
            <template v-for="(item, index) in input.objective.fluid_balance">
              <label :key="'time-' + index">{{ item.added_at }}</label>
              <div class="data-in" :key="'income' + index">
                <label class="text-left body-2 font-weight-bold black--text"
                  >Oral</label
                >
                <label>{{ item.oral }} cc</label>
                <div class="d-flex">
                  <label
                    class="text-left mr-3 body-2 font-weight-bold black--text"
                    >Cairan IV</label
                  >
                  <div class="d-flex flex-column">
                    <template v-for="(liquid, index) in item.in">
                      <label
                        class="text-left body-2 black--text"
                        :key="'nameLiquid-' + index"
                        >{{ liquid.name }}</label
                      >
                    </template>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <template v-for="(liquid, index) in item.in">
                    <label :key="'amountTable-' + index"
                      >{{ liquid.amount }} cc</label
                    >
                  </template>
                </div>
              </div>
              <div class="data" :key="'outcome-' + index">
                <template v-for="(outcome, index) in item.out">
                  <label
                    class="text-left body-2 font-weight-bold black--text"
                    :key="'nameOutcome-' + index"
                    >{{ outcome.name }}</label
                  >
                  <label :key="'amountOutTable-' + index"
                    >{{ outcome.amount }} cc</label
                  >
                </template>
              </div>
              <div class="data" :key="'edit-' + index">
                <v-btn
                  :disabled="hasRoles().isSuperManager"
                  icon
                  x-small
                  @click="editTable(item, index)"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
              <div class="data" :key="'delete-' + index">
                <v-btn
                  :disabled="hasRoles().isSuperManager"
                  icon
                  x-small
                  @click="deleteTable(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </div>
        </v-card>
      </v-row>
    </v-container>
    <v-card-actions class="pb-0">
      <v-btn text class="text-none" @click="$emit('close-form')">Kembali</v-btn>
      <v-spacer></v-spacer>
      <template v-if="!hasRoles().isSuperManager">
        <v-btn
          color="primary"
          tile
          depressed
          width="108"
          v-if="!isEmr"
          class="text-none"
          @click="saveData()"
          >Simpan</v-btn
        >
      </template>
    </v-card-actions>
    <v-dialog
      persistent
      :max-width="selectedForm ? 1300 : 1600"
      v-model="dialog"
    >
      <fluid-balance
        @save="saveFluidBalance"
        :existing-table="fluidBalanceData"
        @close-form="closeForm()"
        v-if="selectedForm === 1 && dialog"
      />
      <infectious-assessment
        :existing-data="infectiousAssessmentData"
        @save="saveInfectiousAssessmentData"
        @close-form="closeForm()"
        v-if="selectedForm === 0 && dialog"
      />
    </v-dialog>
    <v-snackbar timeout="4000" v-model="snackbar" color="success"
      ><span class="font-weight-bold">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          fab
          small
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import SectionTitle from '../../../SharedComponent/Assesment/SectionTitle.vue';
import SubjectiveData from '../../../SharedComponent/Assesment/SubjectiveData.vue';
import ObjectiveData from '../../../SharedComponent/Assesment/ObjectiveData.vue';
import PainAssessment from '../../../SharedComponent/Assesment/PainAssessment.vue';
import NutritionScreening from '../../../SharedComponent/Assesment/NutritionScreening.vue';
import FallRisk from '../../../SharedComponent/Assesment/FallRisk.vue';
import EmergencyAction from '../../../SharedComponent/Assesment/EmergencyAction.vue';
import NursingProblem from '../../../SharedComponent/Assesment/NursingProblem.vue';
import FluidBalance from '../../../SharedComponent/Assesment/FluidBalance.vue';
import InfectiousAssessment from '../../../SharedComponent/Assesment/InfectiousAssessment.vue';
import { createNamespacedHelpers } from 'vuex';
import { postAssessment } from '../../../../fetchApi/Assessment/IGD';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import roleMixin from '@/mixin/roleMixin';

const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters: mapSubjectiveDataGetters } = createNamespacedHelpers(
  'SubjectiveData',
);
const { mapGetters: mapObjectiveDataGetters } = createNamespacedHelpers(
  'ObjectiveData',
);
const { mapGetters: mapPainAssessmentGetters } = createNamespacedHelpers(
  'PainAssessment',
);
const { mapGetters: mapNutritionScreeningGetters } = createNamespacedHelpers(
  'NutritionScreening',
);
const { mapGetters: mapFallRiskGetters } = createNamespacedHelpers('FallRisk');
const { mapGetters: mapEmergencyActionGetters } = createNamespacedHelpers(
  'EmergencyAction',
);
const { mapGetters: mapNursingProblemGetters } = createNamespacedHelpers(
  'NursingProblem',
);

export default {
  name: 'NurseAssessment',
  mixins: [AlertMixin, roleMixin],
  components: {
    SectionTitle,
    SubjectiveData,
    ObjectiveData,
    NutritionScreening,
    PainAssessment,
    InfectiousAssessment,
    FluidBalance,
    NursingProblem,
    EmergencyAction,
    FallRisk,
  },
  props: {
    patientData: {
      type: Object,
    },
    hasinfectiousdata: {
      type: Boolean,
    },
    existingFluidBalanceData: {
      type: Array,
    },
    existingInfectiousData: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      snackbar: false,
      selectedForm: null,
      hasInfectiousAssessmentData: false,
      snackbarText: '',
      isEditingFluidBalance: '',
      isEditinginfectiousAssessment: '',
      fluidBalanceData: [],
      infectiousAssessmentData: {},
    };
  },
  mounted() {
    this.fluidBalanceData = this.existingFluidBalanceData;
    this.hasInfectiousAssessmentData = this.hasinfectiousdata;
    this.infectiousAssessmentData = this.existingInfectiousData || {};
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapSubjectiveDataGetters([
      'getAnamnesis',
      'getAllergy',
      'getPriorDisease',
      'getTreatmentHistory',
      'getPsychologyStatus',
      'getPsychologyStatusCheckbox',
      'getSocialEconomy',
      'getSpiritual',
    ]),
    ...mapObjectiveDataGetters([
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
    ]),
    ...mapPainAssessmentGetters([
      'getScore',
      'getPainFactor',
      'getPainLike',
      'getPainRadiation',
      'getSeverity',
      'getPainFrequency',
      'getPainType',
      'getPainDuration',
      'getDescription',
    ]),
    ...mapNutritionScreeningGetters([
      'getIsThin',
      'getIsLossWeight',
      'getIsHasCondition',
      'getIsMalnutritionRisk',
      'getIsUnplannedWeightLoss',
      'getIsUnplannedWeightLossAmount',
      'getIsFoodIntakePoorDueToDecreasedAppetite',
      'getIsHaveSeriousIllness',
    ]),
    ...mapFallRiskGetters([
      'getSurgery',
      'getDiagnose',
      'getCogntiveImpairment',
      'getMedicamentosa',
      'getHeparinLockTherapy',
      'getMentalStatus',
      'getFallHistory',
      'getSecDiagnose',
      'getWalker',
      'getWalk',
    ]),
    ...mapEmergencyActionGetters([
      'getBasicLifeSupport',
      'getInvasiveAction',
      'getSplintAction',
      'getSupport',
    ]),
    ...mapNursingProblemGetters(['getNursingProblem']),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },

    // Subjective data
    anamnesis: {
      get() {
        return this.getAnamnesis;
      },
      set(value) {
        this.setAnamnesis(value);
      },
    },
    allergy: {
      get() {
        return this.getAllergy;
      },
      set(value) {
        this.setAllergy(value);
      },
    },
    priorDisease: {
      get() {
        return this.getPriorDisease;
      },
      set(value) {
        this.setPriorDisease(value);
      },
    },
    treatmentHistory: {
      get() {
        return this.getTreatmentHistory;
      },
      set(value) {
        this.setTreatmentHistory(value);
      },
    },
    psychologyStatusCheckbox: {
      get() {
        return this.getPsychologyStatusCheckbox;
      },
      set(value) {
        this.setPsychologyStatusCheckbox(value);
      },
    },
    psychologyStatus: {
      get() {
        return this.getPsychologyStatus;
      },
      set(value) {
        this.setPsychologyStatus(value);
      },
    },
    socialEconomy: {
      get() {
        return this.getSocialEconomy;
      },
      set(value) {
        this.setSocialEconomy(value);
      },
    },
    spiritual: {
      get() {
        return this.getSpiritual;
      },
      set(value) {
        this.setSpiritual(value);
      },
    },
    // Objective data
    sistol: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setSistol(value);
      },
    },
    diastol: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setHeight(value);
      },
    },
    bellyCircumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setBellyCircumference(value);
      },
    },
    respirationRate: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setPulse(value);
      },
    },
    // Pain assessment data
    score: {
      get() {
        return this.getScore;
      },
      set(value) {
        this.setScore(value);
      },
    },
    pain_factor: {
      get() {
        return this.getPainFactor;
      },
      set(value) {
        this.setPainFactor(value);
      },
    },
    pain_like: {
      get() {
        return this.getPainLike;
      },
      set(value) {
        this.setPainLike(value);
      },
    },
    pain_radiation: {
      get() {
        return this.getPainRadiation;
      },
      set(value) {
        this.setPainRadiation(value);
      },
    },
    severity: {
      get() {
        return this.getSeverity;
      },
      set(value) {
        this.setSeverity(value);
      },
    },
    pain_frequency: {
      get() {
        return this.getPainFrequency;
      },
      set(value) {
        this.setPainFrequency(value);
      },
    },
    pain_duration: {
      get() {
        return this.getPainDuration;
      },
      set(value) {
        this.setPainDuration(value);
      },
    },
    pain_type: {
      get() {
        return this.getPainType;
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(value) {
        this.setDescription(value);
      },
    },

    // Nutrition screening data
    isThin: {
      get() {
        return this.getIsThin;
      },
      set(value) {
        this.setIsThin(value);
      },
    },
    isLossWeight: {
      get() {
        return this.getIsLossWeight;
      },
      set(value) {
        this.setIsLossWeight(value);
      },
    },
    isHasCondition: {
      get() {
        return this.getIsHasCondition;
      },
      set(value) {
        this.setIsHasCondition(value);
      },
    },
    isMalnutritionRisk: {
      get() {
        return this.getIsMalnutritionRisk;
      },
      set(value) {
        this.setIsMalnutritionRisk(value);
      },
    },
    isUnplannedWeightLoss: {
      get() {
        return this.getIsUnplannedWeightLoss;
      },
      set(value) {
        this.setIsUnplannedWeightLoss(value);
      },
    },
    isUnplannedWeightLossAmount: {
      get() {
        return this.getIsUnplannedWeightLossAmount;
      },
      set(value) {
        this.setIsUnplannedWeightLossAmount(value);
      },
    },
    isFoodIntakePoorDueToDecreasedAppetite: {
      get() {
        return this.getIsFoodIntakePoorDueToDecreasedAppetite;
      },
      set(value) {
        this.setIsFoodIntakePoorDueToDecreasedAppetite(value);
      },
    },
    isHaveSeriousIllness: {
      get() {
        return this.getIsHaveSeriousIllness;
      },
      set(value) {
        this.setIsHaveSeriousIllness(value);
      },
    },
    // Fall risk data
    surgery: {
      get() {
        return this.getSurgery;
      },
      set(value) {
        this.setSurgery(value);
      },
    },
    diagnose: {
      get() {
        return this.getDiagnose;
      },
      set(value) {
        this.setDiagnose(value);
      },
    },
    cogntiveImpairment: {
      get() {
        return this.getCogntiveImpairment;
      },
      set(value) {
        this.setCogntiveImpairment(value);
      },
    },
    medicamentosa: {
      get() {
        return this.getMedicamentosa;
      },
      setSurgery(value) {
        this.setMedicamentosa(value);
      },
    },
    heparinLockTherapy: {
      get() {
        return this.getHeparinLockTherapy;
      },
      set(value) {
        this.setHeparinLockTherapy(value);
      },
    },
    mentalStatus: {
      get() {
        return this.getMentalStatus;
      },
      set(value) {
        this.setMentalStatus(value);
      },
    },
    fallHistory: {
      get() {
        return this.getFallHistory;
      },
      set(value) {
        this.setFallHistory(value);
      },
    },
    secDiagnose: {
      get() {
        return this.getSecDiagnose;
      },
      set(value) {
        this.setSecDiagnose(value);
      },
    },
    walker: {
      get() {
        return this.getWalker;
      },
      set(value) {
        this.setWalker(value);
      },
    },
    walk: {
      get() {
        return this.getWalk;
      },
      set(value) {
        this.setWalk(value);
      },
    },
    // Emergency actio data
    basicLifeSupport: {
      get() {
        return this.getBasicLifeSupport;
      },
      set(value) {
        this.setBasicLifeSupport(value);
      },
    },
    splintAction: {
      get() {
        return this.getSplintAction;
      },
      set(value) {
        this.setSplintAction(value);
      },
    },
    invasiveAction: {
      get() {
        return this.getInvasiveAction;
      },
      set(value) {
        this.setInvasiveAction(value);
      },
    },
    support: {
      get() {
        return this.getSupport;
      },
      set(value) {
        this.setSupport(value);
      },
    },
    // Nursing problem data
    nursingProblem: {
      get() {
        return this.getNursingProblem;
      },
      set(value) {
        this.setNursingProblem(value);
      },
    },
    hasFluidBalanceData() {
      return this.fluidBalanceData.length > 0;
    },
    isAdult() {
      const birthDate = moment(this.patientData.meta.birthDate, 'YYYY-MM-DD');
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
    kidAge() {
      let result,
        age = this.patientData.meta.age;
      if (age < 3) {
        result = {
          text: '<3 Tahun',
          score: 4,
        };
      }
      if (age >= 3 && age <= 7) {
        result = {
          text: '3-7 Tahun',
          score: 3,
        };
      }
      if (age > 7 && age <= 13) {
        result = {
          text: '7-13 Tahun',
          score: 2,
        };
      }
      if (age > 13) {
        result = {
          text: '>13 Tahun',
          score: 1,
        };
      }
      return result;
    },
  },
  methods: {
    saveFluidBalance(data) {
      this.fluidBalanceData = data;
      if (this.isEditingFluidBalance) {
        this.formatSnackbar('Form Keseimbangan Cairan Berhasil Diubah');
      } else {
        this.formatSnackbar('Form Keseimbangan Cairan Berhasil Disimpan');
      }
      this.isEditingFluidBalance = '';
    },
    saveInfectiousAssessmentData(data) {
      Object.assign(this.infectiousAssessmentData, data);
      this.hasInfectiousAssessmentData = Object.values(
        this.infectiousAssessmentData,
      )?.some(i => Boolean(i.checked));
      if (this.isEditinginfectiousAssessment) {
        this.formatSnackbar('Form Assesmen Infeksius Berhasil Diubah');
      } else {
        this.formatSnackbar('Form Assesmen Infeksius Berhasil Disimpan');
      }
      this.isEditinginfectiousAssessment = '';
    },
    formatSnackbar(text) {
      this.snackbar = true;
      this.snackbarText = text;
    },
    changeForm(value) {
      if (value == 0) {
        this.isEditinginfectiousAssessment = this.hasInfectiousAssessmentData;
      }
      if (value == 1) {
        this.isEditingFluidBalance = this.hasFluidBalanceData;
      }
      this.dialog = true;
      this.selectedForm = value;
    },
    closeForm() {
      this.selectedForm = null;
      this.dialog = false;
    },
  },
  close() {
    this.$emit('close-form');
  },
  filterVariable(data, isCheck = false) {
    let result;
    if (data === undefined) {
      result = !isCheck ? null : 'empty';
    } else {
      result = data;
    }
    return result;
  },
  async saveData() {
    // Declare variable for infectious assessment data
    let airborne = [],
      droplet = [],
      contact = [];

    // Filter and mapping infectious assessment data
    const filteredinfectiousAssessmentData = Object.entries(
      this.infectiousAssessmentData,
    ).filter(item => item[1]?.checked || typeof item[1] === 'string');

    filteredinfectiousAssessmentData.forEach(item => {
      if (typeof item[1] !== 'string' && item[1].type === 'airborne') {
        airborne.push(item[1].text);
      }
      if (typeof item[1] !== 'string' && item[1].type === 'droplet') {
        droplet.push(item[1].text);
      }
      if (typeof item[1] !== 'string' && item[1].type === 'contact') {
        contact.push(item[1].text);
      }
    });

    // Mapping payload
    let payload = {
      id_unit: this.$store.getters.selectedPatient.meta.id_poly,
      id_registration: this.patientData.id_registration,
      id_emr: this.patientData.id_emr,
      isAdult: this.isAdult,
      detail_nursery: [
        {
          subjective: {
            anamnesis: this.anamnesis,
            disease_history: this.priorDisease,
            allergy_history: this.allergy,
            treatment_history: this.treatmentHistory,
            psychological_status: this.psychologyStatusCheckbox
              ? this.psychologyStatus
              : ['Tidak Ada Kelainan'],
            social_economy: this.socialEconomy,
            spiritual: this.spiritual,
          },
          objective: {
            heart_rate: this.pulse,
            sistol: this.sistol,
            diastol: this.diastol,
            respiration_rate: this.respirationRate,
            temperature: this.temperature,
            saturation: this.saturation,
            weight: this.weight,
            height: this.height,
            belly_circumference: this.bellyCircumference,
          },
          pain_assesment: {
            type: this.pain_type,
            detail: {
              score: this.score,
              pain_factor: this.pain_factor,
              pain_like: this.pain_like,
              pain_radiation: this.pain_radiation,
              severity: this.severity,
              pain_frequency: this.pain_frequency,
              pain_duration: this.pain_duration,
              description: this.description,
            },
          },
          nutrition_screening: {
            children: {},
            adult: {},
          },
          fall_risk_assessment: {
            ageScore: this.kidAge.score,
            genderScore: this.patientData.meta.gender === 'Laki-laki' ? 2 : 1,
            children: {},
            adult: {},
          },
          infectious_and_immunocompromise: {
            airborne: {
              chosen: airborne,
              other:
                filteredinfectiousAssessmentData.filter(
                  i => i[0] === 'other_airborne',
                )?.[0]?.[1] || null,
            },
            droplet: {
              chosen: droplet,
              other:
                filteredinfectiousAssessmentData.filter(
                  i => i[0] === 'other_droplet',
                )?.[0]?.[1] || null,
            },
            contact: {
              chosen: contact,
              other:
                filteredinfectiousAssessmentData.filter(
                  i => i[0] === 'other_contact',
                )?.[0]?.[1] || null,
            },
          },
          fluid_balance: this.fluidBalanceData.map(item => {
            return {
              added_at: item.time,
              oral: item.sub_income[0].value,
              in: item.sub_income
                .slice(1)
                .map(v => {
                  return {
                    name: v.label,
                    amount: +v.value,
                  };
                })
                .concat(
                  item.otherSubIncome.map(y => {
                    return {
                      name: y.label,
                      amount: +y.value,
                    };
                  }),
                ),
              out: item.outcome.map(i => {
                return {
                  name: i.label,
                  amount: +i.value,
                };
              }),
            };
          }),
          igd_actions: {
            basic_life_support: this.basicLifeSupport,
            invasive_actions: this.invasiveAction,
            splint_actions: this.splintAction,
            support: this.support,
          },
          nursing_problem: this.nursingProblem.map(item => {
            return {
              _id: false,
              data: {
                text: item.content?.text || '',
                value: item.content?.value || '',
              },
              plan: item.treatmentPlan.map(i => {
                return i.content?.text || '';
              }),
            };
          }),
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
            nurse_by: this.$store.getters.userLoggedIn.id,
            nurse_created_at: moment().format(),
          },
        },
      ],
    };
    // Assign variable which depends isAdult variable
    if (this.isAdult) {
      payload = {
        ...payload,
        detail_nursery: [
          {
            ...payload.detail_nursery[0],
            nutrition_screening: {
              children: {},
              adult: {
                isUnplannedWeightLoss: {
                  check:
                    this.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.isUnplannedWeightLossAmount?.check,
                          true,
                        )
                      : this.filterVariable(this.isUnplannedWeightLoss?.check),
                  score:
                    this.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.isUnplannedWeightLossAmount?.score,
                        )
                      : this.filterVariable(this.isUnplannedWeightLoss?.score),
                },
                isFoodIntakePoorDueToDecreasedAppetite: {
                  check: this.filterVariable(
                    this.isFoodIntakePoorDueToDecreasedAppetite?.check,
                  ),
                  score: this.filterVariable(
                    this.isFoodIntakePoorDueToDecreasedAppetite?.score,
                  ),
                },
                isHaveSeriousIllness: {
                  check: this.filterVariable(this.isHaveSeriousIllness?.check),
                  score: this.filterVariable(this.isHaveSeriousIllness?.score),
                },
              },
            },
            fall_risk_assessment: {
              children: {},
              adult: {
                fallHistory: {
                  check: this.filterVariable(this.fallHistory?.check),
                  score: this.filterVariable(this.fallHistory?.score),
                },
                secondaryDiagnosis: {
                  check: this.filterVariable(this.secDiagnose?.check),
                  score: this.filterVariable(this.secDiagnose?.score),
                },
                walkTool: {
                  check: this.filterVariable(this.walker?.check, true),
                  score: this.filterVariable(this.walker?.score),
                },
                useHeparinLockTherapy: {
                  check: this.filterVariable(this.heparinLockTherapy?.check),
                  score: this.filterVariable(this.heparinLockTherapy?.score),
                },
                howToWalk: {
                  check: this.filterVariable(this.walk?.check, true),
                  score: this.filterVariable(this.walk?.score),
                },
                mentalStatus: {
                  check: this.filterVariable(this.mentalStatus?.check, true),
                  score: this.filterVariable(this.mentalStatus?.score),
                },
              },
            },
          },
        ],
      };
    } else {
      payload = {
        ...payload,
        detail_nursery: [
          {
            ...payload.detail_nursery[0],
            nutrition_screening: {
              adult: {},
              children: {
                isThin: {
                  check: this.filterVariable(this.isThin?.check, true),
                  score: this.filterVariable(this.isThin?.score),
                },
                isWeightLossLastMonth: {
                  check: this.filterVariable(this.isLossWeight?.check, true),
                  score: this.filterVariable(this.isLossWeight?.score),
                },
                isThereOfTheseCondition: {
                  check: this.filterVariable(this.isHasCondition?.check, true),
                  score: this.filterVariable(this.isHasCondition?.score),
                },
                isThereCausesMalnourished: {
                  check: this.filterVariable(
                    this.isMalnutritionRisk?.check,
                    true,
                  ),
                  score: this.filterVariable(this.isMalnutritionRisk?.score),
                },
              },
            },
            fall_risk_assessment: {
              adult: {},
              children: {
                ageScore: this.kidAge.score,
                genderScore:
                  this.patientData.meta.gender === 'Laki-laki' ? 2 : 1,
                diagnosis: {
                  check: this.filterVariable(this.diagnose?.value, true),
                  score: this.filterVariable(this.diagnose?.score),
                },
                cognitiveImpairment: {
                  check: this.filterVariable(
                    this.cogntiveImpairment?.value,
                    true,
                  ),
                  score: this.filterVariable(this.cogntiveImpairment?.score),
                },
                surgery: {
                  check: this.filterVariable(this.surgery?.value, true),
                  score: this.filterVariable(this.surgery?.score),
                },
                medicinalUse: {
                  check: this.filterVariable(this.medicamentosa?.value, true),
                  score: this.filterVariable(this.medicamentosa?.score),
                },
              },
            },
          },
        ],
      };
    }
    try {
      const response = await postAssessment({
        payload,
        role: 'perawat',
      });
      if (response.status === 200) {
        Swal.fire('Data Asesmen IGD Berhasil Ditambahkan', '', 'success');
        this.close();
      }
    } catch (error) {
      this.showErrorAxios(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-btn--outlined {
  border: 2px solid currentColor;
}
::v-deep .theme--light.v-label {
  color: black;
}
.container-layout {
  height: 73vh;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.grid-table {
  display: grid;
  grid-template-columns: 150px 218px 100px 150px 100px;
  border-top: 3px solid #f4f5f5;
  border-bottom: 3px solid #f4f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
}
.grid-oral {
  display: grid;
  grid-template-columns: 150px 320px 250px 30px 30px;
  padding-top: 10px;

  .data {
    display: grid;
    grid-template-columns: 150px 100px;
  }
  .data-in {
    display: grid;
    grid-template-columns: 220px 100px;
  }
}
.grid-cairan {
  display: grid;
  grid-template-columns: 180px 120px 120px 130px 100px;
}

.grid-liquid {
  display: grid;
  grid-template-columns: 100px 100px 50px;
  grid-column-gap: 10px;
  text-align: left;
}
::v-deep #input-606 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-620 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-623 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-626 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-629 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
