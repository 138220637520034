<template>
  <v-card flat>
    <div v-show="nextPage === 0" style="height: 60vh;" class="py-3">
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">Cara Masuk</label>
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="triase.entry === 'jalan' ? 'primary' : '#A6ADB4'"
            :outlined="triase.entry !== 'jalan'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.entry = 'jalan'"
            >Jalan</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            :outlined="triase.entry !== 'brandcar'"
            tile
            :color="triase.entry === 'brandcar' ? 'primary' : '#A6ADB4'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.entry = 'brandcar'"
            >Brandcar</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="triase.entry === 'kursi roda' ? 'primary' : '#A6ADB4'"
            :outlined="triase.entry !== 'kursi roda'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.entry = 'kursi roda'"
            >Kursi Roda</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="triase.entry === 'digendong' ? 'primary' : '#A6ADB4'"
            :outlined="triase.entry !== 'digendong'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.entry = 'digendong'"
            >Digendong</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Sudah Terpasang</label
          >
        </v-col>
        <v-col cols="2">
          <template v-for="(mounted, index) in triase.mounted">
            <v-text-field
              :key="'mounted-' + index"
              dense
              :readonly="hasRoles().isSuperManager"
              v-model="mounted[index]"
            ></v-text-field>
          </template>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Alasan Kedatangan</label
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="
              triase.reason.type === 'datang sendiri' ? 'primary' : '#A6ADB4'
            "
            :outlined="triase.reason.type !== 'datang sendiri'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.reason.type = 'datang sendiri'"
            >Datang Sendiri</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="triase.reason.type === 'polisi' ? 'primary' : '#A6ADB4'"
            :outlined="triase.reason.type !== 'polisi'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.reason.type = 'polisi'"
            >Polisi</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="triase.reason.type === 'rujukan' ? 'primary' : '#A6ADB4'"
            :outlined="triase.reason.type !== 'rujukan'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.reason.type = 'rujukan'"
            >Rujukan</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            tile
            :color="triase.reason.type === 'dijemput' ? 'primary' : '#A6ADB4'"
            :outlined="triase.reason.type !== 'dijemput'"
            width="153"
            elevation="0"
            class="text-none"
            @click="triase.reason.type = 'dijemput'"
            >Dijemput</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        class="text-right"
        align="baseline"
        v-show="triase.reason.type === 'rujukan'"
      >
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Rujukan Dari</label
          >
        </v-col>
        <v-col cols="2">
          <v-text-field dense v-model="triase.reason.info"></v-text-field>
        </v-col>
      </v-row>
      <v-row
        class="text-right"
        align="baseline"
        v-show="triase.reason.type === 'dijemput'"
      >
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Dijemput Oleh</label
          >
        </v-col>
        <v-col cols="2">
          <v-text-field dense v-model="triase.reason.info"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text">Kendaraan</label>
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            :color="other === false ? 'primary' : '#A6ADB4'"
            :outlined="other === true"
            tile
            depressed
            class="text-none"
            width="153"
            @click="
              triase.vehicle = 'ambulance';
              other = false;
            "
            >Ambulance</v-btn
          >
        </v-col>
        <v-col cols="2" class="text-left">
          <v-btn
            :disabled="hasRoles().isSuperManager"
            :color="other ? 'primary' : '#A6ADB4'"
            :outlined="!other"
            tile
            depressed
            class="text-none"
            width="153"
            @click="
              other = true;
              triase.vehicle = '';
            "
            >Lain-lain</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="text-right" align="baseline" v-show="other">
        <v-col cols="2">
          <label class="body-2 font-weight-bold black--text"
            >Kendaraan Lain-lain</label
          >
        </v-col>
        <v-col cols="2">
          <v-text-field
            :readonly="hasRoles().isSuperManager"
            placeholder="Tulis kendaraan"
            dense
            v-model="triase.vehicle"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <div v-show="nextPage === 1" class="next-page">
      <v-row align="baseline">
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text"
            >Identitas Pengantar</label
          >
        </v-col>
        <v-col cols="2">
          <v-text-field
            placeholder="Nama Lengkap"
            dense
            :readonly="hasRoles().isSuperManager"
            v-model="triase.agent"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-row align="baseline">
            <v-col cols="3" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Keadaan Waktu Tiba</label
              >
            </v-col>
            <v-col>
              <v-checkbox
                label="Tenang"
                :readonly="hasRoles().isSuperManager"
                color="success"
                hide-details="false"
                dense
                :value="1"
                v-model="triase.arrival_condition"
              ></v-checkbox>
              <v-checkbox
                label="Sesak Nafas"
                :readonly="hasRoles().isSuperManager"
                color="success"
                hide-details="false"
                dense
                :value="2"
                v-model="triase.arrival_condition"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                label="Gelisah"
                :readonly="hasRoles().isSuperManager"
                color="success"
                hide-details="false"
                dense
                :value="3"
                v-model="triase.arrival_condition"
              ></v-checkbox>
              <v-checkbox
                label="Oodem"
                color="success"
                :readonly="hasRoles().isSuperManager"
                hide-details="false"
                dense
                :value="4"
                v-model="triase.arrival_condition"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                label="Kesakitan"
                color="success"
                :readonly="hasRoles().isSuperManager"
                hide-details="false"
                dense
                :value="5"
                v-model="triase.arrival_condition"
              ></v-checkbox>
              <v-checkbox
                label="Pendarahan"
                :readonly="hasRoles().isSuperManager"
                color="success"
                hide-details="false"
                dense
                :value="6"
                v-model="triase.arrival_condition"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                label="Anemia"
                color="success"
                hide-details="false"
                :readonly="hasRoles().isSuperManager"
                dense
                :value="7"
                v-model="triase.arrival_condition"
              ></v-checkbox>
              <v-checkbox
                label="Penurunan Kesadaran"
                :readonly="hasRoles().isSuperManager"
                color="success"
                hide-details="false"
                dense
                :value="8"
                v-model="triase.arrival_condition"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                label="Luka"
                color="success"
                hide-details="false"
                dense
                :value="9"
                v-model="triase.arrival_condition"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="baseline">
        <v-col cols="2" class="text-right">
          <label class="body-2 font-weight-bold black--text"
            >Mekanisme Trauma</label
          >
        </v-col>
        <v-col>
          <v-checkbox
            label="Kecelakaan Lalu Lintas Tunggal"
            :readonly="hasRoles().isSuperManager"
            color="success"
            dense
            v-model="triase.trauma_mechanism.individual_accident.status"
          ></v-checkbox>
          <v-row>
            <v-col class="text-right" cols="2">
              <label class="black--text">Tempat kejadian :</label>
            </v-col>
            <v-col cols="2">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                placeholder="Tempat Kejadian"
                hide-details="false"
                v-model="triase.trauma_mechanism.individual_accident.place"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="
                  triase.trauma_mechanism.individual_accident.date
                "
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="triase.trauma_mechanism.individual_accident.date"
                    append-icon="mdi-chevron-down"
                    placeholder="Tanggal"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :readonly="hasRoles().isSuperManager"
                    hide-details="false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="triase.trauma_mechanism.individual_accident.date"
                  no-title
                  scrollable
                  @input="
                    $refs.menu.save(
                      triase.trauma_mechanism.individual_accident.date,
                    );
                    menu = false;
                  "
                />
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="
                  triase.trauma_mechanism.individual_accident.time
                "
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="triase.trauma_mechanism.individual_accident.time"
                    placeholder="Waktu"
                    append-icon="mdi-chevron-down"
                    v-bind="attrs"
                    :readonly="hasRoles().isSuperManager"
                    v-on="on"
                    dense
                    hide-details="false"
                    clearable
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="triase.trauma_mechanism.individual_accident.time"
                  full-width
                  format="24hr"
                  @click:minute="
                    $refs.menu2.save(
                      triase.trauma_mechanism.individual_accident.time,
                    );
                    menu2 = false;
                  "
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row justify="start" align="baseline">
            <v-col cols="3">
              <v-checkbox
                label="Kecelakaan Lalu Lintas antara :"
                color="success"
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                v-model="triase.trauma_mechanism.accident_between.status"
              ></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                :readonly="hasRoles().isSuperManager"
                hide-details="false"
                v-model="triase.trauma_mechanism.accident_between.vehicle1"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pt-2">
              <label class="black--text">dengan</label>
            </v-col>
            <v-col cols="2">
              <v-text-field
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                v-model="triase.trauma_mechanism.accident_between.vehicle2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col class="text-right" cols="2">
              <label class="black--text">Tempat kejadian :</label>
            </v-col>
            <v-col cols="2">
              <v-text-field
                placeholder="Tempat Kejadian"
                :readonly="hasRoles().isSuperManager"
                dense
                hide-details="false"
                v-model="triase.trauma_mechanism.accident_between.place"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :return-value.sync="
                  triase.trauma_mechanism.accident_between.date
                "
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="triase.trauma_mechanism.accident_between.date"
                    append-icon="mdi-chevron-down"
                    :readonly="hasRoles().isSuperManager"
                    placeholder="Tanggal"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details="false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="triase.trauma_mechanism.accident_between.date"
                  no-title
                  scrollable
                  @input="
                    $refs.menu3.save(
                      triase.trauma_mechanism.accident_between.date,
                    );
                    menu3 = false;
                  "
                />
              </v-menu>
            </v-col>
            <v-col class="pt-0" cols="2">
              <v-menu
                ref="menu4"
                v-model="menu4"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="
                  triase.trauma_mechanism.accident_between.time
                "
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="triase.trauma_mechanism.accident_between.time"
                    placeholder="Waktu"
                    append-icon="mdi-chevron-down"
                    :readonly="hasRoles().isSuperManager"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details="false"
                    clearable
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu4"
                  v-model="triase.trauma_mechanism.accident_between.time"
                  full-width
                  format="24hr"
                  @click:minute="
                    $refs.menu4.save(
                      triase.trauma_mechanism.accident_between.time,
                    );
                    menu4 = false;
                  "
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col cols="2" class="pr-0">
              <v-checkbox
                dense
                label="Jatuh dari ketinggian"
                :readonly="hasRoles().isSuperManager"
                color="success"
                hide-details="false"
                v-model="triase.trauma_mechanism.fall_trauma.status"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-text-field
                placeholder="Deskripsi"
                dense
                hide-details="false"
                :readonly="hasRoles().isSuperManager"
                v-model="triase.trauma_mechanism.fall_trauma.info"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col cols="2">
              <v-checkbox
                dense
                :readonly="hasRoles().isSuperManager"
                label="Luka bakar"
                color="success"
                v-model="triase.trauma_mechanism.burns_trauma.status"
              ></v-checkbox>
              <v-text-field
                placeholder="Deskripsi"
                :readonly="hasRoles().isSuperManager"
                dense
                v-model="triase.trauma_mechanism.burns_trauma.info"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                dense
                label="Trauma Listrik"
                :readonly="hasRoles().isSuperManager"
                color="success"
                v-model="triase.trauma_mechanism.electric_trauma.status"
              ></v-checkbox>
              <v-text-field
                placeholder="Deskripsi"
                dense
                :readonly="hasRoles().isSuperManager"
                v-model="triase.trauma_mechanism.electric_trauma.info"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                :readonly="hasRoles().isSuperManager"
                dense
                label="Trauma zat lainnya"
                color="success"
                v-model="triase.trauma_mechanism.substance_trauma.status"
              ></v-checkbox>
              <v-text-field
                placeholder="Deskripsi"
                dense
                v-model="triase.trauma_mechanism.substance_trauma.info"
                :readonly="hasRoles().isSuperManager"
              ></v-text-field>
            </v-col>
            <v-col cols="3" align-self="end">
              <v-combobox
                placeholder="ICD-10"
                dense
                :readonly="hasRoles().isSuperManager"
                :items="diagnose"
                item-value="_id"
                item-text="description"
                v-model="triase.trauma_mechanism.icd_10"
                :search-input.sync="searchDiagnose"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="text-right">
              <label class="black--text">Keluhan utama :</label>
            </v-col>
            <v-col cols="3">
              <v-text-field
                placeholder="Deskripsi"
                :readonly="hasRoles().isSuperManager"
                dense
                v-model="triase.trauma_mechanism.primary_complaint"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-card-actions class="px-0">
      <v-btn text class="text-none" @click="$emit('close-form')">Kembali</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-show="nextPage === 0"
        color="black"
        width="180"
        class="text-none white--text"
        elevation="0"
        tile
        @click="nextPage = 1"
        >Halaman Selanjutnya</v-btn
      >
      <v-btn
        v-show="nextPage === 1"
        color="primary"
        elevation="0"
        tile
        v-if="!hasRoles().isSuperManager"
        class="text-none white--text"
        width="153"
        @click="addTriase()"
        >Simpan</v-btn
      >
      <v-btn
        v-show="nextPage === 1"
        color="black"
        width="180"
        class="text-none white--text"
        elevation="0"
        tile
        @click="nextPage = 0"
        >Halaman Sebelumnya</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
export default {
  name: 'TriaseCard',
  props: {
    triaseLevel: {
      type: Object,
    },
    wpss: {
      type: Object,
    },
    triase: {
      type: Object,
    },
  },
  mixins: [roleMixin],
  data() {
    return {
      nextPage: 0,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      diagnose: [],
      searchDiagnose: '',
      other: false,
    };
  },
  mounted() {
    this.getDiagnose('');
  },
  watch: {
    searchDiagnose(val) {
      if (val) {
        this.getDiagnose(val);
      }
    },
  },
  methods: {
    getDiagnose(keyword) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=100&search=${keyword ||
              ''}&sort=-code`,
          ),
        )
        .then(response => {
          this.diagnose = response.data.data;
        });
    },
    addTriase() {
      const payload = {
        id_patient: null,
        id_registration: null,
        id_poly: null,
        detail_triase: [
          {
            filled_at: moment(new Date())
              .locale('id')
              .format(),
            triase_level: {
              name: this.triaseLevel.name,
              gender: this.triaseLevel.gender,
              person_in_charge: this.triaseLevel.pic,
              address: this.triaseLevel.address,
              parent_name: this.triaseLevel.parentsName,
              pacs: this.triaseLevel.pacs,
              awareness: this.triaseLevel.awareness,
              blood_pressure_sistole: this.triaseLevel.bloodPressure,
              blood_pressure_diastole: null,
              temperature: this.triaseLevel.temperature,
              pulse: this.triaseLevel.pulse,
              saturation: this.triaseLevel.saturation,
              respiratory: this.triaseLevel.respiration,
              pain: this.triaseLevel.pain,
              weight_body: this.triaseLevel.weight,
              height_body: this.triaseLevel.height,
            },
            wpss: this.wpss,
            triase: this.triase,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        ],
      };

      axios
        .post(Constant.apiUrl.concat('/patient/igd/triase'), payload)
        .then(() => {
          Swal.fire('Triase Berhasil didaftarkan', '', 'success');
          this.$emit('close-form');
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-label {
  color: black;
  font-size: 14px;
}

.next-page {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-button {
    width: 3px;
    background-color: black;
  }
}

::v-deep .v-text-field fieldset,
.v-text-field .v-input__control {
  max-height: 50px;
}
</style>
