<template>
  <v-card class="px-10" style="min-height: 80vh">
    <v-card-title class="justify-center mb-3">
      <span class="font-weight-bold"> Stok Opname</span>
      <v-btn
        absolute
        right
        fab
        @click="dialog = false"
        small
        text
        icon
        color="primary"
      >
        <v-icon dark @click="onCloseButtonClick()"> mdi-close-circle </v-icon>
      </v-btn>
    </v-card-title>

    <v-container fluid class="pa-0">
      <v-row class="mb-3">
        <v-col class="py-0 mb-2 text-start" cols="2">
          <span>Data Barang di Unit</span>
        </v-col>
        <v-col class="py-0 text-start" cols="2">
          <span>{{ unit }}</span>
        </v-col>
        <v-col class="py-0 align-center text-start" cols="3">
          <span>
            Checklist
            {{
              this.input.correctedStock.filter(item => item.isCorrection).length
            }}
            / {{ this.input.totalChecklist }}
          </span>
        </v-col>
        <v-col class="py-0 align-center d-flex justify-end">
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Cari Barang"
            v-model="searchItem"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="grid-display header pl-4">
          <span>No</span>
          <span class="text-left pl-2">Nama Barang</span>
          <span class="text-left pl-5">Supplier</span>
          <span class="text-left pl-1">Expiry Date</span>
          <span class="pl-2">No Batch</span>
          <span class="pl-2">Sisa Stok</span>
          <span class="pl-2">Sisa Fisik</span>
          <span>Catatan</span>
          <span>Koreksi</span>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-progress-linear
            height="2"
            v-if="isLoading"
            indeterminate
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          id="scroll-target"
          v-scroll:#scroll-target="onScroll"
          class="item-container"
          cols="12"
        >
          <v-col
            cols="12"
            style="cursor: pointer"
            @click="showCorrected = !showCorrected"
          >
            <v-layout align-center>
              <span class="grey--text mr-1">Stok Opname</span>
              <span class="grey--text ml-1 mr-2"
                >({{ input.correctedStock.length }})</span
              >
              <v-divider></v-divider>
              <v-icon color="grey" class="mr-3">{{
                showCorrected ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-layout>
          </v-col>
          <v-col
            cols="12"
            v-show="showCorrected"
            v-if="input.correctedStock.length === 0"
          >
            <span class="grey--text my-2">Tidak ada item koreksi</span>
          </v-col>
          <v-form ref="formInput">
            <v-col
              cols="12"
              v-for="(item, index) in input.correctedStock"
              :key="'corrected-item-' + index"
              class="grid-display table-items"
              v-show="showCorrected"
            >
              <span>{{ index + 1 }}</span>
              <span class="text-left">{{ item.displayName }}</span>
              <v-combobox
                v-if="item.isNew"
                :items="resource.supplier"
                v-model="item.supplier"
                style="width: 80%"
                item-text="name"
                append-icon="mdi-chevron-down"
                single-line
                dense
              />
              <span class="text-left pl-3" v-else>{{ item.supplier }}</span>
              <v-col class="pa-0 pr-3" v-if="item.isNew">
                <v-text-field
                  @input="formatExpiryDate(item.displayExpiryDate, index)"
                  v-model="item.displayExpiryDate"
                  placeholder="dd/mm/yyyy"
                  single-line
                  dense
                  class="mt-0 pt-0"
                  maxlength="10"
                  :rules="rules.expiry"
                  required
                ></v-text-field>
              </v-col>
              <span v-else class="text-left">
                {{ formatDate(item.expiryDate) }}
              </span>
              <v-col v-if="item.isNew" class="text-center pa-0 px-4">
                <v-text-field
                  id="items-batch"
                  v-model="item.batch"
                  :value="item.batch"
                  :rules="rules.batch"
                  v-on:keypress="isLetterOrNumber($event)"
                  class="input-text pt-0 mt-0"
                />
                <!-- @input="changeState(input.correctedStock[index])" -->
              </v-col>
              <span v-else class="text-center px-2 pa-0">
                {{ item.batch }}
              </span>
              <span class="text-center px-2 pa-0">{{ item.stock }}</span>
              <v-col class="text-left px-4 pa-0">
                <v-text-field
                  id="items-stock"
                  v-model="item.stockEdit"
                  :value="item.stockEdit"
                  class="input-text pt-0 mt-0"
                  type="number"
                  min="0"
                  :rules="stockRule"
                  oninput="if(Number(this.value) < Number(this.min)) this.value = this.min"
                  :disabled="!item.isCorrection"
                  :suffix="item.package"
                />
                <!-- @input="changeState(input.correctedStock[index])" -->
              </v-col>
              <v-col class="pa-0 px-4">
                <v-textarea
                  id="opname-desc"
                  :rows="1"
                  v-model="item.description"
                  class="input-text max pa-0 mt-0"
                  hide-details
                  no-resize
                  :disabled="!item.isCorrection"
                />
                <!-- @input="changeState(input.correctedStock[index])" -->
              </v-col>
              <v-col class="pa-0 ma-0">
                <v-checkbox
                  v-model="item.isCorrection"
                  class="input-text pa-0 mt-0"
                  hide-details
                  @change="checklistCounter(item.isChecked)"
                ></v-checkbox>
                <!-- changeState(input.correctedStock[index]); -->
              </v-col>
              <v-col class="pa-0 ma-0">
                <v-icon @click="deleteData(item)" color="red"
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-col>
            <v-col
              cols="12"
              style="cursor: pointer"
              @click="showStock = !showStock"
            >
              <v-layout align-center>
                <span class="grey--text mr-1">Stok {{ this.unit }}</span>

                <span class="grey--text ml-1 mr-2"
                  >({{
                    input.detail.filter(
                      arr =>
                        input.correctedStock.findIndex(
                          ar => arr.id === ar.id,
                        ) === -1,
                    ).length
                  }})</span
                >
                <v-divider></v-divider>
                <v-icon color="grey" class="mr-3"
                  >{{ showStock ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-layout>
            </v-col>
            <v-col
              cols="12"
              v-show="showStock"
              v-if="
                input.detail.filter(
                  arr =>
                    input.correctedStock.findIndex(ar => arr.id === ar.id) ===
                    -1,
                ).length === 0
              "
            >
              <span class="my-2 grey--text">Tidak ada item gudang</span>
            </v-col>
            <v-col
              cols="12"
              v-for="(item, index) in input.detail.filter(
                arr =>
                  input.correctedStock.findIndex(ar => arr.id === ar.id) === -1,
              )"
              :key="'item-' + index"
              class="grid-display table-items"
              v-show="showStock"
            >
              <span>{{ index + 1 }}</span>
              <span class="text-left">{{ item.displayName }}</span>
              <span class="text-left pl-3">{{ item.supplier }}</span>
              <v-col v-if="item.isNew">
                <v-menu
                  v-model="item.menu"
                  :close-on-content-click="false"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  class="input-text"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="item.displayExpiryDate"
                      class="input-text pt-0 mt-0"
                      readonly
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    @input="
                      item.menu = false;
                      updateExpiryDate(index);
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <span v-else class="text-left">
                {{ formatDate(item.expiryDate) }}
              </span>
              <v-col v-if="item.isNew" class="text-center px-2">
                <v-text-field
                  id="items-batch"
                  v-model="item.batch"
                  :value="item.batch"
                  v-on:keypress="isLetterOrNumber($event)"
                  class="input-text pt-0 mt-0"
                />
                <!-- @input="changeState(input.correctedStock[index])" -->
              </v-col>
              <span v-else class="text-center px-2"> {{ item.batch }} </span>
              <span class="text-center px-2">{{ item.stock }}</span>
              <v-col class="text-left pa-0 px-2">
                <v-text-field
                  id="items-stock"
                  v-model="item.stockEdit"
                  :value="item.stockEdit"
                  class="input-text pt-0 mt-0"
                  hide-details
                  type="number"
                  min="0"
                  oninput="if(Number(this.value) < Number(this.min)) this.value = this.min"
                  :disabled="!item.isCorrection"
                  :suffix="item.package"
                  :rules="{ stockRule: item.isCorrection }"
                />
                <!-- @input="changeState(input.correctedStock[index])" -->
              </v-col>
              <v-col class="pa-0 px-2">
                <v-textarea
                  id="opname-desc"
                  :rows="1"
                  v-model="item.description"
                  class="input-text max pt-0 mt-0"
                  hide-details
                  no-resize
                  :disabled="!item.isCorrection"
                />
                <!-- @input="changeState(input.correctedStock[index])" -->
              </v-col>
              <v-col class="pa-0">
                <v-icon @click="changeState(item)">mdi-plus</v-icon>
              </v-col>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="4">
          <span class="caption d-flex justify-left">Tambah Barang Lain</span>
          <v-row no-gutters align="baseline">
            <v-col cols="9">
              <v-form ref="form">
                <v-combobox
                  :loading="itemLoad"
                  placeholder="Pilih Barang"
                  :items="selectBarang"
                  item-text="displayName"
                  :rules="rules.name"
                  item-value="id"
                  append-icon="mdi-chevron-down"
                  return-object
                  :search-input.sync="searchBarang"
                  v-model="barang"
                />
              </v-form>
            </v-col>
            <v-col cols="3">
              <v-btn
                class="ml-8"
                outlined
                color="primary"
                width="100"
                @click="addNewList()"
              >
                Tambah
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-end align-center">
          <v-btn
            color="secondary"
            text
            width="100"
            @click="onCloseButtonClick()"
          >
            <span>Kembali</span>
          </v-btn>
          <v-btn
            class="mx-4"
            width="100"
            color="primary"
            dense
            depressed
            @click="addNewItem()"
          >
            <span>Simpan</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';

const moment = require('moment');
const axios = require('axios');
const _ = require('lodash');

export default {
  mixins: [jwtMixin, alertMixin],
  data() {
    return {
      coba: false,
      isLoading: false,
      showCorrected: true,
      showStock: true,
      itemLoad: false,
      searchItem: '',
      selectBarang: [],
      getdrugs: [],
      getgoods: [],
      unit: '',
      correctDate: '',
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 30,
          totalItems: 0,
        },
        search: '',
      },
      barang: '',
      searchBarang: '',
      input: {
        correctedStock: [],
        checked: 0,
        totalChecklist: 0,
        detail: [],
      },
      resource: {
        supplier: [],
      },
      stockRule: [v => !!v || 'Jumlah stok belum diisi'],
      rules: {
        batch: [v => !!v || 'No batch belum diisi'],
        name: [v => !!v || 'Masukkan nama barang'],
        expiry: [
          v => !!v || 'Masukkan tanggal kadaluarsa',
          v => this.checkDateFormat(v) || 'Tanggal Tidak Valid',
        ],
      },
    };
  },
  watch: {
    searchItem() {
      this.fetchItems(this);
    },
    searchBarang(val) {
      if (val) {
        this.getDrugsAndGoods(this);
      }
    },
  },
  computed: {
    today() {
      return moment().format('YYYY-MM-DD');
    },
  },
  mounted() {
    this.getItems();
    this.getUnitById();
    this.getDataDrugs();
    this.getSupplier();
    this.getDataGoods();
  },
  methods: {
    fetchItems: _.debounce(v => {
      v.table.pagination.page = 1; // eslint-disable-line no-param-reassign
      v.getItems();
    }, 650),
    getDrugsAndGoods: _.debounce(v => {
      v.getDataDrugs();
      v.getDataGoods();
    }, 500),
    getSupplier() {
      axios.get(Constant.apiUrl.concat('/master/supplier')).then(response => {
        const data = response.data.data;
        this.resource.supplier = data.map(selectSupplier => {
          return {
            id: selectSupplier._id.toString(),
            name: selectSupplier.name,
          };
        });
      });
    },
    updateCorrectionExpiryDate(index) {
      this.input.correctedStock[index].expiryDate = moment(
        this.correctDate,
      ).format('YYYY-MM-DD');
      this.input.correctedStock[index].displayExpiryDate = this.formatDate(
        this.correctDate,
      );
      this.correctDate = '';
    },
    changeState(item) {
      if (item) {
        const index = this.input.correctedStock.findIndex(
          ar => ar.id === item.id,
        );
        if (index > -1) {
          this.input.correctedStock[index] = item;
        } else {
          this.input.correctedStock.push({ ...item, isCorrection: true });
        }
      }
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    checkDateFormat(date) {
      const dateInput = moment(date, 'DD/MM/YYYY');
      return dateInput.isValid();
    },
    formatExpiryDate(dateString, index) {
      if (dateString.length > 10) {
        this.input.correctedStock[index].displayExpiryDate = dateString.slice(
          0,
          10,
        );
        return;
      }
      let date = dateString.replace(/\D/g, '').substr(0, 10);
      let size = date.length;

      if (size > 4) date = this.insertString(date, '/', 4);

      if (size > 2) date = this.insertString(date, '/', 2);

      this.input.correctedStock[index].displayExpiryDate = date;
      this.input.correctedStock[index].expiryDate = moment(
        date,
        'DD/MM/YYYY',
      ).format('YYYY-MM-DD');
    },
    deleteData(item) {
      const index = this.input.correctedStock.indexOf(item);
      this.input.correctedStock.splice(index, 1);
    },
    checklistCounter(isChecked) {
      this.input.totalChecklist = this.table.pagination.totalItems;
      if (isChecked !== undefined && isChecked !== null) {
        if (isChecked) this.input.checked++;
        else this.input.checked--;
      }
    },
    onScroll() {
      const el = document.getElementById('scroll-target');
      if (el) {
        if (el.scrollTop + el.clientHeight + 3 >= el.scrollHeight) {
          this.table.pagination.page += 1;
          this.getItems(true);
        }
      }
    },
    getItems(isAdd = false) {
      this.isLoading = true;
      if (!isAdd && this.table.pagination.page === 1) {
        this.input.detail = [];
      }
      this.runApiCalls(() =>
        axios
          .get(
            Constant.apiUrl.concat(
              `/transaction/stock?branch=${
                this.$store.getters.userLoggedIn.branch_id
              }&search=${this.searchItem || ''}&unit_name=${
                this.$store.getters.userLoggedIn.unit_id
              }&page=${this.table.pagination.page}&itemCount=${
                this.table.pagination.rowsPerPage
              }&type=opname`,
            ),
          )
          .then(response => {
            const datas = response.data.data;
            this.table.pagination.totalItems = response.data.length;
            this.input.detail = this.input.detail.concat(
              datas.map(item => {
                return {
                  id: item._id,
                  idDrugs: item.id_drugs ? item.id_drugs : undefined,
                  idGoods: item.id_goods ? item.id_goods : undefined,
                  name: item.name,
                  supplier: item.supplier || '-',
                  id_supplier: item.id_supplier,
                  displayName: item.display_name,
                  expiryDate: item.expiry_date,
                  menu: '',
                  inputExpiryDate: '',
                  batch: item.batch_number,
                  stock: item.quantity,
                  stockEdit: item.quantity,
                  package: item.drugs[0]?.detail
                    .filter(i => i.packaging.length > 0)[0]
                    ?.packaging.filter(v => v.isDefault)[0]?.package,
                  category: item.id_goods ? 'goods' : 'drugs',
                  isCorrection: false,
                  isRemove: false,
                  description: '',
                };
              }),
            );
            this.input.detail = this.input.detail.filter(
              (arr, idx) =>
                this.input.detail.findIndex(ar => arr.id === ar.id) === idx,
            );

            // save stock only
            this.table.lastStock = datas.map(item => {
              return item.quantity;
            });
            if (this.idOpname) this.getExistingItems(this.idOpname);

            this.checklistCounter();
          })
          .catch(() => {
            this.input.detail = this.input.detail.concat([]);
          })
          .finally(() => {
            this.isLoading = false;
          }),
      );
    },
    async getUnitById() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/master/unit/${this.$store.getters.userLoggedIn.unit_id}`,
          ),
        );
        this.unit = response.data.data.name;
      } catch (error) {
        this.unit = '';
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    getDataDrugs() {
      this.itemLoad = true;
      this.selectBarang = [];
      axios
        .get(
          Constant.apiUrl
            .concat('/master/drugs?page=1')
            .concat('&itemCount=5')
            .concat('&sort=')
            .concat(`&search=${this.searchBarang}`)
            .concat('&category='),
        )
        .then(response => {
          const { data } = response.data;
          this.getdrugs = data
            .filter(item => item.detail.isActive === true)
            .map(item => {
              return {
                idDrugs: item._id,
                name: item.detail.name,
                displayName: item.display_name,
                supplier: '',
                expiryDate: '',
                menu: '',
                inputExpiryDate: '',
                batch: '',
                stock: 0,
                stockEdit: 0,
                package: item.detail.packaging
                  .filter(i => i.isDefault)
                  .map(i => i.package),
                category: 'drugs',
                isCorrection: false,
                isRemove: false,
                description: '',
                isNew: true,
              };
            });

          this.selectBarang.push(...this.getdrugs);
        })
        .catch(() => {
          this.getdrugs = [];
        })
        .finally(() => {
          this.itemLoad = false;
        });
    },

    getDataGoods() {
      this.selectBarang = [];
      axios
        .get(
          Constant.apiUrl
            .concat('/master/goods?page=1')
            .concat('&itemCount=5')
            .concat(`&search=${this.barang}`)
            .concat('&sort=')
            .concat('&category='),
        )
        .then(response => {
          const getGoods = response.data.data;
          this.getgoods = getGoods.map(item => {
            return {
              idGoods: item._id,
              name: item.detail.name,
              displayName: item.display_name,
              supplier: '',
              expiryDate: '',
              menu: '',
              inputExpiryDate: '',
              batch: '',
              stock: 0,
              stockEdit: 0,
              package: item.packaging
                ? item.packaging.filter(i => i.isDefault)[0].package
                : '-',
              category: 'goods',
              isCorrection: false,
              isRemove: false,
              description: '',
              isNew: true,
            };
          });
          this.selectBarang.push(...this.getgoods);
        })
        .catch(() => {
          this.getgoods = [];
        });
    },

    addNewList() {
      if (this.$refs.form.validate()) {
        this.input.correctedStock.push(this.barang);
        this.barang = '';
        this.$refs.form.resetValidation();
      }
    },

    addNewItem() {
      if (this.input.correctedStock.length > 0) {
        // if (
        //   this.input.correctedStock.some(
        //     i =>
        //       (!i.stockEdit && i.stockEdit !== 0) || !i.expiryDate || !i.batch,
        //   )
        // ) {
        //   return;
        // } else {
        if (this.$refs.formInput.validate()) {
          const itemArr = this.input.correctedStock.map(item => {
            return {
              id_drugs: item.idDrugs,
              id_goods: item.idGoods,
              id_stock: item.id,
              batch_number: item.batch,
              expiry_date: item.expiryDate,
              old_quantity: parseFloat(item.stock),
              quantity: parseFloat(item.stockEdit),
              reason: item.description,
              id_supplier: item.isNew ? item.supplier?.id : item?.id_supplier,
              isCorrection: item.isCorrection,
            };
          });
          axios
            .post(Constant.apiUrl.concat('/transaction/post'), {
              id_branch: this.$store.getters.userLoggedIn.branch_id,
              to: this.$store.getters.userLoggedIn.unit_id,
              from: this.$store.getters.userLoggedIn.unit_id,
              action: 'opname',
              detail: [
                {
                  status: 'requested',
                  item: itemArr,
                },
              ],
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.name,
              },
            })
            .then(response => {
              if (response.status === 200) {
                Swal.fire(
                  'Berhasil',
                  'Data Stock Opname Sudah Masuk',
                  'success',
                );
                this.onCloseButtonClick();
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan;
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: message,
                });
              } else if (error.request) {
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Tidak dapat menyambung ke server',
                });
              }
            });
        }
        // }
      }
    },

    onCloseButtonClick() {
      this.$emit('close-form');
    },

    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input input #items-stock {
  padding: 0;
}

.input {
  max-width: 70%;
}

.grid-display {
  display: grid;
  &.header {
    background-color: rgb(248, 248, 248);
    z-index: 10;
    grid-template-columns: 50px 1.5fr 2fr 1.5fr 1.5fr 1fr 2fr 2fr 50px 30px;
    grid-template-rows: 16px;
    margin-top: 10px;
    padding: 12px 10px;
    height: 40px;
    border-top: 0.1px solid #eeeeee;
    border-bottom: 0.1px solid #eeeeee;
    span {
      display: block;
      color: #222222;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
  &.table-items {
    grid-template-columns: 50px 1.5fr 2fr 1.5fr 1.5fr 1fr 2fr 2fr 40px 20px;
    grid-template-rows: auto;
    padding: 0px 10px;
    margin-top: 10px;
    margin-bottom: 6px;
    align-items: flex-start;
    span {
      display: block;
      color: #222222;
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
}

.item-container {
  max-height: 60vh;
  min-height: 60vh;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: black rgb(163, 163, 163);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.input-text {
  width: 150px;
  font-size: 0.9rem;
  &.max {
    width: 100%;
  }
  ::v-deep .v-input__slot {
    min-height: 0 !important;
    height: 25px !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
    border-width: 0.75px !important;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 5px !important;
  }
  ::v-deep .v-input__slot label {
    margin-top: -12px !important;
  }
  ::v-deep .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
  }
  ::v-deep .v-text-field__details {
    margin: 2px !important;
  }
  ::v-deep .v-input__prepend-inner {
    margin: 2px !important;
  }
  ::v-deep .v-text-field__prefix {
    margin: 2px !important;
  }
  ::v-deep input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  /* Firefox */
  ::v-deep input[type='number'] {
    -moz-appearance: textfield;
  }

  ::v-deep .vue-treeselect__control {
    border: 0.75px solid rgba(0, 0, 0, 0.54);
  }
}
</style>
