<template>
  <v-card width="300px" height="160">
    <v-btn fab text small style="position: absolute; right: 3px">
      <v-icon @click="closeForm" color="grey" small>mdi-close</v-icon>
    </v-btn>
    <v-card-title class="d-flex justify-center"> Pilih Print </v-card-title>
    <v-btn
      @click="printReferralLetter()"
      color="primary"
      outlined
      width="240"
      height="30"
      style="border: 2px solid !important"
      class="primary--text caption font-weight-bold mb-3"
      >Surat Rujuk Lanjut</v-btn
    >
    <v-btn
      @click="printReferralLetter(true)"
      color="primary"
      outlined
      width="240"
      height="30"
      style="border: 2px solid !important"
      class="primary--text caption font-weight-bold"
      >Surat Rujuk Lanjut - Rujuk Balik</v-btn
    >
  </v-card>
</template>

<script>
import getReferralLetter from '../../fetchApi/Letter/getReferralLetter';
import PrintMixin from '@/mixin/printMixin';
import Letter from '@/mixin/Letter';
import alertMixin from '@/mixin/alertMixin';
export default {
  props: {
    letterData: {
      default: '',
    },
    idRegistration: {
      default: null,
    },
  },
  name: 'PrintBPJSReferralDialog',
  mixins: [PrintMixin, Letter, alertMixin],
  methods: {
    closeForm() {
      this.$emit('close-form');
    },
    async printReferralLetter(isBack = false) {
      if (!this.letterData) {
        try {
          const response = await getReferralLetter({
            id_registration: this.idRegistration,
          });
          const { data: referral } = response.data;
          const doc = this.generateReferralLetterBpjs(referral, isBack);
          this.printDocument(doc);
        } catch (error) {
          this.showErrorAxios(error);
        }
      } else {
        const doc = this.generateReferralLetterBpjs(this.letterData, isBack);
        this.printDocument(doc);
      }
    },
  },
};
</script>

<style></style>
