<template>
  <section>
    <v-data-table
      :headers="table.header"
      :items="table.items"
      :items-per-page="table.items.length"
      fixed-header
      :height="height"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:item="{ item, index }">
        <tr :key="`row-${index}`">
          <td class="font-weight-medium text-sm-left px-3 py-3">
            {{ index + item.no }}
          </td>
          <td class="font-weight-medium text-sm-left px-3 py-3">
            {{ item.dateService }}
          </td>
          <td class="font-weight-medium text-sm-left py-3 px-0">
            {{ item.name }} <br />
            <span class="grey--text caption font-weight-bold">{{
              item.roleDetail
            }}</span>
          </td>
          <td class="font-weight-medium cost-column text-sm-right py-3 px-0">
            {{ currency(item.outpatient_fee) }} <br />
            <span class="grey--text caption font-weight-bold"
              >{{ item.outpatient }} Pasien</span
            >
          </td>
          <td class="font-weight-medium cost-column text-sm-right py-3 px-0">
            {{ currency(item.inpatient_fee) }} <br />
            <span class="grey--text caption font-weight-bold"
              >{{ item.inpatient }} Pasien</span
            >
          </td>
          <td class="font-weight-medium cost-column text-sm-right py-3 px-0">
            {{ currency(item.igd_fee) }} <br />
            <span class="grey--text caption font-weight-bold"
              >{{ item.igd }} Pasien</span
            >
          </td>
          <td class="font-weight-medium cost-column text-sm-right py-3 px-0">
            {{ currency(item.medServiceTotal) }} <br />
            <span class="grey--text caption font-weight-bold"
              >{{ item.total_patient }} Pasien</span
            >
          </td>
          <td
            class="px-0 d-flex justify-center align-center"
            style="min-height: 67px"
          >
            <v-chip
              :class="{
                paid: item.status === 'paid',
                unpaid: item.status === 'unpaid',
              }"
              class="status-chip px-3 font-weight-bold text-capitalize"
              >{{ getStatus(item.status) }}</v-chip
            >
          </td>
        </tr>
      </template>
    </v-data-table>
    <footer class="footer-section">
      <p class="font-weight-bold primary--text">Total</p>
      <div class="text-right">
        <p class="font-weight-bold ma-0 primary--text">Rp 5.000.000,-</p>
        <p class="grey--text caption">14 Pasien</p>
      </div>
      <div class="text-right">
        <p class="font-weight-bold ma-0 primary--text">Rp 5.000.000,-</p>
        <p class="grey--text caption">14 Pasien</p>
      </div>
      <div class="text-right">
        <p class="font-weight-bold ma-0 primary--text">Rp 5.000.000,-</p>
        <p class="grey--text caption">14 Pasien</p>
      </div>
      <div class="text-right">
        <p class="font-weight-bold ma-0 primary--text">Rp 5.000.000,-</p>
        <p class="grey--text caption">14 Pasien</p>
      </div>
      <div></div>
    </footer>
  </section>
</template>

<script>
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';
import moment from 'moment-timezone';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'CashTable',
  mixins: [formatMixin],
  props: {
    data: {},
    date: String,
    doctor: {},
    loading: Boolean,
  },
  data() {
    return {
      table: {
        items: [],
        header: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
            class: 'font-weight-bold text-left px-3 ma-0',
          },
          {
            text: 'Tanggal Pelayanan',
            value: 'dateService',
            class: 'font-weight-bold text-left pa-0 ma-0',
          },
          {
            text: 'Nama',
            value: 'name',
            class: 'font-weight-bold text-left pa-0 ma-0',
          },
          {
            text: 'Rawat Jalan',
            value: 'inpatient_fee',
            sortable: false,
            class: 'font-weight-bold text-end pa-0 ma-0',
          },
          {
            text: 'Rawat Inap',
            value: 'outpatient_fee',
            sortable: false,
            class: 'font-weight-bold text-end pa-0 ma-0',
          },
          {
            text: 'IGD',
            value: 'igd_fee',
            sortable: false,
            class: 'font-weight-bold text-end pa-0 ma-0',
          },
          {
            text: 'Total Jasa Medis',
            value: 'medServiceTotal',
            sortable: false,
            class: 'font-weight-bold text-end pa-0 ma-0',
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            align: 'center',
            class: 'font-weight-bold text-center pa-0 ma-0',
            width: '15%',
          },
        ],
      },
    };
  },
  computed: {
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '43vh';
          break;
        case 'lg':
          height = '49vh';
          break;
        case 'xl':
          height = '55vh';
          break;
      }
      return height;
    },
  },
  mounted() {
    this.getAllGeneralData();
  },
  watch: {
    date() {
      this.getAllGeneralData();
    },
    doctor() {
      this.getAllGeneralData();
    },
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case 'unpaid':
          return 'Belum Lunas';
        case 'paid':
          return 'Lunas';
        case 'half':
          return 'Sebagian';
      }
    },
    getAllGeneralData() {
      const month = this.date ? this.date : moment();
      const fixMonth = moment(month).format('YYYY-MM-DD');
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/medicalservice/guarantor?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&category=umum&search=&date=${fixMonth || ''}&doctor=${this.doctor
              ?.doctorId || ''}`,
          ),
        )
        .then(response => {
          const data = response.data.data;

          this.table.items = data.map(item => {
            return {
              no: 1,
              name: _.get(item, 'doctor.detail[0].name'),
              id_staff: _.get(item, 'id_staff'),
              dateService: _.get(item, 'date'),
              status: _.get(item, 'status'),
              medServiceTotal: _.get(item, 'total_fee'),
              total_patient: _.get(item, 'total_patient'),
              outpatient_fee: _.get(item, 'outpatient.fee'),
              inpatient_fee: _.get(item, 'inpatient.fee'),
              igd_fee: _.get(item, 'igd.fee'),
              outpatient: _.get(item, 'outpatient_patient'),
              inpatient: _.get(item, 'inpatient_patient'),
              igd: _.get(item, 'igd_patient'),
              roleDetail: _.get(item, 'doctor.role[0].detail'),
            };
          });
        })
        .catch(() => {
          this.table.items = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cost-column {
  min-width: 7vw;
  max-width: 7vw;
}
.status-chip {
  color: #1976d2;
  background-color: #e3f2fc !important;
  display: flex;
  justify-content: center;
  border-radius: 0px;
  min-width: 0px;
  width: 130px;

  &.paid {
    color: #2d965a;
    background-color: #e6fff0 !important;
  }
  &.unpaid {
    color: #eb4747;
    background-color: #fff2f2 !important;
  }
}

.footer-section {
  box-shadow: 0px -5px 8px -4px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px -5px 8px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px -5px 8px -4px rgba(0, 0, 0, 0.17);
  padding-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 35% 14.6% 11.45% 11.9% 12.2% 0%;
}
</style>
