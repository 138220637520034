<template>
  <v-card>
    <v-card-title class="d-flex justify-center black--text headline"
      >Detail Tarif Layanan</v-card-title
    >
    <v-container fluid class="container-layout">
      <!-- Tarif Layanan -->

      <v-row>
        <v-col cols="5">
          <v-form ref="inputService" lazy-validation>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="font-weight-medium black--text"
                    for="Nama Layanan"
                  >
                    Nama Layanan</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  disabled
                  v-model="input.service"
                  :rules="[v => !!v || 'Nama Layanan Harus diisi']"
                  placeholder="Masukkan Nama Layanan"
                  dense
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label class="font-weight-medium black--text " for="Unit">
                    Unit</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-select
                  disabled
                  class="hide-input"
                  v-model="input.unit"
                  :items="resource.unit"
                  :rules="[v => !!v || 'Unit Harus diisi']"
                  @change="getSubCategory()"
                  item-text="name"
                  placeholder="Pilih Unit"
                  dense
                  multiple
                  single-line
                  hide-selected
                  return-object
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label class="font-weight-medium black--text " for="Kategori">
                    Kategori</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-select
                  disabled
                  v-model="input.category"
                  :items="resource.category"
                  placeholder="Pilih Kategori"
                  dense
                  @change="getSubCategory()"
                  clearable
                  :rules="[v => !!v || 'Kategori Harus diisi']"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="font-weight-medium black--text "
                    for="Sub Kategori"
                  >
                    Sub Kategori</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  disabled
                  :loading="subcategory_loading"
                  :items="resource.sub_category"
                  v-model="input.sub_category"
                  placeholder="Masukkan Nama Layanan"
                  clearable
                  dense
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-15">
              <v-col cols="6">
                <div class="text-left">
                  <label class="font-weight-medium black--text ">
                    Tipe Penjamin</label
                  >
                </div>
              </v-col>
              <v-col cols="6" class="text-left">
                <label class="text-uppercase">{{
                  servicesDetail.assurance
                }}</label>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col class="input-main text-left" cols="7">
          <v-chip v-for="unit in input.unit" :key="unit.id" class="ma-2">
            {{ unit.name }}
          </v-chip>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-title class="pl-7 text-h6 blue--text">Jasa Medis</v-card-title>
      <v-row>
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Jasa Medis Dokter Umum"
                >
                  Jasa Medis Dokter Umum</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.staff"
                prefix="Rp."
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Dokter Spesialis"
                >
                  Jasa Medis Dokter Spesialis</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.specialize"
                prefix="Rp."
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Perawat"
                >
                  Jasa Medis Perawat</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.nurse"
                prefix="Rp."
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Bidan"
                >
                  Jasa Medis Bidan</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.midwife"
                prefix="Rp."
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Jasa Medis Psikologi"
                >
                  Jasa Medis Psikologi</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.psychologist"
                prefix="Rp."
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Ahli Gizi"
                >
                  Jasa Medis Ahli Gizi</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.nutritionists"
                prefix="Rp."
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Analis Kesehatan"
                >
                  Jasa Medis Analis Kesehatan</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-
                disabled
                v-model.number="input.cost.medical_service.health_analyst"
                prefix="Rp."
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Radiografer"
                >
                  Jasa Medis Radiografer</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.medical_service.radiographer"
                prefix="Rp."
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Bahan Medis Habis Pakai -->
      <v-card-title class="pl-7 text-h6 blue--text"
        >Alat Medis Habis Pakai</v-card-title
      >
      <v-row class="mb-7">
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Alat Medis Habis Pakai"
                >
                  Total Alat Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field disabled v-model="totalMedicalTools" prefix="Rp." />
            </v-col>
          </v-row>
          <v-form ref="formMedicalTools" lazy-validation>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="text-subtitle-2 black--text "
                    for="Alat Medis Habis Pakai"
                  >
                    Alat Medis Habis Pakai</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  disabled
                  placeholder="Pilih Alat Medis"
                  dense
                  :items="resource.medicalTools"
                  :rules="[v => !!v || 'Alat Medis Habis Pakai Harus diisi']"
                  item-text="name"
                  v-model="input.cost.medicalTools.detail"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label class="text-subtitle-2 black--text " for="Jumlah">
                    Jumlah</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  disabled
                  placeholder="Masukkan Jumlah"
                  suffix="pcs"
                  type="number"
                  v-model.number="input.cost.medicalTools.quantity"
                  :rules="[v => !!v || 'Jumlah Harus diisi']"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="8">
          <v-data-table
            :headers="table.medicalTools.headers"
            :items="table.medicalTools.items"
          >
            <template v-slot:[`item.no`]="{ item }">
              <td class="text-left">
                {{ table.medicalTools.items.indexOf(item) + 1 }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td class="text-left">{{ item.name }}</td>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <td class="text-left">{{ item.quantity }}</td>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <td class="text-left">{{ item.unit }}</td>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-left">{{ currency(item.price) }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Alat Dan Bahan Medis Habis Pakai -->

      <v-card-title class="pl-7 text-h6 blue--text"
        >Bahan Medis Habis Pakai</v-card-title
      >
      <v-row class="mb-7">
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Bahan Medis Habis Pakai"
                >
                  Total Bahan Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field disabled v-model="totalConsumables" prefix="Rp." />
            </v-col>
          </v-row>
          <v-form ref="formConsumables" lazy-validation>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="text-subtitle-2 black--text "
                    for="Bahan Medis Habis Pakai"
                  >
                    Bahan Medis Habis Pakai</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  disabled
                  placeholder="Pilih Bahan Medis"
                  dense
                  :items="resource.consumables"
                  item-text="name"
                  v-model="input.cost.consumables.detail"
                  :loading="isLoading"
                  :rules="[v => !!v || 'Bahan Medis Habis Pakai Harus diisi']"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label class="text-subtitle-2 black--text " for="Jumlah">
                    Jumlah</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-
                  disabled
                  placeholder="Masukkan Jumlah"
                  suffix="pcs"
                  v-model.number="input.cost.consumables.quantity"
                  type="number"
                  :rules="[v => !!v || 'Jumlah Harus diisi']"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="8">
          <v-data-table
            :headers="table.consumables.headers"
            :items="table.consumables.items"
          >
            <template v-slot:[`item.no`]="{ item }">
              <td class="text-left">
                {{ table.consumables.items.indexOf(item) + 1 }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td class="text-left">{{ item.name }}</td>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <td class="text-left">{{ item.quantity }}</td>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <td class="text-left">{{ item.unit }}</td>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-left">{{ currency(item.price) }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-row no-gutters align="baseline" class="px-16">
            <v-col cols="6">
              <div class="text-left">
                <label class="font-weight-medium black--text " for="Beban Unit">
                  Beban Unit</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.unit_load"
                prefix="Rp."
              />
            </v-col>
          </v-row>

          <v-row no-gutters align="baseline" class="px-16">
            <v-col cols="6">
              <div class="text-left">
                <label class="font-weight-medium black--text " for="Margin">
                  Margin</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                disabled
                v-model.number="input.cost.margin"
                prefix="Rp."
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-row no-gutters align="baseline" class="px-16">
                <v-col cols="12">
                  <v-row
                    class="primary total white--text mt-8"
                    align="baseline"
                  >
                    <span class="pr-3">Total Biaya Layanan</span>
                    <label>{{ totalPrice }}</label>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined width="100" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DetailServices',
  mixins: [jwtMixin, formatMixin],
  props: ['servicesDetail'],
  data() {
    return {
      isLoading: false,
      search: null,
      menu: false,
      subcategory_loading: false,
      medicalTools: [],
      consumables: [],
      resource: {
        unit: [],
        category: [
          'Administrasi',
          'Konsultasi/ Tindakan Medis',
          'Pemeriksaan Penunjang',
          'Pelayanan Penunjang',
        ],
        sub_category: [],
        room: [],
        medicalTools: [],
        consumables: [],
      },

      input: {
        service: this.servicesDetail.servicesName,
        category: this.servicesDetail.category,
        sub_category: this.servicesDetail.subCategories,
        unit: this.servicesDetail.unit.map(unit => {
          return {
            id: unit._id,
            name: unit.name,
          };
        }),
        totalPrice: this.servicesDetail.total,
        room: '',
        cost: {
          assurance: this.servicesDetail.assurance,
          medical_service: {
            staff: _.get(this.servicesDetail, 'cost.medical_service.staff', 0),
            specialize: _.get(
              this.servicesDetail,
              'cost.medical_service.specialize',
              0,
            ),
            nurse: _.get(this.servicesDetail, 'cost.medical_service.nurse', 0),
            midwife: _.get(
              this.servicesDetail,
              'cost.medical_service.midwife',
              0,
            ),
            psychologist: _.get(
              this.servicesDetail,
              'cost.medical_service.psychologist',
              0,
            ),
            nutritionists: _.get(
              this.servicesDetail,
              'cost.medical_service.nutritionists',
              0,
            ),
            health_analyst: _.get(
              this.servicesDetail,
              'cost.medical_service.health_analyst',
              0,
            ),
            radiographer: _.get(
              this.servicesDetail,
              'cost.medical_service.radiographer',
              0,
            ),
          },
          consumables: {
            detail: '',
            quantity: null,
          },
          medicalTools: {
            detail: '',
            quantity: null,
          },
          unit_load: _.get(this.servicesDetail, 'cost.unit_load', 0),
          margin: _.get(this.servicesDetail, 'cost.margin', 0),
        },
      },
      table: {
        medicalTools: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Alat Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
        consumables: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Bahan Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getUnit();
        this.getSubCategory();
        this.getConsumables('');
        this.getMedicalTools('');
        this.getCostMaterialTools();
        // if (this.servicesDetail.cost) {
        //   this.table.medicalTools.items = this.servicesDetail.cost.consumable_tools;
        //   this.table.consumables.items = this.servicesDetail.cost.consumable_material;
        // }
      });
    });
  },
  computed: {
    totalPrice() {
      const biaya =
        Number(this.input.cost.medical_service.staff) +
        Number(this.input.cost.medical_service.specialize) +
        Number(this.input.cost.medical_service.nutritionists) +
        Number(this.input.cost.medical_service.health_analyst) +
        Number(this.input.cost.medical_service.psychologist) +
        Number(this.input.cost.medical_service.nurse) +
        Number(this.input.cost.medical_service.radiographer) +
        Number(this.input.cost.medical_service.midwife) +
        Number(this.input.cost.margin) +
        Number(this.input.cost.unit_load);
      const totalFromTables = this.totalConsumables + this.totalMedicalTools;
      return this.currency(biaya + totalFromTables);
    },
    totalConsumables() {
      return this.table.consumables.items.reduce(
        (a, { price }) => a + price,
        0,
      );
    },
    totalMedicalTools() {
      return this.table.medicalTools.items.reduce(
        (a, { price }) => a + price,
        0,
      );
    },
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    getCostMaterialTools() {
      this.table.medicalTools.items = this.servicesDetail.cost.consumable_tools.map(
        item => {
          return {
            quantity: item.quantity,
            id: item.id_drugs,
            name: item.detail.detail[0].name,
            unit:
              item.detail.detail[0].packaging.length === 0
                ? '-'
                : item.detail.detail[0].packaging[0].package,
            price: item.detail.detail[0].hna * item.quantity,
          };
        },
      );
      this.table.consumables.items = this.servicesDetail.cost.consumable_material.map(
        item => {
          return {
            quantity: item.quantity,
            id: item.id_drugs,
            name: item.detail.detail[0].name,
            unit:
              item.detail.detail[0].packaging.length === 0
                ? '-'
                : item.detail.detail[0].packaging[0].package,
            price: item.detail.detail[0].hna * item.quantity,
          };
        },
      );
    },
    getSubCategory() {
      this.is_subcategory_loading = true;
      axios
        .get(`${Constant.apiUrl}/master/service`)
        .then(res => {
          const { data } = res.data;
          if (this.input.unit.name && !this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return (
                  i.unit.length > 0 && i.unit[0].name === this.input.unit.name
                );
              })
              .map(item => {
                return item.sub_category;
              });
          } else if (!this.input.unit.name && this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return i.category === this.input.category;
              })
              .map(item => {
                return item.sub_category;
              });
          } else if (this.input.unit.name && this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return (
                  i.category === this.input.category &&
                  i.unit[0].name === this.input.unit.name
                );
              })
              .map(item => {
                return item.sub_category;
              });
          } else {
            this.resource.sub_category = data.map(item => {
              return item.sub_category;
            });
          }
        })
        .catch(() => {
          this.resource.sub_category = [];
        })
        .finally(() => (this.is_subcategory_loading = false));
    },
    getUnit() {
      axios.get(`${Constant.apiUrl}/master/unit`).then(response => {
        const unitData = response.data.data;
        this.resource.unit = unitData.map(unit => {
          return {
            name: unit.name,
            id: unit._id,
          };
        });
      });
    },
    getConsumables(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs?page=&itemCount=&search=${keyword}&sort=&category=BHP`,
          ),
        )
        .then(response => {
          const consumables = response.data.data;
          this.resource.consumables = consumables.map(arr => {
            let defaultPackage = '';

            arr.detail.packaging.find(v => {
              if (v.isDefault === true) {
                defaultPackage = v.package;
              }
            });
            return {
              id: arr._id,
              name: arr.detail.name,
              hpp: arr.detail.hpp,
              hna: arr.detail.hna,
              strength: arr.detail.strength,
              strength_unit: arr.detail.strength_unit,
              packaging:
                arr.detail.packaging.length !== 0 ? defaultPackage : '-',
            };
          });
        })
        .catch(() => {
          this.resource.consumables = [];
        })
        .finally(() => (this.isLoading = false));
    },
    getMedicalTools(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs?page=&itemCount=&search=${keyword}&sort=&category=Alat Kesehatan`,
          ),
        )
        .then(response => {
          const medicalTools = response.data.data;
          this.resource.medicalTools = medicalTools.map(arr => {
            let defaultPackage = '';
            arr.detail.packaging.find(v => {
              if (v.isDefault === true) {
                defaultPackage = v.package;
              }
            });
            return {
              id: arr._id,
              name: arr.detail.name,
              hpp: arr.detail.hpp,
              hna: arr.detail.hna,
              packaging:
                arr.detail.packaging.length !== 0 ? defaultPackage : '-',
            };
          });
        })
        .catch(() => {
          this.resource.medicalTools = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-main {
  position: relative;
  width: 100%;
  height: 250px;
}
.hide-input ::v-deep .v-select__selection.v-select__selection--comma {
  display: none;
}
.container-layout {
  height: 82vh;
  overflow: auto;
}
.total {
  border-radius: 8px;
  padding: 20px;
}
</style>
