<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Tambah Data Staff</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container class="container-layout">
        <v-form lazy-validation ref="form">
          <v-row no-gutters>
            <v-col md="10" offset-md="1">
              <v-row>
                <v-col md="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">NIP</label>
                    </div>

                    <v-text-field
                      dense
                      :rules="rules.nip"
                      v-model="input.nip"
                      placeholder="Masukkan NIP"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">Nama Lengkap</label>
                    </div>

                    <v-text-field
                      dense
                      :rules="rules.name"
                      v-model="input.name"
                      placeholder="Masukkan Nama Lengkap"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">NIK</label>
                    </div>

                    <v-text-field
                      dense
                      type="number"
                      counter="16"
                      v-on:keypress="isNumber($event)"
                      :rules="rules.nik"
                      v-model="input.nik"
                      placeholder="Masukkan NIK"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="required" for="name">Jenis Kelamin</label>
                    </div>
                    <v-radio-group
                      dense
                      v-model="input.gender"
                      :rules="rules.gender"
                      row
                    >
                      <v-radio label="Laki-laki" :value="true"></v-radio>
                      <v-radio label="Perempuan" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-date">
                    <div class="detail-input__label">
                      <label class="required" for="Tanggal Lahir"
                        >Tanggal Lahir</label
                      >
                    </div>
                    <v-menu
                      dense
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="input.dob"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :rules="rules.birthDate"
                          dense
                          class="detail-input__input-text"
                          v-model="input.dob"
                          placeholder="YYYY/MM/DD"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :max="today"
                        v-model="input.dob"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(input.dob)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">Tempat Lahir</label>
                    </div>

                    <v-text-field
                      dense
                      :rules="rules.birthPlace"
                      v-model="input.pob"
                      placeholder="Masukkan Tempat Lahir"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Alamat</label>
                    </div>
                    <!-- :rules="rules.address" -->
                    <v-textarea
                      dense
                      v-model="input.address"
                      class="detail-input__input-text"
                      outlined
                      name="input-7-4"
                    ></v-textarea>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">No Telepon</label>
                    </div>

                    <v-text-field
                      type="number"
                      dense
                      v-on:keypress="isNumber($event)"
                      v-model="input.phone_no"
                      placeholder="Masukkan No Telepon"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="agama">Agama</label>
                    </div>
                    <v-autocomplete
                      dense
                      placeholder="Pilih Agama"
                      class="detail-input__input-text"
                      v-model="input.religion"
                      :items="resource.religion"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col md="2"></v-col>
                <v-col md="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">Password</label>
                    </div>

                    <v-text-field
                      placeholder="Password"
                      :rules="rules.password"
                      id="password"
                      autocomplete="new-password"
                      class="detail-input__input-text"
                      v-model="input.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="golongandarah">Golongan Darah</label>
                    </div>
                    <v-autocomplete
                      dense
                      placeholder="Pilih Golongan Darah"
                      class="detail-input__input-text"
                      v-model="input.bloodType"
                      :items="resource.bloodType"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">STR</label>
                    </div>

                    <v-text-field
                      dense
                      placeholder="Masukkan STR"
                      class="detail-input__input-text"
                      required
                      v-model="input.str"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Batas STR</label>
                    </div>

                    <v-text-field
                      dense
                      placeholder="Masukkan Batas STR"
                      class="detail-input__input-text"
                      v-model="input.end_str"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">SIP</label>
                    </div>

                    <v-text-field
                      dense
                      v-model="input.sip"
                      placeholder="Masukkan SIP"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Batas SIP</label>
                    </div>

                    <v-text-field
                      dense
                      placeholder="Masukkan Balas SIP"
                      class="detail-input__input-text"
                      v-model="input.end_sip"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">SIK</label>
                    </div>

                    <v-text-field
                      dense
                      v-model="input.sik"
                      placeholder="Masukkan SIK"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Batas SIK</label>
                    </div>

                    <v-text-field
                      dense
                      v-model="input.end_sik"
                      placeholder="Masukkan SIK"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="specialis">Pilih Specialis</label>
                    </div>
                    <v-autocomplete
                      placeholder="Pilih Specialis"
                      dense
                      class="detail-input__input-text"
                      v-model="input.specialize"
                      :items="resource.specialize"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-date">
                    <div class="detail-input__label">
                      <label for="name">Tanggal Mulai Praktek</label>
                    </div>
                    <v-menu
                      dense
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="input.start_practice"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          class="detail-input__input-text"
                          v-model="input.start_practice"
                          placeholder="YYYY/MM/DD"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="input.start_practice"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(input.start_practice)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-5"></v-divider>
          <v-row>
            <v-col md="10" offset-md="1">
              <v-row>
                <v-col md="5">
                  <v-card-title class="px-0">
                    <span class="font-weight-bold black--text">
                      Rekening Bank
                    </span>
                  </v-card-title>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="namabank">Nama Bank</label>
                    </div>
                    <v-autocomplete
                      dense
                      placeholder="Pilih Bank"
                      class="detail-input__input-text"
                      v-model="input.bank.id_bank"
                      :items="resource.bank"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col></v-col>
                <v-col md="5" class="d-flex align-end">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Nomor Rekening</label>
                    </div>
                    <v-text-field
                      dense
                      type="number"
                      v-on:keypress="isNumber($event)"
                      v-model="input.bank.account"
                      placeholder="Masukkan Rekening"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row no-gutters>
            <v-col md="10" offset-md="1">
              <v-row>
                <v-col md="5" class="pb-0 pl-0"
                  ><v-card-title class="px-0 pb-0 mb-2">
                    <span class="font-weight-bold black--text">Data Role</span>
                  </v-card-title></v-col
                >
                <v-col></v-col>
                <v-col md="5"></v-col>
                <v-row
                  v-for="(role, index) in input.roles"
                  :key="'role-' + index"
                  style="border-bottom: 1px solid rgb(224, 224, 224)"
                  class="mb-2"
                >
                  <v-col md="5">
                    <div class="detail-input">
                      <div class="detail-input__label">
                        <label class="required" for="Poli">Cabang</label>
                      </div>
                      <v-combobox
                        :rules="rules.branch"
                        dense
                        :items="resource.branches"
                        v-model="role.branch"
                        placeholder="Pilih Cabang"
                        class="detail-input__input-text"
                        hide-selected
                        :readonly="!hasRoles().isSuperAdmin"
                      ></v-combobox>
                    </div>
                    <div class="detail-input">
                      <div class="detail-input__label">
                        <label class="required" for="Poli">Unit / Poli</label>
                      </div>
                      <v-combobox
                        :rules="rules.unit"
                        dense
                        placeholder="Pilih Beberapa Poli"
                        class="detail-input__input-text"
                        v-model="role.unit"
                        :items="resource.units"
                        multiple
                        hide-selected
                      ></v-combobox>
                    </div>
                    <div class="detail-input">
                      <div class="detail-input__label">
                        <label class="required" for="Role">Role</label>
                      </div>
                      <v-autocomplete
                        dense
                        :rules="rules.role"
                        placeholder="Pilih Role"
                        class="detail-input__input-text"
                        v-model="role.role"
                        :items="resource.roles"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                  <v-col md="2"></v-col>
                  <v-col md="5">
                    <div class="detail-input">
                      <div class="detail-input__label">
                        <label for="name">Detail</label>
                      </div>

                      <v-text-field
                        dense
                        v-model="role.detail"
                        placeholder="Masukkan Detail"
                        class="detail-input__input-text"
                        required
                      />
                    </div>
                    <div class="detail-radio mb-2">
                      <div class="detail-input__label">
                        <label class="required" for="name">Status</label>
                      </div>
                      <v-radio-group
                        dense
                        v-model="role.status"
                        :rules="rules.status"
                        row
                      >
                        <v-radio label="Aktif" :value="true"></v-radio>
                        <v-radio label="Tidak Aktif" :value="false"></v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      v-if="index === input.roles.length - 1"
                      class="d-flex justify-end"
                    >
                      <v-btn
                        v-if="index !== 0"
                        color="red"
                        text
                        @click="deleteRole"
                        class="catpion text-capitalize"
                        >Hapus</v-btn
                      >
                      <v-tooltip
                        v-if="hasRoles().isSuperAdmin"
                        bottom
                        color="primary"
                        max-width="240"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            v-bind="attrs"
                            @click="addRole"
                            v-on="on"
                            outlined
                            width="110"
                            class="text-capitalize caption font-weight-bold ml-2"
                            ><v-icon color="primary" class="mr-1" small
                              >mdi-plus</v-icon
                            >Tambah Role</v-btn
                          >
                        </template>
                        <span
                          >Anda bisa menambahkan data role lainnya jika staff
                          juga bekerja di cabang lain</span
                        >
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="100" text class="text-none" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn
        color="primary"
        width="100"
        class="text-none"
        depressed
        @click="
          registerNewStaff();
          updateData();
        "
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import roleMixin from '@/mixin/roleMixin';
const axios = require('axios');
import Swal from 'sweetalert2';

const moment = require('moment');

export default {
  mixins: [roleMixin],
  data() {
    return {
      isAdmin: false,
      isLoading: false,
      showPassword: false,
      password: 'Password',
      date: null,
      search: null,
      menu1: false,
      menu2: false,
      startPractice_menu: false,
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        nip: [v => !!v || 'Perlu mengisi NIP'],
        nik: [
          v => !!v || 'Perlu mengisi NIK',
          v => v.length < 17 || 'Maksimal 16 karakter',
        ],
        gender: [v => v !== '' || 'Perlu mengisi jenis kelamin'],
        birthDate: [v => !!v || 'Perlu mengisi tanggal lahir'],
        birthPlace: [v => !!v || 'Perlu mengisi tempat lahir'],
        address: [v => !!v || 'Perlu mengisi alamat'],
        role: [v => !!v || 'Perlu mengisi role'],
        branch: [v => !!v || 'Perlu mengisi cabang'],
        unit: [v => v.length !== 0 || 'Perlu mengisi unit'],
        status: [v => v !== '' || 'Perlu mengisi status'],
        password: [
          v => v !== '' || 'Perlu mengisi password',
          v => v.length >= 8 || 'Minimal 8 karakter',
        ],
      },
      resource: {
        branches: [],
        status: [
          { text: 'Tersedia', value: true },
          { text: 'Tidak Tersedia', value: false },
        ],
        religion: [
          { text: 'Budha', value: 'budha' },
          { text: 'Hindu', value: 'hindu' },
          { text: 'Islam', value: 'islam' },
          { text: 'Katolik', value: 'katolik' },
          { text: 'Konghucu', value: 'konghucu' },
          { text: 'Kristen', value: 'kristen' },
        ],
        bloodType: [
          { text: 'A', value: 'a' },
          { text: 'B', value: 'b' },
          { text: 'AB', value: 'ab' },
          { text: 'O', value: 'o' },
          { text: 'A Rh+', value: 'a rh+' },
          { text: 'A Rh-', value: 'a rh-' },
          { text: 'AB Rh+', value: 'ab rh+' },
          { text: 'AB Rh-', value: 'ab rh-' },
          { text: 'B Rh+', value: 'b rh+' },
          { text: 'B Rh-', value: 'b rh-' },
          { text: 'O Rh+', value: 'o rh+' },
          { text: 'O Rh-', value: 'o rh-' },
        ],
        roles: [],
        units: [],
        specialize: [],
        bank: [],
      },
      input: {
        nip: '',
        name: '',
        nik: '',
        gender: '',
        dob: '',
        pob: '',
        address: '',
        phone_no: '',
        religion: '',
        bloodType: '',
        str: '',
        end_str: '',
        sip: '',
        end_sip: '',
        sik: '',
        end_sik: '',
        specialize: '',
        start_practice: '',
        password: '',
        bank: {
          id_bank: '',
          account: '',
        },
        roles: [
          {
            branch: '',
            unit: [],
            role: '',
            detail: '',
            status: '',
          },
        ],
      },
    };
  },
  computed: {
    today() {
      return moment(new Date()).format();
    },
  },
  mounted() {
    this.getAllRole();
    this.getAllBank();
    this.getUnit();
    this.getAllSpecialize();
    this.getBranch();
  },
  methods: {
    addRole() {
      this.input.roles.push({
        branch: '',
        unit: [],
        role: '',
        detail: '',
        status: '',
      });
    },
    deleteRole() {
      this.input.roles.pop();
    },
    getBranch() {
      axios
        .get(Constant.apiUrl.concat('/branch'))
        .then(response => {
          if (response.status === 200) {
            const { data } = response.data;
            this.resource.branches = data.map(branch => {
              return {
                value: branch._id.toString(),
                text: branch.name,
              };
            });
            if (!this.hasRoles().isSuperManagerAdmin) {
              this.input.roles[0].branch = {
                value: this.$store.getters.userLoggedIn.branch_id,
                text: data.find(
                  item =>
                    item._id === this.$store.getters.userLoggedIn.branch_id,
                ).name,
              };
            }
          }
        })
        .catch(() => {
          this.resource.branch = [];
        });
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    limitChar(type) {
      switch (type) {
        case 'nik':
          if (this.input.nik.length > 3) {
            this.input.nik = this.input.nik.substring(
              0,
              this.input.nik.length - 1,
            );
          }
      }
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    updateData() {
      if (this.$refs.form.validate()) {
        this.$emit('update-data');
      }
    },
    getAllRole() {
      axios
        .get(`${Constant.apiUrl}/master/staff/role`)
        .then(response => {
          const staffRoleDataGet = response.data.data;
          this.resource.roles = staffRoleDataGet.map(role => {
            return {
              text: role.description,
            };
          });
        })
        .catch(() => {
          this.resource.roles = [];
        })
        .finally(() => (this.isLoading = false));
    },
    getAllBank() {
      axios.get(`${Constant.apiUrl}/master/bank`).then(response => {
        const getBank = response.data.data;
        this.resource.bank = getBank.map(bank => {
          return {
            value: bank._id,
            text: bank.name,
          };
        });
      });
    },
    getAllSpecialize() {
      axios.get(`${Constant.apiUrl}/master/specialize`).then(response => {
        const getSpecialize = response.data.data;

        this.resource.specialize = getSpecialize.map(specialize => {
          return specialize.name;
        });
      });
    },
    getUnit() {
      axios.get(`${Constant.apiUrl}/master/unit`).then(response => {
        const unitData = response.data.data;
        this.resource.units = unitData.map(unit => {
          return {
            value: unit._id,
            text: unit.name,
          };
        });
      });
    },
    registerNewStaff() {
      if (this.$refs.form.validate()) {
        axios
          .post(Constant.apiUrl.concat('/master/staff/regis'), {
            nip: this.input.nip,
            detail: {
              name: this.input.name,
              nik: this.input.nik,
              password: this.input.password,
              address: this.input.address,
              gender: this.input.gender,
              religion: this.input.religion,
              blood_type: this.input.bloodType,
              phone_no: this.input.phone_no,
              birth: {
                city: this.input.pob,
                date: this.input.dob,
              },
              str: this.input.str,
              end_str: this.input.end_str,
              sip: this.input.sip,
              end_sip: this.input.end_sip,
              sik: this.input.sik,
              end_sik: this.input.end_sik,
              start_practice: this.input.start_practice,
              specialize: this.input.specialize,
              bank: {
                id_bank: this.input.bank?.id_bank || null,
                account: this.input.bank.account,
              },
            },
            role: this.input.roles.map(role => {
              return {
                ...role,
                id_branch: role.branch.value,
                unit: role.unit.map(unit => {
                  return {
                    id_unit: unit.value,
                  };
                }),
              };
            }),
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil', 'Data Staff Sudah Masuk', 'success');
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  display: grid;
  grid-template-columns: 150px 250px;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
.container-layout {
  height: 608px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
