<template>
  <div class="main-drugs">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Barang Farmasi
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="drugs"
              :expanded="expanded"
              :loading="loading"
              single-expand
              item-key="id"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                  selectItem(item);
                }
              "
            >
              <template v-slot:[`item.isActive`]="{ item }">
                <td>
                  <v-switch
                    v-model="item.isActive"
                    :label="`${item.isActive ? 'Aktif' : 'Tidak'}`"
                    :readonly="hasRoles().isSuperManager"
                    color="info"
                    @click="
                      changeIsActive(item);
                      openSnackbar();
                    "
                    inset
                    dense
                  ></v-switch>
                </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-4 icon-style"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item)"
                      color="red"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> Hapus </span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length" class="expand px-0">
                  <v-card flat>
                    <v-container>
                      <v-row no-gutters>
                        <v-col
                          cols="2"
                          class="text-left text--darken-2 caption"
                        >
                          <span
                            ><li>
                              Formulari:
                              <span class="font-weight-medium">{{
                                item.isFormulary ? 'Ya' : 'Tidak'
                              }}</span>
                            </li>
                          </span></v-col
                        >

                        <v-col
                          cols="2"
                          class="text-left text--darken-2 caption"
                        >
                          <span
                            ><li>
                              Fornas:<span class="font-weight-medium">
                                {{ item.isFornas ? 'Ya' : 'Tidak' }}</span
                              >
                            </li>
                          </span></v-col
                        >
                        <v-col cols="2" class="text-left text--darken-2 caption"
                          ><li>
                            Obat Keras:<span class="font-weight-medium">
                              {{ item.isPotent ? 'Ya' : 'Tidak' }}</span
                            >
                          </li>
                        </v-col>
                        <v-col cols="2" class="text-left text--darken-2 caption"
                          ><li>
                            Generic:<span class="font-weight-medium">
                              {{ item.isGeneric ? 'Ya' : 'Tidak' }}</span
                            >
                          </li>
                        </v-col>
                        <v-col class="text-left text--darken-2 caption"
                          ><li>
                            Katastropik :
                            <span class="font-weight-medium">
                              {{ item.isCatastrophic ? 'Ya' : 'Tidak' }}</span
                            >
                          </li>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                @click="openDetail(item)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-text</v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Barang</span>
                          </v-tooltip>
                          <v-dialog v-model="detailDialog" max-width="1200">
                            <detail-drugs
                              @close-dialog="closeDialog"
                              v-if="selectedItem && detailDialog"
                              :drugsDetail="selectedItem"
                            />
                          </v-dialog>

                          <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="openUpdate(item)"
                              >
                                <v-icon>mdi-clipboard-plus</v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Barang</span>
                          </v-tooltip>

                          <v-dialog
                            v-model="updateDialog"
                            persistent
                            max-width="1200"
                          >
                            <update-drugs
                              @close-dialog="closeDialog"
                              @update-data="getAllDrugsQueue"
                              v-if="updateDialog"
                              :drugsDetail="selectedItem"
                            />
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="drugsPages"
            :total-visible="10"
            @input="getAllDrugsQueue()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1200px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!hasRoles().isSuperManager"
                  color="primary"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <InputNewDrugs
                v-if="dialog"
                @close-dialog="closeDialog"
                @update-data="getAllDrugsQueue"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputNewDrugs from './components/Drugs/InputNewDrugs';
import Constant from '@/const';
import DetailDrugs from './components/Drugs/DetailDrugs.vue';
import UpdateDrugs from './components/Drugs/UpdateDrugs.vue';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterDataDrugs',
  mixins: [jwtMixin, alertMixin, roleMixin],
  components: {
    InputNewDrugs,
    DetailDrugs,
    UpdateDrugs,
  },
  data() {
    return {
      snackbar: false,
      text: 'Data Aktif Diubah',
      timeout: 1000,
      test: 'test',
      loading: true,
      expanded: [],
      dialog: false,
      detailDialog: false,
      updateDialog: false,
      drugs: [],
      drugsPages: 0,
      selectedItem: null,
      table: {
        headers: [
          { text: 'No', align: 'start', value: 'no' },
          {
            text: 'Nama',
            align: 'start',
            value: 'name',
          },
          { text: 'Kategori', value: 'category', sortable: false },
          { text: 'Kekuatan', value: 'strength', sortable: false },
          { text: 'Sediaan', value: 'preparation', sortable: false },
          { text: 'Pabrik', value: 'factory', sortable: false },
          { text: 'Hpp', value: 'hpp', sortable: false },
          { text: 'Status', value: 'isActive', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllDrugsQueue();
    }, 650),
    openSnackbar() {
      this.snackbar = true;
    },
    openDetail(item) {
      this.selectItem(item);
      this.detailDialog = true;
    },
    openUpdate(item) {
      this.selectItem(item);
      this.updateDialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.detailDialog = false;
      this.updateDialog = false;
    },
    selectItem(drug) {
      this.selectedItem = drug;
    },

    changeIsActive(item) {
      const payload = {
        id_drug: item.id,
        isActive: item.isActive,
      };
      axios
        .put(`${Constant.apiUrl}/master/drugs/activation`, payload)
        .then(() => {
          this.getAllDrugsQueue();
        })
        .catch(error => this.showErrorAxios(error));
    },

    getAllDrugsQueue() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      let keyword = this.search;
      this.loading = true;

      this.runApiCalls(() => {
        axios
          .get(
            `${Constant.apiUrl}/master/drugs?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}`,
          )
          .then(response => {
            const getDrugs = response.data.data;
            this.drugsPages = Math.ceil(response.data.length / itemCount);

            this.drugs = getDrugs.map((drug, idx) => {
              return {
                no: idx + 7 * halaman - 7 + 1,
                id: drug._id.toString(),
                name: drug.detail.name,
                barcode: drug.detail.barcode,
                hpp: drug.detail.hpp,
                hna: drug.detail.hna,
                category: drug.detail.category,
                strength: drug.detail.strength,
                strength_unit: drug.detail.strength_unit,
                preparation: drug.detail.preparation,
                indication: drug.detail.indication,
                c_indication: drug.detail.c_indication,
                side_effects: drug.detail.side_effects,
                shelf: drug.detail.shelf,
                ingredients: drug.detail.ingredients,
                roa: drug.detail.roa,
                isFormulary: drug.detail.isFormulary,
                isFornas: drug.detail.isFornas,
                isHighAlert: drug.detail.isHighAlert,
                isPotent: drug.detail.isPotent,
                isGeneric: drug.detail.isGeneric,
                isCatastrophic: drug.detail.isCatastrophic,
                isActive: drug.detail.isActive,
                isVEN: drug.detail.isVEN,
                factory: drug.detail.factory,
                group: drug.detail.group,
                min_stock: drug.detail.min_stock,
                pharmacotherapy: drug.detail.pharmacotherapy,
                packaging: drug.detail.packaging.map(p => {
                  return {
                    isDefault: p.isDefault,
                    package: p.package,
                    package_unit: p.package_unit,
                    quantity: p.quantity,
                  };
                }),
              };
            });
            this.loading = false;
          })
          .catch(() => {
            this.drugs = [];
            this.loading = false;
          });
      });
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Yakin?',
        html: `Anda akan menghapus Data <br> <b>"${item.name}"<b/>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${Constant.apiUrl}/master/drugs/${item.id}`)
            .then(() => {
              Swal.fire('Berhasil', `${item.name} berhasil dilapus`, 'success');
              this.getAllDrugsQueue();
            })
            .catch(error => {
              Swal.fire(
                `${item.name} gagal dihapus`,
                error.response.data.pesan,
                'error',
              );
            });
        }
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllDrugsQueue();
      this.interval = setInterval(this.getAllDrugsQueue, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }
  .v-data-table-header {
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
