var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-drugs"},[_c('v-toolbar',{attrs:{"flat":"","outlined":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-toolbar-title',{staticClass:"font-weight-bold float-left"},[_vm._v(" Data Barang Rumah Tangga ")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"grey","icon":"","text":""},on:{"click":_vm.refreshAndAutoUpdate}},[_c('v-icon',[_vm._v("mdi-autorenew")])],1),_c('v-text-field',{staticClass:"input",attrs:{"background-color":"grey lighten-3","placeholder":"Search","solo":"","dense":"","hide-details":"","flat":"","prepend-inner-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"expand",attrs:{"headers":_vm.table.headers,"items":_vm.goods,"expanded":_vm.expanded,"loading":_vm.loading,"single-expand":"","item-key":"id","items-per-page":_vm.itemsPerRow,"hide-default-footer":""},on:{"click:row":(item, slot) => {
                slot.expand(!slot.isExpanded);
                _vm.selectItem(item);
              }},scopedSlots:_vm._u([{key:`item.isActive`,fn:function({ item }){return [_c('td',[_c('v-switch',{attrs:{"readonly":_vm.hasRoles().isSuperManager,"label":`${item.isActive ? 'Aktif' : 'Tidak'}`,"color":"info","inset":"","dense":""},on:{"click":function($event){_vm.changeIsActive(item);
                    _vm.openSnackbar();}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1)]}},{key:`item.actions`,fn:function({ item }){return [(!_vm.hasRoles().isSuperManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4 icon-style",attrs:{"dense":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(" Hapus ")])]):_vm._e()]}},{key:"expanded-item",fn:function({ item, headers }){return [_c('td',{staticClass:"expand",attrs:{"colspan":headers.length},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-card',{staticClass:"text-right",attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 hover",attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.selectItem(item);
                                _vm.detailDialog = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail Barang")])]),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[(_vm.selectedItem && _vm.detailDialog)?_c('detail-goods',{attrs:{"goodsDetail":_vm.selectedItem},on:{"close-dialog":_vm.closeDialog}}):_vm._e()],1),(!_vm.hasRoles().isSuperManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 hover",attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.selectItem(item);
                                _vm.updateDialog = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Ubah Barang")])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}},[(_vm.updateDialog)?_c('update-goods',{attrs:{"goodsDetail":_vm.selectedItem},on:{"close-dialog":_vm.closeDialog,"update-data":_vm.getAllGoodsQueue}}):_vm._e()],1)],1)],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('v-footer',{attrs:{"color":"white"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-pagination',{attrs:{"length":_vm.goodsPages,"total-visible":10},on:{"input":function($event){return _vm.getAllGoodsQueue()}},model:{value:(_vm.table.pagination.page),callback:function ($$v) {_vm.$set(_vm.table.pagination, "page", $$v)},expression:"table.pagination.page"}}),_c('v-spacer'),[_c('v-dialog',{attrs:{"max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!_vm.hasRoles().isSuperManager)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('InputNewGoods',{on:{"close-dialog":_vm.closeDialog,"update-data":_vm.getAllGoodsQueue}}):_vm._e()],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }