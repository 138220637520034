<template>
  <div id="dental-poly">
    <v-toolbar flat outlined>
      <v-row align="center" class="header-page">
        <div>
          <!-- <v-toolbar-title class="font-weight-bold float-left">
              Poli Gigi dan Mulut
            </v-toolbar-title> -->
          <h2 class="title">Poli Gigi dan Mulut</h2>
          <h5 class="subtitle caption">
            {{ patient.served }} pasien dilayani, {{ patient.unserved }} pasien
            belum dilayani, dari {{ patient.total }} total pasien
            <v-progress-circular
              size="16"
              color="#adb5bd"
              width="2"
              :indeterminate="serveLoading"
              v-if="serveLoading"
            ></v-progress-circular>
          </h5>
        </div>
        <div class="px-0">
          <v-menu
            dense
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Pilih Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="date"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="
                $refs.menu.save(date);
                table.pagination.page = 1;
                showAllDentalPoly();
                getUnit();
              "
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="px-0">
          <v-autocomplete
            :items="resource.doctor"
            :loading="loading"
            @change="
              table.pagination.page = 1;
              showAllDentalPoly();
            "
            v-model="chooseDoctor"
            hide-details="auto"
            item-value="doctorId"
            item-text="doctor"
            placeholder="Pilih Dokter"
            class="ml-3"
            clearable
            outlined
            dense
          ></v-autocomplete>
        </div>
        <div class="px-0">
          <v-btn color="grey" @click="refresh()" icon text>
            <v-icon :class="{ refresh: isRefresh }">mdi-autorenew </v-icon>
          </v-btn>
        </div>
        <div class="px-0">
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </div>
      </v-row>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :expanded="expanded"
          :items-per-page="itemsPerRow"
          item-key="noRegist"
          class="expand"
          hide-default-footer
          single-expand
          @click:row="clickedRow"
          :loading="loading"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  v-if="item?.pcare?.visit && !item.pcare?.visit?.isSuccess"
                  color="red"
                  light
                  small
                  class="send-btn"
                  height="500"
                  ><v-icon left class="icon" color="white" x-small
                    >mdi-send-outline</v-icon
                  ></v-chip
                >
              </template>
              <span>{{ item?.pcare?.visit?.message }}</span>
            </v-tooltip>

            <v-icon
              v-if="item?.pcare?.visit && item.pcare?.visit?.isSuccess"
              color="green"
              >mdi-check-circle</v-icon
            >
          </template>
          <template v-slot:[`item.nurseAssessment`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.nurseAssessment === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="item.activityStatus.nurseAssessment === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.doctorAssessment`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.doctorAssessment === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="item.activityStatus.doctorAssessment === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.serviceStatus === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon v-if="item.activityStatus.serviceStatus === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.laboratory`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.labStatus === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon v-if="item.activityStatus.labStatus === 'scheduled'"
              >mdi-clock-time-three-outline</v-icon
            >
            <v-icon v-if="item.activityStatus.labStatus === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:[`item.erecipe`]="{ item }">
            <v-icon
              color="green"
              v-if="item.activityStatus.recipeStatus === 'confirmed'"
              >mdi-check</v-icon
            >
            <v-icon v-if="item.activityStatus.recipeStatus === 'scheduled'"
              >mdi-clock-time-three-outline</v-icon
            >
            <v-icon v-if="item.activityStatus.recipeStatus === 'not_scheduled'"
              >mdi-minus-thick</v-icon
            >
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-container class="mx-0 px-0">
                  <v-row no-gutters class="row-expand">
                    <v-col></v-col>
                    <v-col class="text-left grey--text caption px-2">
                      <span
                        >Terdaftar {{ item.timestamps.created_at }} WIB <br />
                        {{ item.date }}</span
                      ></v-col
                    >
                    <v-col class="text-left grey--text caption">
                      <span>{{ item.gender }}</span>
                      <br />
                      <span>{{ item.age }} Tahun</span></v-col
                    >
                    <v-col class="text-left grey--text caption px-2">{{
                      item.isNew
                    }}</v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item, 0)"
                          >
                            <font-awesome-icon icon="user" />
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item, 1)"
                          >
                            <font-awesome-icon icon="notes-medical" />
                          </v-btn>
                        </template>
                        <span>Asesmen</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            v-if="!item.isFinish"
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 2)"
                          >
                            <font-awesome-icon icon="syringe" />
                          </v-btn>
                        </template>
                        <span>Tindakan dan BHP</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            v-if="!item.isFinish"
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 3)"
                          >
                            <font-awesome-icon icon="prescription-bottle" />
                          </v-btn>
                        </template>

                        <span>E-Resep</span>
                      </v-tooltip>

                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-if="!item.isFinish"
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item, 4)"
                          >
                            <font-awesome-icon icon="flask" />
                          </v-btn>
                        </template>
                        <span>Permintaan Laboratorium</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item, 5)"
                          >
                            <font-awesome-icon icon="print" />
                          </v-btn>
                        </template>
                        <span>Surat</span>
                      </v-tooltip>
                      <v-tooltip v-if="!hasRoles().isSuperManager" bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 6)"
                          >
                            <v-icon size="21" style="padding-top: 2px"
                              >mdi-printer</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Print Dokumen</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              item.isFinish !== 'Ya' &&
                                !hasRoles().isSuperManager
                            "
                            @click="selectItem(item, 7)"
                          >
                            <font-awesome-icon icon="clipboard-check" />
                          </v-btn>
                        </template>
                        <span>Selesai</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-dialog
        v-model="dialog"
        :max-width="
          selectedForm === null ? 1400 : dialogWidth[selectedForm].width
        "
        :fullscreen="
          selectedForm === 1 || selectedForm === 4 || selectedForm === 3
        "
        :persistent="selectedForm !== 4"
      >
        <SocialDataPatients
          v-if="selectedForm === 0 && dialog"
          @close-form="closeForm"
          :medical-record="selectedPatient.rmNumber"
        />
        <dental-poly-assestment
          v-if="selectedForm === 1 && dialog"
          :patient-data="selectedPatient"
          @close-form="closeForm"
        />
        <BHPandAction
          v-else-if="selectedForm === 2 && dialog"
          :patient-data="selectedPatient"
          @close-form="closeForm"
        />
        <ERecipe
          v-else-if="selectedForm === 3 && dialog"
          :patient-data="selectedPatient"
          @close-form="closeForm"
        />
        <Warrant
          v-else-if="selectedForm === 5 && dialog"
          @close-form="closeForm"
          :patient-data="selectedPatient"
        />
        <PrintDocuments
          v-else-if="selectedForm === 6 && dialog"
          :props="{ generalConsent: generalConsentPatient }"
          @close-form="closeForm"
        />
        <LabRequest
          v-else-if="selectedForm === 4 && dialog"
          @close-dialog="closeForm"
          :patient-data="selectedPatient"
          :activityStatus="activityStatusLab"
          confirmationState="order"
        />
        <FinishService
          v-else-if="selectedForm === 7 && dialog"
          @close-form="closeForm"
          :patient-data="selectedPatient"
        />
      </v-dialog>
    </v-main>
    <v-footer color="white">
      <v-pagination
        v-model="table.pagination.page"
        @input="refreshAndAutoUpdate()"
        :length="pageLength"
        total-visible="5"
      ></v-pagination>
    </v-footer>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import DentalPolyAssestment from './components/DentalPoly/DentalPolyAssestment.vue';
import BHPandAction from './components/BHPandAction';
import ERecipe from './components/ERecipe';
import PrintDocuments from './components/PrintDocuments.vue';
import SocialDataPatients from '../SharedComponent/SocialData.vue';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import FinishService from './components/FinishService.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import Warrant from './components/Letters/Warrant';
import jwtMixin from '@/mixin/jwtMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DentalPoly',
  mixins: [jwtMixin, roleMixin],
  components: {
    DentalPolyAssestment,
    BHPandAction,
    ERecipe,
    Warrant,
    SocialDataPatients,
    FinishService,
    LabRequest,
    PrintDocuments,
  },
  data() {
    return {
      dialog: false,
      isRefresh: false,
      serveLoading: false,
      updateDialog: false,
      generalConsentPatient: false,
      expanded: [],
      dialogBHP: false,
      dialogErecipe: false,
      menu: false,
      date: '',
      search: '',
      chooseDoctor: '',
      patient: {
        total: '',
        served: '',
        unserved: '',
      },
      table: {
        headers: [
          {
            text: 'No. Antrian',
            align: 'start',
            value: 'queue',
            width: '5%',
            sortable: false,
          },
          {
            text: 'No. Pendaftaran',
            value: 'noRegist',
            width: '5%',
            sortable: false,
          },
          { text: 'Nama', value: 'name', width: '15%', sortable: false },
          { text: 'No. RM.', value: 'noRM', width: '5%', sortable: false },
          {
            text: 'Tipe Penjamin',
            value: 'guarantorType',
            width: '7%',
            sortable: false,
          },
          {
            text: 'Nama Dokter',
            value: 'doctor',
            width: '10%',
            sortable: false,
          },
          {
            text: 'As. Perawat',
            value: 'nurseAssessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'As. Dokter',
            value: 'doctorAssessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'Tindakan',
            align: 'center',
            value: 'action',
            width: '6%',
            sortable: false,
          },
          {
            text: 'Lab',
            align: 'center',
            value: 'laboratory',
            width: '6%',
            sortable: false,
          },
          {
            text: 'E-Resep',
            value: 'erecipe',
            align: 'center',
            width: '6%',
            sortable: false,
          },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      resource: {
        doctor: [],
      },
      loading: false,
      selectedForm: null,
      dialogWidth: [
        { name: 'SocialData', width: 1200 },
        { name: 'GeneralPoly', width: 1400 },
        { name: 'BHPandAction', width: 1400 },
        { name: 'ERecipe', width: 1400 },
        { name: 'LabRequest', width: 1400 },
        { name: 'Warrant', width: 1400 },
        { name: 'PrintDocument', width: 346 },
        { name: 'FinishPatient', width: 346 },
      ],
      pageLength: 1,
      idUnit: '',
      unitName: '',
      activityStatusLab: '',
    };
  },
  watch: {
    date(newVal) {
      if (newVal === null) {
        this.showAllDentalPoly();
      }
    },
    search() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
    this.clearSnackbar();
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'clearSnackbar', 'setShowDialog']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 650),
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    refresh() {
      this.isRefresh = true;
      this.refreshAndAutoUpdate();
    },
    closeDialog() {
      this.dialog = false;
      this.detailDialog = false;
      this.updateDialog = false;
    },
    clickedRow(value) {
      if (this.expanded.length && this.expanded[0].number == value.number) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    selectItem(item, index) {
      this.setShowDialog(true);
      this.changeSelectedPatient(item);
      this.activityStatusLab = item.activityStatus.labStatus;
      this.selectedForm = index;
      this.dialog = true;
    },
    getUnit() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/unit?page=1&itemCount=8&search=Poli Gigi&sort=name',
            ),
          )
          .then(response => {
            // const unit = response.data.data;
            const [unit] = response.data.data;
            this.idUnit = unit._id.toString();
            this.unitName = unit.name;

            this.showAllDentalPoly();
            this.getDoctor();
            this.getServedCount(this.idUnit);
          });
      });
    },
    getServedCount(id) {
      const today = moment(new Date()).format('YYYY-MM-DD');
      this.serveLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/registration/count?id_unit=${id}&startDate=${this
                .date || today}&endDate=${this.date || today}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.patient = {
              total: data.servicedPatient + data.unservicedPatient,
              served: data.servicedPatient,
              unserved: data.unservicedPatient,
            };
          })
          .catch(() => {
            this.patient = {
              total: 0,
              served: 0,
              unserved: 0,
            };
          })
          .finally(() => {
            this.serveLoading = false;
          });
      });
    },
    showAllDentalPoly() {
      const itemCount = this.itemsPerRow;
      let doctor = this.chooseDoctor;
      this.loading = true;
      const today = moment().format('YYYY-MM-DD');

      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/registration/unit?page=${
              this.table.pagination.page
            }&itemCount=${itemCount}&search=${this.search ||
              ''}&sort=&unit_name=Poli Gigi&date=${this.date ||
              today}&doctor=${doctor || ''}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data;
            this.pageLength = Math.ceil(
              response.data.length / this.itemsPerRow,
            );

            this.table.items = data.map(patient => {
              const addressData = [patient.emr.social[0].address.text];

              if (patient.emr.social[0].address.sub_district) {
                addressData.push(patient.emr.social[0].address.sub_district);
              }
              if (patient.emr.social[0].address.district) {
                addressData.push(patient.emr.social[0].address.district);
              }
              if (patient.emr.social[0].address.city) {
                addressData.push(patient.emr.social[0].address.city);
              }
              if (patient.emr.social[0].address.province) {
                addressData.push(patient.emr.social[0].address.province);
              }

              return {
                id_registration: patient._id.toString(),
                id_emr: patient.id_emr.toString(),
                hasGeneralConsent: patient.emr?.document?.generalConsentCreated,
                noRegist: patient.registration_number.toString(),
                polyId: patient.id_unit,
                isInformedConsentCreated:
                  patient.document?.informConsentCreated,
                doctorId: patient.doctor[0] ? patient.doctor[0]._id : '',
                doctor: patient.doctor[0]
                  ? patient.doctor[0].detail[0].name
                  : '',
                role: patient.doctor[0] ? patient.doctor[0].role[0].role : '',
                noQueue: patient.queue.number.toString(),
                pcare: patient?.pcare,
                queue: patient.queue_code
                  ? `${
                      patient.queue_code
                    }-${patient.queue.number.toString().padStart(3, '0')}`
                  : patient.queue.number.toString().padStart(3, '0'),
                isNew: patient.status.isNew ? 'Pasien Baru' : 'Pasien Lama',
                status: patient.status,
                isFinish: patient.queue.isFinish,
                time: patient.time,
                date: moment(patient.date)
                  .locale('id')
                  .format('LL'),
                profession: patient.emr.social[0].profession,

                //medical record
                noRM: patient.emr.medical_record_number,
                name: patient.emr.social[0].name,
                phoneNumber: patient.emr.social[0].phone_number,
                birthDate: moment(patient.emr.social[0].birth.date)
                  .locale('id')
                  .format('LL'),
                birthPlace: patient.emr.social[0].birth.place,
                returnStatus: patient.emr.checkup[0].assesment.return_status,
                age: patient.emr.social[0].age,
                gender: patient.emr.social[0].gender
                  ? 'Laki-laki'
                  : 'Perempuan',
                address: addressData.join(', '),
                identityType: patient.emr.social[0].identity.type,
                identityNumber: patient.emr.social[0].identity.number,
                // //assurance
                guarantorType: _.startCase(
                  patient.assurance.type,
                ).toUpperCase(),
                guarantorName: patient.emr.social[0].assurance.name,
                guarantorNumber: patient.emr.social[0].assurance.number,
                isProlanis: patient.emr.social[0].assurance.isProlanis,
                activityStatus: {
                  doctorAssessment:
                    patient.activity_status?.assesment_doctor_status ||
                    'not_scheduled',
                  nurseAssessment:
                    patient.activity_status?.assesment_nurse_status ||
                    'not_scheduled',
                  labStatus:
                    patient.activity_status?.lab_status || 'not_scheduled',
                  recipeStatus:
                    patient.activity_status?.recipe_status || 'not_scheduled',
                  serviceStatus:
                    patient.activity_status?.service_status || 'not_scheduled',
                },
                //allergy
                // allergy: patient.allergy,
                timestamps: {
                  created_at: moment(patient.timestamps.created_at)
                    .locale('id')
                    .format('LT'),
                },
              };
            });
          }
        })
        .catch(() => {
          this.table.items = [];
        })
        .finally(() => {
          this.loading = false;
          this.isRefresh = false;
        });
    },
    getDoctor() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/staff/active?id_unit=${this.idUnit}&branch=${this.$store.getters.userLoggedIn.branch_id}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                doctorId: doctor._id,
                doctor: doctor.detail.name,
              };
            });
          });
        this.pageLength = Math.ceil(this.table.items.length / 5);
      });
    },
    // async filterByDoctor() {
    //   let polyclinic = this.idUnit;
    //   let doctor = this.chooseDoctor;
    //   const today = moment(new Date()).format('YYYY-MM-DD');

    //   this.loading = true;
    //   await axios
    //     .get(
    //       Constant.apiUrl.concat(
    //         `/patient/?polyclinic=${polyclinic}&doctor=${doctor}&startDate=${today}&endDate=`,
    //       ),
    //     )
    //     .then(response => {
    //       if (response.status === 200) {
    //         const data = response.data.data;

    //         this.table.items = data.map(patient => {
    //           return {
    //             _id: patient._id.toString(),
    //             //registration
    //             noRegist: patient.registration.registration_number.toString(),
    //             polyId: patient.registration.id_unit._id,
    //             poly: patient.registration.id_unit.name,
    //             doctorId: patient.registration.id_staff._id,
    //             doctor: patient.registration.id_staff.name,
    //             noQueue: patient.registration.queue.number.toString(),
    //             queue: patient.queue_code
    //               ? `${
    //                   patient.queue_code
    //                 }-${patient.queue.number.toString().padStart(3, '0')}`
    //               : patient.queue.number.toString().padStart(3, '0'),
    //             isNew:
    //               patient.registration.isNew === true
    //                 ? 'Pasien Baru'
    //                 : 'Pasien Lama',
    //             status: patient.status,
    //             isFinish:
    //               patient.registration.queue.isFinish === true ? 'Ya' : 'Tidak',
    //             time: patient.registration.time,
    //             date: moment(patient.registration.date)
    //               .locale('id')
    //               .format('LL'),
    //             //medical record
    //             rmId: patient.medical_record._id.toString(),
    //             noRM: patient.medical_record.medical_record_number,
    //             name: patient.medical_record.name,
    //             birthDate: moment(patient.medical_record.birth.date)
    //               .locale('id')
    //               .format('LL'),
    //             birthPlace: patient.medical_record.birth.place,
    //             age: patient.medical_record.age,
    //             gender:
    //               patient.medical_record.gender === true
    //                 ? 'Laki-laki'
    //                 : 'Perempuan',
    //             //assurance
    //             guarantorType: patient.assurance.type,
    //             guarantorName: patient.assurance.name,
    //             guarantorNumber: patient.assurance.number,
    //             isProlanis: patient.assurance.isProlanis,
    //             //allergy
    //             allergy: patient.allergy,
    //             timestamps: {
    //               created_at: moment(patient.timestamps.created_at)
    //                 .locale('id')
    //                 .format('LT'),
    //             },
    //           };
    //         });
    //       }
    //     })
    //     .catch(() => {
    //       this.showAllDentalPoly();
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    changeSelectedPatient(item) {
      this.generalConsentPatient = item.hasGeneralConsent;
      const patientData = {
        id_emr: item.id_emr,
        id_registration: item.id_registration,
        isInformedConsentCreated: item.isInformedConsentCreated,
        noQueue: item.noQueue,
        patientType: item.status.type,
        rmNumber: item.noRM,
        isFinish: item.isFinish,
        returnStatus: item.returnStatus,
        meta: {
          name: item.name,
          birthDate: item.birthDate,
          phoneNumber: item.phoneNumber,
          gender: item.gender,
          age: item.age,
          address: item.address,
          identityType: item.identityType,
          identityNumber: item.identityNumber,
          doctor: item.doctor,
          doctorId: item.doctorId,
          role: item.role,
          poly: item.poly,
          id_poly: item.polyId,
          unitName: this.unitName,
          polyName: this.unitName,
          profession: item.profession,
          guarantorType: item.guarantorType,
          guarantorName: item.guarantorName,
          guarantorNumber: item.guarantorNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getUnit();
      this.showAllDentalPoly();
      this.interval = setInterval(this.showAllDentalPoly, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.send-btn {
  cursor: pointer;
  width: 25px !important;
  padding-bottom: 3px;
  padding-left: 14px;
  border-radius: 7px !important;
  margin-left: 5px;
  .icon {
    rotate: -45deg;
  }
}
.expand {
  border: none !important;
}

.title {
  text-align: left;
  font-weight: bold !important;
  padding-bottom: 0 !important;
}
.subtitle {
  text-align: left;
  color: #adb5bd !important;
  font-weight: 500;
}

.header-page {
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.6fr 0.1fr 0.6fr;
  padding: 10px 10px 10px 10px;
}

.hover:hover {
  color: #2196f3;
}

.row-expand {
  display: grid;
  grid-template-columns: 15% 22% 13% auto;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }

  .v-icon:hover {
    color: #2196f3;
  }

  tbody {
    border-left: 2px solid blue !important ;
  }
}
</style>
