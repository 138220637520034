<template>
  <v-form ref="form" lazy-validation>
    <v-row class="px-15">
      <v-col class="grid">
        <label class="required pr-15"
          >Apakah nama tindakan prosedur telah dikonfirmasi?</label
        >
        <v-radio-group :rules="rules" row v-model="input.procedureActionName">
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Apakah perhitungan jumlah instrumen, kassa dan jarum sudah
          tepat?</label
        >
        <v-radio-group :rules="rules" row v-model="input.instrument">
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Dari tindakan yang dilakukan, Apakah membutuhkan pengambilan
          specimen?</label
        >
        <v-radio-group :rules="rules" row v-model="input.speciment">
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio>
        </v-radio-group>
        <label class="pr-15" :class="{ 'grey--text': input.speciment }"
          >Apakah spesimen sudah diberi label (nama pasien, nomor
          register)?</label
        >
        <v-radio-group
          :disabled="!input.speciment"
          row
          v-model="input.isSpecimentLabeled"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <div class="grid">
          <label class="pr-15"
            >Apakah ada kesulitan dalam memakai peralatan medis?</label
          >
          <v-radio-group row v-model="input.isAnyDifficulties">
            <v-radio label="Ya" :value="true"></v-radio>
            <v-radio label="Tidak" :value="false"></v-radio>
          </v-radio-group>
          <label class="pr-15"
            >Apakah ada perhatian khusus untuk pemulihan dan rencana perawatan
            pasien?</label
          >
          <v-radio-group row v-model="input.isSpecialAttention">
            <v-radio label="Ya" :value="true"></v-radio>
            <v-radio label="Tidak" :value="false"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'SignOutPhase',
  props: {
    data: { type: Object },
  },
  data() {
    return {
      rules: [v => v || v === false || 'Wajib diisi'],
      input: {
        procedureActionName: true,
        instrument: true,
        speciment: true,
        isSpecimentLabeled: null,
        isAnyDifficulties: false,
        isSpecialAttention: true,
      },
    };
  },
  watch: {
    input: {
      handler: function() {
        this.$emit('update-data', this.input);
      },
      deep: true,
    },
  },
  mounted() {
    this.input = {
      ...this.input,
      procedureActionName: this.data.procedureActionName,
      instrument: this.data.instrument,
      speciment: this.data.speciment,
      isSpecimentLabeled: this.data.isSpecimentLabeled,
      isAnyDifficulties: this.data.isAnyDifficulties,
      isSpecialAttention: this.data.isSpecialAttention,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 40% 50%;
  row-gap: 3dvh;
  text-align: start;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
