<template>
  <v-card class="main">
    <v-container fluid>
      <header-content
        :data="headerData"
        title="Pembuatan Surgical Safety Check List"
        pl="3vw"
        mb="2vw"
        :closeForm="close"
        closeBtn
      />
      <section class="stepper">
        <div class="stepper-content" @click="before()">
          <div :class="`stepper-number${!carousel ? '-active' : ''}`">
            <v-icon color="white" v-if="isSignInComplete && !carousel"
              >mdi-check-bold</v-icon
            >
            <h3 v-else>1</h3>
          </div>
          <h3 class="font-weight-regular">Fase Sign In</h3>
        </div>
        <div class="stepper-content" @click="next()">
          <div :class="`stepper-number${carousel ? '-active' : ''}`">
            <v-icon color="white" v-if="isSignOutComplete && carousel"
              >mdi-check-bold</v-icon
            >
            <h3 v-else>2</h3>
          </div>
          <h3 class="font-weight-regular">Fase Sign Out</h3>
        </div>
      </section>
      <v-carousel v-model="carousel" :show-arrows="false" hide-delimiters>
        <v-progress-circular
          :size="100"
          v-if="!renderChildComponent"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-carousel-item>
          <sign-in-phase
            v-if="renderChildComponent"
            :resource="resource"
            :loading="loading"
            :data="signin"
            @update-data="updateSignInData"
            ref="signin-phase"
          />
        </v-carousel-item>
        <v-carousel-item>
          <sign-out-phase
            @update-data="updateSignOutData"
            :data="signout"
            v-if="renderChildComponent"
            ref="signout-phase"
          />
        </v-carousel-item>
      </v-carousel>
    </v-container>
    <v-footer v-if="!carousel" class="d-flex justify-end">
      <v-btn @click="next()" color="primary" outlined
        ><v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </v-footer>
    <v-footer v-else class="d-flex justify-space-between">
      <v-btn
        @click="print()"
        color="primary"
        outlined
        class="text-capitalize ml-15"
        >Print</v-btn
      >
      <div class="d-flex">
        <v-btn @click="before()" color="primary" outlined
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          @click="saveData()"
          depressed
          color="primary"
          class="text-capitalize ml-3"
          >Simpan</v-btn
        >
      </div>
    </v-footer>
  </v-card>
</template>

<script>
import Constant from '@/const';
import SignInPhase from './SSCComponents/SignInPhase.vue';
import SignOutPhase from './SSCComponents/SignOutPhase.vue';
import HeaderContent from '../../../SharedComponent/HeaderContent.vue';
import Documents from '@/mixin/documents';
import AlertMixin from '@/mixin/alertMixin';
import PrintMixin from '@/mixin/printMixin';
import Swal from 'sweetalert2';

import getSurgicalSafetyChecklistData from '../../../../fetchApi/Service/Documents/getSurgicalSafetyChecklist';
import addSurgicalSafetyChecklistData from '../../../../fetchApi/Service/Documents/addSurgicalSafetyChecklist';

const axios = require('axios');
const moment = require('moment');
export default {
  name: 'SurgicalSafetyChecklist',
  components: { SignInPhase, SignOutPhase, HeaderContent },
  mixins: [Documents, PrintMixin, AlertMixin],
  props: {
    closeForm: {
      type: Function,
    },
  },
  data() {
    return {
      carousel: 0,
      renderChildComponent: false,
      idRegist: null,
      loading: {
        doctor: false,
        data: false,
      },
      resource: {
        doctor: [],
        staff: [],
        risk: [
          'Kesulitan Jalan Nafas dan Risiko Aspirasi',
          'Risiko Pendarahan > 500 cc pada Dewasa dan Anak 7 cc/kg BB',
          'Penyakit Infeksius',
        ],
      },
      signin: {
        date: moment().format('DD/MM/YYYY'),
        doctor: '',
        time: moment().format('HH:mm'),
        staff: '',
        risk: [],
        action: [],
        allergy: [],
        isHasRisk: false,
        dataConfirmation: true,
        isAnestheticDeviceComplitelyChecked: true,
        isOperatingAreaMarked: true,
      },
      signout: {
        procedureActionName: true,
        instrument: true,
        speciment: true,
        isSpecimentLabeled: '',
        isAnyDifficulties: false,
        isSpecialAttention: true,
      },
    };
  },
  computed: {
    headerData() {
      return [
        {
          label: 'No. RM',
          text: this.$store.getters.selectedPatient.rmNumber,
        },
        {
          label: 'Nama',
          text: `${this.$store.getters.selectedPatient.meta.name} (${
            this.$store.getters.selectedPatient.meta.gender === 'Laki-laki'
              ? 'L'
              : 'P'
          })`,
        },
        {
          label: 'Tnggal Lahir',
          text: `${this.$store.getters.selectedPatient.meta.birthDate} (${this.$store.getters.selectedPatient.meta.age})`,
        },
      ];
    },
    isSignInComplete() {
      return (
        this.signin.doctor &&
        this.signin.staff &&
        this.signin.risk?.length > 0 &&
        this.isBoolean(this.signin.dataConfirmation) &&
        this.isBoolean(this.signin.isOperatingAreaMarked) &&
        this.isBoolean(this.signin.isAnestheticDeviceComplitelyChecked)
      );
    },
    isSignOutComplete() {
      return (
        this.isBoolean(this.signout.procedureActionName) &&
        this.isBoolean(this.signout.instrument) &&
        this.isBoolean(this.signout.speciment)
      );
    },
  },
  mounted() {
    this.getStaff();
    this.getSSCData();
  },
  methods: {
    close() {
      this.closeForm();
    },
    next() {
      if (this.$refs['signin-phase'].$refs.form.validate()) {
        this.carousel++;
      }
    },
    before() {
      this.carousel--;
    },
    isDataBoolean(data) {
      if (typeof data === 'boolean') return true;
      if (typeof data !== 'boolean') return null;
    },
    isBoolean(data) {
      return typeof data === 'boolean';
    },
    updateSignInData(newData) {
      this.signin = {
        ...newData,
      };
    },
    updateSignOutData(newData) {
      this.signout = {
        ...newData,
      };
    },
    async getSSCData() {
      this.loading.data = true;
      try {
        const result = await getSurgicalSafetyChecklistData(
          this.$store.getters.selectedPatient.id_registration,
        );
        const data = result.data.data.detail;
        this.signin = {
          ...this.signin,
          date: data.signInPhase.date || moment().format('DD/MM/YYYY'),
          time: data.signInPhase.time,
          action: data.signInPhase.action,
          allergy: data.signInPhase.allergy || [],
          doctor: data.signInPhase.doctor,
          dataConfirmation: this.isDataBoolean(
            data.signInPhase.isPatientConfirmed,
          )
            ? data.signInPhase.isPatientConfirmed
            : true,
          isAnestheticDeviceComplitelyChecked: this.isDataBoolean(
            data.signInPhase.isToolCompleted,
          )
            ? data.signInPhase.isToolCompleted
            : true,
          isOperatingAreaMarked: this.isDataBoolean(
            data.signInPhase.isOperatingAreaMarked,
          )
            ? data.signInPhase.isOperatingAreaMarked
            : true,
          isHasRisk: Array.isArray(data.signInPhase.patientRiskState)
            ? !data.signInPhase.patientRiskState?.includes(
                'Tidak Ada Keadaan Berisiko',
              )
            : false,
          risk: data.signInPhase.patientRiskState,
          staff: data.signInPhase.staff || '',
        };
        this.signout = {
          ...this.signout,
          procedureActionName: this.isDataBoolean(
            data.signOutPhase?.isProcedureActionConfirmed,
          )
            ? data.signOutPhase?.isProcedureActionConfirmed
            : true,
          instrument: this.isDataBoolean(data.signOutPhase?.isToolsCalculated)
            ? data.signOutPhase?.isToolsCalculated
            : true,
          speciment: this.isDataBoolean(data.signOutPhase?.isNeedTookSpecimen)
            ? data.signOutPhase?.isNeedTookSpecimen
            : false,
          isSpecimentLabeled: this.isDataBoolean(
            data.signOutPhase?.isSpecimenLabeled,
          )
            ? data.signOutPhase?.isSpecimenLabeled
            : null,
          isAnyDifficulties: this.isDataBoolean(
            data.signOutPhase?.isDifficultUsingTool,
          )
            ? data.signOutPhase?.isDifficultUsingTool
            : false,
          isSpecialAttention: this.isDataBoolean(
            data.signOutPhase?.isSpecialAttention,
          )
            ? data.signOutPhase?.isSpecialAttention
            : true,
        };
      } catch {
        this.signin = { ...this.signin, isError: true };
        this.signout = { ...this.signout };
      } finally {
        this.loading.data = false;
        this.renderChildComponent = true;
      }
    },
    async saveData() {
      if (this.$refs['signout-phase'].$refs.form.validate()) {
        try {
          const payload = {
            id_emr: this.$store.getters.selectedPatient.id_emr,
            id_registration: this.$store.getters.selectedPatient
              .id_registration,
            detail: {
              signInPhase: {
                date: this.signin.date,
                time: this.signin.time,
                doctor: this.signin.doctor.id,
                staff: this.signin.staff.id,
                isPatientConfirmed: this.signin.dataConfirmation,
                isToolCompleted: this.signin
                  .isAnestheticDeviceComplitelyChecked,
                isOperatingAreaMarked: this.signin.isOperatingAreaMarked,
                action: this.signin.action,
                allergy: this.signin.allergy,
                patientRiskState: this.signin.isHasRisk
                  ? this.signin.risk
                  : ['Tidak Ada Keadaan Berisiko'],
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                },
              },
              signOutPhase: {
                isProcedureActionConfirmed: this.signout.procedureActionName,
                isToolsCalculated: this.signout.instrument,
                isNeedTookSpecimen: this.signout.speciment,
                isSpecimenLabeled: !this.signout.speciment
                  ? null
                  : this.signout.isSpecimentLabeled,
                isDifficultUsingTool: this.signout.isAnyDifficulties,
                isSpecialAttention: this.signout.isSpecialAttention,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                },
              },
            },
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          };
          const response = await addSurgicalSafetyChecklistData(payload);
          if (response.status === 200) {
            Swal.fire(
              'Data Surgical Safety Check List Berhasil Disimpan',
              '',
              'success',
            );
          }
        } catch (error) {
          this.showErrorAxiox(error);
        } finally {
          this.getSSCData();
        }
      }
    },
    async getStaff() {
      this.loading.doctor = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat('/master/staff'),
        );
        const { data } = response.data;
        this.resource.staff = data
          .filter(i => i.role?.status)
          .map(staff => {
            return {
              name: staff.detail.name,
              id: staff._id,
            };
          });
        this.resource.doctor = data
          .filter(
            i => i.role?.status && i.role?.role.toLowerCase() === 'dokter',
          )
          .map(staff => {
            return {
              name: staff.detail.name,
              id: staff._id,
            };
          });
      } catch {
        this.resource.staff = [];
        this.resource.doctor = [];
      } finally {
        this.loading.doctor = false;
      }
    },
    print() {
      const doc = this.generateSscDoc();
      this.printDocument(doc);
    },
  },
};
</script>

<style lang="scss" scoped>
$active-color: #036de2;
@mixin stepper-state($isActive: true) {
  @if $isActive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    color: white;
    background-color: $active-color;
  } @else {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    color: $active-color;
    border: 2px solid $active-color;
    background-color: transparent;
  }
}
.main {
  padding: 0.4rem;
  height: 90dvh;
  overflow: hidden;

  #{&}-header {
    margin-bottom: 3vh;
    > .col {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: start;
      gap: 1rem;
    }

    .col:last-child {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 2rem;
      }
    }

    p {
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }

  .stepper {
    display: flex;
    justify-content: center;
    gap: 4vw;
    margin-bottom: 5vh;

    &-content {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    &-number-active {
      @include stepper-state();
    }

    &-number {
      @include stepper-state(false);
    }
  }

  footer {
    position: absolute;
    width: 100%;
    bottom: 3vh;
    right: 1vw;
    background: transparent;
  }
}
</style>
