<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="6">
        <v-row class="justify-start" align="baseline">
          <h3 class="primary--text mb-1 pl-3">Ubah Jadwal</h3>
        </v-row>
        <v-row class="justify-start mb-1" align="baseline">
          <v-col cols="5"
            ><h4 class="mb-2 text-start">Nama Dokter</h4>
            <v-combobox
              :items="doctors"
              placeholder="Pilih Dokter"
              dense
              auto-select-first
              item-value="id"
              item-text="name"
              clearable
              v-model="inputFilterSchedule.id_staff"
              hide-details="false"
              :loading="doctorLoading"
              @change="getScheduleDoctor()"
            ></v-combobox
          ></v-col>
          <v-col cols="5">
            <h4 class="mb-2 text-start">Nama Poli</h4>
            <v-select
              auto-select-first
              placeholder="Pilih Poli"
              :items="poly"
              item-value="id"
              item-text="name"
              v-model="inputFilterSchedule.id_unit"
              clearable
              dense
              hide-details="false"
              @change="getScheduleDoctor()"
            ></v-select>
          </v-col>
        </v-row>
        <v-expansion-panels
          :disabled="!selectedPoly"
          v-model="panel"
          class="rounded-lg"
          style="border: 2px solid #c2c2c2"
        >
          <v-expansion-panel v-model="panel">
            <v-expansion-panel-header
              class="font-weight-medium"
              style="color: #757575"
            >
              {{ `Jadwal ${this.selectedPoly}` }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <v-row class="px-3">
                <v-col cols="3" class="text-left">
                  <label class="font-weight-medium ml-3">Kode Antrian</label>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-text-field
                    v-model="inputSchedule.queue_code"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-for="(schedule, idx) in inputSchedule.schedule"
                :key="'schedule-' + idx"
                class="px-12 mb-5"
              >
                <v-row class="text-start mb-1">
                  <label class="font-weight-medium">{{
                    generateDayName(+schedule.day)
                  }}</label>
                </v-row>
                <v-row
                  v-for="(item, index) in schedule.data"
                  :key="'item-' + index"
                  class="mb-2"
                >
                  <v-col cols="3" class="pa-0">
                    <v-menu
                      :ref="`${schedule.day}start`"
                      v-model="item.startTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.start_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.start_time"
                          label="Jam Mulai"
                          clearable
                          append-icon="mdi-chevron-down"
                          v-bind="attrs"
                          v-on="on"
                          class="my-0 ml-0 mr-3 pa-0"
                          hide-details="false"
                          dense
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="item.startTimeMenu"
                        v-model="item.start_time"
                        full-width
                        :max="item.end_time"
                        format="24hr"
                        @click:minute="
                          $refs[`${schedule.day}start`][index].save(
                            item.start_time,
                          )
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <!-- ref="mondayEnd" -->
                    <v-menu
                      v-model="item.endTimeMenu"
                      :close-on-content-click="false"
                      :ref="`${schedule.day}end`"
                      :nudge-right="40"
                      :return-value.sync="item.end_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.end_time"
                          label="Jam Selesai"
                          clearable
                          append-icon="mdi-chevron-down"
                          v-bind="attrs"
                          v-on="on"
                          class="my-0 ml-0 mr-3 pa-0"
                          hide-details="false"
                          dense
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="item.endTimeMenu"
                        v-model="item.end_time"
                        full-width
                        :min="item.start_time"
                        format="24hr"
                        @click:minute="
                          $refs[`${schedule.day}end`][index].save(item.end_time)
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-text-field
                      label="Kuota"
                      type="number"
                      class="my-0 ml-0 mr-3 pa-0"
                      v-model="item.quota"
                      hide-details="false"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col lg="2" xl="3" class="pa-0">
                    <v-text-field
                      label="Ruang"
                      class="my-0 ml-0 mr-3 pa-0"
                      v-model="item.room"
                      dense
                      hide-details="false"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 d-flex" lg="2" xl="1">
                    <v-icon
                      @click="deleteSchedule(idx, index)"
                      class="mr-2"
                      :color="
                        inputSchedule.schedule[idx].data.length === 1
                          ? 'grey'
                          : 'red'
                      "
                      medium
                      >mdi-delete</v-icon
                    >
                    <div
                      @click="addSchedule(idx, index)"
                      class="plus-btn"
                      :class="{ disable: isFormIncomplete(idx, index) }"
                    >
                      <v-icon color="white" small>mdi-plus</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <v-row class="pa-3">
                <v-col cols="8"><v-spacer></v-spacer></v-col>
                <v-col cols="4"
                  ><v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      tile
                      elevation="0"
                      @click="addData()"
                      class="text-none"
                      width="153"
                      :loading="loadingButton"
                      :disabled="isDisabled"
                      >Simpan</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="6" class="pr-6">
        <v-row class="justify-start" align="baseline">
          <h3 class="primary--text mb-3">Lihat Jadwal Dokter</h3>
        </v-row>
        <v-row>
          <h4 class="mb-2">Nama Poli</h4>
        </v-row>
        <v-row>
          <v-col cols="3" class="pa-0">
            <v-select
              placeholder="Pilih Poli"
              class="mt-0 pt-0 pa-0"
              :items="poly"
              item-value="id"
              item-text="name"
              return-object
              clearable
              v-model="polyclinic"
              dense
              @change="getAllSpecialistDoctor"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <doctor-schedule-list-card
            :data="{
              loading: getSpecialistLoading,
              schedule: doctorSchedule,
              generateDayName,
            }"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import alertMixin from '@/mixin/alertMixin';
import DoctorScheduleListCard from './Schedule/DoctorScheduleListCard.vue';

const axios = require('axios');
const moment = require('moment');

export default {
  name: 'SchedulePractice',
  components: { DoctorScheduleListCard },
  mixins: [alertMixin],
  data() {
    return {
      doctor: [],
      poly: [],
      doctors: [],
      polyclinic: '',
      disabled: false,
      selectedPoly: '',
      panel: null,
      inputFilterSchedule: {
        id_staff: '',
        id_unit: '',
      },
      fixSchedule: [],
      inputSchedule: {
        queue_code: '',
        schedule: [
          {
            day: 1,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 2,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 3,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 4,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 5,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 6,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 7,
            data: [
              {
                startTimeMenu: false,
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
        ],
      },
      doctorSchedule: [],
      loadingButton: false,
      doctorLoading: false,
      getSpecialistLoading: false,
      isDisabled: false,
    };
  },
  watch: {
    selectedPoly(newValue) {
      if (!newValue) {
        this.panel = null;
      }
    },
  },
  methods: {
    generateDayName(value) {
      switch (+value) {
        case 1:
          return 'Senin';
        case 2:
          return 'Selasa';
        case 3:
          return 'Rabu';
        case 4:
          return 'Kamis';
        case 5:
          return 'Jumat';
        case 6:
          return 'Sabtu';
        case 7:
          return 'Minggu';
      }
    },
    isFormIncomplete(day, schedule) {
      if (
        !this.inputSchedule.schedule[day].data[schedule].start_time ||
        !this.inputSchedule.schedule[day].data[schedule].end_time ||
        !this.inputSchedule.schedule[day].data[schedule].quota ||
        !this.inputSchedule.schedule[day].data[schedule].room
      ) {
        return true;
      } else {
        return false;
      }
    },
    addData() {
      this.fixSchedule = [];
      this.loadingButton = true;
      this.isDisabled = true;

      for (let i = 0; i < this.inputSchedule.schedule.length; i++) {
        for (let y = 0; y < this.inputSchedule.schedule[i].data.length; y++) {
          if (
            // filter empty schedule input
            !this.inputSchedule.schedule[i].data[y].start_time &&
            !this.inputSchedule.schedule[i].data[y].end_time &&
            !this.inputSchedule.schedule[i].data[y].quota &&
            !this.inputSchedule.schedule[i].data[y].room
          ) {
            continue;
          }
          if (
            // warn user when some input are empty
            !this.inputSchedule.schedule[i].data[y].start_time ||
            !this.inputSchedule.schedule[i].data[y].end_time ||
            !this.inputSchedule.schedule[i].data[y].quota ||
            !this.inputSchedule.schedule[i].data[y].room
          ) {
            Swal.fire(
              `Input jadwal dokter pada hari ${this.generateDayName(
                this.inputSchedule.schedule[i].day,
              )} belum lengkap`,
              '',
              'error',
            );
            this.loadingButton = false;
            this.isDisabled = false;
            return;
          }
          if (
            this.inputSchedule.schedule[i].data[y].start_time &&
            this.inputSchedule.schedule[i].data[y].end_time &&
            this.inputSchedule.schedule[i].data[y].quota &&
            this.inputSchedule.schedule[i].data[y].room
          ) {
            this.fixSchedule.push({
              day: this.inputSchedule.schedule[i].day,
              start_time: this.inputSchedule.schedule[i].data[y].start_time,
              end_time: this.inputSchedule.schedule[i].data[y].end_time,
              quota: this.inputSchedule.schedule[i].data[y].quota,
              room: this.inputSchedule.schedule[i].data[y].room,
            });
          }
        }
      }
      axios
        .post(Constant.apiUrl.concat('/schedule/default'), {
          id_branch: this.$store.getters.userLoggedIn.branch_id,
          id_staff: this.inputFilterSchedule.id_staff.id,
          id_unit: this.inputFilterSchedule.id_unit,
          schedule: this.fixSchedule,
          queue_code: this.inputSchedule.queue_code,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        })
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Jadwal Dokter berhasil ditambahkan!', '', 'success');
          }
        })
        .catch(err => {
          this.showErrorAxios(err);
        })
        .finally(() => {
          this.loadingButton = false;
          this.isDisabled = false;
        });
    },
    addSchedule(day, schedule) {
      if (this.isFormIncomplete(day, schedule)) return;
      this.inputSchedule.schedule[day].data.push({
        startTimeMenu: false,
        endTimeMenu: false,
        start_time: '',
        end_time: '',
        quota: '',
        room: '',
      });
    },
    deleteSchedule(dayIndex, itemIndex) {
      if (this.inputSchedule.schedule[dayIndex].data.length === 1) return;
      this.inputSchedule.schedule[dayIndex].data.splice(itemIndex, 1);
    },
    getScheduleDoctor() {
      if (
        this.inputFilterSchedule.id_staff &&
        this.inputFilterSchedule.id_unit
      ) {
        axios
          .get(
            Constant.apiUrl.concat(
              `/schedule/default/unit-doctor/${this.inputFilterSchedule.id_staff.id}/${this.inputFilterSchedule.id_unit}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            if (data !== 0) {
              this.inputSchedule = {
                queue_code: '',
                schedule: [
                  {
                    day: 1,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 2,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 3,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 4,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 5,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 6,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 7,
                    data: [
                      {
                        startTimeMenu: false,
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                ],
              };
              this.inputSchedule = {
                ...this.inputSchedule,
                queue_code: data[0].queue_code,
              };
              data.forEach(arr => {
                const schedule = {
                  day: arr.day,
                  data: arr.schedule.map(item => {
                    return {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: item.start_time,
                      end_time: item.end_time,
                      quota: item.quota,
                      room: item.room,
                    };
                  }),
                };
                this.inputSchedule.schedule.splice(arr.day - 1, 1, schedule);
              });
            } else {
              throw false;
            }
          })
          .catch(() => {
            this.inputSchedule = {
              schedule: [
                {
                  day: 1,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 2,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 3,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 4,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 5,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 6,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 7,
                  data: [
                    {
                      startTimeMenu: false,
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
              ],
            };
          })
          .finally(() => {
            if (
              this.inputFilterSchedule.id_staff &&
              this.inputFilterSchedule.id_unit
            ) {
              this.panel = 0;
              this.getSelectedPoly(this.inputFilterSchedule.id_unit);
            } else {
              this.panel = null;
              this.selectedPoly = '';
            }
          });
      }
    },
    getSchedulePoly() {
      if (this.inputFilterSchedule.id_unit)
        axios
          .get(
            Constant.apiUrl.concat(
              `/schedule/default?id_unit=${this.inputFilterSchedule.id_unit}`,
            ),
          )
          .then(response => {
            if (response.status == 200) {
              let schedule = response.data.data;
              this.doctorSchedule = schedule.map(s => {
                return {
                  doctorName: s.id_staff.detail[0].name,
                  schedule: s.schedule,
                };
              });
            }
          });
    },
    getPoly() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.poly = response.data.data
              .filter(
                x =>
                  x.detail.installation === 'Installasi Rawat Inap' ||
                  x.detail.installation === 'Instalasi Rawat Jalan',
              )
              .map(unit => {
                return {
                  id: unit._id.toString(),
                  name: unit.name,
                };
              });
          }
        });
    },
    getSelectedPoly(polyId) {
      const poly = this.poly.find(v => v.id === polyId);
      this.selectedPoly = poly.name;
    },
    getDoctor() {
      this.doctorLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan&branch=${this.$store.getters.userLoggedIn.branch_id}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.doctors = data
              .filter(v => v.role[0].status)
              .map(doctor => {
                return {
                  id: doctor._id.toString(),
                  name: doctor.detail.name,
                };
              });
          }
        })
        .catch(() => {
          this.doctors = [];
        })
        .finally(() => {
          this.doctorLoading = false;
        });
    },
    getSpecialistDoctor() {
      this.getSpecialistLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/schedule/default?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&id_unit=${this.polyclinic.id || ''}`,
          ),
        )
        .then(response => {
          if (response.status == 200) {
            let specialist = response.data.data;
            this.doctorSchedule = specialist.map(specialistDoctor => {
              return {
                scheduleId: specialistDoctor._id.toString(),
                doctorName: specialistDoctor.id_staff.detail[0].name,
                schedule: specialistDoctor.schedule,
              };
            });
          }
          this.getSpecialistLoading = false;
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.doctorSchedule = '';
          }
          this.getSpecialistLoading = false;
        });
    },
    getAllSpecialistDoctor() {
      this.getSpecialistLoading = true;
      axios
        .post(Constant.apiUrl.concat('/schedule'), {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
          id_unit: this.polyclinic.id,
          id_staff: '',
          isActive: true,
        })
        .then(response => {
          if (response.status == 200) {
            const data = response.data.data;
            this.doctorSchedule = data.map((specialistDoctor, idx) => {
              return {
                scheduleId: idx,
                doctorName: specialistDoctor.id_staff,
                schedule: specialistDoctor.schedule,
                unit: specialistDoctor.id_unit,
              };
            });
          }
          this.getSpecialistLoading = false;
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.doctorSchedule = [];
          }
          this.getSpecialistLoading = false;
        });
    },
  },
  mounted() {
    this.getPoly();
    this.getDoctor();
    this.getAllSpecialistDoctor();
  },
};
</script>

<style lang="scss" scoped>
.centered-input {
  text-align: center !important;
}

.schedule-list {
  width: 100%;
  .schedule-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &.header {
      grid-template-columns: 33.5% repeat(4, 1fr);
    }
  }
}
::v-deep .label-input .theme--light.v-input input,
.theme--light.v-input textarea {
  color: #fff;
  text-align: center;
}

.plus-btn {
  background-color: #137bc0;
  border-radius: 4px;
  width: 1.5rem;
  cursor: pointer;

  &.disable {
    background-color: gray;
  }
}
</style>
