<template>
  <v-form ref="form" lazy-validation>
    <v-row class="px-15">
      <v-col class="grid">
        <label>Tanggal Tindakan</label>
        <label class="font-weight-medium">{{ input.date }}</label>
        <label class="required">Dokter Pemeriksa</label>
        <v-combobox
          dense
          single-line
          clearable
          :rules="rules"
          :loading="loading.doctor"
          :items="resource.doctor"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Dokter Pemeriksa"
          v-model="input.doctor"
        />
      </v-col>
      <v-col class="grid">
        <label>Input Jam Tindakan</label>
        <v-text-field
          @input="formatTime(input.time)"
          dense
          v-model="input.time"
          :rules="timeRule"
          placeholder="Masukkan Jam Tindakan"
        />
        <label class="required">Petugas Pendamping</label>
        <v-combobox
          dense
          single-line
          clearable
          :rules="rules"
          :loading="loading.doctor"
          :items="resource.staff"
          v-model="input.staff"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas Pendamping"
        />
      </v-col>
    </v-row>
    <div class="px-15 my-5">
      <v-divider></v-divider>
    </div>
    <v-row class="px-15">
      <v-col class="grid">
        <label class="required pr-15"
          >Apakah pasien telah dikonfirmasi nama, sisi operasi, dan informed
          consent?</label
        >
        <v-radio-group :rules="rules" row v-model="input.dataConfirmation">
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Apakah alat anestesi dan pengobatan telah diperiksa lengkap?</label
        >
        <v-radio-group
          :rules="rules"
          row
          v-model="input.isAnestheticDeviceComplitelyChecked"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Apakah area operasi sudah ditandai?</label
        >
        <v-radio-group :rules="rules" row v-model="input.isOperatingAreaMarked">
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <div class="grid">
          <label>Tindakan Yang Dilakukan</label>
          <label class="font-weight-medium">{{ input.action.name }}</label>
          <label>Riwayat Alergi</label>
          <label class="font-weight-medium">{{
            Array.isArray(input.allergy) && input.allergy.length > 0
              ? input?.allergy?.join(', ')
              : 'Tidak Ada Alergi'
          }}</label>
          <label class="required">Keadaan Pasien Yang Berisiko</label>
          <v-radio-group class="mt-n1" row v-model="input.isHasRisk">
            <v-radio
              class="mt-n1"
              label="Tidak Ada Keadaan Berisiko"
              :value="false"
            ></v-radio>
            <v-radio class="mt-n1" label="Ada" :value="true"></v-radio>
          </v-radio-group>
          <label v-if="input.isHasRisk"></label>
          <v-combobox
            v-if="input.isHasRisk"
            multiple
            :rules="riskRules"
            class="mt-n10"
            v-model.lazy="input.risk"
            :items="resource.risk"
            append-icon="mdi-chevron-down"
            placeholder="Keadaan Pasien Yang Berisiko"
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const moment = require('moment');

export default {
  name: 'SignInPhase',
  props: {
    resource: { type: Object },
    data: { type: Object },
    loading: { type: Object },
  },
  data() {
    return {
      input: {
        date: moment().format('DD/MM/YYYY'),
        doctor: '',
        time: moment().format('HH:mm'),
        staff: '',
        risk: [],
        action: '-',
        allergy: '-',
        isHasRisk: false,
        dataConfirmation: true,
        isAnestheticDeviceComplitelyChecked: true,
        isOperatingAreaMarked: true,
      },
      rules: [v => v || v === false || 'Wajib diisi'],
      riskRules: [v => v?.length > 0 || 'Wajib diisi'],
      timeRule: [v => this.checkTimeFormat(v) || 'Waktu Tidak Valid'],
    };
  },
  watch: {
    input: {
      handler: function() {
        this.$emit('update-data', this.input);
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.data.isError) {
        this.input = {
          ...this.input,
          isHasRisk: this.data.isHasRisk,
          date: this.data.date,
          doctor: this.data.doctor,
          time: this.data.time,
          staff: this.data.staff,
          risk: this.data.risk,
          action: this.data.action,
          allergy: this.data.allergy,
          dataConfirmation: this.data.dataConfirmation,
          isAnestheticDeviceComplitelyChecked: this.data
            .isAnestheticDeviceComplitelyChecked,
          isOperatingAreaMarked: this.data.isOperatingAreaMarked,
        };
      }
    });
  },
  methods: {
    checkTimeFormat(time) {
      const timeInput = moment(time, 'HH:mm');
      return timeInput.isValid();
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatTime(timeString) {
      if (timeString.length > 5) {
        this.input = {
          ...this.input,
          time: timeString.slice(0, 5),
        };
        return;
      }
      let time = timeString.replace(/\D/g, '').substr(0, 4);
      let size = time.length;

      if (size > 2) time = this.insertString(time, ':', 2);

      this.input.time = time;
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 40% 50%;
  row-gap: 3dvh;
  text-align: start;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
