import moment from 'moment-timezone';
import JsPDF from 'jspdf';
import Constant from '../const';
import checkboxTrue from '@/assets/image/checkboxTrue.png';
import checkboxFalse from '@/assets/image/checkboxFalse.png';
import 'jspdf-autotable';

export default {
  methods: {
    generateGeneralConsentDoc(data, socialData) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 1.0;
      const tab = 0.5;
      const justifyWidth = 5.4;
      const indexingSpace = 0.15;
      const bottomMargin = 10.7;
      let haveAddPage = false;

      doc.setProperties({
        title: `General Consent no.RM: ${data.rmNumber}`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'PERSETUJUAN UMUM', 0.7);
      this.pdfTextPrint(doc, '(GENERAL CONSENT)', 1);
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'IDENTITAS PASIEN', 1.4, 'left', leftMargin);
      doc.setFont('Helvetica', 'normal');
      doc.autoTable({
        body: [
          ['Nama', ':', data.signer === 'wali' ? socialData.name : data.name],
          ['Nomor RM', ':', data.rmNumber],
          ['Tanggal Lahir', ':', data.birthDate],
          [
            'Alamat',
            ':',
            data.signer === 'wali' ? socialData.address : data.address,
          ],
          [
            'No. Telp',
            ':',
            data.signer === 'wali' ? socialData.telp : data.telp,
          ],
        ],
        startY: 1.5,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      this.pdfTextPrint(
        doc,
        'Yang bertanda tangan dibawah ini :',
        lastLine + 0.2,
        'left',
        leftMargin,
      );

      doc.autoTable({
        body: [
          ['Nama', ':', data.name],
          ['Alamat', ':', data.address],
          ['No. Telp', ':', data.telp],
          ['No. Identitas/ KTP/SIM', ':', data.identityNumber],
        ],
        startY: lastLine + 0.3,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      lastLine = doc.autoTable.previous.finalY;

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      this.pdfTextPrint(
        doc,
        `Selaku hukum di ${this.$store.getters.userLoggedIn.clinic.name}, dengan ini menyatakan persetujuan :`,
        0.1 + lastLine,
        'left',
        leftMargin,
      );
      this.pdfTextPrint(
        doc,
        '1. PERSETUJUAN UNTUK PERAWATAN DAN PENGOBATAN',
        0.3 + lastLine,
        'left',
        leftMargin + 0.1,
      );
      this.pdfTextPrint(doc, 'a.', 0.6 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.a1,
        leftMargin + tab + indexingSpace,
        0.6 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');
      this.pdfTextPrint(doc, 'b.', 1.45 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.b1,
        leftMargin + tab + indexingSpace,
        1.45 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');
      this.pdfTextPrint(doc, 'c.', 1.77 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.c1,
        leftMargin + tab + indexingSpace,
        1.77 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');

      this.pdfTextPrint(
        doc,
        '2. PERSETUJUAN PELEPASAN INFORMASI',
        2.3 + lastLine,
        'left',
        leftMargin + 0.1,
      );
      this.pdfTextPrint(doc, 'a.', 2.65 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.a2,
        leftMargin + tab + indexingSpace,
        2.65 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');
      this.pdfTextPrint(doc, 'b.', 3.2 + lastLine, 'left', leftMargin + tab);
      doc.text(
        `${this.resource.pointToPrint.b2} ${
          data.visitorAllowed.filter(i => i.name && i.relation).length < 1
            ? '-'
            : ''
        }`,
        leftMargin + tab + indexingSpace,
        3.2 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');

      let lastHeightAdjust = 3.7 + lastLine;
      if (data.restrictedInfo) {
        data.visitorAllowed.forEach((content, index) => {
          if (lastHeightAdjust > bottomMargin) {
            doc.addPage();
            haveAddPage = true;
            lastHeightAdjust = 1.0;
          }
          if (content.name !== '') {
            this.pdfTextPrint(
              doc,
              `${index + 1}. ${content.name}`,
              lastHeightAdjust,
              'left',
              leftMargin + tab + indexingSpace,
            );
            lastHeightAdjust += 0.15;
          }
        });
      }
      lastHeightAdjust += 0.15;
      this.pdfTextPrint(doc, 'c.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.c2,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      if (haveAddPage) {
        lastHeightAdjust += 0.8;
        haveAddPage = false;
      }
      if (lastHeightAdjust > 8.0) {
        doc.addPage();
        lastHeightAdjust = 1.0;
      }
      this.pdfTextPrint(
        doc,
        '3. HAK DAN KEWAJIBAN PASIEN',
        lastHeightAdjust,
        'left',
        leftMargin,
      );
      lastHeightAdjust += 0.3;
      this.pdfTextPrint(doc, 'a.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.a3,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 0.4;
      this.pdfTextPrint(doc, 'b.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.b3,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 0.4;
      this.pdfTextPrint(doc, 'c.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.c3,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 0.6;
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 1;

      let today = new Date();
      today = `${moment(today)
        .locale('id')
        .format('DD MMMM YYYY')}`;
      this.pdfTextPrint(
        doc,
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion}, ${today}`,
        lastHeightAdjust,
        'halfCenterStart',
        // leftMargin,
      );
      lastHeightAdjust += 0.25;

      this.pdfTextPrint(doc, `Dokter`, lastHeightAdjust, 'halfCenterStart');
      this.pdfTextPrint(doc, `Pasien/ Wali`, lastHeightAdjust, 'halfCenterEnd');

      lastHeightAdjust += 1.0;
      this.pdfTextPrint(
        doc,
        data.staff.text,
        lastHeightAdjust,
        'halfCenterStart',
      );
      this.pdfTextPrint(doc, data.name, lastHeightAdjust, 'halfCenterEnd');

      return doc;
    },
    generateInformedConsentDoc(data, patient) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 1.0;
      const justifyWidth = 6.1;

      doc.setProperties({
        title: `Informed Consent no.RM: ${data.rmNumber}`,
      });
      doc.setFontSize(10);
      this.pdfTextPrint(
        doc,
        'Yang bertanda tangan dibawah ini :',
        1.6,
        'left',
        leftMargin,
      );
      doc.autoTable({
        body: [
          ['Nama', ':', data.signerName],
          ['Umur', ':', data.signer !== 'wali' ? data.age : data.waliAge],
          ['Alamat', ':', data.address],
          ['No. Telp/HP', ':', data.phoneNumber],
        ],
        startY: 1.7,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 3 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      this.pdfTextPrint(doc, data.text[0], lastLine + 0.4, 'left', leftMargin);
      doc.autoTable({
        body: [
          ['Nama', ':', patient.meta.name],
          ['No.Kartu ASKES/BPJS', ':', data.bpjsNumber],
          ['Umur', ':', patient.meta.age],
          ['Alamat', ':', patient.meta.address],
        ],
        startY: lastLine + 0.5,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 3 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      lastLine = doc.autoTable.previous.finalY;

      doc.text(data.text[1], leftMargin, lastLine + 0.5, {
        maxWidth: justifyWidth,
        align: 'justify',
        lineHeightFactor: 1.5,
      });
      let statementLineEnter = lastLine + 0.9;
      data.text.slice(2).forEach((item, index) => {
        let lastLineText;
        if (!index) {
          lastLineText = statementLineEnter;
        } else {
          statementLineEnter += 0.5;
          lastLineText = statementLineEnter;
        }
        doc.text(item, leftMargin, lastLineText, {
          maxWidth: justifyWidth,
          align: 'justify',
          lineHeightFactor: 1.5,
        });
      });

      this.pdfTextPrint(
        doc,
        'Dokter',
        statementLineEnter + 2.5,
        'halfCenterStart',
      );
      this.pdfTextPrint(
        doc,
        `Sleman, ${moment()
          .locale('id')
          .format('DD MMMM YYYY')}`,
        statementLineEnter + 2.1,
        'halfCenterEnd',
      );
      this.pdfTextPrint(
        doc,
        `Yang Menyatakan,`,
        statementLineEnter + 2.3,
        'halfCenterEnd',
      );
      this.pdfTextPrint(
        doc,
        `Pasien/Keluarga`,
        statementLineEnter + 2.5,
        'halfCenterEnd',
      );
      this.pdfTextPrint(
        doc,
        data.doctor.text,
        statementLineEnter + 3.5,
        'halfCenterStart',
      );
      this.pdfTextPrint(
        doc,
        data.signerName,
        statementLineEnter + 3.5,
        'halfCenterEnd',
      );
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(12);
      this.pdfTextPrint(doc, 'INFORMED CONSENT', 0.9);
      this.pdfTextPrint(doc, '(FORM PERSETUJUAN TINDAKAN MEDIS)', 1.15);

      return doc;
    },
    generateSscDoc() {
      const doc = new JsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: 'a4',
      });

      //letter head
      const clientData = this.$store.getters.userLoggedIn.clinic;
      const letterData = Constant.client.find(
        item => item.name.toLowerCase() === clientData.name.toLowerCase(),
      );
      const limitText = (text, limit) => {
        let result;
        if (text.join(', ').length > limit) {
          result = text.join(', ').substring(0, limit) + '...';
        } else {
          result = text;
        }
        return result;
      };
      // Letter Head
      doc.setFontSize(18);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(
        doc,
        clientData.name.toUpperCase(),
        0.5,
        'center',
        letterData.landscape.title.offset,
      );
      doc.setFontSize(13);
      doc.setFont('Helvetica', 'normal');
      this.pdfTextPrint(
        doc,
        `${clientData.address} ${
          clientData.postCode
        }. Telepon ${clientData.phone_number || ''}. ${
          clientData.hp_no ? `Hp. ${clientData.hp_no} ` : ''
        }email: ${clientData.email}`,
        0.8,
        'center',
        letterData.landscape.title.offsetData,
        {
          maxWidth: 7, // wrap text content so when it reaches max width, it will makes a new line
          align: 'center',
          lineHeightFactor: 1.35,
        },
      );
      doc.addImage(
        letterData.leftLogo,
        letterData.leftLogoExt,
        letterData.landscape.image.left.x,
        letterData.landscape.image.left.y,
        letterData.landscape.image.left.width,
        letterData.landscape.image.left.height,
      );
      doc.addImage(
        letterData.rightLogo,
        letterData.rightLogoExt,
        letterData.landscape.image.right.x,
        letterData.landscape.image.right.y,
        letterData.landscape.image.right.width,
        letterData.landscape.image.right.height,
      );
      doc.setLineWidth(0.001);
      doc.line(0.8, 1.45, 10.8, 1.45);

      // title
      doc.setFontSize(14);
      this.pdfTextPrint(
        doc,
        '(DAFTAR ISIAN KESELAMATAN PASIEN)',
        2.05,
        'center',
      );
      doc.setFontSize(15);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'SURGICAL SAFETY CHECK LIST', 1.8, 'center');

      // data header
      doc.autoTable({
        body: [
          [
            'No Rekam Medis',
            `: ${this.$store.getters.selectedPatient.rmNumber}`,
            'Nama Lengkap',
            `: ${this.$store.getters.selectedPatient.meta.name}`,
            'Tanggal Lahir',
            `: ${moment(
              this.$store.getters.selectedPatient.meta.birthDate,
              'DD MMMM YYYY',
            ).format('DD/MM/YYYY')}`,
          ],
          [
            'Tindakan',
            `: ${this.signin.action.name}`,
            'Jenis Kelamin',
            `: ${this.$store.getters.selectedPatient.meta.gender} (${
              this.$store.getters.selectedPatient.meta.gender === 'Laki-laki'
                ? 'L'
                : 'P'
            })`,
            'Umur',
            `: ${this.$store.getters.selectedPatient.meta.age} Tahun`,
          ],
          [
            'Tanggal',
            `: ${this.signin.date}`,
            'Jam Tindakan',
            `: ${this.signin.time}`,
          ],
        ],
        margin: { left: 0.8 },
        columnStyles: {
          0: { cellPadding: 0.01, cellWidth: 1.5, fontSize: 9 },
          1: { cellPadding: 0.01, cellWidth: 2, fontSize: 9 },
          2: { cellPadding: 0.01, cellWidth: 1.5, fontSize: 9 },
          3: { cellPadding: 0.01, cellWidth: 2, fontSize: 9 },
          4: { cellPadding: 0.01, cellWidth: 1.5, fontSize: 9 },
          5: { cellPadding: 0.01, cellWidth: 2, fontSize: 9 },
        },
        startY: 2.3,
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      // main data
      doc.autoTable({
        body: [[{ content: 'FASE SIGN IN' }, { content: 'FASE SIGN OUT' }]],
        startY: lastLine + 0.2,
        margin: { left: 0.8 },
        theme: 'plain',
        columnStyles: {
          0: {
            fillColor: [255, 255, 255],
            cellWidth: 5,
            fontSize: 9,
            lineColor: '#00000',
            fontStyle: 'bold',
            halign: 'center',
            lineWidth: {
              right: 0.001,
              left: 0.001,
              top: 0.001,
              bottom: 0.01,
            },
          },
          1: {
            fillColor: [255, 255, 255],
            cellWidth: 5,
            fontSize: 9,
            lineColor: '#00000',
            fontStyle: 'bold',
            halign: 'center',
            lineWidth: {
              right: 0.001,
              left: 0.001,
              top: 0.001,
              bottom: 0.01,
            },
          },
        },
      });
      lastLine = doc.autoTable.previous.finalY;
      doc.autoTable({
        body: [
          [
            '1. Apakah pasien telah dikonfirmasi nama, sisi operasi, dan informed consent?',
            '            Sudah                     Belum',
            '1. Apakah nama tindakan prosedur telah dikonfirmasi?',
            '            Sudah                     Belum',
          ],
          [
            '2. Apakah alat anestesi dan pengobatan telah diperiksa lengkap?',
            '            Sudah                     Belum',
            '2. Apakah perhitungan jumlah instrumen, kassa dan jarum sudah tepat?',
            '            Sudah                     Belum',
          ],
          [
            '3. Apakah area operasi sudah ditandai?',
            '            Sudah                     Belum',
            '3. Dari tindakan yang dilakukan, Apakah membutuhkan pengambilan specimen?',
            '            Sudah                     Belum',
          ],
          [
            { content: '4. Keadaan pasien yang berisiko?', rowSpan: 3 },
            {
              content:
                '            Tidak Memiliki Keadaan Berisiko\n\n            Kesulitan Jalan Nafas dan Risiko                       Aspirasi\n\n            Risiko Pendarahan > 500 cc pada                      Dewasa dan Anak 7 cc/kg BB\n\n            Penyakit Infeksius',
              rowSpan: 3,
            },
            '4. Apakah specimen sudah diberi label (nama pasien, nomor register)?',
            '            Sudah                     Belum',
          ],
          [
            '5. Apakah ada kesulitan dalam memakai peralatan medis?',
            '             Ya                          Tidak',
          ],
          [
            '6. Apakah ada perhatian khusus untuk pemulihan dan rencana perawatan pasien?',
            '             Ya                          Tidak',
          ],
          ['5. Riwayat Alergi', limitText(this.signin.allergy, 40)],
        ],
        startY: lastLine,
        margin: { left: 0.8 },
        theme: 'plain',
        columnStyles: {
          0: {
            fillColor: [255, 255, 255],
            cellWidth: 2.5,
            fontSize: 9,
            lineColor: '#00000',
            lineWidth: { right: 0.001, left: 0.001 },
          },
          1: {
            cellWidth: 2.5,
            fontSize: 8,
            lineColor: '#00000',
            cellPadding: { bottom: 0.1, left: 0.2, top: 0.07 },
            lineWidth: { right: 0.001, left: 0.001 },
          },
          2: {
            cellWidth: 2.5,
            fontSize: 9,
            lineColor: '#00000',
            lineWidth: { left: 0.001 },
          },
          3: {
            cellWidth: 2.5,
            fontSize: 8,
            lineColor: '#00000',
            lineWidth: { right: 0.001, left: 0.01 },
          },
        },
      });
      lastLine = doc.autoTable.previous.finalY;
      const rows = doc.autoTable.previous.body;
      // footer
      doc.autoTable({
        body: [
          [
            { content: `Perawat\n\n\n\n${this.signin.staff.name}` },
            { content: `Dokter Pemeriksa\n\n\n\n${this.signin.doctor.name}` },
          ],
        ],
        startY: lastLine,
        margin: { left: 0.8 },
        theme: 'plain',
        columnStyles: {
          0: {
            fillColor: [255, 255, 255],
            cellWidth: 5,
            fontSize: 9,
            lineColor: '#00000',
            halign: 'center',
            lineWidth: { right: 0.001, left: 0.001, top: 0.001, bottom: 0.01 },
          },
          1: {
            fillColor: [255, 255, 255],
            cellWidth: 5,
            fontSize: 9,
            lineColor: '#00000',
            halign: 'center',
            lineWidth: { right: 0.001, left: 0.001, top: 0.001, bottom: 0.01 },
          },
        },
      });
      const checkBoxDecider = data => {
        if (typeof data !== 'boolean') return checkboxFalse;
        return data ? checkboxTrue : checkboxFalse;
      };
      const checkboxPaddingSignIn = {
        top: 0.05,
        left1: 0.3,
        left2: 1.25,
      };
      const checkboxPaddingSignOut = {
        top: 0.05,
        left1: 0.2,
        left2: 1.15,
      };
      // sign in no 1
      doc.addImage(
        checkBoxDecider(this.signin.dataConfirmation),
        'PNG',
        rows[0].cells[1].x + checkboxPaddingSignIn.left1,
        rows[0].cells[1].y + checkboxPaddingSignIn.top,
        0.16,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signin.dataConfirmation),
        'PNG',
        rows[0].cells[1].x + checkboxPaddingSignIn.left2,
        rows[0].cells[1].y + checkboxPaddingSignIn.top,
        0.15,
        0.15,
      );
      // sign in no 2
      doc.addImage(
        checkBoxDecider(this.signin.isAnestheticDeviceComplitelyChecked),
        'PNG',
        rows[1].cells[1].x + checkboxPaddingSignIn.left1,
        rows[1].cells[1].y + checkboxPaddingSignIn.top,
        0.16,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signin.isAnestheticDeviceComplitelyChecked),
        'PNG',
        rows[1].cells[1].x + checkboxPaddingSignIn.left2,
        rows[1].cells[1].y + checkboxPaddingSignIn.top,
        0.15,
        0.15,
      );
      // sign in no 3
      doc.addImage(
        checkBoxDecider(this.signin.isOperatingAreaMarked),
        'PNG',
        rows[2].cells[1].x + checkboxPaddingSignIn.left1,
        rows[2].cells[1].y + checkboxPaddingSignIn.top,
        0.16,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signin.isOperatingAreaMarked),
        'PNG',
        rows[2].cells[1].x + checkboxPaddingSignIn.left2,
        rows[2].cells[1].y + checkboxPaddingSignIn.top,
        0.15,
        0.15,
      );
      // sign in no 4
      doc.addImage(
        checkBoxDecider(!this.signin.isHasRisk),
        'PNG',
        rows[3].cells[1].x + checkboxPaddingSignIn.left1,
        rows[3].cells[1].y + checkboxPaddingSignIn.top,
        0.16,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(
          this.signin.isHasRisk &&
            this.signin.risk.includes(
              'Kesulitan Jalan Nafas dan Risiko Aspirasi',
            ),
        ),
        'PNG',
        rows[3].cells[1].x + checkboxPaddingSignIn.left1,
        rows[3].cells[1].y + checkboxPaddingSignIn.top + 0.3,
        0.16,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(
          this.signin.isHasRisk &&
            this.signin.risk.includes(
              'Risiko Pendarahan > 500 cc pada Dewasa dan Anak 7 cc/kg BB',
            ),
        ),
        'PNG',
        rows[3].cells[1].x + checkboxPaddingSignIn.left1,
        rows[3].cells[1].y + checkboxPaddingSignIn.top + 0.7,
        0.16,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(
          this.signin.isHasRisk &&
            this.signin.risk.includes('Penyakit Infeksius'),
        ),
        'PNG',
        rows[3].cells[1].x + checkboxPaddingSignIn.left1,
        rows[3].cells[1].y + checkboxPaddingSignIn.top + 1,
        0.16,
        0.15,
      );

      // sign out no 1
      doc.addImage(
        checkBoxDecider(this.signout.procedureActionName),
        'PNG',
        rows[0].cells[3].x + checkboxPaddingSignOut.left1,
        rows[0].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signout.procedureActionName),
        'PNG',
        rows[0].cells[3].x + checkboxPaddingSignOut.left2,
        rows[0].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      // sign out no 2
      doc.addImage(
        checkBoxDecider(this.signout.instrument),
        'PNG',
        rows[1].cells[3].x + checkboxPaddingSignOut.left1,
        rows[1].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signout.instrument),
        'PNG',
        rows[1].cells[3].x + checkboxPaddingSignOut.left2,
        rows[1].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      // sign out no 3
      doc.addImage(
        checkBoxDecider(this.signout.speciment),
        'PNG',
        rows[2].cells[3].x + checkboxPaddingSignOut.left1,
        rows[2].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signout.speciment),
        'PNG',
        rows[2].cells[3].x + checkboxPaddingSignOut.left2,
        rows[2].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      // sign out no 4
      doc.addImage(
        checkBoxDecider(
          this.signout.speciment ? this.signout.isSpecimentLabeled : null,
        ),
        'PNG',
        rows[3].cells[3].x + checkboxPaddingSignOut.left1,
        rows[3].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(
          this.signout.speciment &&
            typeof this.signout.isSpecimentLabeled === 'boolean'
            ? !this.signout.isSpecimentLabeled
            : null,
        ),
        'PNG',
        rows[3].cells[3].x + checkboxPaddingSignOut.left2,
        rows[3].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      // sign out no 5
      doc.addImage(
        checkBoxDecider(this.signout.isAnyDifficulties),
        'PNG',
        rows[4].cells[3].x + checkboxPaddingSignOut.left1,
        rows[4].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signout.isAnyDifficulties),
        'PNG',
        rows[4].cells[3].x + checkboxPaddingSignOut.left2,
        rows[4].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      // sign out no 6
      doc.addImage(
        checkBoxDecider(this.signout.isSpecialAttention),
        'PNG',
        rows[5].cells[3].x + checkboxPaddingSignOut.left1,
        rows[5].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );
      doc.addImage(
        checkBoxDecider(!this.signout.isSpecialAttention),
        'PNG',
        rows[5].cells[3].x + checkboxPaddingSignOut.left2,
        rows[5].cells[3].y + checkboxPaddingSignOut.top,
        0.15,
        0.15,
      );

      return doc;
    },
  },
};
