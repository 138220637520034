<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Detail Data Staff</h3>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container fluid class="container-layout">
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">NIP</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.nip"
                    placeholder="Masukkan NIP"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Lengkap</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.name"
                    placeholder="Masukkan Nama Lengkap"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">NIK</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.nik"
                    placeholder="Masukkan NIK"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label for="name">Jenis Kelamin</label>
                  </div>
                  <v-radio-group disabled dense v-model="input.gender" row>
                    <v-radio label="Laki-laki" :value="true"></v-radio>
                    <v-radio label="Perempuan" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-date">
                  <div class="detail-input__label">
                    <label for="Tanggal Lahir">Tanggal Lahir</label>
                  </div>
                  <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="input.birth.date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        disabled
                        class="detail-input__input-text"
                        v-model="input.birth.date"
                        placeholder="YYYY/MM/DD"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="input.birth.date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(input.birth.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Tempat Lahir</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.birth.city"
                    placeholder="Masukkan Tempat Lahir"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Alamat</label>
                  </div>
                  <v-textarea
                    dense
                    disabled
                    v-model="input.address"
                    class="detail-input__input-text"
                    outlined
                    name="input-7-4"
                  ></v-textarea>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">No Telepon</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.phone_no"
                    placeholder="Masukkan No Telepon"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="agama">Agama</label>
                  </div>
                  <v-select
                    dense
                    disabled
                    placeholder="Pilih Agama"
                    class="detail-input__input-text"
                    v-model="input.religion"
                    :items="resource.religion"
                  ></v-select>
                </div>
              </v-col>
              <v-col md="2"></v-col>
              <v-col md="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="golongandarah">Golongan Darah</label>
                  </div>
                  <v-select
                    dense
                    disabled
                    placeholder="Pilih Golongan Darah"
                    class="detail-input__input-text"
                    v-model="input.blood_type"
                    :items="resource.blood_type"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">STR</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    placeholder="Masukkan STR"
                    class="detail-input__input-text"
                    required
                    v-model="input.str"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Batas STR</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    placeholder="Masukkan Batas STR"
                    class="detail-input__input-text"
                    v-model="input.end_str"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">SIP</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.sip"
                    placeholder="Masukkan SIP"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Batas SIP</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    placeholder="Masukkan Balas SIP"
                    class="detail-input__input-text"
                    v-model="input.end_sip"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">SIK</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.sik"
                    placeholder="Masukkan SIK"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Batas SIK</label>
                  </div>

                  <v-text-field
                    dense
                    disabled
                    v-model="input.end_sik"
                    placeholder="Masukkan SIK"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="specialis">Pilih Specialis</label>
                  </div>
                  <v-select
                    disabled
                    placeholder="Pilih Specialis"
                    dense
                    class="detail-input__input-text"
                    v-model="input.specialist"
                    :items="resource.specialist"
                  ></v-select>
                </div>
                <div class="detail-date">
                  <div class="detail-input__label">
                    <label for="name">Tanggal Mulai Praktek</label>
                  </div>
                  <v-menu
                    dense
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="input.start_practice"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        disabled
                        class="detail-input__input-text"
                        v-model="input.start_practice"
                        placeholder="YYYY/MM/DD"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="input.start_practice"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(input.start_practice)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="5">
                <v-card-title class="px-0">
                  <span class="font-weight-bold black--text">
                    Rekening Bank
                  </span>
                </v-card-title>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="namabank">Nama Bank</label>
                  </div>
                  <v-autocomplete
                    dense
                    placeholder="Pilih Bank"
                    readonly
                    class="detail-input__input-text"
                    v-model="input.bank.id_bank"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col></v-col>
              <v-col md="5" class="d-flex align-end">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nomor Rekening</label>
                  </div>
                  <v-text-field
                    dense
                    readonly
                    type="number"
                    v-on:keypress="isNumber($event)"
                    v-model="input.bank.account"
                    placeholder="Masukkan Rekening"
                    class="detail-input__input-text"
                    required
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="5" class="pb-0 pl-0"
                ><v-card-title class="px-0 pb-0 mb-2">
                  <span class="font-weight-bold black--text">Data Role</span>
                </v-card-title></v-col
              >
              <v-col></v-col>
              <v-col md="5"></v-col>
              <v-row
                v-for="(role, index) in input.roles"
                :key="'role-' + index"
                style="border-bottom: 1px solid rgb(224, 224, 224)"
                class="mb-2"
              >
                <v-col md="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="Poli">Cabang</label>
                    </div>
                    <v-autocomplete
                      dense
                      readonly
                      :items="resource.branches"
                      v-model="role.branch"
                      placeholder="Pilih Cabang"
                      class="detail-input__input-text"
                      hide-selected
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="Poli">Unit / Poli</label>
                    </div>
                    <v-autocomplete
                      readonly
                      dense
                      :items="resource.units"
                      placeholder="Pilih Beberapa Poli"
                      class="detail-input__input-text"
                      v-model="role.unit"
                      multiple
                      hide-selected
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="Role">Role</label>
                    </div>
                    <v-autocomplete
                      dense
                      v-model="role.role"
                      :items="resource.roles"
                      placeholder="Pilih Role"
                      class="detail-input__input-text"
                      readonly
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col md="2"></v-col>
                <v-col md="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Detail</label>
                    </div>

                    <v-text-field
                      dense
                      readonly
                      v-model="role.detail"
                      placeholder="Masukkan Detail"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-radio mb-2">
                    <div class="detail-input__label">
                      <label class="required" for="name">Status</label>
                    </div>
                    <v-radio-group dense readonly v-model="role.status" row>
                      <v-radio label="Aktif" :value="true"></v-radio>
                      <v-radio label="Tidak Aktif" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="100" text class="text-none" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
const axios = require('axios');

export default {
  name: 'StaffDetail',
  props: {
    staffDetail: {
      type: Object,
    },
  },
  data() {
    return {
      isAdmin: false,
      isLoading: false,
      showPassword: false,
      password: 'Password',
      date: null,
      search: null,
      menu1: false,
      menu2: false,
      startPractice_menu: false,
      resource: {
        status: [
          { text: 'Tersedia', value: true },
          { text: 'Tidak Tersedia', value: false },
        ],
        religion: [
          { text: 'Budha', value: 'budha' },
          { text: 'Hindu', value: 'hindu' },
          { text: 'Islam', value: 'islam' },
          { text: 'Katolik', value: 'katolik' },
          { text: 'Konghucu', value: 'konghucu' },
          { text: 'Kristen', value: 'kristen' },
        ],
        blood_type: [
          { text: 'A', value: 'a' },
          { text: 'B', value: 'b' },
          { text: 'AB', value: 'ab' },
          { text: 'O', value: 'o' },
          { text: 'A Rh+', value: 'a rh+' },
          { text: 'A Rh-', value: 'a rh-' },
          { text: 'AB Rh+', value: 'ab rh+' },
          { text: 'AB Rh-', value: 'ab rh-' },
          { text: 'B Rh+', value: 'b rh+' },
          { text: 'B Rh-', value: 'b rh-' },
          { text: 'O Rh+', value: 'o rh+' },
          { text: 'O Rh-', value: 'o rh-' },
        ],
        roles: [],
        units: [],
        specialist: [],
        bank: [],
      },
      input: {
        address: '',
        bank: {
          id_bank: '',
          account: '',
        },
        birth: {
          city: '',
          date: '',
        },
        roles: [
          {
            branch: '',
            unit: [],
            role: '',
            detail: '',
            status: '',
          },
        ],
        blood_type: '',
        end_sik: '',
        end_sip: '',
        end_str: '',
        gender: '',
        name: '',
        nik: '',
        phone_no: '',
        religion: '',
        sik: '',
        sip: '',
        specialist: '',
        start_practice: '',
        str: '',
        nip: '',
        id: '',
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllRole();
      this.getAllBank();
      this.getUnit();
      this.getAllSpecialize();
      this.changeProps();
      this.getBranch();
    });
  },
  methods: {
    getBranch() {
      axios
        .get(Constant.apiUrl.concat('/branch'))
        .then(response => {
          if (response.status === 200) {
            const { data } = response.data;
            this.resource.branches = data.map(branch => {
              return {
                value: branch._id.toString(),
                text: branch.name,
              };
            });
          }
        })
        .catch(() => {
          this.resource.branch = [];
        });
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    updateData() {
      this.$emit('update-data');
    },
    changeProps() {
      this.input = {
        ...this.staffDetail,
        roles: this.staffDetail.role.map(item => {
          return {
            branch: {
              text: item.id_branch.name,
              value: item.id_branch._id,
            },
            unit: item.unit.map(i => {
              return {
                text: i.name,
                value: i.id_unit,
              };
            }),
            role: item.role,
            detail: item.detail,
            status: item.status,
          };
        }),
      };
    },
    getAllRole() {
      axios
        .get(`${Constant.apiUrl}/master/staff/role`)
        .then(response => {
          const staffRoleDataGet = response.data.data;
          this.resource.roles = staffRoleDataGet.map(role => {
            return {
              text: role.description,
              value: role.name,
            };
          });
        })
        .catch(() => {
          this.resource.roles = [];
        })
        .finally(() => (this.isLoading = false));
    },
    getAllBank() {
      axios.get(`${Constant.apiUrl}/master/bank`).then(response => {
        const getBank = response.data.data;
        this.resource.bank = getBank.map(bank => {
          return {
            value: bank._id,
            text: bank.name,
          };
        });
      });
    },
    getAllSpecialize() {
      axios.get(`${Constant.apiUrl}/master/specialize`).then(response => {
        const getSpecialize = response.data.data;

        this.resource.specialist = getSpecialize.map(specialize => {
          return specialize.name;
        });
      });
    },
    getUnit() {
      axios.get(`${Constant.apiUrl}/master/unit`).then(response => {
        const unitData = response.data.data;
        this.resource.units = unitData.map(unit => {
          return {
            value: unit._id,
            text: unit.name,
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  display: grid;
  grid-template-columns: 150px 250px;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
.container-layout {
  height: 608px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
