<template>
  <div>
    <v-layout pa-4 align-center>
      <v-flex xs12 md6 class="text-left">
        <h3>Permintaan Obat dan Alkes</h3>
      </v-flex>
      <v-flex xs3>
        <v-menu
          v-model="datePicker"
          dense
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              outlined
              dense
              class="mr-4"
              prepend-inner-icon="mdi-calendar"
              append-icon="mdi-chevron-down"
              hide-details="auto"
              placeholder="Tanggal Permintaan Obat"
              @change="getAllPatient()"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            width="400"
            v-model="selectedDate"
            no-title
            scrollable
            @input="datePicker = false"
          >
            <!-- <v-spacer></v-spacer>
            <v-btn text color="primary">
              OK
            </v-btn> -->
          </v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs1>
        <router-link :to="routeBack" active>
          <font-awesome-icon style="color: #3f484a" icon="home" />
        </router-link>
        <v-btn @click="refresh()" icon>
          <font-awesome-icon
            icon="sync-alt"
            :class="{ refresh: isRefresh }"
            class="ml-2"
          />
        </v-btn>
      </v-flex>
      <v-flex xs4 md4>
        <v-text-field
          solo
          dense
          flat
          label="Cari Pasien"
          prepend-inner-icon="mdi-magnify"
          class="search"
          v-model="search"
          background-color="grey lighten-3"
          hide-details="false"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <div class="header">
      <v-tabs v-model="tab" grow slider-color="blue">
        <v-tab key="service">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Pelayanan</label
          >
        </v-tab>
        <v-tab key="takeHomeDrug">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Obat dibawa pulang</label
          >
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item key="service">
        <v-data-table
          :headers="headers"
          :items="data.service"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          class="px-2"
          :page.sync="servicePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{
                  (servicePage - 1) * itemsPerRow +
                    data.service.indexOf(item) +
                    1
                }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Resep</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item key="takeHomeDrug">
        <v-data-table
          :headers="headers"
          :items="data.takehome"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          class="px-2"
          :page.sync="takeHomePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{
                  (takeHomePage - 1) * itemsPerRow +
                    data.takehome.indexOf(item) +
                    1
                }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-1">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Resep</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-dialog>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="icon"
                        slot="activator"
                        v-bind="attrs"
                        v-on="on"
                      ></div>
                    </template>
                  </v-dialog>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="servicePage"
            :length="serviceLengthPage"
            :total-visible="7"
            v-if="tab === 0"
            @input="getAllPatient()"
          ></v-pagination>
          <v-pagination
            v-model="takeHomePage"
            :length="takeHomeLengthPage"
            :total-visible="7"
            v-if="tab === 1"
            @input="getAllPatient()"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-footer>

    <v-dialog v-model="dialog" persistent :fullscreen="selectedDialog === 2">
      <SocialData
        v-if="selectedDialog === 1 && dialog"
        :medical-record="selectedPatient.rm_number"
        @close-form="dialog = false"
      ></SocialData>
      <drug-request-form
        v-else-if="selectedDialog === 2 && dialog"
        :patient="selectedPatient"
        :isTakeHome="tab ? true : false"
        @close-form="
          dialog = false;
          getAllPatient();
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import Constant from '@/const';
import DrugRequestForm from './DrugToolsRequest/DrugRequestForm.vue';
import jwtMixin from '@/mixin/jwtMixin';
import roleMixin from '@/mixin/roleMixin';
import SocialData from '../../SharedComponent/SocialData.vue';

const _ = require('lodash');
const axios = require('axios');
const moment = require('moment-timezone');

export default {
  name: 'DrugToolsRequest',
  mixins: [jwtMixin, roleMixin],
  components: {
    DrugRequestForm,
    SocialData,
  },
  data() {
    return {
      tab: 1,
      expanded: [],
      search: '',
      date: moment().format('DD-MM-YYYY'),
      datePicker: false,
      isRefresh: false,
      selectedDate: '',
      headers: [
        { text: 'No', align: 'start', value: 'no' },
        { text: 'Nama', value: 'name' },
        { text: 'No. RM.', value: 'rm_number' },
        { text: 'Tipe Penjaminan', value: 'assurance' },
        { text: 'Unit', value: 'unit' },
        { text: 'Nama Dokter', value: 'doctor' },
      ],
      data: {
        service: [],
        takehome: [],
      },
      isExpanded: false,
      dialog: false,
      isLoading: false,
      servicePage: 1,
      takeHomePage: 1,
      rowsPerPage: 5,
      serviceLengthPage: 0,
      takeHomeLengthPage: 0,
      selectedDialog: null,
      selectedPatient: null,
    };
  },
  watch: {
    tab() {
      this.getAllPatient();
    },
    search() {
      this.searchData(this);
    },
    selectedDate() {
      this.date = moment(this.selectedDate).format('DD-MM-YYYY');
      this.getAllPatient();
    },
    date() {
      if (!this.date) {
        this.date = '';
        this.getAllPatient();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeBack() {
      return `/farmasi/${this.routeController}`;
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat inap';

        case 'rawat-jalan':
          return 'rawat jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '58vh';
          break;
        case 'lg':
          height = '60vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.takeHomePage = 1;
      v.getAllPatient();
    }, 650),
    refresh() {
      this.search = '';
      this.date = '';
      this.isRefresh = true;
      this.getAllPatient();
    },
    getAllPatient() {
      this.isLoading = true;
      const category = 'requested';
      const itemCount = this.itemsPerRow;
      const halamanService = this.servicePage;
      const halamanTakeHome = this.takeHomePage;
      if (this.tab === 0) {
        this.getService(itemCount, halamanService, category); // get service
        return;
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/pharmacy/category/${category}?page=${halamanTakeHome}&itemCount=${itemCount}&search=${this
                .search || ''}&sort=&date=${this.date ||
                ''}&type=${this.routeName.toLowerCase()}&takeHome=1`,
            ),
          )
          .then(response => {
            const patient = response.data.data;
            const dataLength = response.data.length;
            this.takeHomeLengthPage = Math.ceil(dataLength / itemCount);
            this.data.takehome = patient.map(data => {
              return {
                id: data._id.toString(),
                name: data.name,
                rm_number: data.medical_record_number,
                assurance: data.assurance.toUpperCase(),
                unit: data.unit,
                gender: data.gender ? 'Laki-laki' : 'Perempuan',
                age: data.age,
                doctor: data.doctor,
                officer: data.staff_order,
              };
            });
            this.isLoading = false;
          })
          .catch(error => {
            if (error) {
              this.data.takehome = [];
              this.takeHomeLengthPage = 1;
              this.isLoading = false;
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.isRefresh = false;
          });
      });
    },
    async getService(itemCount, page, category) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/pharmacy/category/${category}?page=${page}&itemCount=${itemCount}&search=${this
              .search || ''}&sort=&date=${this.date ||
              ''}&type=${this.routeName.toLowerCase()}&takeHome=0`,
          ),
        );
        const patient = response.data.data;
        const dataLength = response.data.length;
        this.serviceLengthPage = Math.ceil(dataLength / itemCount);
        this.data.service = patient.map(data => {
          return {
            id: data._id.toString(),
            name: data.name,
            rm_number: data.medical_record_number,
            assurance: data.assurance.toUpperCase(),
            unit: data.unit,
            gender: data.gender ? 'Laki-laki' : 'Perempuan',
            age: data.age,
            doctor: data.doctor,
            officer: data.staff_order,
          };
        });
        this.isLoading = false;
      } catch {
        this.data.service = [];
        this.serviceLengthPage = 1;
        this.isLoading = false;
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllPatient();
      this.interval = setInterval(this.getAllPatient, 60000);
    },
    onClick(item, val) {
      this.selectedDialog = val;
      this.selectedPatient = item;
      this.dialog = true;
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
  padding: 10px 25px 25px 25px;
}

.header {
  border-bottom: 1px solid #e7e7e7;
}
.icon {
  float: right;
  padding-left: 20px;
}

.expand {
  border: none;
  background-color: white;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
.pagination {
  position: absolute;
  bottom: 3vh;
}
</style>
