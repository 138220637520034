<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-toolbar flat height="56">
        <v-row no-gutters align="center">
          <v-col cols="6" class="text-left">
            <h2>Buku Besar Pembantu</h2>
          </v-col>
          <v-col cols="1" class="text-right">
            <router-link to="/keuangan/akuntansi">
              <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
            </router-link>
          </v-col>
          <v-col class="" cols="1">
            <v-btn depressed color="primary" class="text-none" small
              >Export</v-btn
            >
          </v-col>
          <v-col cols="3" class="pl-5">
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left pl-3">
            <v-btn icon @click="refreshAndAutoUpdate()"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar>
    </v-row>
    <v-tabs v-model="tabs" grow height="32">
      <v-tab class="text-none font-weight-bold">Rincian Utang</v-tab>
      <v-tab class="text-none font-weight-bold">Rincian Piutang</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-row no-gutters class="px-5 py-2" align="center">
          <v-col cols="4" class="pr-5">
            <div class="header-style">
              <label class="font-weight-bold body-2">Tipe Kreditur</label>
              <v-combobox
                placeholder="Pilih Tipe Kreditur"
                hide-details="false"
                :items="resource.crediturType"
                item-text="text"
                return-object
                dense
                v-model="filter.crediturType"
                @change="crediturName()"
              ></v-combobox>

              <label class="font-weight-bold body-2">Nama</label>
              <v-combobox
                placeholder="Pilih Nama"
                hide-details="false"
                :items="resource.creditur"
                item-text="name"
                dense
                v-model="filter.crediturName"
                @change="getSubsLedgerDebt()"
              ></v-combobox>
            </div>
          </v-col>
          <v-col cols="4" class="pr-5">
            <div class="header-style">
              <label class="font-weight-bold body-2">Kode Akun</label>
              <v-combobox
                placeholder="Pilih Kode Akun"
                hide-details="false"
                :items="resource.accountCode"
                item-text="account_code"
                dense
                :loading="isLoading"
                v-model="filter.account"
              ></v-combobox>

              <label class="font-weight-bold body-2">Nama Akun</label>
              <v-combobox
                placeholder="Pilih Nama Akun"
                hide-details="false"
                :items="resource.accountCode"
                item-text="name"
                dense
                :loading="isLoading"
                v-model="filter.account"
              ></v-combobox>
            </div>
          </v-col>
          <v-col cols="4" class="pl-5">
            <div style="display: flex">
              <v-menu
                ref="menuDebt"
                v-model="menuDebt"
                :close-on-content-click="false"
                :return-value.sync="dateDebt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateDebt"
                    placeholder="Pilih Tanggal"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="false"
                    :disabled="!filter.crediturName"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateDebt"
                  @input="
                    $refs.menuDebt.save(dateDebt);
                    menuDebt = false;
                  "
                />
              </v-menu>

              <v-icon>mdi-minus</v-icon>

              <v-menu
                ref="menu2Debt"
                v-model="menu2Debt"
                :close-on-content-click="false"
                :return-value.sync="endDateDebt"
                transition="scale-transition"
                offset-y
                nudge-left="130"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateDebt"
                    placeholder="Pilih Tanggal"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="false"
                    :disabled="!filter.crediturName"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDateDebt"
                  @input="
                    $refs.menu2Debt.save(endDateDebt);
                    menu2Debt = false;
                  "
                />
              </v-menu>
            </div>
            <div>
              <v-combobox
                label="Urutkan dari unit"
                outlined
                dense
                multiple
                small-chips
                class="pt-1"
                hide-details="false"
                hide-selected
                :items="resource.unit"
                deletable-chips
                v-model="filter.unit"
                @change="sortUnit()"
                :disabled="!filter.crediturName"
              ></v-combobox>
            </div>
          </v-col>
        </v-row>
        <div
          class="text-left"
          v-if="filter.crediturType && filter.crediturName"
        >
          <v-expansion-panels multiple flat hover v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <label class="primary--text font-weight-bold body-2"
                  >Tabel Utama</label
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="table.headers"
                  :items="table.debtItems"
                  :items-per-page="table.debtItems.length"
                  hide-default-footer
                >
                  <template v-slot:[`item.no`]="{ item }">
                    <td>{{ table.debtItems.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:footer>
                    <div class="footer-total">
                      <label class="text-right font-weight-bold px-5"
                        >TOTAL</label
                      >
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          table.debtItems.reduce(
                            (a, { debit }) => a + debit,
                            0,
                          ),
                        )
                      }}</label>
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          table.debtItems.reduce(
                            (a, { credit }) => a + credit,
                            0,
                          ),
                        )
                      }}</label>
                      <label></label>
                    </div>
                    <div class="footer-balance py-2">
                      <label></label>
                      <label class="font-weight-bold"
                        >Saldo Akhir Periode</label
                      >
                      <label class="font-weight-bold">0,00</label>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- <template v-for="(unit, idx) in table.unitItems"> -->
          <v-expansion-panels multiple flat hover>
            <v-expansion-panel
              v-for="(unit, idx) in table.unitItems"
              v-model="panels"
              :key="'unitItem-' + idx"
            >
              <v-expansion-panel-header class="py-0">
                <label class="primary--text font-weight-bold body-2">{{
                  unit.unit
                }}</label>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="table.headers"
                  :items="unit.items"
                  :items-per-page="unit.items.length"
                  hide-default-footer
                >
                  <template v-slot:[`item.no`]="{ item }">
                    <td>{{ unit.items.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:footer>
                    <div class="footer-total">
                      <label class="text-right font-weight-bold px-5"
                        >TOTAL</label
                      >
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          unit.items.reduce((a, { debit }) => a + debit, 0),
                        )
                      }}</label>
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          unit.items.reduce((a, { credit }) => a + credit, 0),
                        )
                      }}</label>
                      <label></label>
                    </div>
                    <div class="footer-balance py-2">
                      <label></label>
                      <label class="font-weight-bold"
                        >Saldo Akhir Periode</label
                      >
                      <label class="font-weight-bold">0,00</label>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- </template> -->
        </div>
        <v-row no-gutters v-else justify="center" class="pa-5">
          <label class="grey--text body-2"
            >Masukkan data filter terlebih dahulu</label
          >
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row no-gutters class="px-5 py-3" align="center">
          <v-col cols="4" class="pr-5">
            <div class="header-style">
              <label class="font-weight-bold body-2">Tipe Debitur</label>
              <v-combobox
                placeholder="Pilih Tipe Debitur"
                hide-details="false"
                :items="resource.crediturType"
                item-text="text"
                return-object
                dense
                v-model="filter.debiturType"
                @change="debiturName()"
              ></v-combobox>

              <label class="font-weight-bold body-2">Nama</label>
              <v-combobox
                placeholder="Pilih Nama"
                hide-details="false"
                :items="resource.debitur"
                item-text="name"
                dense
                v-model="filter.debiturName"
                @change="getSubsLedgerReceiveable"
              ></v-combobox>
            </div>
          </v-col>
          <v-col cols="4" class="pr-5">
            <div class="header-style">
              <label class="font-weight-bold body-2">Kode Akun</label>
              <v-combobox
                placeholder="Pilih Kode Akun"
                hide-details="false"
                :items="resource.accountCode"
                :loading="isLoading"
                dense
              ></v-combobox>
              <label class="font-weight-bold body-2">Nama Akun</label>
              <v-combobox
                placeholder="Pilih Nama Akun"
                hide-details="false"
                :items="resource.accountName"
                :loading="isLoading"
                dense
              ></v-combobox>
            </div>
          </v-col>
          <v-col cols="4" class="pl-5">
            <div style="display: flex">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    placeholder="Pilih Tanggal"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="false"
                    :disabled="!filter.debiturName"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="
                    $refs.menu.save(date);
                    menu = false;
                  "
                />
              </v-menu>

              <v-icon>mdi-minus</v-icon>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                nudge-left="130"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    placeholder="Pilih Tanggal"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="false"
                    :disabled="!filter.debiturName"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="
                    $refs.menu2.save(endDate);
                    menu2 = false;
                  "
                />
              </v-menu>
            </div>
            <div>
              <v-combobox
                label="Urutkan dari unit"
                outlined
                multiple
                small-chips
                dense
                class="pt-1"
                hide-details="false"
                hide-selected
                :items="resource.unit"
                :disabled="!filter.debiturName"
                @change="sortUnit()"
              ></v-combobox>
            </div>
          </v-col>
        </v-row>
        <div class="text-left" v-if="filter.debiturType && filter.debiturName">
          <v-expansion-panels multiple flat hover v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <label class="primary--text font-weight-bold body-2"
                  >Tabel Utama</label
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="table.headers"
                  :items="table.receiveableItems"
                  :items-per-page="table.receiveableItems.length"
                  hide-default-footer
                >
                  <template v-slot:[`item.no`]="{ item }">
                    <td>{{ table.receiveableItems.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:[`item.debit`]="{ item }">
                    <td>{{ formattedCurrency(item.debit) }}</td>
                  </template>
                  <template v-slot:[`item.credit`]="{ item }">
                    <td>{{ formattedCurrency(item.credit) }}</td>
                  </template>
                  <template v-slot:footer>
                    <div class="footer-total">
                      <label class="text-right font-weight-bold px-5"
                        >TOTAL</label
                      >
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          table.receiveableItems.reduce(
                            (a, { debit }) => a + debit,
                            0,
                          ),
                        )
                      }}</label>
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          table.receiveableItems.reduce(
                            (a, { credit }) => a + credit,
                            0,
                          ),
                        )
                      }}</label>
                      <label></label>
                    </div>
                    <div class="footer-balance py-2">
                      <label></label>
                      <label class="font-weight-bold"
                        >Saldo Akhir Periode</label
                      >
                      <label class="font-weight-bold">0,00</label>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels multiple flat hover>
            <v-expansion-panel
              v-for="(unit, idx) in table.unitItemsReceivable"
              :key="'unitItem-' + idx"
              v-model="panels"
            >
              <v-expansion-panel-header class="py-0">
                <label class="primary--text font-weight-bold body-2">{{
                  unit.unit
                }}</label>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="table.headers"
                  :items="unit.items"
                  :items-per-page="unit.items.length"
                  hide-default-footer
                >
                  <template v-slot:[`item.no`]="{ item }">
                    <td>{{ unit.items.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:footer>
                    <div class="footer-total">
                      <label class="text-right font-weight-bold px-5"
                        >TOTAL</label
                      >
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          unit.items.reduce((a, { debit }) => a + debit, 0),
                        )
                      }}</label>
                      <label class="font-weight-bold">{{
                        formattedCurrency(
                          unit.items.reduce((a, { credit }) => a + credit, 0),
                        )
                      }}</label>
                      <label></label>
                    </div>
                    <div class="footer-balance py-2">
                      <label></label>
                      <label class="font-weight-bold"
                        >Saldo Akhir Periode</label
                      >
                      <label class="font-weight-bold">0,00</label>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-row no-gutters v-else justify="center" class="pa-5">
          <label class="grey--text body-2"
            >Masukkan data filter terlebih dahulu</label
          >
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'SubsdiaryLedger',
  mixins: [formatMixin],
  data() {
    return {
      tabs: null,
      menu: false,
      menu2: false,
      date: null,
      endDate: null,
      menuDebt: false,
      menu2Debt: false,
      dateDebt: null,
      endDateDebt: null,
      search: '',
      table: {
        headers: [
          { text: 'No', value: 'no', width: '6%' },
          { text: 'Tanggal', value: 'displayDate', width: '10%' },
          { text: 'Nomor Bukti', value: 'invoiceNumber', width: '15%' },
          { text: 'Nomor Jurnal', value: 'journalNumber', width: '14%' },
          { text: 'Uraian', value: 'description', width: '20%' },
          { text: 'Debit', value: 'debit', width: '13%' },
          { text: 'Kredit', value: 'credit', width: '13%' },
          { text: 'Saldo', value: 'balance' },
        ],
        debtItems: [],
        receiveableItems: [],
        unitItems: '',
        unitItemsReceivable: '',
      },
      resource: {
        accountCode: [],
        crediturType: [
          { text: 'Supplier', value: 'supplier' },
          { text: 'Instansi Rekanan', value: 'institution' },
          { text: 'Karyawan', value: 'staff' },
          { text: 'Pasien', value: 'patient' },
        ],
        unit: [],
        creditur: [],
        debitur: [],
      },
      filter: {
        crediturType: '',
        crediturName: '',
        debiturType: '',
        debiturName: '',
        account: '',
        unit: [],
      },
      isLoading: false,
      panel: [0],
      panels: [],
    };
  },
  mounted() {
    this.getAccount();
  },
  methods: {
    async crediturName() {
      if (this.filter.crediturType.text === 'Karyawan') {
        const { data } = await this.getStaff();
        this.resource.creditur = data
          .filter(x => x.role.status)
          .map(arr => {
            return {
              id: arr._id,
              name: arr.detail.name,
              role: {
                detail: arr.role.detail,
                role: arr.role.role ? arr.role.role : '',
              },
            };
          });
      } else if (this.filter.crediturType.text === 'Pasien') {
        const { data } = await this.getPatient();
        this.resource.creditur = data.map(arr => {
          return {
            id: arr._id,
            name: arr.social.name,
          };
        });
      }
    },
    async debiturName() {
      if (this.filter.debiturType.text === 'Karyawan') {
        const { data } = await this.getStaff();
        this.resource.debitur = data
          .map(arr => {
            return {
              id: arr._id,
              name: arr.detail.name,
              role: {
                detail: arr.role.detail,
                role: arr.role.role ? arr.role.role : '',
                status: arr.role.status,
              },
            };
          })
          .filter(x => x.role.status);
      } else if (this.filter.debiturType.text === 'Pasien') {
        const { data } = await this.getPatient();
        this.resource.debitur = data.map(arr => {
          return {
            id: arr._id,
            name: arr.social.name,
          };
        });
      }
    },
    async getStaff() {
      const data = await axios.get(
        Constant.apiUrl.concat(
          `/master/staff/active?page=1&itemCount=&sort=&search=&branch=${this.$store.getters.userLoggedIn.branch_id}`,
        ),
      );
      return data.data;
    },
    async getPatient() {
      const data = await axios.get(
        Constant.apiUrl.concat(
          `/patient/emr/all?page=1&itemCount=&search=&sort= `,
        ),
      );
      return data.data;
    },
    getAccount() {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat('/finance/ledger/account?&target=selection'),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.accountCode = data;
          this.isLoading = false;
        });
    },
    getSubsLedgerDebt() {
      axios
        .get(
          Constant.apiUrl
            .concat(`/finance/journal?target=subsidiary&filter=`)
            .concat(
              `${this.filter.crediturType.text},${this.filter.crediturName.id},creditor`,
            )
            .concat(`&startDate=&endDate=`),
        )
        .then(response => {
          const { data } = response.data;
          this.table.debtItems = data.map(arr => {
            return {
              date: arr.date,
              displayDate: moment(arr.date).format('DD/MM/YYYY'),
              invoiceNumber: arr.proof_code,
              journalNumber: arr.journal_code,
              description: arr.description || '',
              debit: arr.entries.debit || 0,
              credit: arr.entries.credit || 0,
              balance: arr.balance || 0,
              unit: arr.unit || 'Tidak Ada Unit',
            };
          });

          this.resource.unit = data
            .map(x => {
              return x.unit;
            })
            .filter(x => x);

          this.table.unitItems = _(this.table.debtItems)
            .groupBy('unit')
            .map((value, key) => ({ unit: key, items: value }))
            .value();

          this.panels = [...Array(this.table.unitItems.length).keys()].map(
            () => true,
          );
        });
    },
    sortUnit() {
      var sorted = [];
      var unsorted = [];

      this.table.unitItems.forEach(obj => {
        if (_.indexOf(this.filter.unit, obj.unit) !== -1) {
          var index = _.indexOf(this.filter.unit, obj.unit);
          sorted[index] = obj;
        } else {
          unsorted.push(obj);
        }
      });

      this.table.unitItems = sorted.concat(unsorted);
    },
    getSubsLedgerReceiveable() {
      axios
        .get(
          Constant.apiUrl
            .concat(`/finance/journal?target=subsidiary&filter=`)
            .concat(
              `${this.filter.debiturType.text},${this.filter.debiturName.id},debtor`,
            )
            .concat(`&startDate=&endDate=`),
        )
        .then(response => {
          const { data } = response.data;
          this.table.receiveableItems = data.map(arr => {
            return {
              date: arr.date,
              displayDate: moment(arr.date).format('DD/MM/YYYY'),
              invoiceNumber: arr.proof_code,
              journalNumber: arr.journal_code,
              description: arr.description || '',
              debit: arr.entries.debit || 0,
              credit: arr.entries.credit || 0,
              balance: arr.balance || 0,
              unit: arr.unit || 'Tidak Ada Unit',
            };
          });

          this.resource.unit = data
            .map(x => {
              return x.unit;
            })
            .filter(x => x);

          this.table.unitItemsReceivable = _(this.table.receiveableItems)
            .groupBy('unit')
            .map((value, key) => ({ unit: key, items: value }))
            .value();

          this.panels = [
            ...Array(this.table.unitItemsReceivable.length).keys(),
          ].map(() => true);
        });
    },
  },
};
</script>

<style>
.header-style {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  grid-row-gap: 1vh;
  align-items: baseline;
  text-align: left;
}

.footer-total {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
  height: 6vh;
  background-color: #d9edff;
  align-content: center;
  font-size: 0.9rem;
}

.footer-balance {
  display: grid;
  grid-template-columns: 6fr 2fr 1fr;
  font-size: 0.9rem;
}

.v-expansion-panel-header {
  /* background-color: #d9edff !important; */
  min-height: 6vh !important;
  border-bottom: 1px solid lightgrey;
}

::v-deep
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 10px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
