<template>
  <v-card class="pa-3 main-card">
    <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
      <v-col>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Nomor Surat</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ numberLetter }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Nama Pasien</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.name }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">No RM</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.rmNumber }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Tanggal Lahir</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.birthDate }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Usia</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.age }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Jenis Kelamin</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.gender }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Alamat</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.address }}</label>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Dokter</label>
          </v-col>
          <v-col cols="7">
            <v-select
              dense
              class="pt-0 mt-0"
              placeholder="Nama Dokter"
              hide-details="false"
              v-model="input.doctor"
              :items="resource.doctor"
              item-text="doctorName"
              item-value="doctorId"
              :readonly="isBpjs"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Diagnosa</label>
          </v-col>
          <v-col cols="7">
            <v-combobox
              :readonly="isBpjs"
              dense
              class="pt-0 mt-0"
              placeholder="Diagnosa"
              hide-details="false"
              :items="resource.diagnose"
              :loading="diagnoseLoading"
              v-model="input.diagnose"
            ></v-combobox>
          </v-col>
        </v-row>
        <!-- <v-row align="center">
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Diagnosa Keluar</label>
          </v-col>
          <v-col cols="7">
            <v-combobox
              dense
              class="pt-0 mt-0"
              placeholder="Diagnosa Keluar"
              hide-details="false"
              :items="resource.diagnose"
              :search-input.sync="searchDiagnoseOut"
              clearable
              :loading="diagnoseLoading"
              v-model="input.diagnosis_out"
            ></v-combobox>
          </v-col>
        </v-row> -->
      </v-col>
      <v-col cols="7">
        <v-row>
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tujuan Rujukan</label>
          </v-col>
          <v-col cols="7" class="px-0">
            <v-radio-group
              row
              class="mt-0 pt-0"
              hide-details="false"
              v-model="input.reference_type"
              :readonly="isBpjs"
            >
              <v-radio
                label="Spesialis"
                @click="changeRefferenceType('specialist')"
                value="specialist"
              ></v-radio>
              <!-- <v-radio
                label="Sub Spesialis"
                @click="changeRefferenceType('specialistSub')"
                value="sub Specialis"
              ></v-radio> -->
              <v-radio
                label="Khusus"
                value="khusus"
                @click="changeRefferenceType('particular')"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-show="radioSelected === 'specialist'" align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tujuan Rujukan Spesialis</label>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="input.referral_specialist_purpose"
              :readonly="isBpjs"
            ></v-text-field>
            <!-- <v-select
              placeholder="Tujuan Rujukan Spesialis"
              dense
              hide-details="false"
              v-model="input.referral_specialist_purpose"
            ></v-select> -->
          </v-col>
        </v-row>
        <v-row v-show="radioSelected === 'specialist'" align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tujuan Rujukan Sub Spesialis</label>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="input.referral_sub_purpose"
              :readonly="isBpjs"
            ></v-text-field>
            <!-- <v-select
              placeholder="Tujuan Rujukan Sub Spesialis"
              dense
              hide-details="false"
              v-model="input.referral_sub_purpose"
            ></v-select> -->
          </v-col>
        </v-row>
        <v-row v-show="radioSelected === 'particular'" align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tujuan Rujukan Khusus</label>
          </v-col>
          <v-col cols="6">
            <v-select
              placeholder="Tujuan Rujukan"
              dense
              hide-details="false"
              v-model="input.referral_sub_purpose"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Tanggal Rencana Rujukan</label>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details
              :value="input.referral_plan_date"
              readonly
              v-if="isBpjs"
            ></v-text-field>
            <v-menu
              v-else
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="input.referral_plan_date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="input.referral_plan_date"
                  placeholder="Pilih tanggal"
                  append-icon="mdi-chevron-down"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details="false"
                  :readonly="isBpjs"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="input.referral_plan_date"
                no-title
                scrollable
                @input="
                  $refs.menu.save(input.referral_plan_date);
                  menu = false;
                "
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Jadwal Periksa</label>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="input.schedule_check"
              :readonly="isBpjs"
            ></v-text-field>
            <!-- <v-select
              placeholder="Jadwal Periksa"
              dense
              hide-details="false"
              v-model="input.schedule_check"
            ></v-select> -->
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Sarana</label>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="input.sarana"
              :readonly="isBpjs"
            ></v-text-field>
            <!-- <v-select
              placeholder="Pilih Sarana"
              dense
              hide-details="false"
              v-model="input.sarana"
            ></v-select> -->
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Nama Instansi Rujukan</label>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="input.instansi_name"
              :readonly="isBpjs"
            ></v-text-field>
            <!-- <v-select
              placeholder="Nama Instansi"
              dense
              hide-details="false"
              v-model="input.instansi_name"
            ></v-select> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Terapi yang telah diberikan</label>
          </v-col>
          <v-col cols="7">
            <v-textarea
              outlined
              placeholder="Masukkan Terapi"
              hide-details="false"
              dense
              rows="5"
              v-model="input.therapy"
              :readonly="isBpjs"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="text-left">
            <label class="font-weight-bold">Saran/Anjuran</label>
          </v-col>
          <v-col cols="7">
            <v-textarea
              outlined
              placeholder="Masukkan Saran"
              hide-details="false"
              dense
              rows="5"
              v-model="input.suggestions"
              :readonly="isBpjs"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-btn class="text-none" plain @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        class="text-none"
        :loading="isLetterLoading"
        @click="dialog = true"
        width="116"
      >
        <font-awesome-icon icon="print" />
        Print
      </v-btn>
      <!-- <v-btn
        color="primary"
        tile
        elevation="0"
        width="108"
        class="text-none"
        @click="addReferralLetter()"
        >Simpan</v-btn
      > -->
    </v-card-actions>
    <v-dialog v-model="dialog" max-width="300px">
      <print-BPJS-referral-dialog
        :letter-data="letterData || {}"
        @close-form="dialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import jwtMixin from '@/mixin/jwtMixin';
import Letter from '@/mixin/Letter';
import getReferralLetter from '../../../../fetchApi/Letter/getReferralLetter';
import PrintBPJSReferralDialog from '../../../SharedComponent/PrintBPJSReferralDialog.vue';
import 'jspdf-autotable';

const axios = require('axios');
export default {
  name: 'Hospital',
  mixins: [AlertMixin, jwtMixin, Letter],
  components: { PrintBPJSReferralDialog },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      menu: false,
      radioSelected: 'specialist',
      diagnoseLoading: false,
      isLetterLoading: false,
      isBpjs:
        this.patientData.meta.guarantorType.toLowerCase() == 'bpjs'
          ? true
          : false,
      resource: {
        diagnose: [],
        doctor: [],
      },
      searchDiagnoseIn: '',
      searchDiagnoseOut: '',
      letterData: '', // For save original response from BE
      input: {
        reference_type: '',
        doctor: '',
        referral_sub_purpose: '',
        referral_plan_date: null,
        schedule_check: '',
        sarana: '',
        instansi_name: '',
        therapy: '',
        suggestions: '',
      },
      numberLetter: '',
      disabled: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getDoctor();
        this.getDiagnose('');
        this.getReferralLetter();
      });
    });
  },
  watch: {
    searchDiagnoseIn(val) {
      if (val !== '') {
        this.getDiagnose(val);
      }
      this.getDiagnose('');
    },
    searchDiagnoseOut(val) {
      if (val !== '') {
        this.getDiagnose(val);
      }
      this.getDiagnose('');
    },
  },
  methods: {
    changeRefferenceType(type) {
      if (this.isBpjs) return;
      this.radioSelected = type;
    },
    async getDiagnose(keyword) {
      this.diagnoseLoading = true;
      await axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=100&search=${
              keyword === null ? '' : keyword
            }&sort=-code`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.resource.diagnose = allDiagnose.map(diagnose => {
            return {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.description,
            };
          });
          this.diagnoseLoading = false;
        })
        .catch(() => {
          this.diagnoseLoading = false;
        });
    },
    addReferralLetter() {
      const payload = {
        id_registration: this.patientData.id_registration,
        detail: [
          {
            reference_type: this.input.reference_type,
            doctor: this.input.doctor,
            diagnosis_in: this.input.diagnosis_in.id,
            diagnosis_out: this.input.diagnosis_out.id,
            referral_sub_purpose: this.input.referral_sub_purpose,
            referral_plan_date: this.input.referral_plan_date,
            schedule_check: this.input.schedule_check,
            sarana: this.input.sarana,
            instansi_name: this.input.instansi_name,
            therapy: this.input.therapy,
            suggestions: this.input.suggestions,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        ],
      };

      axios
        .post(
          Constant.apiUrl.concat('/letter/referral-patient-letter'),
          payload,
        )
        .then(() => {
          Swal.fire(
            'Surat Rujukan ke Rumah Sakit Berhasil Dibuat',
            '',
            'success',
          );
          this.$emit('close-form');
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff/active?role=dokter&branch=${this.$store.getters.userLoggedIn.branch_id}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });
          }
        });
    },
    generateNumber() {
      axios
        .get(Constant.apiUrl.concat('/letter/referral-patient-letter/generate'))
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    async getReferralLetter() {
      try {
        this.isLetterLoading = true;
        const response = await getReferralLetter({
          id_registration: this.patientData.id_registration,
        });
        const { data: referral } = response.data;
        this.letterData = { ...referral };
        this.numberLetter = referral.letter_number;
        this.input = {
          reference_type: referral.detail.reference_type,
          doctor: {
            doctorId: referral.detail.doctor.id,
            doctorName: referral.detail.doctor.name,
          },
          referral_specialist_purpose: referral.detail.referral_specialist.name,
          referral_sub_purpose: referral.detail.referral_sub_specialist,
          referral_plan_date: referral.detail.referral_plan_date,
          diagnose: {
            id: referral.detail.diagnose.id,
            code: referral.detail.diagnose.code,
            text: referral.detail.diagnose.text,
          },
          secDiagnose1: {
            id: referral.detail.secDiagnose1?.id,
            code: referral.detail.secDiagnose1?.code,
            text: referral.detail.secDiagnose1?.description,
          },
          secDiagnose2: {
            id: referral.detail.secDiagnose2?.id,
            code: referral.detail.secDiagnose2?.code,
            text: referral.detail.secDiagnose2?.description,
          },
          sarana: referral.detail.sarana,
          schedule_check: referral.detail.schedule_check,
          instansi_name: referral.detail.instansi_name,
          therapy: referral.detail.therapy,
          suggestions: referral.detail.suggestions,
        };
      } catch (error) {
        this.generateNumber();
        if (error.response.status === 404) this.disabled = true;
      } finally {
        this.isLetterLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  font-family: 'Roboto', sans-serif;
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}
</style>
