<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Detail Barang Farmasi</h3>
      <v-btn absolute right @click="onCloseButtonClick()" small text>
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container style="height: 70dvh; overflow: auto" class="scrollbar">
        <v-row no-gutters>
          <v-col md="11" offset-md="1">
            <v-row no-gutters>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Barang</label>
                  </div>

                  <v-text-field
                    disabled
                    v-model="drugs.name"
                    id="name"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="barcode">Barcode</label>
                  </div>
                  <v-text-field
                    disabled
                    v-model="drugs.barcode"
                    id="barcode"
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="category">Kategori</label>
                  </div>
                  <v-select
                    disabled
                    class="detail-input__input-text"
                    v-model="drugs.category"
                    :items="resource.category"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="rak">Rak</label>
                  </div>
                  <v-text-field
                    disabled
                    v-model="drugs.shelf"
                    id="rak"
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="sediaan">Sediaan</label>
                  </div>
                  <v-select
                    disabled
                    class="detail-input__input-text"
                    v-model="drugs.preparation"
                    :items="resource.preparation"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="pabrik">Pabrik</label>
                  </div>
                  <v-select
                    disabled
                    class="detail-input__input-text"
                    v-model="drugs.factory"
                    :items="resource.factory"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="pabrik">Minimal Stok</label>
                  </div>
                  <v-text-field
                    class="detail-input__input-text"
                    v-model="drugs.min_stock"
                    readonly
                    dense
                  ></v-text-field>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="farmakoterapi">Farmako Terapi</label>
                  </div>
                  <v-select
                    disabled
                    class="detail-input__input-text"
                    v-model="drugs.pharmacotherapy"
                    :items="resource.pharmacotherapy"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="indikasi">
                      Indikasi
                    </label>
                  </div>
                  <v-textarea
                    disabled
                    v-model="drugs.indication"
                    id="indikasi"
                    class="detail-input__input-text align-start"
                    height="100"
                    outlined
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="efeksamping">
                      Efek Samping
                    </label>
                  </div>
                  <v-textarea
                    disabled
                    v-model="drugs.side_effects"
                    id="efeksamping"
                    class="detail-input__input-text align-start"
                    height="100"
                    outlined
                  />
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="generik">
                      Generik
                    </label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isGeneric"
                    v-model="drugs.isGeneric"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="katastropik">
                      Katastropik
                    </label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isCatastrophic"
                    v-model="drugs.isCatastrophic"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="aktif">Aktif</label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isActive"
                    v-model="drugs.isActive"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="1"> </v-col>
              <v-col cols="5">
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="isHighAlert">
                      High Alert Medicine
                    </label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isHighAlert"
                    v-model="drugs.isHighAlert"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="ven">VEN</label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isVEN"
                    v-model="drugs.isVEN"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="kekuatan">Kekuatan</label>
                  </div>

                  <v-text-field
                    disabled
                    v-model="drugs.strength"
                    id="kekuatan"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="kekuatanunit">Kekuatan Unit</label>
                  </div>
                  <v-select
                    disabled
                    class="detail-input__input-text"
                    v-model="drugs.strength_unit"
                    :items="resource.packages"
                    placeholder="Kekuatan Unit"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="hpp">HPP</label>
                  </div>
                  <v-currency-field
                    disabled
                    v-model="drugs.hpp"
                    id="hpp"
                    prefix="Rp."
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="hna">HNA</label>
                  </div>
                  <v-currency-field
                    disabled
                    v-model="drugs.hna"
                    prefix="Rp."
                    id="hna"
                    class="detail-input__input-text"
                  />
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="carapakai">Cara Pakai</label>
                  </div>
                  <v-select
                    disabled
                    class="detail-input__input-text"
                    v-model="drugs.roa"
                    :items="resource.roa"
                  ></v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="golongan">Golongan</label>
                  </div>
                  <v-select
                    disabled
                    placeholder="Golongan"
                    class="detail-input__input-text"
                    v-model="drugs.group"
                    :items="resource.group"
                  ></v-select>
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="kontraindikasi">
                      Kontra Indikasi
                    </label>
                  </div>
                  <v-textarea
                    disabled
                    v-model="drugs.c_indication"
                    id="kontraindikasi"
                    class="detail-input__input-text align-start"
                    height="100"
                    outlined
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="bahanbaku">
                      Bahan Baku
                    </label>
                  </div>
                  <v-textarea
                    disabled
                    v-model="drugs.ingredients"
                    id="bahanbaku"
                    class="detail-input__input-text align-start"
                    height="100"
                    outlined
                  />
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="bahanbaku">
                      Formulari
                    </label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isFormulary"
                    v-model="drugs.isFormulary"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="bahanbaku">
                      Fornas
                    </label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isFornas"
                    v-model="drugs.isFornas"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label class="detail-input__label" for="bahanbaku">
                      Obat Keras
                    </label>
                  </div>
                  <v-radio-group
                    disabled
                    id="isPotent"
                    v-model="drugs.isPotent"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="11" offset-md="1">
            <v-row no-gutters>
              <label class="grey--text font-weight-medium"
                >SATUAN YANG DIGUNAKAN</label
              >
            </v-row>
            <v-row class="mt-3" no-gutters>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name" class="required">Satuan Terkecil</label>
                  </div>
                  <v-select
                    v-model="smallestPackage"
                    class="detail-input__input-text"
                    required
                    :items="resource.packages"
                    dense
                    disabled
                  />
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <template v-for="(item, index) in drugs.packaging">
                  <v-row class="mb-1" :key="index">
                    <v-col class="py-1" cols="4">
                      <v-select
                        disabled
                        v-model="drugs.packaging[index].package"
                        class="p-1 mt-0"
                        dense
                        required
                        :items="resource.packages"
                        placeholder="Kemasan"
                        hide-details
                      />
                    </v-col>
                    <v-col class="py-1" cols="2">
                      <v-text-field
                        disabled
                        v-model.number="drugs.packaging[index].quantity"
                        required
                        class="p-1 mt-0"
                        dense
                        type="number"
                        placeholder="Jumlah"
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="4">
                      <v-select
                        disabled
                        placeholder="Satuan"
                        v-model="drugs.packaging[index].package_unit"
                        required
                        class="p-1 mt-0"
                        dense
                        :items="resource.packages"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        width="100"
        class="mr-4"
        text
        large
        @click="onCloseButtonClick()"
      >
        Kembali
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const axios = require('axios');
import Constant from '@/const';

export default {
  props: {
    drugsDetail: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      loading: true,
      resource: {
        category: [],
        packages: [],
        preparation: [],
        roa: [],
        factory: [],
        group: [],
        pharmacotherapy: [],
      },
      drugs: {
        name: this.drugsDetail.name,
        barcode: this.drugsDetail.barcode,
        hna: this.drugsDetail.hna,
        hpp: this.drugsDetail.hpp,
        min_stock: this.drugsDetail.min_stock,
        category: this.drugsDetail.category,
        shelf: this.drugsDetail.shelf,
        factory: this.drugsDetail.factory,
        auto_stock: 0,
        strength: this.drugsDetail.strength,
        strength_unit: this.drugsDetail.strength_unit,
        preparation: this.drugsDetail.preparation,
        roa: this.drugsDetail.roa,
        indication: this.drugsDetail.indication,
        c_indication: this.drugsDetail.c_indication,
        dosage: this.drugsDetail.dosage,
        ingredients: this.drugsDetail.ingredients,
        side_effects: this.drugsDetail.side_effects,
        status: this.drugsDetail.status,
        isFormulary: this.drugsDetail.isFormulary,
        isFornas: this.drugsDetail.isFornas,
        isECatalog: this.drugsDetail.isECatalog,
        isHighAlert: this.drugsDetail.isHighAlert,
        isPotent: this.drugsDetail.isPotent,
        isGeneric: this.drugsDetail.isGeneric,
        isCatastrophic: this.drugsDetail.isCatastrophic,
        isActive: this.drugsDetail.isActive,
        isVEN: this.drugsDetail.isVEN,
        group: this.drugsDetail.group,
        pharmacotherapy: this.drugsDetail.pharmacotherapy,
        packaging: [],
        timestamps: {
          created_by: '',
        },
      },
      smallestPackage: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.drugsDetail) {
        this.getDataById();
        this.getCategoryByMenu();
        this.getPackagesByMenu();
        this.getPreparation();
        this.getRoa();
        this.getGroup();
        this.getPharmacotherapy();
        this.getFactory();
      }
    });
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    getDataById() {
      axios
        .get(`${Constant.apiUrl}/master/drugs/${this.drugsDetail.id}`)
        .then(response => {
          this.loading = true;
          const drugsData = response.data.data;

          const getPackaging = drugsData.detail.packaging;

          this.drugs.packaging = getPackaging
            .filter(v => v.isDefault != true)
            .map(a => {
              return {
                package: a.package,
                quantity: a.quantity,
                package_unit: a.package_unit,
                isDefault: a.isDefault === true ? 'Ya' : 'Tidak',
              };
            });

          const defaultPac = getPackaging.find(v => v.isDefault == true);
          if (defaultPac) this.smallestPackage = defaultPac.package;

          this.loading = false;
        });
    },
    getFactory() {
      axios.get(Constant.apiUrl.concat('/master/factory')).then(response => {
        const resData = response.data.data;
        this.resource.factory = resData.map(prep => {
          return prep.name;
        });
      });
    },
    getCategoryByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/category/menu/Farmasi'))
        .then(response => {
          const categoryData = response.data.data;
          this.resource.category = categoryData.map(cat => {
            return cat.name;
          });
        });
    },
    getPackagesByMenu() {
      axios
        .get(Constant.apiUrl.concat('/master/package/menu/inventory'))
        .then(response => {
          const packageData = response.data.data;
          this.resource.packages = packageData.map(pac => {
            return pac.name;
          });
        });
    },
    getPreparation() {
      axios
        .get(Constant.apiUrl.concat('/master/preparation'))
        .then(response => {
          const preparationData = response.data.data;
          this.resource.preparation = preparationData.map(prep => {
            return prep.name;
          });
        });
    },
    getRoa() {
      axios.get(Constant.apiUrl.concat('/master/roa')).then(response => {
        const roaData = response.data.data;
        this.resource.roa = roaData.map(prep => {
          return prep.name;
        });
      });
    },
    getGroup() {
      axios.get(Constant.apiUrl.concat('/master/drugsgroup')).then(response => {
        const resData = response.data.data;
        this.resource.group = resData.map(prep => {
          return prep.name;
        });
      });
    },

    getPharmacotherapy() {
      axios.get(Constant.apiUrl.concat('/master/farmaco')).then(response => {
        const resData = response.data.data;
        this.resource.pharmacotherapy = resData.map(prep => {
          return prep.name;
        });
        this.resource.pharmacotherapy.sort((a, b) => a - b);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
    margin-right: 10px;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}

.padding {
  padding-left: 10px;
}

.margin-top {
  margin-top: 20px;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
