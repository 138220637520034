<template>
  <v-card>
    <v-card-title class="justify-end pa-1">
      <v-btn icon @click="$emit('close-form')">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <h2 class="black--text pb-3">Pilih Print Dokumen</h2>
      <v-col>
        <v-btn
          color="primary"
          tile
          elevation="0"
          class="white--text text-none"
          width="267"
          @click="printPatientCard"
          >Kartu Pasien</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          tile
          elevation="0"
          class="white--text text-none"
          width="267"
          @click="printQueue"
          >{{ onState()?.textQueue }}</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          :color="hasGeneralConsent ? '#f5f5f5' : 'primary'"
          tile
          elevation="0"
          class="white--text text-none"
          :class="{ 'green--text': hasGeneralConsent }"
          width="267"
          @click="
            changeForm(0);
            state === 'rawat-jalan' ? $emit('close-form') : () => {};
          "
          >{{ onState()?.textGeneralConsent }}</v-btn
        >
        <v-dialog width="1700" v-model="dialogConsent">
          <general-consent-form
            v-if="dialogConsent && selectedForm === 0"
            :social-data="socialData"
            :id_emr="onState().idEmr"
            @close-form="dialogConsent = false"
          />
        </v-dialog>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import GeneralConsentForm from './GeneralConsentForm.vue';
import JsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import Canvas from 'canvas';
import moment from 'moment-timezone';
// import QRCode from 'qrcodejs2';

export default {
  name: 'PrintDialog',
  components: {
    GeneralConsentForm,
  },
  props: {
    selectedPatient: Object,
    hasGeneralConsent: Boolean,
    state: String,
  },
  data() {
    return {
      dialogConsent: false,
      selectedForm: '',
      socialData: {}, // social data for general consent props
    };
  },
  mounted() {
    this.socialData = {
      name: this.selectedPatient.meta.name,
      address: this.selectedPatient.meta.address,
      telp: this.selectedPatient.meta.phoneNumber,
      identityNumber: this.selectedPatient.meta.identityNumber,
      age: this.selectedPatient.meta.age,
      gender:
        this.selectedPatient.meta.gender !== 'Perempuan' ||
        this.selectedPatient.meta.gender === true,
      rmNumber: this.selectedPatient.rmNumber,
      birthDate: this.selectedPatient.meta.birthDate,
    };
  },
  methods: {
    onState() {
      switch (this.state) {
        case 'rawat-jalan': {
          return {
            textQueue: 'Antrean Rawat Jalan',
            idEmr: this.selectedPatient.id_emr,
            textGeneralConsent: `General Consent ${
              this.hasGeneralConsent ? 'Sudah Diproses' : 'Rawat Jalan'
            }`,
            polyName: this.selectedPatient.meta.poly[0],
          };
        }
        case 'laboratorium': {
          return {
            textQueue: 'Antrean Laboratorium',
            idEmr: this.selectedPatient.noRm,
            textGeneralConsent: `General Consent ${
              this.hasGeneralConsent ? 'Sudah Diproses' : 'Laboratorium'
            }`,
            polyName: this.selectedPatient.meta.polyName,
          };
        }
      }
    },
    changeForm(value) {
      this.selectedForm = value;
      this.dialogConsent = true;
    },
    printPatientCard() {
      const transformRm = Canvas.createCanvas();
      JsBarcode(transformRm, `${this.selectedPatient.rmNumber}`, {
        height: 70,
        font: 'times',
        width: 4,
        margin: 3,
        displayValue: false,
        fontOptions: 'bold',
        fontSize: 9,
      });
      const doc = new JsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: 'a5',
      }).setProperties({
        title: `Kartu Pasien`,
      });
      const marginX = 2;

      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1.2;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      alignCenter(
        this.$store.getters.userLoggedIn.clinic.name,
        undefined,
        addLine(1),
      );
      // alignCenter('Kertek Wonosobo', undefined, addLine(2));

      doc.setFontSize(20);
      doc.text(`${this.selectedPatient.meta.name}`, marginX, addLine(6));
      const jpegUrl = transformRm.toDataURL('image/jpeg');
      doc.addImage(jpegUrl, 'JPEG', marginX, addLine(7));
      doc.setFontSize(14);
      doc.setFont('helvetica', 'normal');
      doc.text(`${this.selectedPatient.rmNumber}`, marginX, addLine(12));
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    printQueue() {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'cm',
        format: [6.5, 8],
      }).setProperties({
        title: `Kartu Antrian Rawat Jalan`,
      });
      // const marginX = 2;

      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 0.4;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setFontSize(11);
      doc.setFont('Helvetica', 'normal');
      alignCenter(
        `${this.$store.getters.userLoggedIn.clinic.altName}`,
        undefined,
        addLine(1),
      );
      doc.setLineWidth(0.01);
      doc.line(1, 1.2, 5.6, 1.2);

      doc.setFontSize(this.selectedPatient.meta.doctor === '' ? 18 : 11);
      doc.setFont('helvetica', 'bold');
      alignCenter(
        `${this.onState()?.polyName}`,
        undefined,
        addLine(this.selectedPatient.meta.doctor === '' ? 2.6 : 2.3),
      );
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      alignCenter(
        `${this.selectedPatient.meta.doctor}`,
        undefined,
        addLine(3.1),
      );
      doc.setFontSize(25);
      doc.setFont('helvetica', 'bold');
      alignCenter(this.selectedPatient.queue, undefined, addLine(4.7));

      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      alignCenter(`${this.selectedPatient.meta.name}`, undefined, addLine(5.5));
      alignCenter(
        `RM. ${this.selectedPatient.rmNumber} (${this.selectedPatient.meta.age} tahun)`,
        undefined,
        addLine(6.4),
      );
      doc.setFontSize(13);
      doc.setFont('helvetica', 'bold');
      alignCenter(
        `${this.selectedPatient.guarantorType?.toUpperCase()}`,
        undefined,
        addLine(7.5),
      );
      const transformRm = Canvas.createCanvas();
      JsBarcode(transformRm, this.selectedPatient.noRegist, {
        height: 30,
        font: 'times',
        width: 1.2,
        margin: 1,
        displayValue: false,
        fontOptions: 'bold',
        fontSize: 9,
      });
      const jpegUrl = transformRm.toDataURL('image/jpeg');
      doc.addImage(jpegUrl, 'JPEG', 1.4, 4.9);
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      alignCenter(
        `Dicetak ${moment(new Date())
          .locale('id')
          .format('DD-MM-YYYY')} ${moment(new Date())
          .locale('id')
          .format('LT')} WIB`,
        undefined,
        addLine(11),
      );
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
  },
};
</script>

<style lang="scss" scoped></style>
