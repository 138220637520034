<template>
  <v-card>
    <v-container fluid>
      <div class="sticky">
        <div class="d-flex justify-space-between center-width">
          <div class="text-sm-left mb-2 pl-3">
            <h2 class="heading_big mt-3">Penyerahan Obat dan Alkes</h2>
          </div>
          <v-btn @click="$emit('close-form')" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row class="header-layout center-width">
          <v-col>
            <v-row class="row-info">
              <v-col class="header-info-widget text-sm-left pt-2" cols="5"
                ><span class="info-head">Nama</span></v-col
              >
              <v-col class="header-info-widget text-sm-left pt-2" cols="7">
                <span class="label-head"
                  >{{ patient.name }} ({{
                    patient.gender === 'Perempuan' ? 'P' : 'L'
                  }})</span
                >
              </v-col>

              <v-col class="header-info-widget text-sm-left py-0" cols="5">
                <span class="info-head">Tanggal Lahir</span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head"
                  >{{ data.birthDate }} ({{ patient.age }} tahun)
                </span>
              </v-col>

              <v-col class="header-info-widget text-sm-left pt-2" cols="5"
                ><span class="info-head">Tipe Penjamin</span></v-col
              >
              <v-col class="header-info-widget text-sm-left pt-2" cols="7">
                <span class="label-head">{{ patient.assurance }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="row-info">
              <v-col class="header-info-widget text-sm-left pt-2" cols="5"
                ><span class="info-head">No. RM</span></v-col
              >
              <v-col class="header-info-widget text-sm-left pt-2" cols="7">
                <span class="label-head">{{ patient.rm_number }}</span>
              </v-col>

              <v-col class="header-info-widget text-sm-left py-0" cols="5"
                ><span class="info-head">Unit</span></v-col
              >
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head">{{ patient.unit }}</span>
              </v-col>

              <v-col class="header-info-widget text-sm-left pt-2" cols="5"
                ><span class="info-head">DPJP</span></v-col
              >
              <v-col class="header-info-widget text-sm-left pt-2" cols="7">
                <span class="label-head">{{ patient.doctor }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pa-0"
            ><v-col class="header-info-widget text-sm-left pa-0" cols="12">
              <span class="label-head font-weight-bold"> Riwayat Alergi </span>
            </v-col>
            <v-col class="text-sm-left px-0" cols="12">
              <span
                v-if="data.allergy.length === 0"
                class="red--text font-weight-bold label-head"
                >Tidak Ada Alergi</span
              >
              <v-chip
                v-for="(allergy, i) in data.allergy"
                :key="i"
                color="#EB4747"
                class="allergy text-capitalize mr-2 mb-2"
                label
                small
                caption
                >{{ allergy }}</v-chip
              >
            </v-col>
          </v-col>
        </v-row>

        <v-tabs class="center-width" v-model="tab" grow slider-color="blue">
          <v-tab
            key="DrugToolReq"
            class="text-none font-weight-bold"
            @click="menuSelected = 1"
          >
            Permintaan Obat dan Alkes
          </v-tab>
          <v-tab
            key="drugReview"
            class="text-none font-weight-bold"
            @click="menuSelected = 2"
          >
            Telaah Obat dan Resep
          </v-tab>
        </v-tabs>
      </div>
      <v-tabs-items class="after-sticky" v-model="tab">
        <v-tab-item key="DrugToolReq" class="mb-16">
          <v-card flat class="body-container">
            <span class="title pb-2 center-width"
              >Permintaan Obat Non Racik</span
            >
            <v-data-table
              :headers="nonRacikTable.headers"
              :items="nonRacikTable.item"
              :items-per-page="nonRacikTable.item.length"
              hide-default-footer
              class="center-width"
            >
              <template slot="no-data">
                <span style="color: black">Tidak ada obat yang dipesan</span>
              </template>
              <template v-slot:[`item.no`]="{ item }">
                <tr class="text-left">
                  <td>
                    {{ nonRacikTable.item.indexOf(item) + 1 }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-simple-checkbox
                  v-model="item.status"
                  color="success"
                ></v-simple-checkbox>
              </template>
            </v-data-table>

            <span class="title pb-2 center-width mt-4"
              >Permintaan Obat Racik</span
            >
            <v-data-table
              :headers="racikTable.headers"
              :items="racikTable.item"
              :items-per-page="racikTable.item.length"
              hide-default-footer
              class="center-width"
              ><template slot="no-data">
                <span style="color: black">Tidak ada obat yang dipesan</span>
              </template>
              <template v-slot:[`item.no`]="{ item }">
                <tr class="text-left">
                  <td>
                    {{ racikTable.item.indexOf(item) + 1 }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.drugs`]="{ item }">
                <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
                  <td class="py-1">{{ drug.id_drugs.name }}</td>
                </tr>
              </template>
              <template v-slot:[`item.preparation`]="{ item }">
                <tr
                  v-for="(preparation, index) in item.drugs"
                  :key="'drug-' + index"
                >
                  <td class="py-1">{{ preparation.preparation }}</td>
                </tr>
              </template>
              <template v-slot:[`item.dosage`]="{ item }">
                <tr
                  v-for="(dosage, index) in item.drugs"
                  :key="'dosage-' + index"
                >
                  <td class="py-1">
                    {{ dosage.dosage + ' ' + dosage.unit_dosage }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.drugQuantity`]="{ item }">
                <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
                  <td class="py-1">{{ drug.quantity }}</td>
                </tr>
              </template>
              <template v-slot:[`item.requested_quantity`]="{ item }">
                <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
                  <td class="py-1">{{ drug.requested_quantity }}</td>
                </tr>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-simple-checkbox
                  v-model="item.status"
                  color="success"
                ></v-simple-checkbox>
              </template>
            </v-data-table>

            <span class="title pb-2 center-width mt-4"
              >Penyiapan Alkes/BHP</span
            >
            <v-data-table
              :headers="consumableTable.headers"
              :items="consumableTable.item"
              :items-per-page="consumableTable.item.length"
              hide-default-footer
              class="center-width"
              ><template slot="no-data">
                <span style="color: black">Tidak ada alkes yang dipesan</span>
              </template>
              <template v-slot:[`item.no`]="{ item }">
                <tr class="text-left">
                  <td>
                    {{ consumableTable.item.indexOf(item) + 1 }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-simple-checkbox
                  v-model="item.status"
                  color="success"
                ></v-simple-checkbox>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item key="drugReview" class="mb-10">
          <DrugReview
            :review-drug="reviewDrug"
            :total-price="totalPrice"
            :patient="patient"
            :patient_status="patient_status"
            :recipe="nonRacikTable.item"
            :mix_recipe="racikTable.item"
            :medtool="consumableTable.item"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-card-actions
      v-if="tab === 0"
      class="action-layout pt-3 mt-10 footer-sticky center-width"
    >
      <v-btn
        depressed
        tile
        class="text-none"
        color="primary"
        outlined
        @click="printTicket()"
        >Cetak E-Ticket Obat
      </v-btn>
      <v-spacer />
      <div class="action-footer">
        <!-- <v-btn @click="onClick(menuSelected)" class="btn-create" tile depressed>
          Simpan
        </v-btn> -->
        <v-btn
          @click="next()"
          v-if="tab !== 1"
          outlined
          color="primary"
          tile
          depressed
        >
          Selanjutnya
        </v-btn>
      </div>
    </v-card-actions>
    <v-btn
      depressed
      tile
      class="text-none absolute-e-ticket"
      color="primary"
      outlined
      v-if="tab === 1"
      @click="printTicket()"
      >Cetak E-Ticket Obat
    </v-btn>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import DrugReview from '../DrugToolsRequest/component/DrugReview';
import printMixin from '@/mixin/printMixin';
import Constant from '@/const';
import moment from 'moment-timezone';
import printDrugsEticket from '../../../../mixin/printDrugsEticket';

const axios = require('axios');
const _ = require('lodash');

export default {
  mixins: [printMixin, printDrugsEticket],
  components: {
    DrugReview,
  },
  props: {
    patient: Object,
  },
  data() {
    return {
      drugList: [],
      tab: null,
      patient_status: '',
      show: false,
      totalPrice: 0,
      racikTable: {
        headers: [
          { text: 'No', align: 'start', value: 'no' },
          { text: 'Nama Obat Racik', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'packaging' },
          { text: 'Aturan Pakai', value: 'usage' },
          { text: 'Aturan Umum', value: 'general_rule' },
          { text: 'Nama Obat Racik', value: 'drugs' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: 'Jumlah Diberikan', value: 'drugQuantity' },
          { text: 'Jumlah Ditagihkan', value: 'requested_quantity' },
          { text: 'Diserahkan', value: 'status' },
        ],
        item: [],
      },
      nonRacikTable: {
        headers: [
          { text: 'No', align: 'start', value: 'no' },
          { text: 'Nama Obat', value: 'shownName' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit_dosage' },
          { text: 'Aturan Pakai', value: 'usage' },
          { text: 'Aturan Umum', value: 'general_rule' },
          { text: 'Jumlah Diberikan', value: 'drugTaken' },
          { text: 'Diserahkan', value: 'status' },
        ],
        item: [],
      },
      consumableTable: {
        headers: [
          { text: 'No', align: 'start', value: 'no', width: '4%' },
          { text: 'Nama Alkes/BHP', value: 'name' },
          { text: 'Jumlah diberikan', value: 'goodsGiven' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Diserahkan', value: 'status' },
        ],
        item: [],
      },
      menuSelected: 1,
      reviewDrug: [],
      data: [],
    };
  },
  methods: {
    async printTicket() {
      const doc = this.printEticket(this.data, this.patient, this.drugList);

      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    next() {
      this.tab += 1;
    },
    getPatient() {
      const id_pharmacy = this.patient.id;
      axios
        .get(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`))
        .then(response => {
          const data = response.data.data;
          this.patient_status = data.recipe.patient_status;
          this.data = {
            id_registration: data.id_registration._id,
            allergy: _.get(data, 'id_emr.allergy', []),
            birthDate:
              data.id_emr !== undefined
                ? moment(data.id_emr.social[0].birth.date)
                    .locale('id')
                    .format('L')
                : '',
            id_recipe: data.id_recipe,
            id_consumable: data.id_consumable,
          };
          this.getRecipe(this.data.id_recipe);

          this.reviewDrug = {
            administrative: data.administrative,
            clinical: data.clinical,
            drugs_review: data.drugs_review,
            pharmaceuticals: data.pharmaceutical,
            id_staff: data.status[0].id_staff,
            recipeVerifier: data.recipe_verifier
              ? data.recipe_verifier.detail[0].name
              : null,
            drugsVerifier: data.drugs_verifier
              ? data.drugs_verifier.detail[0].name
              : null,
            type: data.status[0].type,
          };
        });
    },
    async getRecipe(id) {
      await axios
        .get(Constant.apiUrl.concat(`/patient/recipe/${id}/handover`))
        .then(response => {
          const data = response.data.data;
          let price = [];

          this.consumableTable.item = data.medical_tools.map(arr => {
            price.push(arr.price);
            return {
              id: arr._id,
              name: arr.name,
              goodsGiven: arr.quantity,
              unit: arr.packaging,
              drugs: arr.id_drugs,
              status: arr.isDelivered,
              price: arr.price,
            };
          });

          this.racikTable.item = data.mix_recipe
            ? data.mix_recipe.map(mix => {
                price.push(mix.price);
                return {
                  id: mix._id,
                  name: mix.name,
                  drugs: mix.drugs,
                  usage: mix.usage,
                  general_rule: mix.general_rule,
                  expiry: mix.expiry,
                  isRacik: true,
                  isApproved: mix.isApproved,
                  recipe_number: mix.recipe_number,
                  isCreated: mix.isCreated,
                  packaging: mix.packaging,
                  description: mix.description,
                  date: moment(mix.date)
                    .locale('id')
                    .format('l'),
                  staff: mix.staff,
                  change_note: mix.change_note,
                  price: mix.price,
                  status: mix.isDelivered,
                  quantity: mix.quantity,
                };
              })
            : '';

          this.nonRacikTable.item =
            data.recipe !== null
              ? data.recipe.map(nonMix => {
                  price.push(nonMix.price);
                  return {
                    id: nonMix._id,
                    recipe_number: nonMix.recipe_number,
                    isApproved: nonMix.isApproved,
                    drugs: nonMix.id_drugs,
                    preparation: nonMix.preparation,
                    dosage: nonMix.dosage,
                    expiry: nonMix.expiry,
                    general_rule: nonMix.general_rule,
                    unit_dosage: nonMix.unit_dosage,
                    drugTaken: nonMix.quantity,
                    quantity: nonMix.requested_quantity,
                    packaging: nonMix.packaging,
                    description: nonMix.description,
                    usage: nonMix.usage,
                    name: nonMix.name,
                    shownName: nonMix.id_drugs.name,
                    date: moment(nonMix.date)
                      .locale('id')
                      .format('l'),
                    staff: nonMix.staff,
                    isRacik: false,
                    change_note: nonMix.change_note,
                    price: nonMix.price,
                    status: nonMix.isDelivered,
                  };
                })
              : '';
          this.drugList = this.racikTable.item
            .concat(this.nonRacikTable.item)
            .sort((a, b) => a.recipe_number - b.recipe_number);
          this.totalPrice = price.reduce((a, b) => a + b);
        });
    },
    saveData(type) {
      const id_recipe = this.data.id_recipe;

      if (type === 'nonRacik') {
        const recipe = this.nonRacikTable.item.map(nonRacik => {
          return {
            _id: nonRacik.id,
            isDelivered: nonRacik.status,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        axios
          .put(Constant.apiUrl.concat(`/patient/recipe/${id_recipe}`), {
            recipe: recipe,
            id_staff: this.$store.getters.userLoggedIn.id,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Penyerahan Obat dan Alkes Selesai', '', 'success');
            }
          });
      }
      if (type === 'racik') {
        const mix_recipe = this.racikTable.item.map(racik => {
          return {
            _id: racik.id,
            name: racik.name,
            isDelivered: racik.status,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        axios
          .put(Constant.apiUrl.concat(`/patient/recipe/${id_recipe}`), {
            mix_recipe: mix_recipe,
            id_staff: this.$store.getters.userLoggedIn.id,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Penyerahan Obat dan Alkes Selesai', '', 'success');
            }
          });
      }
      if (type === 'consumable') {
        const medical_tools = this.consumableTable.item.map(alkes => {
          return {
            _id: alkes.id,
            id_drugs: alkes.drugs._id,
            isDelivered: alkes.status,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        axios
          .put(Constant.apiUrl.concat(`/patient/recipe/${id_recipe}`), {
            medical_tools: medical_tools,
            id_staff: this.$store.getters.userLoggedIn.id,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Penyerahan Obat dan Alkes Selesai', '', 'success');
            }
          });
      }
    },
    onClick(index) {
      switch (index) {
        case 1:
          this.saveData('nonRacik');
          break;
        case 2:
          this.saveData('racik');
          break;
        case 3:
          this.saveData('consumable');
          break;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPatient();
    });
  },
  computed: {
    allergyList() {
      let allergy = '';
      for (let i = 0; i < this.data.allergy.length; i++) {
        allergy = allergy
          .concat(i + 1)
          .concat('. ')
          .concat(this.data.allergy[i])
          .concat(' ');
      }
      return allergy || 'Tidak ada alergi';
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  position: fixed;
  top: 110px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
  padding-bottom: 15px;
  height: fit-content;
}

.footer-sticky {
  width: 100%;
  position: fixed;
  bottom: -30px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
}

.after-sticky {
  margin-top: 200px;
}

.before-sticky {
  margin-bottom: 70px;
}

.absolute-e-ticket {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  left: 4%;
}

.header,
.header-2 {
  display: flex;
  list-style-type: none;
}

.detail {
  width: 100%;
  height: 100%;
  .container {
    max-width: 1280px;
  }
}

.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
  }
}
.body-container {
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.header-layout {
  // margin: 0 !important;
  // width: 100%;
  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: auto;
    color: #9aa0a4;
    cursor: pointer;
    &:hover {
      color: rgb(207, 15, 15);
    }
  }
  .heading_big {
    text-align: left;
    padding-right: 22px;
  }
  .row-info {
    height: 100%;
    .header-info-widget {
      .info-head {
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 0.9rem;
        // padding-right: 50px;
      }
      .label-head {
        text-align: left;
        font-size: 0.9rem;
        font-weight: normal;
        font-family: Roboto;
        color: #949a9d;
        font-style: normal;
        &.special-label {
          max-width: 400px;
          white-space: normal;
          padding-left: 10px;
          padding-right: 15px;
        }
        &.red-color {
          font-weight: normal;
          color: red;
        }
      }
    }
  }
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .v-data-table-header {
    background-color: #f4f5f5;
  }
}

.total-price {
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  min-width: 30%;
  padding: 0.8rem;
  color: #3f484a;
  display: flex;
  align-items: center;
  background-color: #f0f9ff;
}

.title {
  text-align: left;
  font-size: 16px !important;
  color: #137bc0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.center-width {
  margin: 0 auto;
  width: 95%;
}

.action-layout {
  .action-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    .btn-create {
      margin: 0 20px;
      min-width: 145px;
      height: 40px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;
      outline: 2px solid #349cdc;
      border: 2px solid #349cdc;
    }
  }
}

// .action-layout {
//   display: flex;
//   .action-footer {
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     &.disabled {
//       cursor: not-allowed;
//     }
//     .btn-create {
//       margin: 0 20px;
//       min-width: 145px;
//       height: 37px;
//       font-weight: 700;
//       background-color: #3498db !important;
//       color: white;
//     }
//   }
// }
</style>
