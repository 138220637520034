<template>
  <v-card>
    <v-container fluid class="container-layout">
      <!-- Case typ & anamnesis -->
      <section-title title="jenis kasus dan anamnesis" />
      <v-row class="mb-10">
        <v-col class="grid">
          <label>Jenis Kasus</label>
          <v-combobox
            :items="resource.case"
            v-model="case_type"
            placeholder="Pilih atau Masukkan Jenis Kasus"
            append-icon="mdi-chevron-down"
            multiple
            :readonly="isEmr || hasRoles().isSuperManager"
          >
            <template v-slot:prepend-item>
              <p class="grey--text ma-4 text-start body-1">
                Pilih atau Ketik Untuk Pilihan Lainnya
              </p>
            </template>
          </v-combobox></v-col
        >
        <v-col class="grid">
          <template v-for="(item, index) in anamnesis">
            <label
              :key="'anamnesis-label-' + index"
              :class="{ required: !index }"
              >{{ index ? '' : 'Anamnesis' }}</label
            >
            <div :key="'anamnesis-' + index">
              <v-textarea
                no-resize
                :readonly="isEmr || hasRoles().isSuperManager"
                height="100"
                outlined
                :rules="rule"
                dense
                single-line
                :placeholder="'Anamnesis ' + (index + 1)"
                v-model="anamnesis[index]"
                :id="'anamnesis-' + index"
              />
              <div style="margin-top: -3vh" class="d-flex justify-end">
                <v-btn
                  :disabled="anamnesis.length < 2 || isEmr"
                  @click="deleteAnamnesis(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  @click="addAnamnesis()"
                  :disabled="isEmr || hasRoles().isSuperManager"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </div>
          </template>
        </v-col>
      </v-row>

      <!-- Glasgown coma scale (GCS) -->
      <section-title title="glasgown coma scale" />
      <v-row class="mb-10">
        <v-col class="grid">
          <label>Eye</label>
          <v-combobox
            :readonly="isEmr || hasRoles().isSuperManager"
            dense
            placeholder="Eye"
            :items="resource.gcsEyePick"
            :clearable="!isEmr"
            v-model="eye"
            item-text="text"
            item-value="value"
            append-icon="mdi-chevron-down"
          />
          <label>Motor</label>
          <v-combobox
            :readonly="isEmr || hasRoles().isSuperManager"
            dense
            placeholder="Motor"
            :items="resource.gcsMotorPick"
            v-model="motoric"
            :clearable="!isEmr"
            item-text="text"
            item-value="value"
            append-icon="mdi-chevron-down"
          />
        </v-col>
        <v-col class="grid">
          <label>Verbal</label>
          <v-combobox
            :readonly="isEmr || hasRoles().isSuperManager"
            dense
            placeholder="Verbal"
            :clearable="!isEmr"
            :items="resource.gcsVerbalPick"
            v-model="verbal"
            item-text="text"
            item-value="value"
            append-icon="mdi-chevron-down"
          />
          <label>Hasil Skor</label>
          <label class="ma-0">{{ total }}</label>
        </v-col>
      </v-row>

      <!-- Data objektif -->
      <section-title title="data obyektif" />
      <v-row class="mb-10">
        <v-col class="grid">
          <label>Tekanan Darah</label>
          <div class="d-flex">
            <v-text-field
              :readonly="isEmr || hasRoles().isSuperManager"
              dense
              placeholder="Sistolik"
              :rules="[rangeRules(sistol, 'sistole')]"
              v-model="sistol"
              v-on:keypress="isNumber($event)"
              id="sistole"
              type="number"
            />
            <span class="title mx-4 grey--text">/</span>
            <v-text-field
              :readonly="isEmr || hasRoles().isSuperManager"
              dense
              :rules="[rangeRules(diastol, 'diastole')]"
              placeholder="Diastolik"
              type="number"
              suffix="mmhg"
              v-model="diastol"
              id="diastole"
              v-on:keypress="isNumber($event)"
            />
          </div>
          <label>Nadi</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="x/menit"
            placeholder="Nadi"
            dense
            :rules="[rangeRules(pulse, 'heart-rate')]"
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="pulse"
          ></v-text-field>
          <label>Berat Badan</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="kg"
            placeholder="Berat Badan"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="weight"
          ></v-text-field>
          <label>Tinggi Badan</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="cm"
            placeholder="Tinggi Badan"
            dense
            :rules="[rangeRules(height, 'height')]"
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="height"
          ></v-text-field>
        </v-col>
        <v-col class="grid">
          <label>Lingkar Perut</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="cm"
            placeholder="Lingkar Perut"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="belly_circumference"
          ></v-text-field>
          <label>RR</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="cm"
            placeholder="RR"
            dense
            :rules="[rangeRules(respiration_rate, 'rr')]"
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="respiration_rate"
          ></v-text-field>
          <label>Saturasi O<sub>2</sub></label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="%"
            placeholder="Saturasi O"
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            v-model.number="saturation"
          ></v-text-field>
          <label>Suhu</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            suffix="°C"
            placeholder="Suhu Tubuh"
            dense
            type="number"
            :rules="[rangeRules(temperature, 'temperature')]"
            v-on:keypress="isNumber($event)"
            v-model.number="temperature"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- pemeriksaan fisik -->
      <physical-check mb="5vh" />

      <!-- pemeriksaan penunjang -->
      <supporting-investigation mb="5vh" />

      <!-- diagnosis -->
      <section-title title="assesmen" />
      <v-row class="mb-10">
        <v-col cols="6" class="grid mb-3">
          <label>Diagnosa Utama</label>
          <v-combobox
            :readonly="isEmr || hasRoles().isSuperManager"
            dense
            :clearable="!isEmr"
            append-icon="mdi-chevron-down"
            placeholder="Pilih Diagnosa Utama"
            hide-details="auto"
            :items="resource.mainDiagnose"
            item-text="displayName"
            v-model="main_diagnose.text"
            :loading="diagnoseLoading"
            :search-input.sync="searchDiagnose"
            id="mainDiagnose"
          />
        </v-col>
        <v-col class="grid">
          <label>Informasi Ket. Klinis</label>
          <v-text-field
            :readonly="isEmr || hasRoles().isSuperManager"
            dense
            placeholder="Masukkan Informasi"
            hide-details
            v-model="main_diagnose.detail"
          />
        </v-col>
        <template v-for="(diagnose, index) in secondary_diagnose">
          <v-col cols="6" class="py-0 grid" :key="'diagnoseLabel-' + index">
            <label v-if="index === 0">Diagnosa Sekunder</label>
            <v-col cols="5" v-else></v-col>
            <div class="d-flex pa-0 mb-4">
              <v-combobox
                :readonly="isEmr || hasRoles().isSuperManager"
                dense
                :clearable="!isEmr"
                placeholder="Pilih Diagnosa Sekunder"
                hide-details
                :items="resource.secondaryDiagnose"
                append-icon="mdi-chevron-down"
                item-text="displayName"
                @focus="secDiagnoseActvIdx = index"
                :search-input.sync="searchDiagnoseSecondary[index]"
                :loading="secDiagnoseLoading"
                v-model="secondary_diagnose[index].text"
              />
              <v-btn
                :disabled="secondary_diagnose.length < 2 || isEmr"
                icon
                @click="deleteSecondaryDiagnose(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                :disabled="isEmr || hasRoles().isSuperManager"
                icon
                @click="addSecondaryDiagnose()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0 mb-4 grid"
            :key="'diagnoseInfo2-' + index"
          >
            <label>Informasi Ket. Klinis</label>
            <v-text-field
              :readonly="isEmr || hasRoles().isSuperManager"
              dense
              placeholder="Masukkan Informasi"
              hide-details
              v-model="secondary_diagnose[index].detail"
            />
          </v-col>
        </template>
        <template v-for="(diagnose, index) in differential_diagnose">
          <v-col cols="6" class="py-0 grid" :key="'diagnoseLabel2-' + index">
            <label v-if="index === 0"
              >Diagnosa Banding
              <v-tooltip
                v-if="index === 0"
                max-width="300"
                color="#3F484AE5"
                content-class="text-start"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small text fab v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-help-circle</v-icon>
                  </v-btn>
                </template>
                <span
                  >Keputusan yang dibuat oleh DPJP ketika pasien dalam masa
                  perawatan</span
                >
              </v-tooltip></label
            >

            <v-col cols="5" v-else></v-col>
            <div class="d-flex pa-0 mb-4">
              <v-text-field
                :readonly="isEmr || hasRoles().isSuperManager"
                dense
                placeholder="Pilih Diagnosa Banding"
                hide-details
                v-model="differential_diagnose[index].text"
              />
              <v-btn
                :disabled="differential_diagnose.length < 2 || isEmr"
                icon
                @click="deleteDiffDiagnose(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                :disabled="isEmr || hasRoles().isSuperManager"
                icon
                @click="addDiffDiagnose()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" class="py-0 mb-4 grid" :key="'diagnoseInfo-' + index">
            <label>Informasi Ket. Klinis</label>
            <v-text-field
              :readonly="isEmr || hasRoles().isSuperManager"
              dense
              placeholder="Masukkan Informasi"
              hide-details
              v-model="differential_diagnose[index].description"
            />
          </v-col>
        </template>
      </v-row>

      <section-title title="planning" />
      <v-row>
        <template v-for="(action, index) in action_plan">
          <v-col cols="6" :key="'action-' + index" class="grid">
            <label>{{ index === 0 ? 'Rencana Tindakan' : '' }}</label>
            <div class="d-flex pa-0 mb-4">
              <v-text-field
                :placeholder="'Tindakan ' + (index + 1)"
                :readonly="isEmr || hasRoles().isSuperManager"
                dense
                v-model="action_plan[index].info"
              ></v-text-field>
              <v-btn
                :disabled="action_plan.length < 2 || isEmr"
                icon
                @click="deleteActionPlan(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                :disabled="isEmr || hasRoles().isSuperManager"
                icon
                @click="addActionPlan()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" :key="'actions-' + index" class="grid">
            <label>{{ index === 0 ? 'Waktu' : '' }}</label>
            <v-menu
              ref="menu"
              :disabled="isEmr || hasRoles().isSuperManager"
              v-model="menu[index]"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="action_plan[index].time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="action_plan[index].time"
                  :clearable="!isEmr"
                  placeholder="Waktu"
                  append-icon="mdi-chevron-down"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu"
                v-model="action_plan[index].time"
                full-width
                format="24hr"
                @click:minute="$refs.menu[index].save(action_plan[index].time)"
              ></v-time-picker> </v-menu
          ></v-col>
        </template>
      </v-row>

      <v-divider class="mb-10"></v-divider>
      <v-row>
        <v-col class="grid">
          <label>Tindak Lanjut</label>
          <v-combobox
            append-icon="mdi-chevron-down"
            :readonly="isEmr || hasRoles().isSuperManager"
            :items="resource.followUp"
            v-model="follow_up"
            placeholder="Pilih Tindak Lanjut"
          />
          <label v-if="follow_up === 'Rujuk'">Dirujuk ke</label>
          <v-text-field
            v-if="follow_up === 'Rujuk'"
            dense
            :readonly="isEmr || hasRoles().isSuperManager"
            v-model="refer_to"
            placeholder="Dirujuk ke"
          />
        </v-col>
        <v-col class="grid">
          <label>Terapi IGD</label>
          <v-textarea
            :readonly="isEmr || hasRoles().isSuperManager"
            outlined
            :rules="rule"
            v-model="igd_therapy"
            no-resize
          />
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isloading"
        indeterminate
        color="cyan"
      ></v-progress-linear>
    </v-container>

    <v-card-actions class="pb-0">
      <v-btn tile text class="text-none" depressed @click="$emit('close-form')"
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <template v-if="!hasRoles().isSuperManager">
        <v-btn
          tile
          v-if="!isEmr"
          color="primary"
          depressed
          @click="saveData()"
          class="text-none"
          >Simpan</v-btn
        >
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import SectionTitle from '../../../SharedComponent/Assesment/SectionTitle.vue';
import PhysicalCheck from '../../../SharedComponent/Assesment/PhysicalCheck.vue';
import SupportingInvestigation from '../../../SharedComponent/Assesment/SupportingInvestigation.vue';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import roleMixin from '@/mixin/roleMixin';
import { postAssessment } from '../../../../fetchApi/Assessment/IGD';
import jwtMixin from '@/mixin/jwtMixin';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const {
  mapGetters: mapDoctorAssessmentGetters,
  mapMutations: mapDoctorAssessmentMutations,
} = createNamespacedHelpers('DoctorAssessmentIGD');
const {
  mapGetters: mapSupportingInvestigationGetters,
} = createNamespacedHelpers('SupportingInvestigation');
const { mapGetters: mapPhysicalCheckGetters } = createNamespacedHelpers(
  'PhysicalCheck',
);

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DoctorAssessment',
  mixins: [AlertMixin, jwtMixin, roleMixin],
  components: {
    SectionTitle,
    PhysicalCheck,
    SupportingInvestigation,
  },
  props: {
    triase: {
      type: Object,
    },
    patientData: {
      type: Object,
    },
    doctor: {
      type: Object,
    },
    nursery: {
      type: Object,
    },
    isloading: {
      type: Boolean,
    },
  },
  data() {
    return {
      menu: [false],
      rule: [v => v.split(' ').length <= 3000 || 'Maksimal 3000 karakter!'],
      resource: {
        followUp: [
          'Pulang',
          'Rawat Inap',
          'Rujuk',
          'Pulang Paksa',
          'Meninggal',
        ],
        case: [
          'Bedah Trauma',
          'Bedah Non-Trauma',
          'Non-Bedah',
          'Anak',
          'Syaraf',
          'Jantung',
        ],
        gcsEyePick: [
          { text: 'Open spontaneously', value: 4 },
          { text: 'Open to verbal command', value: 3 },
          { text: 'Open to pain', value: 2 },
          { text: 'No eye opening', value: 1 },
        ],
        gcsMotorPick: [
          { text: 'Obeys command', value: 6 },
          { text: 'Localising pain', value: 5 },
          { text: 'Withdrawal from pain', value: 4 },
          { text: 'Flexion to pain', value: 3 },
          { text: 'Extension to pain', value: 2 },
          { text: 'No motor response', value: 1 },
        ],
        gcsVerbalPick: [
          { text: 'Orientated', value: 5 },
          { text: 'Confused', value: 4 },
          { text: 'Inappropriate words', value: 3 },
          { text: 'Incomprehensible sounds', value: 2 },
          { text: 'No verbal response', value: 1 },
        ],
        mainDiagnose: [],
        secondaryDiagnose: [],
      },
      diagnoseLoading: false,
      secDiagnoseLoading: false,
      secDiagnoseActvIdx: 0,
      searchDiagnose: '',
      searchDiagnoseSecondary: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDiagnose('');
    });
  },
  beforeDestroy() {
    this.clearDoctorAssessmentState();
  },
  watch: {
    // search main diagnose
    searchDiagnose(val) {
      if (val) {
        this.searchMainDiagnose(this, val);
      } else {
        this.searchMainDiagnose(this, '');
      }
    },
    // search secondary diagnose
    searchDiagnoseSecondary(val) {
      if (!val) return;
      this.searchSecondaryDiagnose(this);
    },
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapSupportingInvestigationGetters([
      'getOtherNotes',
      'getLab',
      'getRadiology',
    ]),
    ...mapPhysicalCheckGetters([
      'getHead',
      'getPhysicalEye',
      'getEar',
      'getNose',
      'getMouth',
      'getNeck',
      'getHair',
      'getLips',
      'getTeeth',
      'getTongue',
      'getPalate',
      'getThroat',
      'getTonsil',
      'getChest',
      'getBreast',
      'getBack',
      'getStomach',
      'getGenitalia',
      'getAnus',
      'getUpperArm',
      'getForeArm',
      'getFinger',
      'getHandNail',
      'getHandJoint',
      'getUpperLimb',
      'getLowerLimb',
      'getToe',
      'getToeNail',
      'getLegJoint',
    ]),
    ...mapDoctorAssessmentGetters([
      'getAnamnesis',
      'getCaseType',
      'getMainDiagnose',
      'getSecondaryDiagnose',
      'getDifferentialDiagnose',
      'getEye',
      'getMotoric',
      'getVerbal',
      'getTotal',
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
      'getActionPlan',
      'getFollowUp',
      'getReferTo',
      'getIgdTherapy',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    other_notes: {
      get() {
        return this.getOtherNotes;
      },
    },
    lab: {
      get() {
        return this.getLab;
      },
    },
    radiology: {
      get() {
        return this.getRadiology;
      },
    },
    head: {
      get() {
        return this.getHead;
      },
    },
    physical_eye: {
      get() {
        return this.getPhysicalEye;
      },
    },
    ear: {
      get() {
        return this.getEar;
      },
    },
    nose: {
      get() {
        return this.getNose;
      },
    },
    mouth: {
      get() {
        return this.getMouth;
      },
    },
    neck: {
      get() {
        return this.getNeck;
      },
    },
    hair: {
      get() {
        return this.getHair;
      },
    },
    lips: {
      get() {
        return this.getLips;
      },
    },
    teeth: {
      get() {
        return this.getTeeth;
      },
    },
    tongue: {
      get() {
        return this.getTongue;
      },
    },
    palate: {
      get() {
        return this.getPalate;
      },
    },
    throat: {
      get() {
        return this.getThroat;
      },
    },
    tonsil: {
      get() {
        return this.getTonsil;
      },
    },
    chest: {
      get() {
        return this.getChest;
      },
    },
    breast: {
      get() {
        return this.getBreast;
      },
    },
    back: {
      get() {
        return this.getBack;
      },
    },
    stomach: {
      get() {
        return this.getStomach;
      },
    },
    genitalia: {
      get() {
        return this.getGenitalia;
      },
    },
    anus: {
      get() {
        return this.getAnus;
      },
    },
    upper_arm: {
      get() {
        return this.getUpperArm;
      },
    },
    forearm: {
      get() {
        return this.getForeArm;
      },
    },
    finger: {
      get() {
        return this.getFinger;
      },
    },
    hand_nail: {
      get() {
        return this.getHandNail;
      },
    },
    hand_joint: {
      get() {
        return this.getHandJoint;
      },
    },
    upper_limb: {
      get() {
        return this.getUpperLimb;
      },
    },
    lower_limb: {
      get() {
        return this.getLowerLimb;
      },
    },
    toe: {
      get() {
        return this.getToe;
      },
    },
    toe_nail: {
      get() {
        return this.getToeNail;
      },
    },
    leg_joint: {
      get() {
        return this.getLegJoint;
      },
    },
    anamnesis: {
      get() {
        return this.getAnamnesis;
      },
      set(value) {
        this.setDoctorAnamnesis(value);
      },
    },
    case_type: {
      get() {
        return this.getCaseType;
      },
      set(value) {
        this.setCaseType(value);
      },
    },
    main_diagnose: {
      get() {
        return this.getMainDiagnose;
      },
      set(value) {
        this.setMainDiagnose(value);
      },
    },
    secondary_diagnose: {
      get() {
        return this.getSecondaryDiagnose;
      },
      set(value) {
        this.setSecondaryDiagnose(value);
      },
    },
    differential_diagnose: {
      get() {
        return this.getDifferentialDiagnose;
      },
      set(value) {
        this.setDifferentialDiagnose(value);
      },
    },
    eye: {
      get() {
        return this.getEye;
      },
      set(value) {
        this.setEye(value);
      },
    },
    motoric: {
      get() {
        return this.getMotoric;
      },
      set(value) {
        this.setMotoric(value);
      },
    },
    verbal: {
      get() {
        return this.getVerbal;
      },
      set(value) {
        this.setVerbal(value);
      },
    },
    total: {
      get() {
        return this.getTotal;
      },
    },
    sistol: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setDoctorSistol(value);
      },
    },
    diastol: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDoctorDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setDoctorWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setDoctorHeight(value);
      },
    },
    belly_circumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setDoctorBellyCircumference(value);
      },
    },
    respiration_rate: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setDoctorRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setDoctorSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setDoctorTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setDoctorPulse(value);
      },
    },
    action_plan: {
      get() {
        return this.getActionPlan;
      },
      set(value) {
        this.setActionPlan(value);
      },
    },
    follow_up: {
      get() {
        return this.getFollowUp;
      },
      set(value) {
        this.setFollowUp(value);
      },
    },
    refer_to: {
      get() {
        return this.getReferTo;
      },
      set(value) {
        this.setReferTo(value);
      },
    },
    igd_therapy: {
      get() {
        return this.getIgdTherapy;
      },
      set(value) {
        this.setIgdTherapy(value);
      },
    },
    isAdult() {
      const birthDate = moment(this.patientData.meta.birthDate, 'YYYY-MM-DD');
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
  },
  methods: {
    ...mapDoctorAssessmentMutations([
      'clearDoctorAssessmentState',
      'setDoctorAnamnesis',
      'addAnamnesis',
      'deleteAnamnesis',
      'addActionPlan',
      'setActionPlan',
      'deleteActionPlan',
      'deleteSecondaryDiagnose',
      'setSecondaryDiagnose',
      'setDifferentialDiagnose',
      'addSecondaryDiagnose',
      'addDiffDiagnose',
      'deleteDiffDiagnose',
      'setMainDiagnose',
      'setCaseType',
      'setEye',
      'setMotoric',
      'setVerbal',
      'setDoctorSistol',
      'setDoctorDiastol',
      'setDoctorPulse',
      'setDoctorWeight',
      'setDoctorHeight',
      'setDoctorBellyCircumference',
      'setDoctorRespirationRate',
      'setDoctorSaturation',
      'setDoctorTemperature',
      'setFollowUp',
      'setReferTo',
      'setIgdTherapy',
    ]),
    searchMainDiagnose: _.debounce((v, keyword) => {
      v.getDiagnose(keyword, true, false, false);
    }, 600),
    searchSecondaryDiagnose: _.debounce(v => {
      v.getDiagnose(
        v.searchDiagnoseSecondary[v.secDiagnoseActvIdx],
        false,
        true,
        false,
      );
    }, 600),
    rangeRules(value, type) {
      switch (type) {
        case 'sistole':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'diastole':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          } else {
            return true;
          }
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          } else {
            return true;
          }
        case 'temperature':
          if ((value < 20 || value > 45) && value !== '') {
            return 'Angka suhu tidak valid';
          } else {
            return true;
          }
        case 'height':
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          } else {
            return true;
          }
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9,.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    searchData: _.debounce(
      (v, index) => v.getDiagnose(v.searchDiagnose[index]),
      650,
    ),
    close() {
      this.$emit('close-form');
    },
    async saveData() {
      try {
        const payload = {
          id_unit: this.$store.getters.selectedPatient.meta.id_poly,
          id_registration: this.patientData.id_registration,
          id_emr: this.patientData.id_emr,
          isAdult: this.isAdult,
          detail_doctor: [
            {
              anamnesis: this.anamnesis.filter(x => x !== ''),
              case_type: this.case_type.filter(x => x !== ''),
              gcs: {
                eye: this.eye?.text || '',
                motoric: this.motoric?.text || '',
                verbal: this.verbal?.text || '',
                total: this.total,
              },
              objective: {
                sistol: this.sistol,
                diastol: this.diastol,
                heart_rate: this.pulse,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.belly_circumference,
                respiration_rate: this.respiration_rate,
                saturation: this.saturation,
                temperature: this.temperature,
              },
              physical: {
                head: this.head,
                eye: this.physical_eye,
                ear: this.ear,
                nose: this.nose,
                mouth: this.mouth,
                neck: this.neck,
                hair: this.hair,
                lips: this.lips,
                teeth: this.teeth,
                tongue: this.tongue,
                palate: this.palate,
                throat: this.throat,
                tonsil: this.tonsil,
                chest: this.chest,
                breast: this.breast,
                back: this.back,
                stomach: this.stomach,
                genitalia: this.genitalia,
                anus: this.anus,
                upper_arm: this.upper_arm,
                forearm: this.forearm,
                finger: this.finger,
                hand_nail: this.hand_nail,
                hand_joint: this.hand_joint,
                upper_limb: this.upper_limb,
                lower_limb: this.lower_limb,
                toe: this.toe,
                toe_nail: this.toe_nail,
                leg_joint: this.leg_joint,
              },
              other: {
                laboratory_result: this.lab,
                radiology_result: this.radiology,
                other_notes: this.other_notes,
              },
              assesment: {
                main_diagnose: this.main_diagnose?.text?.id
                  ? this.main_diagnose
                  : undefined,
                other_diagnose: this.secondary_diagnose
                  .filter(item => item?.text?.id)
                  .map(item => {
                    return {
                      text: {
                        id: item.text?.id,
                        text: item.detail,
                        code: item.text?.code,
                      },
                    };
                  }),
                differential_diagnose: this.differential_diagnose
                  .filter(i => i.text)
                  .map(item => {
                    return {
                      diagnose: item.text,
                      description: item.description,
                    };
                  }),
              },
              planning: {
                action_plan: this.action_plan.filter(x => x.info),
                follow_up: {
                  action: this.follow_up,
                  description: this.refer_to || '',
                },
                igd_therapy: this.igd_therapy,
              },
              timestamps: {
                doctor_by: this.$store.getters.userLoggedIn.id,
                doctor_created_at: moment().format(),
                created_by: this.$store.getters.userLoggedIn.id,
                created_at: moment().format(),
              },
            },
          ],
        };
        const response = await postAssessment({
          payload,
          role: 'dokter',
        });
        if (response.status === 200) {
          Swal.fire('Data Asesmen IGD Berhasil Ditambahkan', '', 'success');
          this.close();
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    getDiagnose(keyword, isPrimer = true, isSecondary = true) {
      if (isPrimer) this.diagnoseLoading = true;
      if (isSecondary) this.secDiagnoseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=100&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            if (isPrimer) {
              this.resource.mainDiagnose = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.diagnoseLoading = false;
            }
            if (isSecondary) {
              this.resource.secondaryDiagnose = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.secDiagnoseLoading = false;
            }
          })
          .catch(() => {
            if (isPrimer) this.diagnoseLoading = false;
            if (isSecondary) this.secDiagnoseLoading = false;
          });
      });
    },
    changeDiagnose(event, index) {
      if (event.keyCode === 9 && event.keyCode === 13) return;
      if (this.diagnosis[index].text.displayName !== undefined) {
        this.diagnosis.push({
          text: '',
          detail: '',
        });
      } else if (
        (this.diagnosis[index].text === '' &&
          this.diagnosis[index].text === null) &
        (this.diagnosis.length !== 1)
      ) {
        this.diagnosis.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  border-radius: 6px;
  color: $outline;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  height: max-content;
  font-size: calc($standard-font-size - 0.1vw);
}

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.container-layout {
  height: 73vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }

  .grid {
    @include grid-display(14vw minmax(16vw, 30vw), true);
  }
}
::v-deep .theme--light.v-label {
  color: black;
  font-size: 14px;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
