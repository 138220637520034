import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment-timezone';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts';
import VueKonva from 'vue-konva';
import './sass/main.scss';
import './sass/variables.scss';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faSyncAlt,
  faUser,
  faNotesMedical,
  faSyringe,
  faPrescriptionBottle,
  faFlask,
  faPrint,
  faCalendarAlt,
  faClipboardCheck,
  faGlasses,
  faFileSignature,
  faLongArrowAltUp,
  faLongArrowAltDown,
} from '@fortawesome/free-solid-svg-icons';
import VueMask from 'v-mask';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.config.productionTip = false;
library.add(
  faHome,
  faSyncAlt,
  faUser,
  faNotesMedical,
  faSyringe,
  faPrescriptionBottle,
  faFlask,
  faPrint,
  faClipboardCheck,
  faCalendarAlt,
  faGlasses,
  faFileSignature,
  faLongArrowAltUp,
  faLongArrowAltDown,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueKonva);
Vue.use(VueMask);
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'id',
  decimalLength: 0,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

moment.tz.setDefault('Asia/Jakarta');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
