<template>
  <div class="main-drugs">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Staf
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>
          <v-combobox
            v-if="hasRoles().isSuperManagerAdmin"
            append-icon="mdi-chevron-down"
            class="branch-filter"
            placeholder="Cabang"
            label="Cabang"
            outlined
            dense
            hide-details="false"
            single-line
            v-model="branchFilter"
            @change="refreshAndAutoUpdate()"
            clearable
            item-text="name"
            :items="resource.branch"
          />
          <v-btn class="mr-2" color="grey" icon text @click="getAllStaffQueue">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-form autocomplete="off" onsubmit="return false;">
            <v-text-field
              class="input"
              background-color="grey lighten-3"
              placeholder="Search"
              v-model="search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
            ></v-text-field>
          </v-form>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="staffs"
              :loading="loading"
              :expanded="expanded"
              single-expand
              item-key="nip"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                  selectItem(item);
                }
              "
            >
              <template v-slot:item="{ item, expand, isExpanded }">
                <tr
                  class="text-left"
                  :key="item.index"
                  @click="expand(!isExpanded)"
                >
                  <td>{{ item.nip }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.phone_no }}
                  </td>
                  <td>
                    {{ item.specialist }}
                  </td>
                  <td class="text-capitalize">
                    <p>
                      {{ item.role[0].id_branch.name }}
                    </p>
                  </td>
                  <td class="text-capitalize">
                    <p>{{ item.role[0].role }}</p>
                  </td>
                  <td>
                    <v-switch
                      :readonly="hasRoles().isSuperManager"
                      inset
                      v-model="item.role[0].status"
                      @change="handleChangeStatus(item)"
                    ></v-switch>
                  </td>
                </tr>
                <tr
                  v-for="(x, i) in item.role.slice(1)"
                  class="text-left"
                  :key="i"
                  @click="expand(!isExpanded)"
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-capitalize">
                    <p>
                      {{ x.id_branch.name }}
                    </p>
                  </td>
                  <td class="text-capitalize">
                    <p>{{ x.role }}</p>
                  </td>
                  <td>
                    <v-switch
                      :readonly="hasRoles().isSuperManager"
                      inset
                      v-model="x.status"
                    ></v-switch>
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td
                  @click="selectItem(item)"
                  :colspan="headers.length"
                  class="expand"
                >
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-dialog
                            v-model="staffDetailDialog"
                            width="1206px"
                            persistent
                          >
                            <template #activator="{ on: staffDetailDialog }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    icon
                                    class="mx-2 hover"
                                    v-on="{ ...tooltip, ...staffDetailDialog }"
                                    @click="selectItem(item)"
                                  >
                                    <v-icon small> mdi-account </v-icon>
                                  </v-btn>
                                </template>
                                <span>Detail Staff</span>
                              </v-tooltip>
                            </template>
                            <staff-detail
                              @close-dialog="closeDialog"
                              @update-data="getAllStaffQueue"
                              :staffDetail="selectedItem"
                              v-if="selectedItem && staffDetailDialog"
                            />
                          </v-dialog>

                          <v-dialog
                            v-model="updateDialog"
                            width="1206px"
                            persistent
                          >
                            <template #activator="{ on: updateDialog }">
                              <v-tooltip
                                bottom
                                v-if="!hasRoles().isSuperManager"
                              >
                                <template #activator="{ on: tooltip }">
                                  <v-btn
                                    icon
                                    class="mx-2 hover"
                                    v-on="{ ...tooltip, ...updateDialog }"
                                    @click="selectItem(item)"
                                  >
                                    <v-icon small> mdi-clipboard-text </v-icon>
                                  </v-btn>
                                </template>
                                <span>Ubah Staff</span>
                              </v-tooltip>
                            </template>
                            <update-data-staff
                              :staffDetail="selectedItem"
                              @close-dialog="closeDialog"
                              @update-data="getAllStaffQueue"
                              v-if="selectedItem && updateDialog"
                            />
                          </v-dialog>
                          <v-dialog
                            v-model="accessRightDialog"
                            fullscreen
                            persistent
                          >
                            <template #activator="{ on: accessRightDialog }">
                              <v-tooltip bottom>
                                <template #activator="{ on: tooltip }">
                                  <v-btn
                                    icon
                                    class="mx-2 hover"
                                    v-on="{ ...tooltip, ...accessRightDialog }"
                                    @click="selectItem(item)"
                                  >
                                    <v-icon small> mdi-shield-remove </v-icon>
                                  </v-btn>
                                </template>
                                <span>Ubah Hak Akses</span>
                              </v-tooltip>
                            </template>
                            <AccessRights
                              @close-dialog="closeDialog"
                              :staffDetail="selectedItem"
                              v-if="accessRightDialog"
                            />
                          </v-dialog>

                          <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                            <template #activator="{ on: tooltip }">
                              <v-btn
                                icon
                                class="mx-2 hover"
                                v-on="{ ...tooltip }"
                                @click="resetPassword(item)"
                              >
                                <v-icon small> mdi-lock </v-icon>
                              </v-btn>
                            </template>
                            <span>Ganti Kata Sandi</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="!hasRoles().isSuperManager">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="changeStaffStatus(item)"
                              >
                                <v-icon small> mdi-close-thick </v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Status</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="staffPages"
            @input="getAllStaffQueue()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" width="1206px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!hasRoles().isSuperManager"
                  color="primary"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <InputNewStaff
                v-if="dialog"
                @close-dialog="closeDialog"
                @update-data="getAllStaffQueue"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputNewStaff from './components/Staff/InputNewStaff';
import Constant from '@/const';
import Swal from 'sweetalert2';
import StaffDetail from './components/Staff/StaffDetail.vue';
import UpdateDataStaff from './components/Staff/UpdateDataStaff.vue';
import AccessRights from './components/Staff/AccessRights.vue';
import jwtMixin from '@/mixin/jwtMixin';
import roleMixin from '@/mixin/roleMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterDataStaff',
  mixins: [jwtMixin, roleMixin],
  components: {
    InputNewStaff,
    StaffDetail,
    UpdateDataStaff,
    AccessRights,
  },
  data() {
    return {
      dialog: false,
      staffDetailDialog: false,
      updateDialog: false,
      accessRightDialog: false,
      loading: true,
      branchFilter: '',
      selectedItem: null,
      staffs: [],
      staffNewStatus: false,
      staffPages: 0,
      expanded: [],
      resource: {
        branch: [],
      },
      table: {
        headers: [
          {
            text: 'NIP',
            align: 'start',

            value: 'nip',
          },
          { text: 'Nama', value: 'name' },
          { text: 'Telepon', value: 'phone_no' },
          { text: 'Spesialis', value: 'specialist' },
          { text: 'Cabang', value: 'branch' },
          { text: 'Role', value: 'role' },
          { text: 'Status', value: 'status' },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      search: '',
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getBranch();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllStaffQueue();
    }, 650),
    closeDialog() {
      this.dialog = false;
      this.staffDetailDialog = false;
      this.updateDialog = false;
      this.accessRightDialog = false;
    },
    handleChangeStatus(item) {
      axios.put(
        Constant.apiUrl.concat('/master/staff/status/').concat(item.id),
        {
          role: {
            status: item.role[0].status,
          },
        },
      );
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    getBranch() {
      axios
        .get(Constant.apiUrl.concat('/branch'))
        .then(response => {
          if (response.status === 200) {
            const { data } = response.data;
            this.resource.branch = data.map(branch => {
              return {
                id: branch._id.toString(),
                name: branch.name,
              };
            });
          }
        })
        .catch(() => {
          this.resource.branch = [];
        });
    },
    resetPassword(items) {
      Swal.fire({
        title: 'Ganti Kata Sandi',
        html:
          '<label>Minimal 8 karakter</label><div></div>' +
          '<label>kombinasi huruf kapital, huruf kecil, angka</label>' +
          '<input id="password" type="password" placeholder="Kata sandi baru" class="swal2-input">' +
          '<input id="rePassword" type="password" placeholder="Ulang kata sandi baru" class="swal2-input">',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          const pass = document.getElementById('password').value;
          const rePass = document.getElementById('rePassword').value;
          if (pass === rePass) {
            // /^
            //   (?=.*\d)          // should contain at least one digit
            //   (?=.*[a-z])       // should contain at least one lower case
            //   (?=.*[A-Z])       // should contain at least one upper case
            //   [a-zA-Z0-9!@#$%&?"']{8,}   // should contain at least 8 from the mentioned characters
            // $/
            if (pass.match(Constant.passwordAuth)) {
              axios
                .post(Constant.apiUrl.concat('/master/staff/reset'), {
                  id_staff: items.id,
                  password: pass,
                })
                .then(response => {
                  if (response.status === 200) {
                    Swal.fire(
                      'Berhasil',
                      'Kata Sandi Berhasil Diperbaharui',
                      'success',
                    );
                    this.getAllStaffQueue();
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan;
                    Swal.fire({
                      type: 'error',
                      title: 'Oops...',
                      text: message,
                    });
                  } else if (error.request) {
                    Swal.fire({
                      type: 'error',
                      title: 'Oops...',
                      text: 'Tidak dapat menyambung ke server',
                    });
                  }
                });
            } else {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Kata sandi tidak sesuai ketentuan',
              });
            }
          } else {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Kata sandi tidak sama',
            });
          }
        }
      });
    },
    changeStaffStatus(items) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: items.role.status ? 'Non aktifkan staff ?' : 'Aktifkan staff ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          if (items.role.status === true) {
            this.staffNewStatus = false;
          } else {
            this.staffNewStatus = true;
          }
          axios
            .put(
              Constant.apiUrl.concat('/master/staff/status/').concat(items.id),
              {
                role: {
                  status: this.staffNewStatus,
                },
              },
            )
            .then(response => {
              if (response.status === 200) {
                Swal.fire(
                  'Berhasil',
                  'Status Staff Berhasil Diubah',
                  'success',
                );
                this.getAllStaffQueue();
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan;
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: message,
                });
              } else if (error.request) {
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Tidak dapat menyambung ke server',
                });
              }
            });
        }
      });
    },
    getAllStaffQueue() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      const keyword = this.search;
      this.loading = true;

      this.runApiCalls(() => {
        axios
          .get(
            `${
              Constant.apiUrl
            }/master/staff?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}&branch=${this
              .branchFilter?.id || this.$store.getters.userLoggedIn.branch_id}`,
          )
          .then(response => {
            const { data } = response.data;
            this.staffPages = Math.ceil(response.data.length / itemCount);

            this.staffs = data.map(staff => {
              return {
                address: staff.detail.address,
                bank:
                  staff.detail.bank !== undefined
                    ? {
                        id_bank: staff.detail.bank.id_bank,
                        account: staff.detail.bank.account,
                      }
                    : {
                        id_bank: '',
                        account: '',
                      },
                birth: {
                  city: staff.detail.birth.city,
                  date: staff.detail.birth.date,
                },
                blood_type: staff.detail.blood_type,
                end_sik: staff.detail.end_sik,
                end_sip: staff.detail.end_sip,
                end_str: staff.detail.end_str,
                gender: staff.detail.gender,
                name: staff.detail.name,
                nik: staff.detail.nik,
                phone_no: staff.detail.phone_no,
                religion: staff.detail.religion,
                sik: staff.detail.sik,
                sip: staff.detail.sip,
                specialist: staff.detail.specialize,
                start_practice: staff.detail.start_practice,
                str: staff.detail.str,
                nip: staff.nip.toString(),
                role: staff.role,
                id: staff._id.toString(),
              };
            });
            this.loading = false;
          })
          .catch(() => {
            this.staffs = [];
            this.loading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllStaffQueue();
      this.interval = setInterval(this.getAllStaffQueue, 60000000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 100%;
}
.branch-filter {
  width: 20px;
}
.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
