<template>
  <section :style="style">
    <section-title title="pemeriksaan fisik" />
    <img src="@/assets/image/HumanBody.svg" alt="Human Body Image" />
    <v-row>
      <v-col cols="6" class="grid-display">
        <label>Kepala</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="head"
          placeholder="Contoh: Normal"
          class="mb-5"
          hide-details
          dense
        />
        <label>Mata</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="eye"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Telinga</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="ear"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Hidung</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="nose"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Mulut</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="mouth"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Leher</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="neck"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Rambut</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="hair"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Bibir</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="lips"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Gigi Geligi</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="teeth"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Lidah</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="tongue"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Langit-langit</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="palate"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Tenggorokan</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="throat"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Tonsil</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="tonsil"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Dada</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="chest"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Payudara</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="breast"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6" class="grid-display">
        <label>Punggung</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="back"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Perut</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="stomach"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Genitalia</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="genitalia"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Anus/Dubur</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="anus"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Lengan Atas</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="upper_arm"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Lengan Bawah</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="forearm"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Jari Tangan</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="finger"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Kuku Tangan</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="hand_nail"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Persendian Tangan</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="hand_joint"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Tungkai Atas</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="upper_limb"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Tungkai Bawah</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="lower_limb"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Jari Kaki</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="toe"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Kuku Kaki</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="toe_nail"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
        <label>Persendian Kaki</label>
        <v-text-field
          :readonly="isEmr || hasRoles().isSuperManager"
          v-model="leg_joint"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
import roleMixin from '@/mixin/roleMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('PhysicalCheck');
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);

export default {
  name: 'PhysicalCheck',
  components: { SectionTitle },
  mixins: [roleMixin],
  props: {
    pl: { type: String },
    pr: { type: String },
    pt: { type: String },
    pb: { type: String },
    ml: { type: String },
    mr: { type: String },
    mt: { type: String },
    mb: { type: String },
  },
  computed: {
    style() {
      return `
      --pl: ${this.pl}; 
      --pr: ${this.pr};
      --pt: ${this.pt};
      --pb: ${this.pb};
      --mb: ${this.mb};
      --mt: ${this.mt};
      --ml: ${this.ml};
      --mr: ${this.mr};
      `;
    },
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getHead',
      'getPhysicalEye',
      'getEar',
      'getNose',
      'getMouth',
      'getNeck',
      'getHair',
      'getLips',
      'getTeeth',
      'getTongue',
      'getPalate',
      'getThroat',
      'getTonsil',
      'getChest',
      'getBreast',
      'getBack',
      'getStomach',
      'getGenitalia',
      'getAnus',
      'getUpperArm',
      'getForeArm',
      'getFinger',
      'getHandNail',
      'getHandJoint',
      'getUpperLimb',
      'getLowerLimb',
      'getToe',
      'getToeNail',
      'getLegJoint',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    head: {
      get() {
        return this.getHead;
      },
      set(value) {
        this.setHead(value);
      },
    },
    eye: {
      get() {
        return this.getPhysicalEye;
      },
      set(value) {
        this.setPhysicalEye(value);
      },
    },
    ear: {
      get() {
        return this.getEar;
      },
      set(value) {
        this.setEar(value);
      },
    },
    nose: {
      get() {
        return this.getNose;
      },
      set(value) {
        this.setNose(value);
      },
    },
    mouth: {
      get() {
        return this.getMouth;
      },
      set(value) {
        this.setMouth(value);
      },
    },
    neck: {
      get() {
        return this.getNeck;
      },
      set(value) {
        this.setNeck(value);
      },
    },
    hair: {
      get() {
        return this.getHair;
      },
      set(value) {
        this.setHair(value);
      },
    },
    lips: {
      get() {
        return this.getLips;
      },
      set(value) {
        this.setLips(value);
      },
    },
    teeth: {
      get() {
        return this.getTeeth;
      },
      set(value) {
        this.setTeeth(value);
      },
    },
    tongue: {
      get() {
        return this.getTongue;
      },
      set(value) {
        this.setTongue(value);
      },
    },
    palate: {
      get() {
        return this.getPalate;
      },
      set(value) {
        this.setPalate(value);
      },
    },
    throat: {
      get() {
        return this.getThroat;
      },
      set(value) {
        this.setThroat(value);
      },
    },
    tonsil: {
      get() {
        return this.getTonsil;
      },
      set(value) {
        this.setTonsil(value);
      },
    },
    chest: {
      get() {
        return this.getChest;
      },
      set(value) {
        this.setChest(value);
      },
    },
    breast: {
      get() {
        return this.getBreast;
      },
      set(value) {
        this.setBreast(value);
      },
    },
    back: {
      get() {
        return this.getBack;
      },
      set(value) {
        this.setBack(value);
      },
    },
    stomach: {
      get() {
        return this.getStomach;
      },
      set(value) {
        this.setStomach(value);
      },
    },
    genitalia: {
      get() {
        return this.getGenitalia;
      },
      set(value) {
        this.setGenitalia(value);
      },
    },
    anus: {
      get() {
        return this.getAnus;
      },
      set(value) {
        this.setAnus(value);
      },
    },
    upper_arm: {
      get() {
        return this.getUpperArm;
      },
      set(value) {
        this.setUpperArm(value);
      },
    },
    forearm: {
      get() {
        return this.getForeArm;
      },
      set(value) {
        this.setForeArm(value);
      },
    },
    finger: {
      get() {
        return this.getFinger;
      },
      set(value) {
        this.setFinger(value);
      },
    },
    hand_nail: {
      get() {
        return this.getHandNail;
      },
      set(value) {
        this.setHandNail(value);
      },
    },
    hand_joint: {
      get() {
        return this.getHandJoint;
      },
      set(value) {
        this.setHandJoint(value);
      },
    },
    upper_limb: {
      get() {
        return this.getUpperLimb;
      },
      set(value) {
        this.setUpperLimb(value);
      },
    },
    lower_limb: {
      get() {
        return this.getLowerLimb;
      },
      set(value) {
        this.setLowerLimb(value);
      },
    },
    toe: {
      get() {
        return this.getToe;
      },
      set(value) {
        this.setToe(value);
      },
    },
    toe_nail: {
      get() {
        return this.getToeNail;
      },
      set(value) {
        this.setToeNail(value);
      },
    },
    leg_joint: {
      get() {
        return this.getLegJoint;
      },
      set(value) {
        this.setLegJoint(value);
      },
    },
  },
  beforeDestroy() {
    this.clearPhysicalCheckState();
  },
  methods: {
    ...mapMutations([
      'setHead',
      'setPhysicalEye',
      'clearPhysicalCheckState',
      'setEar',
      'setNose',
      'setMouth',
      'setNeck',
      'setHair',
      'setLips',
      'setTeeth',
      'setTongue',
      'setPalate',
      'setThroat',
      'setTonsil',
      'setChest',
      'setBreast',
      'setBack',
      'setStomach',
      'setGenitalia',
      'setAnus',
      'setUpperArm',
      'setForeArm',
      'setFinger',
      'setHandNail',
      'setHandJoint',
      'setUpperLimb',
      'setLowerLimb',
      'setToe',
      'setToeNail',
      'setLegJoint',
    ]),
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-left: var(--pl);
  padding-right: var(--pr);
  padding-top: var(--pt);
  padding-bottom: var(--pb);
  margin-left: var(--ml);
  margin-right: var(--mr);
  margin-top: var(--mt);
  margin-bottom: var(--mb);
}
.grid-display {
  display: grid;
  grid-template-columns: 14vw 30vw;
  font-size: 0.9vw;
  label {
    font-weight: 500;
    text-align: start;
    margin-left: 1vw;
    font-size: 0.9vw;
  }
}
</style>
