<template>
  <v-card>
    <v-card-title class="text-left">
      <span class="font-weight-bold">Detail Tagihan Pasien</span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="closeButton">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="container-width mx-auto">
      <v-row no-gutters>
        <v-col cols="9" class="text-left">
          <v-row no-gutters>
            <span class="blue--text font-weight-medium text-subtitle-1"
              >Data Pasien</span
            >
          </v-row>
          <div class="box-data__grey py-2">
            <v-row no-gutters>
              <v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">No. RM</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">{{
                    billingData.rm_number
                  }}</span>
                </v-row>
              </v-col>
              <v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Nama Pasien</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">{{
                    billingData.name
                  }}</span>
                </v-row>
              </v-col>
              <v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Unit</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">{{
                    billingData.unit
                  }}</span>
                </v-row> </v-col
              ><v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Tanggal Masuk</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text"
                    >{{ patientBill.enterDate }} WIB</span
                  >
                </v-row> </v-col
              ><v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Status Kelas</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text"
                    >Status Kelas</span
                  >
                </v-row> </v-col
              ><v-col cols="2" class="pl-2">
                <v-row no-gutters>
                  <span class="text-caption">DPJP</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">{{
                    patientBill.doctor
                  }}</span>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">No Kartu</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">
                    -</span
                  >
                </v-row>
              </v-col>
              <v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Tanggal Lahir</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text"
                    >{{ patientBill.dob }} ({{ billingData.age }}) Tahun</span
                  >
                </v-row>
              </v-col>
              <v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">No Pendaftaran</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">{{
                    billingData.registNumber
                  }}</span>
                </v-row> </v-col
              ><v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Tanggal Keluar</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text"
                    >{{ patientBill.leaveDate }} WIB</span
                  >
                </v-row> </v-col
              ><v-col cols="2" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption">Tipe Penjamin</span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">{{
                    billingData.assurance.toUpperCase()
                  }}</span>
                </v-row> </v-col
              ><v-col cols="2" class="pl-2">
                <v-row no-gutters>
                  <span class="text-caption"></span>
                </v-row>
                <v-row no-gutters> </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="3" class="pl-4">
          <v-row no-gutters>
            <span class="blue--text font-weight-medium text-subtitle-1"
              >Ringkasan Tagihan</span
            >
          </v-row>
          <div class="box-data__grey py-2">
            <v-row no-gutters>
              <v-col cols="6" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption"> Tagihan Klinik </span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">
                    {{ currency(patientBill.sub_total || 0) }}
                  </span>
                </v-row>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-row no-gutters>
                  <span class="text-caption"> Hal Kelas/Limit </span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">
                    {{ currency(patientBill.insurance || 0) }}
                  </span>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="6" class="border-right pl-2">
                <v-row no-gutters>
                  <span class="text-caption"> Data Kebajikan </span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">
                    {{ currency(patientBill.kebajikan || 0) }}
                  </span>
                </v-row>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-row no-gutters>
                  <span class="text-caption"> Tagihan Pasien </span>
                </v-row>
                <v-row no-gutters>
                  <span class="font-weight-medium text-caption black--text">
                    {{ currency(patientBill.remaining_bill || 0) }}
                  </span>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-space-between mt-2">
        <v-col cols="3" class="d-flex text-left align-center">
          <span class="blue--text font-weight-medium text-subtitle-1"
            >Tagihan</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" class="pl-4">
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Cari"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <div class="data-table mt-2 mx-auto">
      <v-data-table
        :headers="headersBilling"
        :items="itemBilling"
        :items-per-page="5"
        :search="search"
        :loading="isLoading"
      >
        <template v-slot:[`item.no`]="{ item }">
          <tr>
            <td>{{ itemBilling.indexOf(item) + 1 }}</td>
          </tr>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.quantity"
            lazy
            :ref="`jumlah${item.id}`"
            @save="
              editItem(item);
              getData();
            "
            large
            persistent
          >
            <div>{{ item.quantity }}</div>
            <template v-slot:input>
              <v-col class="text-left px-0">
                <label class="body-2 blue--text font-weight-bold">Jumlah</label>
              </v-col>
              <v-text-field
                v-model="item.quantity"
                :label="item.quantity.toString()"
                mask="###"
                single-line
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.price"
            lazy
            :ref="`diskon${item.id}`"
            @save="
              editItem(item);
              getData();
            "
            large
            persistent
          >
            <div>{{ currency(parseInt(item.price || 0)) }}</div>
            <template v-slot:input>
              <v-col class="text-left px-0">
                <label class="body-2 blue--text font-weight-bold"
                  >Input Diskon</label
                >
              </v-col>
              <v-text-field
                v-model="item.diskon"
                :label="item.price.toString()"
                single-line
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.subTotal`]="{ item }">
          <tr>
            <td>{{ currency(item.subTotal || 0) }}</td>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-show="!patientBill.isPaid"
            small
            class="mr-2"
            @click="
              $refs[`jumlah${item.id}`].isActive = true;
              $refs[`diskon${item.id}`].isActive = true;
            "
          >
            mdi-square-edit-outline
          </v-icon>
          <v-icon
            small
            v-show="!patientBill.isPaid"
            @click="deleteItem(item)"
            color="red"
          >
            mdi-close-box
          </v-icon>
        </template>
        <template v-slot:[`body.append`]>
          <tr class="total-row">
            <td class="text-right font-weight-bold" :colspan="7">Total</td>
            <td :colspan="2" class="text-left font-weight-bold">
              {{ currency(patientBill.sub_total || 0) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-card-actions color="white" class="py-3 justify-space-between">
      <v-btn
        depressed
        color="primary"
        outlined
        width="100"
        class="ml-2"
        @click="printInvoice"
      >
        <v-icon class="mr-1">mdi-printer</v-icon>
        Print
      </v-btn>
      <v-spacer></v-spacer>
      <div
        style="height: 36px"
        class="box-data__blue mx-2 d-flex align-center px-4"
      >
        <v-row no-gutters>
          <v-icon class="mr-2">mdi-credit-card</v-icon>
          <span class="text-subtitle-2">Total Tagihan Pasien</span>
          <span class="text-subtitle-2 ml-6">
            {{ currency(patientBill.total_bill || 0) }}</span
          >
        </v-row>
      </div>
      <v-btn
        depressed
        outlined
        color="primary"
        @click="onClick(1)"
        class="mx-2"
        :disabled="
          patientBill.assurance === 'UMUM' ||
            patientBill.assurance === 'BPJS' ||
            patientBill.isPaid === true
        "
      >
        Input Limit Asuransi
      </v-btn>

      <v-btn
        v-show="patientBill.isPaid"
        class="mx-2"
        depressed
        color="error"
        outlined
        @click="cancelPaid()"
        >Batalkan Pelunasan</v-btn
      >

      <v-btn
        depressed
        color="primary"
        class="mx-2"
        width="90"
        :disabled="patientBill.isPaid"
        @click="onClick(2)"
      >
        {{ billingData.isWadiah ? 'Topup' : 'Bayar' }}
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog" :max-width="selectedItem === 2 ? 200 : 1280">
      <InputHakKelas
        v-if="selectedItem === 1 && dialog"
        :data="patientBill"
        @close-form="closeForm(0)"
      />
      <v-card v-if="selectedItem === 2 && dialog" height="200">
        <div class="pa-3">
          <h3>Pilih Cara Bayar</h3>
        </div>
        <div class="pa-3">
          <v-btn
            @click="
              selectedButton = 'debit';
              debitModal = true;
              dialog = false;
            "
            class="button-payment text-none"
            :class="{ active: selectedButton === 'debit' }"
            >Debit</v-btn
          >
        </div>
        <div class="pa-3">
          <v-btn
            @click="
              selectedButton = 'tunai';
              debitModal = true;
              dialog = false;
            "
            class="button-payment text-none"
            :class="{ active: selectedButton === 'tunai' }"
            >Tunai</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="debitModal" width="550">
      <PaymentInput
        v-if="debitModal"
        :data="patientBill"
        :selectedButton="selectedButton"
        @close-form="closeForm(1)"
        @is-close-detail="$emit('close-detail')"
      ></PaymentInput>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
import InputHakKelas from './InputHakKelas';
import PaymentInput from './PaymentInput';
import JsPDF from 'jspdf';
import formatMixin from '@/mixin/formatMixin';
import 'jspdf-autotable';
import terbilang from '@/helper/terbilang.js';
import Constant from '@/const';
import Swal from 'sweetalert2';
import printMixin from '@/mixin/printMixin';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DialogBilling',
  mixins: [printMixin, alertMixin, formatMixin],
  components: {
    InputHakKelas,
    PaymentInput,
  },
  props: {
    billingData: Object,
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      headersBilling: [
        {
          text: 'No',
          sortable: false,
          value: 'no',
        },
        {
          text: 'Tanggal',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        { text: 'Item Pelayanan', value: 'name' },
        { text: 'Operator', value: 'created_by' },
        { text: 'Jumlah', value: 'quantity' },
        { text: 'Satuan', value: 'unit' },
        { text: 'Harga', value: 'price' },
        { text: 'Sub Total', value: 'subTotal' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      itemBilling: [],
      dialog: false,
      selectedItem: 0,
      search: '',
      selectedButton: 'debit',
      debitModal: false,
      patientBill: [],
      isLoading: false,
      disabled: false,
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  methods: {
    deleteItem(item) {
      const payload = {
        staff: this.$store.getters.userLoggedIn.id,
      };
      Swal.fire({
        html: `Anda akan menghapus<br/> <strong>${item.name}?<strong/>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          axios
            .put(
              Constant.apiUrl.concat(
                `/billing/delete/${this.billingData.id}/${item.id}/${item.type}`,
              ),
              payload,
            )
            .then(response => {
              if (response) {
                Swal.fire(
                  `${item.name} berhasil dihapus dari tagihan`,
                  '',
                  'success',
                );
                this.getData();
              }
            })
            .catch(error => {
              if (error) {
                Swal.fire(
                  `${item.name} gagal dihapus dari tagihan`,
                  '',
                  'error',
                );
              }
            });
        }
      });
    },
    closeButton() {
      this.$emit('close-form');
    },
    closeForm(index) {
      switch (index) {
        case 0:
          this.getData();
          this.dialog = false;
          break;
        case 1:
          this.getData();
          this.debitModal = false;
          break;
        default:
          this.dialog = false;
          break;
      }
    },
    getData() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat(`/billing/${this.billingData.id}`))
        .then(response => {
          const data = response.data.data;
          this.lengthPage = Math.ceil(data.length / 5);
          const addressData = [data.id_emr.social[0].address.text];

          if (data.id_emr.social[0].address.sub_district) {
            addressData.push(data.id_emr.social[0].address.sub_district);
          }
          if (data.id_emr.social[0].address.district) {
            addressData.push(data.id_emr.social[0].address.district);
          }
          if (data.id_emr.social[0].address.city) {
            addressData.push(data.id_emr.social[0].address.city);
          }
          if (data.id_emr.social[0].address.province) {
            addressData.push(data.id_emr.social[0].address.province);
          }

          this.patientBill = {
            id: data._id,
            name: data.id_emr.social[0].name,
            registNumber: data.id_registration.registration_number,
            rm_number: data.id_emr.medical_record_number,
            assurance: data.id_registration.assurance.type.toUpperCase(),
            unit: _.get(data, 'id_registration.id_unit.name', '-'),
            status: data.id_registration ? 'Pasien Terdaftar' : '',
            isPaid: data.isPaid,
            gender: data.id_emr.social[0].gender ? 'Laki-laki' : 'Perempuan',
            age: data.id_emr.social[0].age,
            date: moment(data.id_registration.timestamps.created_at)
              .locale('id')
              .format('l'),
            time: moment(data.id_registration.timestamps.created_at)
              .locale('id')
              .format('HH:mm'),
            isWadiah: this.billingData.isWadiah,
            detail: data.detail,
            sub_total: data.sub_total,
            total_paid: data.total_paid,
            total_bill: data.total_bill,
            insurance:
              data.insurance_limit.length === 0
                ? _.sumBy(data.hak_kelas, 'amount')
                : _.sumBy(data.insurance_limit, 'amount'),
            kebajikan: data.benevolence_fund,
            enterDate: moment(data.id_registration.timestamps.created_at)
              .locale('id')
              .format('DD/MM/YYYY HH:mm'),
            leaveDate: moment(new Date())
              .locale('id')
              .format('DD/MM/YYYY HH:mm'),
            doctor: data.staff_name,
            dob: moment(data.id_emr.social[0].birth.date)
              .locale('id')
              .format('ll'),
            remaining_bill: data.remaining_bill,
            total_wadiah: data.total_wadiah,
            isBpjs: data.isBpjs,
            isInsurance: data.isInsurance,
            wadiah: data.wadiah,
            payment: data.payment,
            return_detail: data.return_detail,
            id_registration: data.id_registration._id,
            id_emr: data.id_registration.id_emr,
            total_return: data.total_return,
            hak_kelas: data.hak_kelas,
            insurance_limit: data.insurance_limit,
            address: addressData.join(', '),
          };

          const intervention = data.detail.intervention.map(inter => {
            return {
              id: inter._id,
              created_at: moment(inter.timestamps.created_at)
                .locale('id')
                .format('L'),
              name: _.get(inter, 'id_service.name', '-'),
              category: _.get(inter, 'id_service.category', '-'),
              subcategory: _.get(inter, 'id_service.sub_category', '-'),
              created_by: inter.operator.detail[0].name,
              quantity: inter.amount,
              unit: inter.package_unit,
              price: inter.price,
              id_intervention: inter.id_intervention,
              subTotal: inter.discount
                ? inter.price * inter.amount - inter.discount
                : inter.price * inter.amount,
              isIntervention: true,
              id_service: _.get(inter, 'id_service._id', '-'),
              type: 'service',
            };
          });

          const recipe = data.detail.recipe.map(recipe => {
            return {
              id: recipe._id,
              created_at: moment(recipe.timestamps.created_at)
                .locale('id')
                .format('L'),
              name: recipe.name_mix_recipe || recipe.id_drugs.detail[0].name,
              created_by: recipe.timestamps.created_by.detail[0].name,
              quantity: recipe.amount,
              unit: recipe.package_unit,
              price: recipe.price,
              // subTotal: recipe.price * recipe.amount,
              isRecipe: true,
              subTotal: recipe.discount
                ? recipe.price * recipe.amount - recipe.discount
                : recipe.price * recipe.amount,
              id_drugs: recipe.id_drugs ? recipe.id_drugs._id : null,
              id_recipe: recipe.id_recipe ? recipe.id_recipe : null,
              type: 'recipe',
            };
          });

          const item = intervention.concat(recipe);

          const consumable = data.detail.consumable.map(consume => {
            return {
              id: consume._id,
              created_at: moment(consume.timestamps.created_at)
                .locale('id')
                .format('L'),
              name:
                consume.id_drugs === null && consume.id_goods === null
                  ? null
                  : consume.id_drugs === null
                  ? consume.id_goods.detail[0].name
                  : consume.id_drugs.detail[0].name,
              created_by: consume.timestamps.created_by.detail[0].name,
              quantity: consume.amount,
              unit: consume.package_unit,
              price: consume.price,
              // subTotal: consume.price * consume.amount,
              isConsumable: true,
              subTotal: consume.discount
                ? consume.price * consume.amount - consume.discount
                : consume.price * consume.amount,
              id_consumable: consume.id_consumable,
              id_drugs: consume.id_drugs === null ? null : consume.id_drugs._id,
              id_goods: consume.id_goods === null ? null : consume.id_goods._id,
              type: 'consumable',
            };
          });

          this.itemBilling = item.concat(consumable);
          this.isLoading = false;
        });
    },
    onClick(index) {
      switch (index) {
        case 1:
          this.selectedItem = index;
          this.dialog = true;
          break;
        case 2:
          this.selectedItem = index;
          this.dialog = true;
          break;
        default:
          this.dialog = true;
          break;
      }
    },
    cancelPaid() {
      const payload = {
        cancel_by: this.$store.getters.userLoggedIn.id,
      };

      Swal.fire({
        html:
          '<strong>Anda akan melanjutkan<br/> pembatalan pelunasan tagihan<strong/>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          axios
            .put(
              Constant.apiUrl.concat(
                `/billing/rollback/${this.patientBill.id_registration}`,
              ),
              payload,
            )
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', `${response.data.pesan}`, 'success').then(
                  result => {
                    if (result.isConfirmed) {
                      this.$emit('close-form');
                    }
                  },
                );
              }
            })
            .catch(error => {
              this.showErrorAxios(error);
            });
        }
      });
    },
    printInvoice() {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'cm',
        format: 'a4',
      });
      doc.setProperties({
        title: `Print Ticket Invoice : ${this.billingData.rm_number}`,
      });
      const leftMargin = 1.0 * 2.54;
      const rightMargin = 7.3 * 2.54;

      doc.setFontSize(11);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(
        doc,
        this.clientData.name.toUpperCase(),
        0.85 * 2.54,
        'halfCenterStart',
        this.letterData.title.offset * 2.54,
      );
      doc.setFontSize(9);
      doc.setFont('Helvetica', 'normal');
      this.pdfTextPrint(
        doc,
        `${this.clientData.address} ${this.clientData.postCode}. Telepon ${this
          .clientData.phone_number || ''}. ${
          this.clientData.hp_no ? `Hp. ${this.clientData.hp_no} ` : ''
        }email: ${this.clientData.email}`,
        1 * 2.54,
        'halfCenterStart',
        this.letterData.title.offsetData * 2.54,
        {
          maxWidth: 3.7 * 2.54, // wrap text content so when it reaches max width, it will makes a new line
          align: 'justify',
        },
      );
      doc.addImage(
        this.letterData.leftLogo,
        this.letterData.leftLogoExt,
        this.letterData.image.left.x * 2.54,
        this.letterData.image.left.y * 2.54,
        this.letterData.image.left.width * 2.54,
        this.letterData.image.left.height * 2.54,
      );
      doc.addImage(
        this.letterData.rightLogo,
        this.letterData.rightLogoExt,
        this.letterData.image.right.x * 2.54,
        this.letterData.image.right.y * 2.54,
        this.letterData.image.right.width * 2.54,
        this.letterData.image.right.height * 2.54,
      );
      doc.setLineWidth(0.01 * 2.54);
      doc.line(leftMargin, 1.55 * 2.54, rightMargin, 1.55 * 2.54);
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'KUITANSI RAWAT JALAN',
        doc.internal.pageSize.width / 2,
        5,
        'center',
      );

      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');

      doc.autoTable({
        body: [
          ['No. RM', ':', `${this.patientBill.rm_number}`],
          ['Tanggal Masuk', ':', `${this.patientBill.enterDate}`],
          ['Tanggal Keluar', ':', `${this.patientBill.leaveDate}`],
          ['Tipe Penjamin', ':', `${this.patientBill.assurance}`],
        ],
        startY: 5.5,
        style: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 3 },
          1: { cellWidth: 0.5 },
          2: { cellWidth: 5 },
        },
        margin: {
          left: 12,
          right: 2,
        },
        theme: 'plain',
      });

      doc.autoTable({
        body: [
          ['Nama', ':', `${this.patientBill.name}`],
          ['Alamat', ':', `${this.patientBill.address}`],
          ['Dokter', ':', `${this.patientBill.doctor}`],
        ],
        startY: 5.5,
        styles: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 2 },
          1: { cellWidth: 0.5 },
          2: { cellWidth: 5 },
        },
        margin: {
          left: 2,
          right: 2,
        },
        theme: 'plain',
      });

      const data = this.itemBilling.map(item => [
        item.name,
        item.created_by,
        item.quantity,
        this.currency(item.price || 0),
        this.currency(item.subTotal || 0),
      ]);
      const itemTable = doc.autoTable.previous;
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'Rincian Biaya',
        doc.internal.pageSize.width / 2,
        itemTable.finalY + 0.8,
        'center',
      );

      doc.autoTable({
        head: [
          [
            'Item',
            'Operator',
            'Jumlah',
            'Harga (Rp.)',
            // 'Diskon (Rp.)',
            'Total (Rp.)',
          ],
        ],
        body: data,
        startY: itemTable.finalY + 1,
        style: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          2: { cellWidth: 1.6, halign: 'center' },
          3: { cellWidth: 2.3, halign: 'right' },
          4: { cellWidth: 2.1, halign: 'right' },
        },
        margin: {
          left: 2,
          right: 2,
        },
        theme: 'plain',
      });

      const table = doc.autoTable.previous;

      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      doc.autoTable({
        body: [
          [
            'Total Tagihan',
            ':',
            this.currency(this.patientBill.total_bill || 0),
          ],
          [
            'Limit Asuransi',
            ':',
            this.currency(this.patientBill.insurance || 0),
          ],
          ['Pembulatan', ':', this.patientBill.kebajikan || 0],
        ],
        startY: table.finalY + 0.5,
        style: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 3 },
          1: { cellWidth: 0.5 },
          2: { cellWidth: 5 },
        },
        margin: {
          left: 13,
          right: 2,
        },
        theme: 'plain',
      });

      const tablePrev = doc.autoTable.previous;
      doc.setFont('helvetica', 'bold');
      doc.text(
        `Tagihan Pasien : ${this.currency(this.patientBill.total_bill || 0)}`,
        13.2,
        tablePrev.finalY + 0.5,
      );
      doc.text('Terbilang : ', 2, tablePrev.finalY + 0.5);
      doc.setFont('helvetica', 'italic');
      doc.text(
        `${terbilang(this.patientBill.total_bill)}`,
        2,
        tablePrev.finalY + 1,
        {
          maxWidth: 8,
        },
      );

      doc.setFont('helvetica', 'normal');
      doc.text(
        `(Kasir : ${this.$store.getters.userLoggedIn.name})`,
        13.2,
        tablePrev.finalY + 2,
      );
      doc.text(
        `${moment(new Date())
          .locale('id')
          .format('DD MMMM YYYY HH:mm:ss')}`,
        13.2,
        tablePrev.finalY + 2.5,
      );
      // print action
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    editItem(item) {
      if (item.isIntervention) {
        const payload = {
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
            created_at: moment(new Date()).format(),
          },
          sub_total: this.patientBill.sub_total,
          isBpjs: this.patientBill.isBpjs,
          isInsurance: this.patientBill.isInsurance,
          total_bill: this.patientBill.total_bill,
          remaining_bill: this.patientBill.remaining_bill,
          benevolence_fund: this.patientBill.kebajikan,
          wadiah: this.patientBill.wadiah,
          payment: this.patientBill.payment,
          total_paid: this.patientBill.total_paid,
          return_detail: this.patientBill.return_detail,
          total_return: this.patientBill.total_return,
          isPaid: this.patientBill.isPaid,
          id_registration: this.patientBill.id_registration,
          id_emr: this.patientBill.id_emr,
          hak_kelas: this.patientBill.hak_kelas,
          insurance_limit: this.patientBill.insurance_limit,
          detail: {
            intervention: [
              {
                id: item.id,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                  created_at: moment(new Date()).format(),
                },
                id_service: item.id_service,
                operator: this.$store.getters.userLoggedIn.id,
                amount: item.quantity,
                package_unit: item.unit,
                price: item.price,
                discount: item.diskon,
              },
            ],
          },
        };
        axios
          .post(
            Constant.apiUrl.concat(`/billing/${this.patientBill.id}`),
            payload,
          )
          .then(() => this.getData());
      } else if (item.isRecipe) {
        const payload = {
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
            created_at: moment(new Date()).format(),
          },
          sub_total: this.patientBill.sub_total,
          isBpjs: this.patientBill.isBpjs,
          isInsurance: this.patientBill.isInsurance,
          total_bill: this.patientBill.total_bill,
          remaining_bill: this.patientBill.remaining_bill,
          benevolence_fund: this.patientBill.kebajikan,
          wadiah: this.patientBill.wadiah,
          payment: this.patientBill.payment,
          total_paid: this.patientBill.total_paid,
          return_detail: this.patientBill.return_detail,
          total_return: this.patientBill.total_return,
          isPaid: this.patientBill.isPaid,
          id_registration: this.patientBill.id_registration,
          id_emr: this.patientBill.id_emr,
          hak_kelas: this.patientBill.hak_kelas,
          insurance_limit: this.patientBill.insurance_limit,
          detail: {
            recipe: [
              {
                id: item.id,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                  created_at: moment(new Date()).format(),
                },
                id_drugs: item.id_drugs,
                name_mix_recipe: item.name,
                operator: this.$store.getters.userLoggedIn.id,
                id_recipe: item.id_recipe,
                amount: item.quantity,
                package_unit: item.unit,
                price: item.price,
                discount: item.diskon,
              },
            ],
          },
        };
        axios
          .post(
            Constant.apiUrl.concat(`/billing/${this.patientBill.id}`),
            payload,
          )
          .then(() => this.getData());
      } else {
        const payload = {
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
            created_at: moment(new Date()).format(),
          },
          sub_total: this.patientBill.sub_total,
          isBpjs: this.patientBill.isBpjs,
          isInsurance: this.patientBill.isInsurance,
          total_bill: this.patientBill.total_bill,
          remaining_bill: this.patientBill.remaining_bill,
          benevolence_fund: this.patientBill.kebajikan,
          wadiah: this.patientBill.wadiah,
          payment: this.patientBill.payment,
          total_paid: this.patientBill.total_paid,
          return_detail: this.patientBill.return_detail,
          total_return: this.patientBill.total_return,
          isPaid: this.patientBill.isPaid,
          id_registration: this.patientBill.id_registration,
          id_emr: this.patientBill.id_emr,
          hak_kelas: this.patientBill.hak_kelas,
          insurance_limit: this.patientBill.insurance_limit,
          detail: {
            consumable: [
              {
                id: item.id,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                  created_at: moment(new Date()).format(),
                },
                id_consumable: item.id_consumable,
                id_drugs: item.id_drugs,
                id_goods: item.id_goods,
                operator: this.$store.getters.userLoggedIn.id,
                id_recipe: item.id_recipe,
                amount: item.quantity,
                package_unit: item.unit,
                price: item.price,
                discount: item.diskon,
              },
            ],
          },
        };
        axios
          .post(
            Constant.apiUrl.concat(`/billing/${this.patientBill.id}`),
            payload,
          )
          .then(() => this.getData());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 24px 24px 16px 24px !important;
}

.container-width {
  max-width: 1315px !important;
}

.data-table {
  max-width: 1315px !important;
}

.v-small-dialog__content {
  text-align: left !important;
}

.box-data {
  &__grey {
    border: 1px solid #9ca2a5;
    border-radius: 4px;
    background-color: #f5f5f5;
  }
  &__blue {
    border: 0.5px solid #bdbdbd;
    border-radius: 4px;
    background-color: #f0f9ff;
  }
}

.border-right {
  border-right: 1px solid #9ca2a5;
}
.button-payment {
  &.active {
    background-color: #3498db !important;
    color: white;
  }
}

.total-row {
  background-color: #f4f5f5;
}
.container-scroll-table {
  overflow-y: scroll;
  height: 58vh;
  max-width: 1300px;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.v-dialog > .v-card > .v-card__actions {
  position: absolute;
  width: 100%;
  bottom: 1px;
}

.swal2-icon.swal2-question {
  border-color: #f2c94c;
  opacity: 0.2;
  color: #f2c94c;
}
</style>
