import Constant from '../const';

export default {
  methods: {
    pdfTextPrint(doc, text, y, mode = 'center', offset = 0, options) {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let textOffset = 0;
      if (mode === 'center') {
        textOffset = (doc.internal.pageSize.width - textWidth) / 2 + offset;
      }
      if (mode === 'halfCenterStart') {
        textOffset = (doc.internal.pageSize.width - textWidth) / 4 + offset;
      }
      if (mode === 'halfCenterEnd') {
        textOffset = (doc.internal.pageSize.width - textWidth - offset) * 0.75;
      } else if (mode === 'right') {
        textOffset = doc.internal.pageSize.width - textWidth - offset;
      } else if (mode === 'left') {
        textOffset += offset;
      }
      doc.text(text, textOffset, y, options);
    },
    printDocument(doc) {
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    letterHead(doc, clinic) {
      const letterData = Constant.client.find(
        item => item.name.toLowerCase() === clinic.name.toLowerCase(),
      );
      let width = doc.internal.pageSize.getWidth();
      doc.addImage(
        letterData.letterHead.src,
        'PNG',
        (8.27 - letterData.letterHead.width) / 2,
        letterData.letterHead.height === 1.5 ? 0.1 : 0,
        letterData.letterHead.width,
        letterData.letterHead.height,
      );
      if (
        letterData.letterHead.src === '/img/kopsurat_jmc_header.3ff7eec3.png'
      ) {
        doc.addImage(
          letterData.letterFoot.src,
          'PNG',
          (8.27 - width) / 2,
          5.7,
          width,
          letterData.letterFoot.height,
          '',
          'FAST',
        );
      }
    },
  },
};
