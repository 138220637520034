<template>
  <div class="px-3 body-container">
    <v-row no-gutters class="row-style">
      <v-card class="list rounded-lg ml-0" height="550" width="541" flat>
        <v-row class="text-left pl-3" style="width: 541px">
          <span class="title pb-2">Daftar Obat Pasien</span>
        </v-row>
        <div v-if="drugList.length !== 0" class="text-sm-left pl-6 mb-3">
          <span class="sub-title"
            >Diresepkan Oleh
            <span v-if="!isLoadingRecipe" class="text-uppercase">{{
              staffRecipe
            }}</span></span
          >
        </div>
        <span
          v-if="drugList.length === 0 && !isLoadingRecipe"
          class="font-weight-medium grey--text pt-5 caption"
          >Tidak ada permintaan obat</span
        >
        <v-progress-linear
          class="mx-n5"
          indeterminate
          v-if="isLoadingRecipe"
        ></v-progress-linear>
        <div v-if="!isLoadingRecipe">
          <v-card
            :color="item.isActive && !isNew ? '#deeef9' : '#F4F5F5'"
            class="druglist rounded-lg mb-3"
            flat
            light
            :ripple="false"
            v-for="(item, index) in drugList"
            @click="onClick(item, index)"
            :key="item.id"
          >
            <v-card-actions>
              <v-card-title class="py-0 text-left body-2 font-weight-bold">
                {{ index + 1 }}. {{ item.name }}
                <v-icon
                  small
                  color="red"
                  v-show="!item.isAvailable"
                  class="pl-2"
                  >mdi-alert</v-icon
                >
              </v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.stop="deleteItem(item, index)">
                <v-icon medium>mdi-delete </v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-subtitle class="py-4 pb-3 subtitle">
              <v-row class="d-flex align-center">
                <v-col cols="9" class="py-0 pl-5">
                  <span class="mr-3">{{ item.quantity || '' }}</span>
                  <span class="mr-2">•</span>
                  <span class="mr-3">{{ item.route || '-' }}</span>
                  <span class="mr-2">•</span>
                  <span class="mr-3">{{ item.usage || '-' }}</span>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>
        </div>
      </v-card>
      <EntryNewDrug
        v-if="isNew"
        :patient="patient"
        :drug-detail="totalData"
        :id_recipe="id_recipe"
        @close-form="closeForm()"
      />
      <DrugRequestEdit
        v-else
        :drug-detail="selectedData"
        :patient="patient"
        @close-form="closeForm()"
        :isTakeHome="isTakeHome"
        @input-new="inputNewDrug()"
      />
    </v-row>
    <v-row>
      <drug-review
        @review="hadReviewed = true"
        :medtool-amount="totalMedtool"
        :total-data="totalData"
        :review-drug="drugReview"
        :patient="patient"
        :recipeAll="recipeAll"
        :total-price="totalDrugPrice"
        @next="next()"
        :id-pharmacy="idPharmacy"
        v-if="!isLoadingRecipe"
      ></drug-review>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
        class="progress-circular"
        :size="50"
      ></v-progress-circular>
    </v-row>
    <v-card-actions class="action-layout">
      <div class="action-footer">
        <v-btn
          v-if="hadReviewed || recipeReviewed"
          tile
          depressed
          class="btn-entry text-none"
          @click="printTicket(patient, selectedPatient, drugList)"
        >
          Cetak E-Ticket Obat
        </v-btn>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import DrugRequestEdit from './DrugRequestEdit';
import EntryNewDrug from './EntryNewDrug';
import DrugReview from './DrugReview';
import moment from 'moment-timezone';
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
import PrintDrugsEticket from '../../../../../mixin/printDrugsEticket';

const axios = require('axios');

export default {
  mixins: [alertMixin, formatMixin, PrintDrugsEticket],
  components: {
    DrugRequestEdit,
    EntryNewDrug,
    DrugReview,
  },
  props: {
    idPharmacy: String,
    totalMedtool: Number,
    patient: Object,
    recipe: Object,
    selectedPatient: Object,
    drugReview: Object,
    recipeReviewed: Boolean,
    id_recipe: String,
    isTakeHome: Boolean,
  },
  data() {
    return {
      hadReviewed: false,
      isNew: true,
      isLoadingRecipe: true,
      previousIndex: 0,
      selectedItem: '',
      dialog: false,
      staffRecipe: '',
      drugList: [],
      recipeAll: {
        recipe: [],
        mix_recipe: [],
        medical_tools: [],
      },
      totalData: '',
      selectedData: '',
      list_racik: [],
      list_nonRacik: [],
      idRecipe: '',
    };
  },
  computed: {
    totalDrugPrice() {
      const prices = this.drugList.map(drug => {
        return drug.price;
      });
      return prices.reduce((a, b) => a + b, 0);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getRecipe();
    });
  },
  methods: {
    next() {
      this.$emit('next');
    },
    deleteItem(drug, index) {
      Swal.fire({
        text: 'Apakah Anda Yakin Menghapus Obat ini?',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Lanjutkan',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        icon: 'warning',
      }).then(result => {
        if (result.isConfirmed) {
          if (this.previousIndex === index) {
            this.previousIndex = 0;
          }
          axios
            .put(
              Constant.apiUrl.concat(
                `/patient/recipe/${this.idRecipe}/${drug.id}`,
              ),
            )
            .then(response => {
              if (response) {
                if (result.isConfirmed) {
                  this.showFillSuccess(
                    'Berhasil Menghapus',
                    'Resep berhasil dihapus',
                  ),
                    this.getRecipe();
                }
              }
            })
            .catch(error => this.showErrorAxios(error));
        }
      });
    },
    getStaff(id) {
      if (!id) return;
      axios
        .get(Constant.apiUrl.concat(`/master/staff/find/${id}`))
        .then(response => {
          const { data } = response.data;
          this.staffRecipe = data.detail.name;
        });
    },

    getRecipe() {
      // const id_pharmacy = this.selectedPatient.id;
      this.isLoadingRecipe = true;
      axios
        .get(
          Constant.apiUrl.concat(`/patient/recipe/${this.id_recipe}/requested`),
        )
        .then(response => {
          const data = response.data.data;
          if (data.recipe.length > 0) {
            this.getStaff(data.recipe[0].staff);
          }
          if (data.mix_recipe.length > 0) {
            this.getStaff(data.mix_recipe[0].staff);
          }
          const medical_tools = data.medical_tools;
          // const recipe = data.recipe;
          // const mix_recipe = data.mix_recipe;

          this.recipeAll = {
            recipe: data.recipe,
            mix_recipe: data.mix_recipe,
            medical_tools: data.medical_tools,
          };
          // console.log(this.recipeAll);

          this.idRecipe = data._id;
          this.list_racik =
            data.recipe !== null
              ? data.mix_recipe.map(mix => {
                  return {
                    id: mix._id,
                    name: mix.name,
                    drugs: mix.drugs,
                    usage: mix.usage,
                    isRacik: true,
                    isApproved: mix.isApproved,
                    quantity: mix.quantity,
                    general_rule: mix.general_rule,
                    isOdd: mix.isOdd || false,
                    packaging: mix.packaging,
                    description: mix.description,
                    date: moment(mix.date)
                      .locale('id')
                      .format('l'),
                    staff: mix.staff,
                    expanded: false,
                    change_note: mix.change_note,
                    price: mix.price,
                    pricePerItem: mix.pricePerItem,
                    time_delivery: mix.time_delivery,
                    route: mix.route,
                    recipe_number: mix.recipe_number,
                    special_instruction: mix.special_instruction,
                    is_extra_drugs: mix.is_extra_drugs,
                    isActive: false,
                    isAvailable: mix.drugs.every(
                      drug => drug.available_stock === true,
                    ),
                  };
                })
              : '';
          this.list_nonRacik =
            data.recipe !== null
              ? data.recipe.map(nonMix => {
                  return {
                    id: nonMix._id,
                    id_stock: nonMix.id_stock,
                    isApproved: nonMix.isApproved,
                    drugs: nonMix.id_drugs,
                    preparation: nonMix.preparation,
                    dosage: nonMix.dosage,
                    unit_dosage: nonMix.unit_dosage,
                    quantity: nonMix.quantity,
                    packaging: nonMix.packaging,
                    general_rule: nonMix.general_rule,
                    isOdd: nonMix.isOdd || false,
                    description: nonMix.description,
                    usage: nonMix.usage,
                    usage_type: nonMix.usage_type,
                    name: nonMix.id_drugs.name,
                    drug_name: nonMix.name,
                    expiry: nonMix.expiry || '-',
                    date: moment(nonMix.date)
                      .locale('id')
                      .format('l'),
                    staff: nonMix.staff,
                    isRacik: false,
                    expanded: false,
                    change_note: nonMix.change_note,
                    price: nonMix.price,
                    pricePerItem: nonMix.pricePerItem,
                    time_delivery: nonMix.time_delivery,
                    route: nonMix.route,
                    special_instruction: nonMix.special_instruction,
                    is_extra_drugs: nonMix.is_extra_drugs,
                    requested_quantity: nonMix.requested_quantity,
                    recipe_number: nonMix.recipe_number,
                    isActive: false,
                    isAvailable: nonMix.available_stock,
                    remainingStock: nonMix.availability_stock,
                  };
                })
              : '';
          this.drugList = this.list_racik
            .concat(this.list_nonRacik)
            .sort((a, b) => a.recipe_number - b.recipe_number);
          this.totalData = this.drugList.length + medical_tools.length;
        })
        .catch(() => {
          this.drugList = [];
          this.totalData = 0;
        })
        .finally(() => {
          this.isLoadingRecipe = false;
        });
    },
    pdfTextPrint(doc, text, y, mode = 'center', offset = 0) {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let textOffset = 0;
      if (mode === 'center') {
        textOffset = (doc.internal.pageSize.width - textWidth) / 2 + offset;
      } else if (mode === 'right') {
        textOffset = doc.internal.pageSize.width - textWidth - offset;
      } else if (mode === 'left') {
        textOffset += offset;
      }
      doc.text(text, textOffset, y);
    },

    async printTicket(data, patient, drugs) {
      const doc = this.printEticket(data, patient, drugs);

      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    onClick(item, index) {
      this.selectedData = item;
      this.isNew = false;
      this.drugList[this.previousIndex] = {
        ...this.drugList[this.previousIndex],
        isActive: false,
      };
      this.drugList[index] = {
        ...this.drugList[index],
        isActive: true,
      };
      this.previousIndex = index;
    },
    inputNewDrug() {
      this.isNew = true;
    },
    closeForm() {
      this.getRecipe();
      this.dialog = false;
      this.$emit('change-data');
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  margin-top: 20px;
  width: 100%;
  .row-style {
    overflow: auto;
    display: grid;
    grid-template-columns: 541px 1fr;
    &::-webkit-scrollbar {
      display: none;
      // width: 3px;
      // background-color: rgb(163, 163, 163);
    }
    &::-webkit-scrollbar-thumb {
      display: none;
      // width: 3px;
      // background-color: black;
    }
  }
}
.list {
  margin-left: 30px;
  overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 3px;
  //   background-color: rgb(163, 163, 163);
  // }
  // &::-webkit-scrollbar-thumb {
  //   width: 3px;
  //   background-color: black;
  // }
  .title {
    padding: 5px 25px 25px 25px;
    font-size: 0.5rem;
    color: #137bc0;
  }

  .sub-title {
    padding-left: 0.08rem;
    font-size: 0.9rem;
    color: grey;
  }
}
.druglist {
  width: 491px;
  max-height: 100%;
  margin: 0 auto;
  cursor: pointer;

  .subtitle {
    line-height: 5px;
    font-size: 15px;
    text-align: left;

    .icon {
      margin-top: -30px;
      float: right;
      margin-right: 0px;
    }
  }
}
.action-layout {
  display: flex;
  .action-footer {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: -60px;
    &.disabled {
      cursor: not-allowed;
    }
    .btn-create {
      margin: 0 20px;
      min-width: 145px;
      height: 37px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;
    }
    .btn-entry {
      border: 2px solid #3498db;
      color: #3498db;
      outline: cadetblue;
      background-color: white !important;
    }
  }
}
</style>
