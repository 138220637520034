<template>
  <v-container class="main-card">
    <v-progress-circular
      color="primary"
      size="150"
      class="mt-15"
      width="5"
      indeterminate
      v-if="loading"
    ></v-progress-circular>
    <v-expansion-panels
      v-else
      v-model="item.isexpanded"
      class="mb-3"
      v-for="(item, i) in items"
      :key="i"
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-card class="card-history" flat>
            <p class="date">
              <strong>{{ item.date }}, {{ item.time }} {{ item.unit }}</strong>
            </p>
            <p class="doctor">Diresepkan oleh {{ item.staff }}</p>
            <p v-show="item.isexpanded !== 0" class="drug">
              <strong
                style="font-size: 12px"
                v-for="(drug, index) in item.drugs"
                :key="index"
                >{{ drug.name }}{{ item.drugs.length === index + 1 ? '' : ',' }}
              </strong>
            </p>
          </v-card>
          <div class="edit-icon text-right">
            <v-icon @click.stop="addToTable(item.drugs)" size="20"
              >mdi-plus</v-icon
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container class="text-left">
            <ul>
              <li
                v-for="(drug, index) in item.drugs"
                style="font-size: 14px"
                :key="index"
              >
                <p class="ma-0">
                  <strong>{{ `${drug.name} ${drug.usage || ''}` }}</strong>
                </p>
              </li>
            </ul>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import Constant from '@/const';

const axios = require('axios');
const moment = require('moment-timezone');

export default {
  name: 'RecipeHistory',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      expand: null,
      items: [],
    };
  },
  mounted() {
    this.getRecipeHistory();
  },
  computed: {
    route() {
      return this.$route.path;
    },
  },
  methods: {
    isDrug(item) {
      if (!item.id_drugs) {
        return item.item[0].id_drugs.detail[0].category === 'Obat';
      } else {
        return item.id_drugs.detail[0].category === 'Obat';
      }
    },
    addToTable(drugs) {
      this.$emit('add-to-table', drugs);
    },
    async getRecipeHistory() {
      this.loading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/recipe/all/${this.patientData.id_emr}`,
          ),
        );
        const { data } = response.data;
        const dataItem = data
          .filter(d => d.takeHomeDrugs.length !== 0)
          .map(item => {
            return {
              isexpanded: false,
              staff: item.staff_create,
              date: item.dateSplit,
              unit: item.unit,
              drugs: item.takeHomeDrugs.map(drug => {
                if (this.isDrug(drug) && !drug.isMix) {
                  return {
                    isDrug: true,
                    usage_type: drug.usage_type || true,
                    name: drug.name,
                    preparation: drug.preparation,
                    dosage: +drug.strength.split(' ')[0],
                    unit_dosage: drug.strength.split(' ')[1],
                    quantity: drug.quantity,
                    description: drug.description,
                    price: drug.price,
                    usage: drug.usage,
                    general_rule: drug.general_rule,
                    date: moment(new Date()).format(),
                    route: drug.route,
                    id_staff: this.$store.getters.userLoggedIn.id,
                    packaging: drug.packaging,
                    id: drug.id_drugs._id,
                  };
                }
                if (this.isDrug(drug) && drug.isMix) {
                  return {
                    isDrug: true,
                    isMix: true,
                    quantity: drug.quantity,
                    packaging: drug.packaging,
                    description: drug.description,
                    usage: drug.usage,
                    general_rule: drug.general_rule,
                    date: moment(new Date()).format(),
                    id_staff: this.$store.getters.userLoggedIn.id,
                    name: drug.name,
                    route: drug.route,
                    price: drug.price,
                    drugs: drug.item.map(item => {
                      return {
                        name: item.name,
                        preparation: item.preparation,
                        dosage: +item.strength.split(' ')[0],
                        dosage_needed: +item.strength.split(' ')[0],
                        unit_dosage: item.strength.split(' ')[1],
                        quantity: item?.quantity,
                      };
                    }),
                  };
                }
                if (!this.isDrug(drug)) {
                  // medtool mapping
                  return {
                    nameOnly: drug.id_drugs.detail[0].name,
                    name: drug.name,
                    id: drug.id_drugs._id,
                    quantity: drug.quantity,
                    packaging: drug.packaging,
                    strength: drug.strength.split(' ')[0],
                    strength_unit: drug.strength.split(' ')[1],
                    date: moment(new Date()).format(),
                  };
                }
              }),
            };
          });
        for (let i = 0; i < dataItem.length; i++) {
          dataItem[i].drugs = dataItem[i].drugs.flat();
        }
        this.items = dataItem;
      } catch (error) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  background-color: #f4f5f5;
  min-height: 400px;
  max-height: 400px;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card-history {
  min-height: 80px;

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .doctor {
    color: #9ca2a5;
    margin-bottom: 20px;
  }

  .drug {
    font-size: 16px;
  }
}
::v-deep .edit-icon {
  margin-right: -21px;
  margin-bottom: -47px;
}

::v-deep .v-expansion-panel-header__icon {
  margin-top: -75px;
}
</style>
