<template>
  <v-card>
    <v-card-title class="justify-center font-weight-bold">
      Permintaan Barang Gudang
    </v-card-title>
    <v-container fluid class="text-left px-5 container-height">
      <label class="body-2 font-weight-bold">DETAIL TRANSAKSI</label>
      <v-row no-gutters>
        <v-col cols="9" class="pr-3">
          <v-card
            color="#F4F5F5"
            height="64"
            width="100%"
            flat
            class="detail-trans pa-3"
          >
            <div class="fill-detail mr-3">
              <label class="caption grey--text">Kode</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.code
              }}</label>
            </div>
            <div class="fill-detail mr-3">
              <label class="caption grey--text">Tanggal</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.created_at
              }}</label>
            </div>
            <div class="fill-detail mr-3">
              <label class="caption grey--text">Dari</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.from.name
              }}</label>
            </div>
            <div style="display: grid">
              <label class="caption grey--text">Ke</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.to.name
              }}</label>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-checkbox
            label="Dikirim"
            color="success"
            class="mt-0"
            hide-details="false"
            v-model="selectedItem.status"
            value="accepted"
            :disabled="!isWarehouse || itemStatus !== 'requested'"
          ></v-checkbox>
          <v-checkbox
            label="Diterima"
            color="success"
            hide-details="false"
            v-model="selectedItem.status"
            value="received"
            :disabled="
              isWarehouse ||
                itemStatus === 'requested' ||
                itemStatus === 'received'
            "
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-5">
        <label class="body-2 font-weight-bold">DETAIL PERMINTAAN</label>
      </v-row>
      <v-data-iterator
        v-if="!isWarehouse || itemStatus !== 'requested'"
        :loading="isLoading"
        :items="requestedItems"
        hide-default-footer
      >
        <template v-slot:header>
          <v-row no-gutters align="center" class="request-detail">
            <span class="caption font-weight-bold text-center">No</span>
            <span class="caption font-weight-bold text-left">Nama Barang</span>
            <span class="caption font-weight-bold text-left"
              >Jenis Sediaan</span
            >
            <span class="caption font-weight-bold text-left"
              >Jumlah Permintaan</span
            >
            <span class="caption font-weight-bold text-left"
              >Jumlah Diberikan</span
            >
            <span class="caption font-weight-bold text-left"
              >Stok Akhir Unit</span
            >
          </v-row>
        </template>
        <template v-slot:default="{ items }">
          <v-card
            v-for="(item, idx) in items"
            width="95vw"
            class="d-flex align-center"
            flat
            tile
            :key="'drug-' + idx"
          >
            <v-expansion-panels v-model="item.expand" flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  expand-icon=""
                  class="request-detail items pa-0"
                >
                  <label class="body-2 text-center">{{ idx + 1 }}</label>
                  <v-icon v-if="item.expand === 0">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-right</v-icon>
                  <label class="body-2">{{ item.displayName }}</label>
                  <label class="body-2">{{ item.preparation || '-' }}</label>
                  <label class="body-2">{{ item.req_quantity }}</label>
                  <label class="body-2">{{ item.amount_given }}</label>
                  <label class="body-2">{{
                    item.unit_stock
                  }}</label></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row
                    v-for="(list, index) in item.fulfillment"
                    :key="'detail' + '-' + index"
                    no-gutters
                    align="center"
                    class="request-detail list"
                  >
                    <div></div>
                    <label class="font-weight-bold body-2"
                      >{{
                        `${list.id_drugs.detail[0].factory || ''} | ${
                          list.batch_number
                        },`
                      }}
                      <span class="font-weight-regular">{{
                        formatDate(list.expiry_date)
                      }}</span></label
                    >
                    <label class="font-weight-bold body-2">{{
                      list.id_drugs.detail[0].preparation || ''
                    }}</label>
                    <label class="font-weight-bold body-2">-</label>
                    <label class="font-weight-bold body-2">{{
                      list.amount
                    }}</label>
                    <label class="font-weight-bold body-2">{{
                      list.latest_quantity
                    }}</label>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel></v-expansion-panels
            >
          </v-card>
        </template>
      </v-data-iterator>
      <v-data-iterator
        :loading="isLoading"
        :items="requestedItems"
        hide-default-footer
        v-else
      >
        <template v-slot:header>
          <v-row
            no-gutters
            align="center"
            class="request-detail-warehouse header"
          >
            <label class="caption font-weight-bold text-center">No</label>
            <label class="caption font-weight-bold">Nama Barang</label>
            <label class="caption font-weight-bold">Sisa Unit</label>
            <label class="caption font-weight-bold">Stok Gudang</label>
            <label class="caption font-weight-bold">Jumlah Permintaan</label>
            <label class="caption font-weight-bold">Jumlah Diberikan</label>
            <label class="caption font-weight-bold">Satuan</label>
            <label class="caption font-weight-bold">Beri Barang</label>
          </v-row>
        </template>
        <template v-slot:default="{ items }">
          <v-card
            v-for="(drug, idx) in items"
            width="95vw"
            class="d-flex align-center"
            flat
            tile
            :key="'drug-' + idx"
          >
            <v-expansion-panels v-model="drug.expand" flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  expand-icon=""
                  class="request-detail-warehouse pa-0"
                >
                  <label class="body-2 font-weight-bold text-center">{{
                    requestedItems.indexOf(drug) + 1
                  }}</label>
                  <v-icon v-if="drug.expand === 0">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-right</v-icon>
                  <label class="body-2 font-weight-bold">{{
                    drug.displayName
                  }}</label>
                  <label class="body-2 font-weight-bold">{{
                    drug.unitRemain || 0
                  }}</label>
                  <label class="body-2 font-weight-bold text-left">{{
                    drug.warehouseRemain
                  }}</label>
                  <label class="body-2 font-weight-bold">{{
                    drug.req_quantity
                  }}</label>
                  <label class="body-2 font-weight-bold text-left">{{
                    itemGiven(drug, idx) + drug.amount_given
                  }}</label>
                  <label class="body-2 font-weight-bold">{{
                    drug.strength_unit || '-'
                  }}</label>
                  <v-switch
                    @click.stop=""
                    hide-details="false"
                    inset
                    class="mt-0 pt-0"
                    v-model="items[idx].status"
                    :disabled="items[idx].noStock"
                  ></v-switch>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-for="(item, index) in drug.listStock"
                    :key="'detail' + '-' + index"
                    no-gutters
                    align="center"
                    class="request-detail-warehouse list"
                  >
                    <div></div>
                    <v-checkbox
                      dense
                      hide-details="false"
                      class="mt-0 pt-0"
                      v-model="item.check"
                      @change="autoFill(idx, index)"
                    ></v-checkbox>
                    <label
                      class="body-2 text-uppercase"
                      :class="{
                        'black--text font-weight-bold': item.isMenu,
                        'black--text font-weight-medium': item.isSubMenu,
                        'primary--text font-weight-bold':
                          !item.isMenu && !item.isSubMenu,
                      }"
                      >{{ `${item.factory}` }}
                      <!-- <br /> -->
                      {{
                        `${formatDate(item.expiry_date)} | ${item.batch_number}`
                      }}
                    </label>
                    <label
                      class="body-2 text-uppercase"
                      :class="{
                        'black--text font-weight-bold': item.isMenu,
                        'grey--text font-weight-medium': item.isSubMenu,
                        'primary--text font-weight-bold':
                          !item.isMenu && !item.isSubMenu,
                      }"
                    ></label>
                    <label
                      class="body-2 text-left"
                      :class="{
                        'font-weight-bold': item.isMenu,
                      }"
                      >{{ item.quantity }}</label
                    >
                    <span></span>
                    <label
                      v-if="item.isMenu"
                      class="body-2"
                      :class="{ 'font-weight-bold': item.isMenu }"
                      >{{ item.amountGiven }}</label
                    >
                    <v-text-field
                      v-else-if="item.isSubMenu"
                      class="given-input"
                      v-model.number="item.amountGiven"
                      :disabled="!item.check || item.quantity < 0"
                      hide-details="true"
                      single-line
                      type="number"
                      @change="amountGivenValidation(idx, index)"
                    ></v-text-field>
                    <label
                      class="body-2 text-capitalize"
                      :class="{
                        'black--text font-weight-bold': item.isMenu,
                        'grey--text font-weight-medium': item.isSubMenu,
                        'primary--text font-weight-bold':
                          !item.isMenu && !item.isSubMenu,
                      }"
                      >{{ item.preparation }}</label
                    >
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </template>
      </v-data-iterator>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="px-5 pt-5">
          <h3 class="primary--text text-left font-weight-bold pb-3">
            Tambah Barang Baru
          </h3>
          <v-row no-gutters>
            <v-col cols="3" class="text-left">
              <label class="font-weight-bold body-2"
                >Nama Barang<span class="red--text">*</span></label
              >
              <v-autocomplete
                dense
                outlined
                placeholder="Pilih Barang"
                :items="resource.stocks"
                @change="
                  getExpStock();
                  getUnitStock();
                "
                item-text="displayName"
                v-model="input.stock"
                return-object
                :loading="addStockLoading"
                :search-input.sync="search"
              ></v-autocomplete>

              <label class="font-weight-bold body-2"
                >Tanggal ED<span class="red--text">*</span></label
              >
              <v-autocomplete
                :items="resource.items"
                :loading="expStockLoading"
                item-text="exp_date_display"
                return-object
                outlined
                dense
                v-model="input.expiredDate"
              ></v-autocomplete>
              <v-row no-gutters>
                <v-col cols="7">
                  <label class="font-weight-bold body-2"
                    >Jumlah Diberikan<span class="red--text">*</span></label
                  >
                  <v-text-field
                    outlined
                    placeholder="Masukkan jumlah barang"
                    dense
                    v-model="input.givenAmount"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3">
                  <label class="font-weight-bold body-2">Sisa Stok</label>
                  <br />
                  <label class="body-2">{{ input.expiredDate.quantity }}</label>
                </v-col>
              </v-row>

              <v-row no-gutters justify="end">
                <v-btn outlined class="text-none" tile @click="addStuff()"
                  >Tambah</v-btn
                >
              </v-row>
            </v-col>
            <v-col class="px-5">
              <v-data-table
                :headers="addTable.headers"
                :items="addTable.items"
                :items-per-page="5"
              >
                <template v-slot:[`item.no`]="{ item }">
                  <td>{{ addTable.items.indexOf(item) + 1 }}</td>
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <v-btn icon @click="deleteItem(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-footer color="white">
            <v-spacer />
            <v-btn
              width="150"
              tile
              class="pa-4"
              depressed
              color="primary"
              @click="addToDetail()"
            >
              Simpan
            </v-btn>
          </v-footer>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>

    <v-footer color="white">
      <v-btn text class="text-none" @click="$emit('close-dialog')"
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        tile
        class="text-none mr-3"
        @click="
          sheet = true;
          getWarehouseStock();
        "
        v-if="isWarehouse"
        :disabled="itemStatus === 'received' || itemStatus === 'accepted'"
        >Tambah Baru</v-btn
      >
      <v-btn
        color="primary"
        depressed
        tile
        class="text-none"
        @click="addDetail()"
        v-if="isWarehouse || itemStatus !== 'requested'"
        :disabled="itemStatus === 'received'"
      >
        SIMPAN
      </v-btn>
    </v-footer>
    <v-dialog v-model="dialog" width="1000">
      <DialogDetail
        v-if="dialog"
        @close-dialog="dialog = false"
        @close-confirm="$emit('close-dialog')"
        :data-request="requestFinal"
        :selected-item="selectedItem"
      />
    </v-dialog>
    <v-snackbar
      color="error"
      timeout="3000"
      style="cursor: pointer"
      v-model="snackbar"
    >
      <div class="d-flex justify-space-between">
        <label>Status Transaksi Belum Dipilih</label>
        <v-icon @click.native="snackbar = false" small color="#ffff"
          >mdi-close</v-icon
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import Constant from '@/const';
import DialogDetail from './DialogDetail.vue';

const _ = require('lodash');
const axios = require('axios');
const moment = require('moment-timezone');

export default {
  name: 'DetailStorageRequest',
  props: {
    selectedItem: {
      type: Object,
    },
  },
  components: {
    DialogDetail,
  },
  data() {
    return {
      table: {
        items: [],
      },
      snackbar: false,
      itemStatus: '',
      search: null,
      id_unit: '',
      id_warehouse: '',
      sheet: false,
      date: null,
      menu: false,
      addTable: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Barang', value: 'displayName' },
          { text: 'Batch', value: 'batch_number' },
          { text: 'Kadaluarsa', value: 'expiry_date' },
          { text: 'Jumlah Diberikan', value: 'givenAmount' },
          { text: '', value: 'edit', sortable: false, width: '5%' },
          { text: '', value: 'delete', sortable: false, width: '5%' },
        ],
        items: [],
      },
      resource: {
        stocks: [],
        items: [],
      },
      input: {
        stock: '',
        expiredDate: '',
        givenAmount: null,
        stock_unit: 0,
      },
      isLoading: false,
      addStockLoading: false,
      expStockLoading: false,
      dialog: false,
      selection: [],
      requestedItems: [],
      requestFinal: [],
    };
  },
  mounted() {
    this.getRequested();
    this.itemStatus = this.selectedItem.status;
  },
  watch: {
    search() {
      this.searchData(this);
    },
    // searchStock(val) {
    //   if (val) {
    //     this.getStock(val);
    //   }
    // },
  },
  computed: {
    isWarehouse() {
      return this.$store.getters.userLoggedIn.is_warehouse;
    },
    addDisabled() {
      return this.input.stock === '' || this.input.givenAmount === null;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getWarehouseStock();
    }, 650),
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    autoFill(idx, index) {
      this.selectedItem.status = 'accepted';
      this.requestedItems[idx].status = true;

      let item = this.requestedItems[idx];
      let selectedListItem = this.requestedItems[idx].listStock[index];

      if (!selectedListItem.check) {
        selectedListItem.amountGiven = 0;
        return;
      }

      // NEED ENHANCE LATER FOR CONDITIONING
      if (item.quantity === 0) {
        if (item.req_quantity >= selectedListItem.quantity)
          selectedListItem.amountGiven = selectedListItem.quantity;
        else selectedListItem.amountGiven = item.req_quantity;
      } else if (item.quantity > 0 && item.req_quantity > item.quantity) {
        if (item.quantity >= selectedListItem.quantity) {
          selectedListItem.amountGiven = selectedListItem.quantity;
        } else {
          selectedListItem.amountGiven = item.req_quantity - item.quantity;
          if (selectedListItem.amountGiven > selectedListItem.quantity)
            selectedListItem.amountGiven = selectedListItem.quantity;
        }
      } else {
        selectedListItem.amountGiven = 0;
      }
    },
    amountGivenValidation(idx, index) {
      let selectedListItem = this.requestedItems[idx].listStock[index];

      if (selectedListItem.amountGiven > selectedListItem.quantity)
        selectedListItem.amountGiven = selectedListItem.quantity;
    },
    itemGiven(arr, index) {
      const result = arr.listStock
        .filter(item => item.check)
        .reduce((a, b) => +a + +b.amountGiven, 0);
      this.requestedItems[index].quantity = result;
      return result;
    },
    addStuff() {
      this.addTable.items.push({
        name: this.input.stock.name,
        id_stock: this.input.expiredDate.id_stock,
        id_drugs: this.input.expiredDate?.id_drugs,
        id_goods: this.input.expiredDate?.id_goods,
        displayName: this.input.stock.displayName,
        batch_number: this.input.expiredDate.batch_number,
        expiry_date: this.input.expiredDate.exp_date,
        givenAmount: this.input.givenAmount,
        strength_unit: this.input.stock.strength_unit,
        preparation: this.input.stock.preparation,
        warehouse_stock: this.input.expiredDate.quantity,
        stock_unit: this.input.stock_unit,
        factory: this.input.expiredDate.factory_stock,
      });

      this.input = {
        stock: '',
        expiredDate: '',
        givenAmount: null,
        stock_unit: 0,
      };
    },
    deleteItem(val) {
      const index = this.addTable.items.indexOf(val);
      this.addTable.items.splice(index, 1);
    },
    addDetail() {
      if (this.selectedItem.status === 'accepted') {
        this.requestFinal = this.requestedItems
          .filter(x => x.status)
          .map(item => {
            const factory = [];
            const stock = item.listStock.filter(x => x.check);
            stock.forEach(item => {
              factory.push(item.factory);
            });
            return {
              ...item,
              listStock: stock,
              factory: factory.join(', '),
              given_quantity: item.quantity + item.amount_given,
              quantity: item.req_quantity,
            };
          });
        if (this.requestFinal.length == 0 && !this.isWarehouse) {
          this.snackbar = true;
          return;
        }
        this.dialog = true;
      } else if (this.selectedItem.status === 'received') {
        this.requestFinal = this.requestedItems.map(item => {
          const factory = [];
          item.listStock.map(item => {
            factory.push(item.factory);
          });
          return {
            ...item,
            factory: factory.join(', '),
            given_quantity: item.amount_given,
            quantity: item.req_quantity,
          };
        });
        this.dialog = true;
      } else {
        if (this.requestFinal.length == 0 && !this.isWarehouse) {
          this.snackbar = true;
          return;
        }
        this.requestFinal = [];
        this.selectedItem.status = 'rejected';
        this.dialog = true;
      }
    },
    getRequested() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat(`/stock/request/${this.selectedItem.id}`))
        .then(response => {
          const { data } = response.data;
          this.id_warehouse = data.id_warehouse._id;
          this.id_unit = data.id_clinic._id;

          this.detailRequest = {
            from: data.id_warehouse.name,
            to: data.id_clinic.name,
            status: data.status,
          };

          this.requestedItems = data.request.map(arr => {
            const factory_stock = [];
            return {
              _id: arr._id,
              displayName: arr.display_name,
              name: arr.name,
              strength: arr.strength,
              strength_unit: arr.strength_unit,
              preparation: arr.preparation,
              amount_given: arr.amount_given || 0,
              fulfillment: arr.fulfillment,
              req_quantity: arr.amount,
              unitRemain: arr.unit_stock,
              warehouseRemain: arr.warehouse_remain,
              unit_stock: arr.unit_stock,
              noStock: arr.stock.length === 0 ? true : false,
              listStock: arr.stock.map(item => {
                const factory = item.id_stock.id_drugs
                  ? item.id_stock.id_drugs.detail[0].factory
                  : item.id_stock.id_goods.detail[0].factory;
                factory_stock.push(factory);
                return {
                  factory: factory,
                  preparation: item.id_stock.id_drugs?.detail[0].preparation,
                  id_drugs: item.id_stock.id_drugs?._id,
                  id_goods: item.id_stock.id_goods?._id,
                  id_stock: item.id_stock._id,
                  expiry_date: item.id_stock.expiry_date,
                  batch_number: item.id_stock.batch_number,
                  quantity: item.id_stock.quantity,
                  amountGiven: 0,
                  isSubMenu: true,
                };
              }),
              isMenu: true,
              expand: null,
              factory_stock: factory_stock.join(', '),
            };
          });
        })
        .catch(() => {
          this.requestedItems = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addToDetail() {
      const data = this.addTable.items.map(arr => {
        return {
          displayName: arr.displayName,
          name: arr.name,
          strength: arr?.strength,
          strength_unit: arr?.strength_unit,
          preparation: arr?.preparation,
          amount_given: 0,
          req_quantity: +arr.givenAmount,
          unitRemain: +arr.stock_unit,
          warehouseRemain: +arr.warehouse_stock,
          status: true,
          noStock: false,
          expand: 0,
          isMenu: true,
          factory_stock: arr.factory,
          listStock: [
            {
              factory: arr.factory,
              preparation: arr.preparation,
              id_drugs: arr?.id_drugs,
              id_goods: arr?.id_goods,
              id_stock: arr.id_stock,
              expiry_date: arr.expiry_date,
              batch_number: arr.batch_number,
              quantity: arr.quantity,
              amountGiven: +arr.givenAmount,
              isSubMenu: true,
              check: true,
            },
          ],
        };
      });
      data.forEach(x => {
        this.requestedItems.push(x);
      });
      this.addTable.items = [];
      this.selectedItem.status = 'accepted';
      this.sheet = false;
    },
    getWarehouseStock() {
      const keyword = this.search;
      this.addStockLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&page=1&itemCount=10&search=${keyword ||
              ''}&strength=&unit=&unit_name=${this.id_warehouse}`,
          ),
        )
        .then(response => {
          const { stock } = response.data.data;

          this.resource.stocks = stock.map(arr => {
            return {
              name:
                arr.drug.length > 0
                  ? arr.drug[0].detail.name
                  : arr.good[0].detail.name,
              displayName: arr.display_name,
              strength: arr?.strength,
              strength_unit: arr?.strength_unit,
              preparation: arr?.preparation,
              category: arr?.category,
              factory: arr.factory,
            };
          });
        })
        .catch(() => {
          this.resource.stocks = [];
        })
        .finally(() => {
          this.addStockLoading = false;
        });
    },
    getExpStock() {
      this.expStockLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&search=${this.input.stock.name}&strength=${this.input.stock
              .strength || ''}&unit=${this.input.stock.strength_unit ||
              ''}&unit_name=${this.id_warehouse}&factory=${this.input.stock
              .factory || ''}`,
          ),
        )
        .then(response => {
          const { stock } = response.data.data;
          this.resource.items = stock
            .filter(v => v.quantity !== 0)
            .map(v => {
              return {
                id_stock: _.get(v, '_id', ''),
                exp_date: _.get(v, 'expiry_date', ''),
                exp_date_display: moment(_.get(v, 'expiry_date', ''))
                  .locale('id')
                  .format('LL'),
                quantity: _.get(v, 'quantity', 0),
                batch_number: _.get(v, 'batch_number', ''),
                id_drugs: v?.id_drugs,
                id_goods: v?.id_goods,
                factory_stock:
                  v.drug.length != 0
                    ? v.drug[0].detail.factory
                    : v.good[0].detail.factory,
              };
            });
        })
        .catch(() => {
          this.resource.items = [];
        })
        .finally(() => {
          this.expStockLoading = false;
        });
    },
    getUnitStock() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?branch=${
              this.$store.getters.userLoggedIn.branch_id
            }&search=${this.input.stock.name}&strength=${this.input.stock
              .strength || ''}&unit=${this.input.stock.strength_unit ||
              ''}&unit_name=${this.id_unit}&factory=${this.input.stock
              .factory || ''}`,
          ),
        )
        .then(response => {
          this.addStockLoading = true;
          const { stock } = response.data.data;
          if (stock || stock.length > 0) {
            const stok = stock.map(arr => arr.quantity).reduce((a, b) => a + b);
            this.input = {
              ...this.input,
              stock_unit: stok,
            };
          }
          this.input.stock_unit = 0;
          this.addStockLoading = false;
        })
        .catch(() => {
          this.addStockLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// .v-expansion-panel-header:before {
//   back
// }

.container-height {
  height: 82vh;
  overflow: auto;
}
.given-input {
  min-width: 0px;
  width: 2.5vw;
}
.detail-trans {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  border: 1px solid #9ca2a5 !important;
  border-radius: 5px;
  .fill-detail {
    display: grid;
    border-right: 0.5px solid #e0e0e0 !important;
  }
}
.request-detail {
  display: grid;
  grid-template-columns: 3% 47% repeat(4, 12.5%);
  width: 95vw;
  height: 7vh;
  align-content: center;
  border-radius: 4px;
  border-bottom: 1.5px solid #9ca2a5;
  background-color: #f4f5f5;

  &.items {
    grid-template-columns: 3% 2% 45% repeat(4, 12.5%);
    height: 2vh;
    border-bottom: 0px solid #9ca2a5;
    background-color: white;
  }

  &.list {
    grid-template-columns: 5% 45% repeat(4, 12.5%);
    height: 3vh;
    border-bottom: 0px solid #9ca2a5;
    background-color: #f0f9ff;
  }
}
.request-detail-warehouse {
  width: 95vw;
  display: grid;
  grid-template-columns: 2.5% 2.5% 37.5% 10% 8% 10% 10% 10% 5% 5%;
  height: 7vh;
  border-radius: 4px;
  align-content: center;

  &.header {
    grid-template-columns: 2.5% 40% 10% 8% 10% 10% 10% 10% 5%;
    border-bottom: 1.5px solid #9ca2a5;
    background-color: #f4f5f5;
  }
  &.list {
    grid-template-columns: 5% 2% 35.5% 10% 8% 10% 10% 10% 5% 5%;
    background-color: #f0f9ff;
    height: 35px;
  }
}
.tree-grid {
  display: grid;
  grid-template-columns: 3% 27% 10% 22% 10% 15% 10%;
  align-items: center;
  grid-template-rows: 35px;
  border-radius: 5px;
  // background: #f4f5f5;
  &.batch {
    // background: #f0f9ff;
    grid-template-columns: 3% 13% 18% 10% 15.39% 5% 15% 10%;
  }
  .input-text {
    width: 50px;
    font-size: 0.9rem;
  }
}
.drug-type {
  background-color: #f4f5f5;
  height: 5vh;
  align-content: center;
}
::v-deep .v-treeview-node__root {
  background-color: #deeef9;
  height: 40px;
}
// :v-deep .v-treeview-node__children {
//   background-color: #f0f9ff;
//   height: 40px;
// }
// ::v-deep .v-treeview-node {
//   margin-left: 0px;
//   width: inherit;
//   /* background-color: rgba(var('--node-color-r'), var('--node-color-g'), var('--node-color-b'), 0.1); */
//   background-color: rgba(222, 238, 249, 0.1);
// }
</style>
