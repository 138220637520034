import Vue from 'vue';
import Vuex from 'vuex';
import OldNewPatientRegistration from './modules/oldNewPatientRegistration/OldNewPatientRegistration';
import SubjectiveData from './modules/Assessment/SubjectiveData';
import ObjectiveData from './modules/Assessment/ObjectiveData';
import PainAssessment from './modules/Assessment/PainAssessment';
import NutritionScreening from './modules/Assessment/NutritionScreening';
import FallRisk from './modules/Assessment/FallRisk';
import EmergencyAction from './modules/Assessment/EmergencyAction';
import NursingProblem from './modules/Assessment/NursingProblem';
import FluidBalance from './modules/Assessment/FluidBalance';
import InfectiousAssessment from './modules/Assessment/InfectiousAssessment';
import DoctorAssessmentIGD from './modules/Assessment/DoctorAssessmentIGD';
import SupportingInvestigation from './modules/Assessment/SupportingInvestigation';
import PhysicalCheck from './modules/Assessment/PhysicalCheck';
import IGDAssessment from './modules/Assessment/IGDAssessment';
import ExaminationLaboratory from './modules/examinationLaboratory/ExaminationLaboratory';
import PatientRegistration from './modules/patientRegistration/PatientRegistration';

const moment = require('moment-timezone');
const jwt = require('jsonwebtoken');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    selectedPatient: null,
    mjknPatient: null,
    clinic: {
      name: 'Neurovi Clinic Medical System',
      address: '',
    },
    recipeReview: null,
  },
  getters: {
    token: state => state.token,
    userLoggedIn: state => {
      if (state.token !== null) {
        return jwt.decode(state.token).tokenStaff;
      }
      return null;
    },
    isJwtVerified: state => {
      try {
        const decoded = jwt.decode(state.token);
        const now = moment().valueOf() / 1000;
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
          throw Error;
        }
        return true;
      } catch {
        return false;
      }
    },
    tokenTime: state => {
      try {
        const decoded = jwt.decode(state.token);
        return decoded.exp;
      } catch {
        return false;
      }
    },
    selectedPatient: state => state.selectedPatient,
    mjknPatient: state => state.mjknPatient,
    recipeReview: state => state.recipeReview,
  },
  mutations: {
    saveToken(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
    removeToken(state) {
      state.token = null;
    },
    changeSelectedPatient(state, patient) {
      state.selectedPatient = patient;
    },
    addMjknPatient(state, patient) {
      state.mjknPatient = patient;
    },
    resetMjknPatient(state) {
      state.mjknPatient = null;
    },
    saveRecipeReview(state, review) {
      state.recipeReview = review;
    },
    removeRecipeReview(state) {
      state.recipeReview = null;
    },
  },
  actions: {},
  modules: {
    OldNewPatientRegistration: {
      namespaced: true,
      ...OldNewPatientRegistration,
    },
    // Subjective data for assessment
    SubjectiveData: {
      namespaced: true,
      ...SubjectiveData,
    },
    // Objective data for assessment
    ObjectiveData: {
      namespaced: true,
      ...ObjectiveData,
    },
    // Pain assessment data for assessment
    PainAssessment: {
      namespaced: true,
      ...PainAssessment,
    },
    // Nutrition screening data for assessment
    NutritionScreening: {
      namespaced: true,
      ...NutritionScreening,
    },
    // Fall risk data for assessment
    FallRisk: {
      namespaced: true,
      ...FallRisk,
    },
    // emergency action data for assessment
    EmergencyAction: {
      namespaced: true,
      ...EmergencyAction,
    },
    // nursing problem data for assessment
    NursingProblem: {
      namespaced: true,
      ...NursingProblem,
    },
    // fluid balance data for assessment
    FluidBalance: {
      namespaced: true,
      ...FluidBalance,
    },
    // infectious assessment data for assessment
    InfectiousAssessment: {
      namespaced: true,
      ...InfectiousAssessment,
    },
    // doctor igd assessment
    DoctorAssessmentIGD: {
      namespaced: true,
      ...DoctorAssessmentIGD,
    },
    // supporting investigation for igd doctor assessment
    SupportingInvestigation: {
      namespaced: true,
      ...SupportingInvestigation,
    },
    // physical check for igd doctor assessment
    PhysicalCheck: {
      namespaced: true,
      ...PhysicalCheck,
    },
    // physical check for igd doctor assessment
    IGDAssessment: {
      namespaced: true,
      ...IGDAssessment,
    },
    // Laboratory
    ExaminationLaboratory: {
      namespaced: true,
      ...ExaminationLaboratory,
    },
    PatientRegistration: {
      namespaced: true,
      ...PatientRegistration,
    },
  },
});
