export default {
  data() {
    return {
      roles: {
        isSuperManager: false,
        isSuperManagerAdmin: false,
        isSuperAdmin: false,
      },
    };
  },
  methods: {
    // if want make disabled than hidden just add ! into event like v-if="!hasRoles()"
    hasRoles() {
      if (this.$store.getters.userLoggedIn.role !== null) {
        const userRole = this.$store.getters.userLoggedIn.role.toLowerCase();
        this.roles.isSuperAdmin = userRole === 'superadministrator';
        this.roles.isSuperManager = userRole === 'supermanager';
        this.roles.isSuperManagerAdmin =
          userRole === 'superadministrator' || userRole === 'supermanager';
        return this.roles;
      }
    },
  },
};
