<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Input Pembayaran Medis</h3>
    </v-card-title>
    <v-card-text class="pl-16 pt-6">
      <v-row no-gutters align="baseline">
        <v-col cols="2" class="text-left">
          <label class="black--text">Tanggal Bayar</label>
        </v-col>
        <v-col cols="3" class="text-left">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="input.paymentDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="input.paymentDate"
                placeholder="Pilih Tanggal"
                append-icon="mdi-chevron-down"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="input.paymentDate"
              no-title
              scrollable
              @input="
                $refs.menu.save(input.paymentDate);
                menu = false;
              "
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters align="baseline">
        <v-col cols="2" class="text-left">
          <label class="black--text">Nama Dokter</label>
        </v-col>
        <v-col cols="3">
          <v-combobox
            dense
            placeholder="Pilih Dokter"
            :items="resource.staff"
            item-text="name"
            return-object
            :loading="loading"
            v-model="input.staff"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row no-gutters align="baseline">
        <v-col cols="2" class="text-left">
          <label class="black--text">Periode Pelayanan</label>
        </v-col>
        <v-col cols="2">
          <v-select
            dense
            placeholder="Pilih Tahun"
            :items="yearRange"
            v-model="input.year"
          ></v-select>
        </v-col>
        <v-col cols="2" class="mx-2">
          <v-select
            dense
            placeholder="Pilih Bulan"
            :items="resource.month"
            v-model="input.startMonth"
          ></v-select>
        </v-col>
        <v-col cols="1" style="max-width: 30px" class="pl-1">
          <v-icon>mdi-minus</v-icon>
        </v-col>
        <v-col cols="2" class="mx-2">
          <v-select
            dense
            placeholder="Pilih Bulan"
            :items="resource.month"
            v-model="input.endMonth"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters align="baseline">
        <v-col cols="2" class="text-left">
          <label class="black--text">Jumlah Jasa Medis</label>
        </v-col>
        <v-col cols="3">
          <v-currency-field
            prefix="Rp"
            dense
            v-model="input.amount"
          ></v-currency-field>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col cols="2" class="text-left">
          <label class="black--text">Tipe Bayar</label>
        </v-col>
        <v-col cols="3">
          <v-radio-group row v-model="input.paymentType">
            <v-radio label="Cash" value="cash"></v-radio>
            <v-radio label="Transfer" value="transfer"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row no-gutters align="baseline">
        <v-col cols="2" class="text-left">
          <label class="black--text">Rekening Asal</label>
        </v-col>
        <v-col cols="3">
          <v-combobox
            dense
            placeholder="Pilih Rekening"
            :items="resource.bank"
            v-model="input.account_from"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row no-gutters align="baseline">
        <v-col cols="2" class="text-left">
          <label class="black--text">Rekening Tujuan</label>
        </v-col>
        <v-col class="text-left">
          <label class="black--text"
            >{{ bankName ? bankName.text : '' }} -
            {{ input.staff.account }}</label
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        elevation="0"
        color="primary"
        tile
        width="108"
        class="text-none"
        @click="addPayment()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MedPaymentInput',
  mixins: [alertMixin],
  data() {
    return {
      menu: false,
      resource: {
        month: [
          { text: 'Januari', value: '01' },
          { text: 'Februari', value: '02' },
          { text: 'Maret', value: '03' },
          { text: 'April', value: '04' },
          { text: 'Mei', value: '05' },
          { text: 'Juni', value: '06' },
          { text: 'Juli', value: '07' },
          { text: 'Agustus', value: '08' },
          { text: 'September', value: '09' },
          { text: 'Oktober', value: '10' },
          { text: 'November', value: '11' },
          { text: 'Desember', value: '12' },
        ],
        staff: [],
        bank: [],
      },
      input: {
        paymentDate: null,
        staff: '',
        year: '',
        startMonth: '',
        endMonth: '',
        amount: null,
        paymentType: '',
        account_from: '',
        account_to: '',
      },
      loading: false,
    };
  },
  mounted() {
    this.getStaff();
    this.getBank();
  },
  computed: {
    yearRange() {
      let startYear = 2019;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
    bankName() {
      return this.resource.bank.find(x => x.value === this.input.staff.bankId);
    },
  },
  methods: {
    getStaff() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff/active?id_unit=&role=dokter&branch=${this.$store.getters.userLoggedIn.branch_id}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.staff = data.map(doctor => {
              return {
                id: doctor._id.toString(),
                name: doctor.detail.name,
                bankId: _.get(doctor, 'detail.bank.id_bank', ''),
                account: _.get(doctor, 'detail.bank.account', ''),
              };
            });
            this.loading = false;
          }
        })
        .catch(() => {
          this.resource.staff = [];
          this.loading = false;
        });
    },
    getBank() {
      axios.get(`${Constant.apiUrl}/master/bank`).then(response => {
        const getBank = response.data.data;
        this.resource.bank = getBank.map(bank => {
          return {
            value: bank._id,
            text: bank.name,
          };
        });
      });
    },
    addPayment() {
      const payload = {
        total_amount: this.input.amount,
        startDate: `${this.input.year}-${this.input.startMonth}-01`,
        endDate: moment(`${this.input.year}-${this.input.endMonth}`)
          .endOf('month')
          .format('YYYY-MM-DD'),
        source_account: this.input.account_from.value,
        target_account: this.bankName.value,
        payment_type: this.input.paymentType,
        payment_date: this.input.paymentDate,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };

      axios
        .put(
          Constant.apiUrl.concat(`/master/staff/fee/${this.input.staff.id}`),
          payload,
        )
        .then(() => {
          Swal.fire('Sukses', 'Pembayaran Medis Berhasil', 'success');
          this.$emit('close-dialog');
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
  },
};
</script>

<style></style>
