<template>
  <v-card>
    <v-container fluid>
      <div class="sticky center-width">
        <div class="d-flex justify-space-between">
          <div class="text-sm-left mb-2 pl-3">
            <h2 class="heading_big mt-3">Pembuatan Obat dan Penyiapan Alkes</h2>
          </div>
          <v-btn @click="$emit('close-form')" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row class="header-layout">
          <v-col>
            <v-row class="row-info">
              <v-col class="header-info-widget text-sm-left pt-2" cols="5"
                ><span class="info-head">Nama</span></v-col
              >
              <v-col class="header-info-widget text-sm-left pt-2" cols="7">
                <span class="label-head"
                  >{{ patient.name }} ({{
                    patient.gender === 'Perempuan' ? 'P' : 'L'
                  }})</span
                >
              </v-col>

              <v-col class="header-info-widget text-sm-left py-0" cols="5">
                <span class="info-head">Tanggal Lahir</span>
              </v-col>
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head"
                  >{{ data.birthDate }} ({{ patient.age }} tahun)
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="row-info">
              <v-col class="header-info-widget text-sm-left pt-2" cols="5"
                ><span class="info-head">No. RM</span></v-col
              >
              <v-col class="header-info-widget text-sm-left pt-2" cols="7">
                <span class="label-head">{{ patient.rm_number }}</span>
              </v-col>

              <v-col class="header-info-widget text-sm-left py-0" cols="5"
                ><span class="info-head">DPJP</span></v-col
              >
              <v-col class="header-info-widget text-sm-left py-0" cols="7">
                <span class="label-head">{{ patient.doctor }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pa-0"
            ><v-col class="header-info-widget text-sm-left pa-0" cols="12">
              <span class="label-head font-weight-bold">
                Riwayat Alergi
              </span>
            </v-col>
            <v-col class="text-sm-left px-0" cols="12">
              <span
                v-if="data.allergy.length === 0"
                class="red--text font-weight-bold label-head"
                >Tidak Ada Alergi</span
              >
              <v-chip
                v-for="(allergy, i) in data.allergy"
                :key="i"
                color="#EB4747"
                class="allergy text-capitalize mr-2 mb-2"
                label
                small
                caption
                >{{ allergy }}</v-chip
              >
            </v-col>
          </v-col>
        </v-row>
      </div>

      <div class="after-sticky">
        <v-card flat class="body-container">
          <span class="title pb-2 center-width">Pembuatan Obat Non Racik</span>
          <v-data-table
            :headers="nonRacikTable.headers"
            :items="nonRacikTable.item"
            :items-per-page="nonRacikTable.item.length"
            hide-default-footer
            class="center-width"
          >
            <template slot="no-data">
              <span style="color: black;">Tidak ada obat yang dipesan</span>
            </template>
            <template v-slot:[`item.no`]="{ item }">
              <tr class="text-left">
                <td>
                  {{ nonRacikTable.item.indexOf(item) + 1 }}
                </td>
              </tr>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-simple-checkbox
                v-model="item.status"
                color="success"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <v-card flat class="body-container">
        <span class="title pb-2 center-width">Pembuatan Obat Racik</span>
        <v-data-table
          :headers="racikTable.headers"
          :items="racikTable.item"
          :items-per-page="racikTable.item.length"
          hide-default-footer
          class="center-width"
          ><template slot="no-data">
            <span style="color: black;">Tidak ada obat yang dipesan</span>
          </template>
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{ racikTable.item.indexOf(item) + 1 }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.drugs`]="{ item }">
            <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
              <td class="py-1">{{ drug.id_drugs.name }}</td>
            </tr>
          </template>
          <template v-slot:[`item.preparation`]="{ item }">
            <tr
              v-for="(preparation, index) in item.drugs"
              :key="'drug-' + index"
            >
              <td class="py-1">{{ preparation.preparation }}</td>
            </tr>
          </template>
          <template v-slot:[`item.dosage`]="{ item }">
            <tr v-for="(dosage, index) in item.drugs" :key="'dosage-' + index">
              <td class="py-1">
                {{ dosage.dosage + ' ' + dosage.unit_dosage }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.drugQuantity`]="{ item }">
            <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
              <td class="py-1">{{ drug.quantity }}</td>
            </tr>
          </template>
          <template v-slot:[`item.requested_quantity`]="{ item }">
            <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
              <td class="py-1">{{ drug.requested_quantity }}</td>
            </tr>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-simple-checkbox
              v-model="item.status"
              color="success"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card>

      <v-card flat class="body-container before-sticky">
        <span class="title pb-2 center-width">Penyiapan Alkes/BHP</span>
        <v-data-table
          :headers="consumableTable.headers"
          :items="consumableTable.item"
          :items-per-page="consumableTable.item.length"
          hide-default-footer
          class="center-width"
          ><template slot="no-data">
            <span style="color: black;"
              >Tidak ada pesanan alat kesehatan pada pasien ini</span
            >
          </template>
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{ consumableTable.item.indexOf(item) + 1 }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-simple-checkbox
              v-model="item.status"
              color="success"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-card-actions class="action-layout pt-3 footer-sticky center-width">
      <v-btn
        depressed
        tile
        class="text-none"
        v-if="tab !== 2"
        color="primary"
        outlined
        @click="printTicket()"
        ><font-awesome-icon icon="print" />&nbsp;Cetak E-Ticket Obat
      </v-btn>
      <v-spacer />
      <div class="total-price">
        <label for="total-price" class="font-weight-bold subtitle-2 mr-lg-16"
          ><v-icon class="mr-2">mdi-shopping-outline</v-icon> Total Harga Obat
          dan Alkes</label
        >
        <v-spacer></v-spacer>
        <label for="total-price" class="font-weight-bold subtitle-2">{{
          currency(totalPrice || 0)
        }}</label>
      </div>
      <div class="action-footer">
        <v-btn
          color="primary"
          @click="saveData()"
          class="btn-create"
          tile
          depressed
        >
          Simpan
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import printMixin from '@/mixin/printMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
import Constant from '@/const';
import moment from 'moment-timezone';
import printDrugsEticket from '../../../../mixin/printDrugsEticket';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DrugManuEdit',
  mixins: [printMixin, formatMixin, printDrugsEticket],
  components: {},
  props: {
    patient: Object,
  },
  data() {
    return {
      totalPrice: 0,
      drugList: [],
      patient_status: '',
      tab: null,
      show: false,
      nonRacikTable: {
        headers: [
          { text: 'No', align: 'start', value: 'no' },
          { text: 'Nama Obat', value: 'shownName' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'preparation' },
          { text: 'Aturan Pakai', value: 'usage' },
          { text: 'Aturan Umum', value: 'general_rule' },
          { text: 'Jumlah Diberikan', value: 'drugTaken' },
          { text: 'Selesai', value: 'status' },
        ],
        item: [],
      },
      racikTable: {
        headers: [
          { text: 'No', align: 'start', value: 'no' },
          { text: 'Nama Obat Racik', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'packaging' },
          { text: 'Aturan Pakai', value: 'usage' },
          { text: 'Nama Obat', value: 'drugs' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: 'Jumlah Diberikan', value: 'drugQuantity' },
          { text: 'Jumlah Ditagihkan', value: 'requested_quantity' },
          { text: 'Selesai', value: 'status' },
        ],
        item: [],
      },
      consumableTable: {
        headers: [
          { text: 'No', align: 'start', value: 'no', width: '4%' },
          { text: 'Nama Alkes/BHP', value: 'name' },
          { text: 'Jumlah barang diberikan', value: 'goodsGiven' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Selesai', value: 'status', width: '6%' },
        ],
        item: [],
      },
      data: [],
      recipe_price: [],
      mix_recipe_price: [],
      medical_tools_price: [],
      menuSelected: 1,
    };
  },
  computed: {
    isDrugsReady() {
      return (
        this.racikTable.item.some(item => item.status) ||
        this.nonRacikTable.item.some(item => item.status) ||
        this.consumableTable.item.some(item => item.status)
      );
    },
    isDrugsComplete() {
      return (
        this.racikTable.item.every(item => item.status) &&
        this.nonRacikTable.item.every(item => item.status) &&
        this.consumableTable.item.every(item => item.status)
      );
    },
    allergyList() {
      let allergy = this.data.allergy ? this.data.allergy : 'Tidak ada alergi';
      return allergy;
    },
  },
  methods: {
    async printTicket() {
      const doc = this.printEticket(this.data, this.patient, this.drugList);

      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    getPatient() {
      const id_pharmacy = this.patient.id;
      axios
        .get(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`))
        .then(response => {
          const data = response.data.data;
          this.patient_status = data.recipe.patient_status;
          this.data = {
            id_registration: data.id_registration._id,
            allergy: _.get(data, 'id_emr.allergy', []),
            birthDate:
              data.id_emr !== undefined
                ? moment(data.id_emr.social[0].birth.date)
                    .locale('id')
                    .format('L')
                : '',
            id_recipe: data.id_recipe,
            id_consumable: data.id_consumable,
          };

          this.getRecipe(this.data.id_recipe);

          // this.consumableTable.item = data.recipe.medical_tools.map(arr => {
          //   return {
          //     id: arr._id,
          //     name: arr.name,
          //     goodsGiven: arr.quantity,
          //     unit: arr.packaging,
          //     drugs: arr.id_drugs,
          //     status: arr.isCreated,
          //   };
          // });

          this.medical_tools_price = data.recipe.medical_tools.map(arr => {
            return {
              isApproved: arr.isApproved,
              price: arr.price,
            };
          });

          this.recipe_price = data.recipe.recipe.map(arr => {
            return {
              isApproved: arr.isApproved,
              price: arr.price,
            };
          });

          this.mix_recipe_price = data.recipe.mix_recipe.map(arr => {
            return {
              isApproved: arr.isApproved,
              price: arr.price,
            };
          });

          const [history] = data.history;
          this.historyDrug = {
            mix_recipe: _.get(history, 'mix_recipe', []),
            recipe: _.get(history, 'recipe', []),
            timestamps: _.get(history, `timestamps.created_at`, ''),
            // history.timestamps !== undefined
            //   ? moment(history.timestamps.created_at)
            //       .locale('id')
            //       .format('ll')
            //   : '',
          };

          this.reviewDrug = {
            administrative: data.administrative,
            clinical: data.clinical,
            drugs_review: data.drugs_review,
            pharmaceuticals: data.pharmaceutical,
            id_staff: data.status[0].id_staff,
            type: data.status[0].type,
          };
        });
    },
    getSatuan(id, index) {
      axios
        .get(Constant.apiUrl.concat(`/master/drugs/${id}`))
        .then(response => {
          const { data } = response.data;
          this.nonRacikTable.item[index].unit_amount =
            data.detail.packaging.filter(item => item.isDefault === true)
              .length > 0
              ? data.detail.packaging.filter(item => item.isDefault === true)[0]
                  .package_unit
              : data.detail.packaging[0].package_unit;
        });
    },
    getRecipe(id) {
      axios
        .get(Constant.apiUrl.concat(`/patient/recipe/${id}/production`))
        .then(response => {
          const data = response.data.data;

          this.consumableTable.item = data.medical_tools.map(arr => {
            return {
              id: arr._id,
              name: arr.name,
              goodsGiven: arr.quantity,
              unit: arr.packaging,
              drugs: arr.id_drugs,
              status: arr.isCreated,
            };
          });

          this.racikTable.item =
            data.recipe !== null
              ? data.mix_recipe
                  .sort(
                    (a, b) =>
                      +a.name.replace(/^\D+/g, '') -
                      +b.name.replace(/^\D+/g, ''),
                  )
                  .map(mix => {
                    return {
                      id: mix._id,
                      name: mix.name,
                      drugs: mix.drugs,
                      usage: mix.usage,
                      expiry: mix.expiry,
                      isRacik: true,
                      isApproved: mix.isApproved,
                      drugQuantity: mix.drugs,
                      quantity: mix.quantity,
                      requested_quantity: mix.drugs,
                      packaging: mix.packaging,
                      description: mix.description,
                      general_rule: mix.general_rule,
                      recipe_number: mix.recipe_number,
                      date: moment(mix.date)
                        .locale('id')
                        .format('l'),
                      staff: mix.staff,
                      change_note: mix.change_note,
                      price: mix.price,
                      time_delivery: mix.time_delivery,
                      route: mix.route,
                      status: mix.isCreated,
                      preparation: mix.drugs,
                    };
                  })
              : '';

          this.nonRacikTable.item =
            data.recipe !== null
              ? data.recipe.map(nonMix => {
                  return {
                    id: nonMix._id,
                    isApproved: nonMix.isApproved,
                    drugs: nonMix.id_drugs,
                    expiry: nonMix.expiry,
                    preparation: nonMix.preparation,
                    dosage: `${nonMix.dosage} ${
                      nonMix.unit_dosage === '-' ? '' : nonMix.unit_dosage
                    }`,
                    unit_amount: nonMix.preparation,
                    quantity: nonMix.requested_quantity,
                    packaging: nonMix.packaging,
                    description: nonMix.description,
                    recipe_number: nonMix.recipe_number,
                    usage: nonMix.usage,
                    general_rule: nonMix.general_rule,
                    name: nonMix.name,
                    shownName: nonMix.id_drugs.name,
                    date: moment(nonMix.date)
                      .locale('id')
                      .format('l'),
                    staff: nonMix.staff,
                    isRacik: false,
                    change_note: nonMix.change_note,
                    price: nonMix.price,
                    time_delivery: nonMix.time_delivery,
                    route: nonMix.route,
                    drugTaken: nonMix.quantity,
                    status: nonMix.isCreated,
                  };
                })
              : '';
          this.drugList = this.racikTable.item
            .concat(this.nonRacikTable.item)
            .sort((a, b) => a.recipe_number - b.recipe_number);
          this.nonRacikTable.item.forEach((arr, index) => {
            this.getSatuan(arr.drugs._id, index);
          });
          const drugPrice = this.drugList.reduce((a, b) => a + b.price, 0);
          const medtoolPrice = data.medical_tools.reduce(
            (a, b) => a + b.price,
            0,
          );
          this.totalPrice = drugPrice + medtoolPrice;
        });
    },
    saveData() {
      const id_pharmacy = this.patient.id;
      // const id_recipe = this.data.id_recipe;
      // const id_consumable = this.data.id_consumable;

      const recipe = this.nonRacikTable.item.map(nonRacik => {
        return {
          _id: nonRacik.id,
          isApproved: true,
          isCreated: true,
          isDelivered: false,
          staff_create: this.$store.getters.userLoggedIn.name,
        };
      });

      const mix_recipe = this.racikTable.item.map(racik => {
        return {
          _id: racik.id,
          name: racik.name,
          isApproved: true,
          isCreated: true,
          isDelivered: false,
          staff_create: this.$store.getters.userLoggedIn.name,
        };
      });

      const medical_tools = this.consumableTable.item.map(alkes => {
        return {
          name: alkes.name,
          _id: alkes.id,
          isApproved: true,
          isCreated: true,
          isDelivered: false,
          staff_create: this.$store.getters.userLoggedIn.name,
        };
      });
      if (!this.isDrugsComplete) {
        Swal.fire({
          text:
            'Terdapat obat yang belum selesai dibuat, Apakah Anda yakin akan melanjutkan?',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Lanjutkan',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          icon: 'warning',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .put(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`), {
                status: {
                  type: 'handover',
                  id_staff: this.$store.getters.userLoggedIn.id,
                },
                patient_status: this.patient_status,
                recipe: recipe,
                mix_recipe: mix_recipe,
                medical_tools: medical_tools,
                id_staff: this.$store.getters.userLoggedIn.id,
              })
              .then(response => {
                if (response.status === 200 || response.status === 204) {
                  Swal.fire('Pembuatan Obat Selesai', '', 'success');
                  this.$emit('close-form');
                }
              })
              .catch(error => {
                if (error) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Pembuatan Obat belum berhasil tersimpan',
                  });
                }
              });
          }
        });
      } else {
        axios
          .put(Constant.apiUrl.concat(`/pharmacy/${id_pharmacy}`), {
            status: {
              type: 'handover',
              id_staff: this.$store.getters.userLoggedIn.id,
            },
            recipe: recipe,
            mix_recipe: mix_recipe,
            medical_tools: medical_tools,
            patient_status: this.patient_status,
            id_staff: this.$store.getters.userLoggedIn.id,
          })
          .then(response => {
            if (response.status === 200 || response.status === 204) {
              Swal.fire('Pembuatan Obat Selesai', '', 'success');
              this.$emit('close-form');
            }
          })
          .catch(error => {
            if (error) {
              Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: 'Pembuatan Obat belum berhasil tersimpan',
              });
            }
          });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPatient();
    });
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  position: fixed;
  top: 70px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
  padding-bottom: 15px;
}

.footer-sticky {
  width: 100%;
  position: fixed;
  bottom: -30px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
}

.after-sticky {
  margin-top: 150px;
}

.before-sticky {
  margin-bottom: 70px;
}

.header,
.header-2 {
  display: flex;
  list-style-type: none;
}

.detail {
  width: 100%;
  height: 100%;
  .container {
    max-width: 1280px;
  }
}

.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
  }
}
.body-container {
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.header-layout {
  margin: 0 !important;
  width: 100%;
  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: auto;
    color: #9aa0a4;
    cursor: pointer;
    &:hover {
      color: rgb(207, 15, 15);
    }
  }
  .heading_big {
    text-align: left;
    padding-right: 22px;
  }
  .row-info {
    height: 100%;
    .header-info-widget {
      .info-head {
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 0.9rem;
        // padding-right: 50px;
      }
      .label-head {
        text-align: left;
        font-size: 0.9rem;
        font-weight: normal;
        font-family: Roboto;
        color: #949a9d;
        font-style: normal;
        &.special-label {
          max-width: 400px;
          white-space: normal;
          padding-left: 10px;
          padding-right: 15px;
        }
        &.red-color {
          font-weight: normal;
          color: red;
        }
      }
    }
  }
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .v-data-table-header {
    background-color: #f4f5f5;
  }
}

.total-price {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  background-color: #f0f9ff;
}

.title {
  text-align: left;
  font-size: 16px !important;
  color: #137bc0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.center-width {
  margin: 0 auto;
  width: 95%;
}

.action-layout {
  display: flex;
  .action-footer {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .btn-create {
      margin: 0 20px;
      min-width: 145px;
      height: 37px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;
    }
  }
}
</style>
