<template>
  <v-card class="main-container">
    <v-form ref="form">
      <v-row class="d-flex justify-space-between mb-5">
        <div class="d-flex align-center">
          <label class="grey--text mr-2">No. RM</label>
          <label class="font-weight-bold mr-15">{{
            patientData.rmNumber
          }}</label>
          <label class="grey--text mr-2">Nama</label>
          <label class="font-weight-bold mr-15">{{
            patientData.meta.name
          }}</label>
          <label class="grey--text mr-2">Tanggal Lahir</label>
          <label class="font-weight-bold">{{
            patientData.meta.birthDate
          }}</label>
        </div>
        <div class="d-flex align-center">
          <v-progress-circular
            v-if="loading.data"
            size="20"
            width="2"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h3 class="ml-4">Pembuatan Informed Consent</h3>
          <v-btn class="ml-4" fab text @click="closeForm()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </v-row>
      <div class="main-body">
        <v-row>
          <v-col class="grid-layout">
            <label class="text-grey">Tanggal</label>
            <label class="text-black">{{ today }}</label>
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-divider class="mb-15"></v-divider>
        <v-row class="mb-3">
          <v-col class="py-0 ma-0 grid-layout">
            <!-- Signer input field -->
            <label class="text-grey">Yang Bertandatangan</label>
            <v-radio-group row class="mt-0 pt-0" v-model="input.signer">
              <v-radio
                class="text-black"
                label="Pasien"
                value="pasien"
              ></v-radio>
              <v-radio class="text-black" label="Wali" value="wali"></v-radio>
            </v-radio-group>
            <!-- patient name input field -->
            <label class="text-grey required"
              >Nama {{ input.signer === 'pasien' ? 'Pasien' : 'Wali' }}</label
            >
            <v-text-field
              :rules="rule.textfield"
              v-model="input.signerName"
              class="input-field"
              :placeholder="
                `Nama ${input.signer === 'pasien' ? 'Pasien' : 'Wali'}`
              "
            ></v-text-field>
            <template v-if="input.signer !== 'pasien'">
              <div></div>
              <v-combobox
                :items="resource.familyStatus"
                v-model="input.waliAs"
                class="input-field"
                :rules="rule.textfield"
                placeholder="Hubungan"
                append-icon="mdi-chevron-down"
              />
              <div class="required">Usia</div>
              <v-text-field
                :rules="rule.textfield"
                v-model="input.waliAge"
                class="input-field"
                placeholder="Usia"
              ></v-text-field>
            </template>
            <!-- phone number input field -->
            <label class="text-grey">No Hp</label>
            <v-text-field
              v-model="input.phoneNumber"
              class="input-field"
              placeholder="Nomor HP"
              @keydown="validateInput($event)"
              type="number"
            ></v-text-field>
            <!-- address input field -->
            <label class="text-grey">Alamat Lengkap</label>
            <v-textarea
              class="input-field"
              v-model="input.address"
              outlined
              no-resize
              placeholder="Alamat Lengkap"
            ></v-textarea>
          </v-col>
          <v-col class="py-0 ma-0 grid-layout">
            <!-- family member input field -->
            <label class="text-grey">Nama Keluarga Pasien</label>
            <v-text-field
              v-model="input.familyName"
              class="input-field"
              placeholder="Nama Keluarga Pasien"
            ></v-text-field>
            <div></div>
            <v-combobox
              :items="resource.familyStatus"
              v-model="input.familyAs"
              class="input-field"
              placeholder="Hubungan"
              append-icon="mdi-chevron-down"
            />
            <!-- doctor input field -->
            <label class="text-grey required">Dokter Pemberi Penjelasan</label>
            <v-combobox
              :loading="loading.staff"
              v-model="input.doctor"
              :rules="rule.combobox"
              class="input-field"
              :items="resource.staff"
              placeholder="Dokter Pemberi Penjelasan"
              append-icon="mdi-chevron-down"
            />
            <!-- staff input field -->
            <label class="text-grey">Petugas Pendamping</label>
            <v-combobox
              :loading="loading.staff"
              v-model="input.staff"
              item-text="text"
              class="input-field"
              :items="resource.staff"
              placeholder="Petugas Pendamping"
              append-icon="mdi-chevron-down"
            />
          </v-col>
        </v-row>
        <v-divider class="mb-15"></v-divider>
        <v-row>
          <v-col class="py-0 ma-0 grid-layout">
            <!-- action input field -->
            <label class="text-grey required"
              >Tindakan yang Akan Dilakukan</label
            >
            <v-combobox
              :search-input.sync="searchService"
              :rules="rule.combobox"
              :loading="loading.services"
              v-model="input.action"
              :items="resource.services"
              class="input-field"
              placeholder="Tindakan yang Akan Dilakukan"
              append-icon="mdi-chevron-down"
            />
            <template
              v-if="input.action?.text?.toLocaleLowerCase() === 'lain-lain'"
            >
              <div></div>
              <v-text-field
                :rules="rule.textfield"
                v-model="input.otherService"
                class="input-field"
                placeholder="Masukkan Tindakan Lain"
              ></v-text-field>
            </template>
          </v-col>
          <v-col class="py-0 ma-0 grid-layout">
            <!-- Signer input field -->
            <label class="text-grey pr-15"
              >Apakah Pasien Menyetujui Tindakan yang Akan Dilakukan?</label
            >
            <v-radio-group
              row
              class="mt-0 pt-0"
              v-model="input.patientAgreement"
            >
              <v-radio class="text-black" label="Ya" :value="true"></v-radio>
              <v-radio
                class="text-black"
                label="Tidak"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading.data"
          :disabled="disabled"
          class="text-none"
          outlined
          color="primary"
          elevation="0"
          width="auto"
          @click="printInformConsent()"
        >
          <!-- @click="printConsent"
          :disabled="disabled" -->
          <font-awesome-icon icon="print" />
          Print</v-btn
        >
        <v-btn
          @click="saveInformedConsent()"
          class="text-none"
          color="primary"
          elevation="0"
          width="auto"
          >Simpan</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Documents from '@/mixin/documents';
import AlertMixin from '@/mixin/alertMixin';
import PrintMixin from '@/mixin/printMixin';

const axios = require('axios');
const _ = require('lodash');
const moment = require('moment');
export default {
  name: 'InformedConsent',
  props: {
    socialData: {
      type: Object,
    },
    isEmr: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [AlertMixin, Documents, PrintMixin],
  data() {
    return {
      searchService: '',
      date: '',
      disabled: false,
      loading: {
        data: false,
        saveButton: false,
        staff: false,
        services: false,
      },
      rule: {
        textfield: [v => !!v || 'Wajib diisi'],
        combobox: [v => !!v?.text || 'Wajib diisi'],
      },
      resource: {
        services: [],
        staff: [],
        familyStatus: [
          'Ayah',
          'Ibu',
          'Anak',
          'Saudara Kandung',
          'Suami',
          'Istri',
          'Bibi',
          'Paman',
          'Kakek',
          'Nenek',
          'Cucu',
          'Cicit',
        ],
      },
      input: {
        signer: 'pasien',
        waliAge: '',
        rmNumber: '',
        age: '',
        otherService: '',
        signerName: '',
        waliAs: '',
        phoneNumber: '',
        address: '',
        familyName: '',
        familyAs: '',
        doctor: {
          text: '',
          id: '',
        },
        staff: {
          text: '',
          id: '',
        },
        action: {
          text: '',
          id: '',
        },
        patientAgreement: true,
      },
    };
  },
  watch: {
    searchService() {
      this.searchServiceData(this);
    },
  },
  computed: {
    patientData() {
      if (this.isEmr) {
        return this.socialData;
      } else {
        return this.$store.getters.selectedPatient;
      }
    },
    today() {
      return moment().format('DD/MM/YYYY');
    },
  },
  mounted() {
    this.getInformedConsentData();
    this.getService();
    this.getStaff();
  },
  methods: {
    searchServiceData: _.debounce(v => {
      v.getService(v.searchService);
    }, 600),
    closeForm() {
      this.$emit('close-form');
    },
    validateInput(evt) {
      return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
    },
    printInformConsent() {
      if (this.$refs.form.validate()) {
        const doc = this.generateInformedConsentDoc(
          this.input,
          this.patientData,
        );
        this.printDocument(doc);
      }
    },
    async getInformedConsentData() {
      this.loading.data = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/document/inform-consent/${this.patientData.id_registration}`,
          ),
        );
        const { data } = response.data;
        this.input = {
          ...this.input,
          text: data.masterdata,
          rmNumber: data.detail.rmNumber,
          bpjsNumber: data.detail.bpjsNumber,
          age: data.detail.age,
          signerName: data.detail.name,
          waliAge: data.detail.signer_as_age,
          signer: data.detail.signer,
          waliAs: data.detail.signer_as,
          phoneNumber: data.detail.no_telp,
          patientAgreement: data.detail.patient_action_agreement,
          familyName: data.detail.family_name,
          familyAs: data.detail.family_as,
          address: data.detail.address,
          otherService: !data.detail.action.id ? data.detail.action?.name : '',
          staff: {
            text: data.detail.staff_compation?.name || '',
            id: data.detail.staff_compation?.id || '',
          },
          doctor: {
            text: data.detail.doctor?.name,
            id: data.detail.doctor?.id,
          },
          action: {
            text: data.detail.action.id
              ? data.detail.action?.name
              : 'Lain-Lain',
            id: data.detail.action?.id,
          },
        };
        this.disabled = false;
      } catch (error) {
        this.disabled = true;
        this.input = {
          ...this.input,
          signer: 'pasien',
          signerName: this.patientData.meta.name,
          phoneNumber: this.patientData.meta.phoneNumber,
          address: this.patientData.meta.address,
        };
      } finally {
        this.loading.data = false;
      }
    },
    async saveInformedConsent() {
      if (this.$refs.form.validate()) {
        try {
          const payload = {
            id_emr: this.patientData.id_emr,
            id_registration: this.patientData.id_registration,
            detail: {
              signer: this.input.signer,
              signer_as: this.input.waliAs || '',
              signer_as_age: this.input.waliAge || null,
              signer_name: this.input.signerName,
              address: this.input.address,
              no_telp: this.input.phoneNumber,
              family_name: this.input.familyName,
              family_as: this.input.familyAs,
              doctor: this.input.doctor.id,
              staff_compation: this.input.staff.id || null,
              action: {
                id: this.input.action.id || null,
                name: !this.input.action.id
                  ? this.input.otherService
                  : this.input.action.text,
              },
              patient_action_agreement: this.input.patientAgreement,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          };
          const response = await axios.post(
            Constant.apiUrl.concat('/document/inform-consent'),
            payload,
          );
          if (response) {
            this.showFillSuccess(response.data.pesan);
            this.getInformedConsentData();
            this.$refs.form.resetValidation();
            this.$store.commit('changeSelectedPatient', {
              ...this.$store.getters.selectedPatient,
              isInformedConsentCreated: true,
            });
          }
        } catch (error) {
          this.showErrorAxios(error);
        }
      }
    },
    async getStaff() {
      this.loading.staff = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat('/master/staff'),
        );
        const { data } = response.data;
        this.resource.staff = data.map(staff => {
          return {
            text: staff.detail.name,
            id: staff._id,
          };
        });
      } catch {
        this.resource.staff = [];
      } finally {
        this.loading.staff = false;
      }
    },
    getService(keyword) {
      this.loading.services = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/service?page=&itemCount=15&sort=&search=${keyword ||
              ''}&category=`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.resource.services = data.map(service => {
            return {
              id: service._id.toString(),
              text: service.name,
            };
          });
          this.resource.services.unshift({
            id: '',
            text: 'Lain-lain',
          });
        })
        .catch(error => {
          if (error) {
            this.resource.services = [];
          }
        })
        .finally(() => {
          this.loading.services = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
  height: 90vh !important;
  padding: 1rem 3rem;

  .main-body {
    padding: 2rem 3rem;
    height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(214, 214, 214);
    }
    &::-webkit-scrollbar-thumb {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(146, 146, 146);
    }

    .grid-layout {
      display: grid;
      text-align: start;
      height: fit-content;
      width: 100%;
      row-gap: 0.5rem;
      grid-template-columns: 40% 60%;
      margin-bottom: 1rem;

      .input-field {
        width: 70%;
      }

      .text-grey {
        color: #616161;
      }
      .text-black {
        color: black;
      }
    }
  }
}

.required:after {
  content: ' *';
  color: red;
}
</style>
